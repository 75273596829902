import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const ProjectAssistantSchema = z.object({
  id: z.string().nanoid(),
  projectId: z.string().nanoid(),
  sessionId: z.string().nanoid(),
  name: z.string().max(255).nullable(),
  description: z.string().nullable(),
  sessionCreatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type ProjectAssistant = z.infer<typeof ProjectAssistantSchema>;

export const CreateProjectAssistantRequestSchema = z.object({
  projectId: z.string().nanoid(),
  name: z.string().max(255).nullable(),
  description: z.string().nullable(),
});

export type CreateProjectAssistantRequest = z.infer<
  typeof CreateProjectAssistantRequestSchema
>;

export const ListProjectAssistantsRequestSchema = z.object({
  projectAssistantIds: commaDelimitedList(
    z.string().nanoid().array(),
  ).optional(),
  projectIds: commaDelimitedList(z.string().nanoid().array()).optional(),
  teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
});

export type ListProjectAssistantsRequest = z.infer<
  typeof ListProjectAssistantsRequestSchema
>;

export const ListProjectAssistantsResponseSchema = z.array(
  ProjectAssistantSchema,
);

export type ListProjectAssistantsResponse = z.infer<
  typeof ListProjectAssistantsResponseSchema
>;

export const GetProjectAssistantUrlResponseSchema = z.object({
  url: z.string().url(),
});

export type GetProjectAssistantUrlResponse = z.infer<
  typeof GetProjectAssistantUrlResponseSchema
>;

export const UpdateProjectAssistantRequestSchema = z.object({
  name: z.string().max(255).nullable().optional(),
  description: z.string().nullable().optional(),
  shouldRegenerateSession: z.boolean().optional(),
});

export type UpdateProjectAssistantRequest = z.infer<
  typeof UpdateProjectAssistantRequestSchema
>;

import ArrowDownOutlined from '@ant-design/icons/ArrowDownOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { useQuery } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import Skeleton from 'antd/es/skeleton';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import uniqBy from 'lodash.uniqby';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { type ListTeamsRequest, ListTeamsResponseSchema } from '@mai/types';

import { apiClient } from '../queries';

import { useFetchTeamUsers } from '@queries/teams';
import { useSessionUserId } from '@utils/auth';

const useFetchTeams = (params: ListTeamsRequest, enabled?: boolean) => {
  return useQuery({
    queryKey: ['teams', params],
    queryFn: async () => {
      const teamsQuery = await apiClient.get('teams', {
        params,
      });
      return ListTeamsResponseSchema.parse(teamsQuery.data);
    },
    enabled,
  });
};

const StyledButton = styled(Button)`
  &.collapsed {
    & > span {
      display: none;
    }
    & > span:nth-of-type(1) {
      display: inline;
    }
  }
`;

const CompanySelector = ({ collapsed }: { collapsed: boolean }) => {
  const sessionUserId = useSessionUserId();

  const [teamSearch, setTeamSearch] = useState('');

  const { token } = theme.useToken();

  const { teamId } = useParams();

  const teamUsersQuery = useFetchTeamUsers(
    {
      userIds: sessionUserId ? [sessionUserId] : undefined,
    },
    {
      enabled: Boolean(sessionUserId),
    },
  );
  const teamIds = teamUsersQuery.data?.map((teamUser) => teamUser.teamId) ?? [];

  const teamsQuery = useFetchTeams({ teamIds }, teamIds.length > 0);

  const urlTeamsQuery = useFetchTeams(
    { teamIds: teamId ? [teamId] : [] },
    !!teamId,
  );

  if (!sessionUserId || teamsQuery.isLoading || teamUsersQuery.isLoading) {
    return (
      <Skeleton.Button
        active
        style={{
          width: '100%',
        }}
      />
    );
  }

  const teams = uniqBy(
    [...(teamsQuery.data ?? []), ...(urlTeamsQuery.data ?? [])],
    ({ id }) => id,
  );
  const selectedTeam = teams.find((team) => team.id === teamId);

  if (!teams.length) {
    return <Button type="primary">No teams</Button>;
  }

  const filteredTeams = teams
    .filter((team) =>
      team.name.toLowerCase().includes(teamSearch.toLowerCase()),
    )
    .splice(0, 5);

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: 'search',
            type: 'group',
            label: (
              <Input.Search
                allowClear
                placeholder="Search teams"
                loading={teamsQuery.isLoading}
                value={teamSearch}
                onChange={(event) => {
                  setTeamSearch(event.target.value);
                }}
              />
            ),
          },
          ...filteredTeams.map((team) => ({
            key: team.id,
            label: <Link to={`/team/${team.id}`}>{team.name}</Link>,
          })),
          {
            type: 'divider',
          },
          {
            type: 'item',
            key: 'manage-teams',
            label: (
              <Link
                to="/team/manage"
                style={{
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <SettingOutlined /> Manage Teams
              </Link>
            ),
          },
        ],
      }}
    >
      <StyledButton
        type="primary"
        icon={<TeamOutlined />}
        style={{
          borderRadius: token.borderRadiusLG,
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
        }}
        className={collapsed ? 'collapsed' : ''}
      >
        <Typography.Text
          ellipsis
          style={{
            color: token.colorTextLightSolid,
          }}
        >
          {selectedTeam?.name}
        </Typography.Text>
        <ArrowDownOutlined />
      </StyledButton>
    </Dropdown>
  );
};

export default CompanySelector;

import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useFetchTeams } from '@queries/teams';

const TeamSelect = <T extends string | string[]>({
  teamIds,
  allowWildcard = false,
  onChange,
  value,
  style,
}: {
  allowWildcard?: boolean;
  teamIds?: string[];
  value?: T;
  onChange?: (value: NonNullable<T>) => void;
  style?: React.CSSProperties;
}) => {
  const mode = Array.isArray(value) ? 'multiple' : undefined;
  const { token } = theme.useToken();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const queryEnabled = allowWildcard ? true : !!teamIds?.length;
  const teamsQuery = useFetchTeams(
    {
      teamIds,
      name: debouncedSearch ? debouncedSearch : undefined,
    },
    queryEnabled,
  );
  const teams = useMemo(() => teamsQuery.data ?? [], [teamsQuery.data]);

  useEffect(() => {
    if (teamsQuery.isSuccess && teams.length && !value) {
      onChange?.(teams[0].id as NonNullable<T>);
    }
  }, [teamsQuery.isSuccess, teams.length, onChange, teams, value]);

  return (
    <Select
      placeholder="Select a team"
      style={style}
      options={teams.map((team) => ({
        value: team.id,
        label: team.name,
      }))}
      mode={mode}
      maxTagCount={1}
      loading={teamsQuery.isLoading || debouncedSearch !== search}
      onSearch={setSearch}
      filterOption={false}
      value={value as string[]}
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <Tag closable={closable} onClose={onClose} color={token.colorPrimary}>
            <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
              {label}
            </Typography.Text>
          </Tag>
        );
      }}
      onChange={onChange as (value: string | string[]) => void}
    />
  );
};

export default TeamSelect;

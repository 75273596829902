import CopyOutlined from '@ant-design/icons/CopyOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Tabs from 'antd/es/tabs';
import Tag from 'antd/es/tag';
import { useSearchParams } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import TeamsTable from '@components/tables/TeamsTable';
import UsersTable from '@components/tables/UsersTable';
import { useSessionUserId } from '@utils/auth';

const AdminPage = () => {
  const { message } = App.useApp();
  const sessionUserId = useSessionUserId();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') ?? 'users';

  return (
    <ContentContainer>
      <ContentContainer.Header title="Admin Console" />
      <Tag style={{ width: 'fit-content' }}>
        {`Your Session User ID: ${sessionUserId}`}
        <Button
          size="small"
          type="text"
          icon={<CopyOutlined />}
          onClick={() => {
            void navigator.clipboard.writeText(sessionUserId ?? '');
            void message.success('Copied to clipboard');
          }}
        />
      </Tag>
      <Tabs
        activeKey={activeTab}
        onChange={(key) => {
          setSearchParams(
            (prev) => {
              prev.set('tab', key);
              return prev;
            },
            { replace: true },
          );
        }}
        items={[
          {
            key: 'users',
            label: 'Users',
            children: (
              <ContentContainer>
                <ContentContainer.Header title="Users" />
                <UsersTable />
              </ContentContainer>
            ),
          },
          {
            key: 'teams',
            label: 'Teams',
            children: (
              <ContentContainer>
                <ContentContainer.Header title="Teams" />
                <TeamsTable />
              </ContentContainer>
            ),
          },
        ]}
      />
    </ContentContainer>
  );
};

export default AdminPage;

import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Badge from 'antd/es/badge';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import { type InputRef } from 'antd/es/input';
import Select from 'antd/es/select';
import { type ColumnsType, type TableProps } from 'antd/es/table';
import theme from 'antd/es/theme';
import Tooltip from 'antd/es/tooltip';
import { useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Table from '@components/Table';
import TableHeader from '@components/TableHeader';
import { useFetchTeams } from '@queries/teams';
import { renderDate } from '@utils/dates';

type TeamRow = {
  teamId: string;
  name: string;
};

const TeamsTableHeader = ({
  dataSource,
  pageSize,
  page,
  isLoading,
}: {
  dataSource: TeamRow[];
  pageSize: string;
  page: number;
  isLoading: boolean;
}) => {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  return (
    <TableHeader>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div />
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
          }}
        >
          <Select
            options={[
              { label: '10', value: '10' },
              { label: '25', value: '25' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
            size="small"
            value={pageSize}
            suffixIcon={<>/ Page</>}
            onChange={(value) => {
              setSearchParams((prev) => {
                prev.set('pageSize', value);
                return prev;
              });
            }}
            style={{
              width: '5rem',
            }}
          />
          <Tooltip title="Previous Page">
            <Button
              size="small"
              disabled={page === 1}
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setSearchParams((prev) => {
                  prev.set('page', (page - 1).toString());
                  return prev;
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Next Page">
            <Button
              size="small"
              disabled={dataSource.length < parseInt(pageSize ?? '10', 10)}
              icon={<ArrowRightOutlined />}
              onClick={() => {
                setSearchParams((prev) => {
                  prev.set('page', (page + 1).toString());
                  return prev;
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Refresh">
            <Button
              size="small"
              icon={<SyncOutlined spin={isLoading} />}
              onClick={() => {
                void queryClient.invalidateQueries({
                  queryKey: ['teams'],
                });
              }}
            />
          </Tooltip>
        </div>
      </div>
    </TableHeader>
  );
};

const TeamsTable = ({
  size = 'middle',
}: {
  size?: 'large' | 'middle' | 'small';
}) => {
  const { token } = theme.useToken();

  const searchNameInputRef = useRef<InputRef>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize') ?? '50';
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const nameFilter = searchParams.get('name') ?? undefined;
  const [debouncedNameFilter] = useDebounce(nameFilter, 500);

  const teamsQuery = useFetchTeams(
    {
      pageSize: parseInt(pageSize, 10),
      page,
      orderBy:
        (searchParams.get('orderBy') as 'name' | 'createdAt' | null) ||
        undefined,
      orderDirection:
        (searchParams.get('orderDirection') as 'asc' | 'desc' | null) ||
        undefined,
      name: debouncedNameFilter || undefined,
    },
    true,
  );
  const teams = teamsQuery.data ?? [];

  const isLoading = teamsQuery.isLoading || debouncedNameFilter !== nameFilter;

  const columns: ColumnsType<TeamRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => {
        return <Link to={`/team/${row.teamId}`}>{name}</Link>;
      },
      filterIcon: () => (
        <Badge count={nameFilter ? 1 : 0} dot color={token.colorPrimary}>
          <SearchOutlined
            style={{ color: nameFilter ? token.colorPrimary : undefined }}
          />
        </Badge>
      ),
      filterDropdown: () => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchNameInputRef}
              value={nameFilter}
              placeholder="Search by name"
              onChange={(e) => {
                setSearchParams((prev) => {
                  if (!e.target.value) {
                    prev.delete('name');
                  } else {
                    prev.set('name', e.target.value);
                  }
                  return prev;
                });
              }}
              allowClear
            />
          </div>
        );
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchNameInputRef.current?.select();
          }, 100);
        }
      },
      sorter: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => renderDate(createdAt),
    },
  ];

  const dataSource = teams.map((team) => ({
    teamId: team.id,
    name: team.name,
    createdAt: team.createdAt,
  }));

  const handleTableChange: TableProps<TeamRow>['onChange'] = (
    _pagination,
    _filters,
    _sorter,
  ) => {
    setSearchParams((prev) => {
      const sorter = Array.isArray(_sorter) ? _sorter[0] : _sorter;
      if (!sorter.order) {
        prev.delete('orderBy');
        prev.delete('orderDirection');
      } else {
        prev.set('orderBy', sorter.field as string);
        prev.set('orderDirection', sorter.order === 'ascend' ? 'asc' : 'desc');
      }
      return prev;
    });
  };

  return (
    <>
      <TeamsTableHeader
        dataSource={dataSource}
        pageSize={pageSize}
        page={page}
        isLoading={isLoading}
      />
      <Table
        loading={isLoading}
        columns={columns}
        size={size}
        dataSource={dataSource}
        rowKey={(row) => row.teamId}
        pagination={false}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TeamsTable;

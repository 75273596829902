import { type ColumnsType } from 'antd/es/table';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';
import startCase from 'lodash.startcase';
import { Link } from 'react-router-dom';

import { type InsightRequestLink } from '@mai/types';

import Table from '@components/Table';
import { useDocumentsQuery } from '@queries/documents';
import { useInsightRequestsQuery } from '@queries/insight-requests';
import { renderDate } from '@utils/dates';

type InsightRequestRowType = {
  id: string;
  type: string;
  links: InsightRequestLink[];
  createdAt: string;
  latestRunStatus: string | null;
};

const InsightRequestsTable = ({ teamId }: { teamId: string }) => {
  const insightRequestsQuery = useInsightRequestsQuery({
    teamIds: [teamId],
    orderBy: 'createdAt',
    orderDirection: 'desc',
  });
  const insightRequests = insightRequestsQuery.data ?? [];

  const documentIds = Array.from(
    new Set(
      insightRequests.flatMap((request) =>
        request.links
          .filter(({ type }) => type === 'document')
          .map(({ id }) => id),
      ),
    ),
  );
  const documentsQuery = useDocumentsQuery(
    {
      documentIds: documentIds,
    },
    documentIds.length > 0,
  );
  const documents = documentsQuery.data ?? [];

  const columns: ColumnsType<InsightRequestRowType> = [
    {
      title: 'Name',
      dataIndex: 'type',
      render: (value, row) => {
        const document = documents.find(
          (doc) =>
            doc.id === row.links.find((link) => link.type === 'document')?.id,
        );
        if (document) {
          return (
            <Link to={`/team/${teamId}/insights/${row.id}`}>
              <Typography.Text>{`${startCase(value)}: `}</Typography.Text>
              <Typography.Text type="secondary">
                {document.title}
              </Typography.Text>
            </Link>
          );
        }
        return <Link to={`/team/${teamId}/insights/${row.id}`}>{value}</Link>;
      },
    },
    {
      title: 'Latest Status',
      dataIndex: 'latestRunStatus',
      width: '180px',
      align: 'center',
      render: (value) => {
        if (!value) {
          return '-';
        }
        const color = (value: string) => {
          switch (value) {
            case 'pending':
              return 'orange';
            case 'running':
              return 'blue';
            case 'completed':
              return 'green';
            case 'failed':
              return 'red';
            case 'cancelled':
              return 'gray';
          }
        };
        return <Tag color={color(value)}>{startCase(value)}</Tag>;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '180px',
      render: (value) => renderDate(value),
    },
  ];

  const dataSource: InsightRequestRowType[] = insightRequests.map(
    (request) => ({
      id: request.id,
      type: request.analysisType,
      createdAt: request.createdAt.toISOString(),
      links: request.links,
      latestRunStatus: request.latestRunStatus,
    }),
  );

  return (
    <Table
      loading={insightRequestsQuery.isLoading}
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      pagination={false}
    />
  );
};

export default InsightRequestsTable;

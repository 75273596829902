import MoonOutlined from '@ant-design/icons/MoonOutlined';
import SunOutlined from '@ant-design/icons/SunOutlined';
import Switch from 'antd/es/switch';
import { useContext } from 'react';
import styled from 'styled-components';

import { DarkLightModeContext } from '../App';

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ThemeSwitch = () => {
  const themeContext = useContext(DarkLightModeContext);
  return (
    <Container>
      <Switch
        checkedChildren={<MoonOutlined />}
        unCheckedChildren={<SunOutlined />}
        checked={themeContext.value === 'dark'}
        onChange={(checked) => {
          themeContext.setValue(checked ? 'dark' : 'light');
        }}
      />
    </Container>
  );
};

export default ThemeSwitch;

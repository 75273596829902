import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import SendOutlined from '@ant-design/icons/SendOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import App from 'antd/es/app';
import AutoComplete from 'antd/es/auto-complete';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Typography from 'antd/es/typography';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import CenterSpin from '@components/CenterSpin';
import DocumentSelect from '@components/DocumentSelect';
import MarkdownContent from '@components/MarkdownContent';
import { useDocumentsQuery } from '@queries/documents';
import {
  createWorkflowRunEvent,
  deleteWorkflowRunEvent,
  useWorkflowRunEventsQuery,
  useWorkflowRunQuery,
  useWorkflowRunStateQuery,
} from '@queries/workflows';

const ChatBubble = styled.div`
  background-color: ${({ theme }) => theme.colorBgElevated};
  border-radius: ${({ theme }) => theme.borderRadiusLG}px;
  padding: ${({ theme }) => theme.padding}px;
  margin-bottom: 0.5rem;
  max-width: 80%;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BubbleHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.marginSM}px;
  gap: 0.25rem;
`;

const BubbleIcon = styled.div`
  margin-right: 0.25rem;
`;

const ChatMessage = ({
  message,
}: {
  message: { role: string; content: string; workflowRunEventId: string | null };
}) => {
  return (
    <ChatBubble>
      <BubbleHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BubbleIcon>
            {message.role === 'assistant' ? (
              <RobotOutlined />
            ) : (
              <UserOutlined />
            )}
          </BubbleIcon>
          <Typography.Text strong>
            {message.role === 'assistant' ? 'Assistant' : 'You'}
          </Typography.Text>
        </div>
        {message.workflowRunEventId && (
          <Button
            size="small"
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              void deleteWorkflowRunEvent(message.workflowRunEventId!);
            }}
          />
        )}
      </BubbleHeader>
      <MarkdownContent content={message.content} />
    </ChatBubble>
  );
};

const InsightDocumentSelection = ({
  workflowRunId,
}: {
  workflowRunId: string;
}) => {
  const { message } = App.useApp();
  const workflowRunQuery = useWorkflowRunQuery(workflowRunId);
  const workflowRun = workflowRunQuery.data;
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  if (!workflowRun) {
    return <CenterSpin />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Typography.Text strong>Select Documents:</Typography.Text>
      <div style={{ display: 'flex', gap: 8 }}>
        <DocumentSelect
          teamIds={[workflowRun?.teamId]}
          onChange={setSelectedDocumentIds}
          value={selectedDocumentIds}
        />
        <Button
          onClick={async () => {
            try {
              await createWorkflowRunEvent({
                workflowRunId,
                eventType: 'documents_selected',
                eventData: { documentIds: selectedDocumentIds },
              });
            } catch (error) {
              void message.error('Failed to save document selection');
            }
          }}
          icon={<SendOutlined />}
        />
      </div>
    </div>
  );
};

const InsightTaskSelection = ({ workflowRunId }: { workflowRunId: string }) => {
  const { message } = App.useApp();
  const workflowRunStateQuery = useWorkflowRunStateQuery(workflowRunId);
  const workflowRunState = workflowRunStateQuery.data;
  const [selectedTaskLabel, setSelectedTaskLabel] = useState<string | null>(
    null,
  );

  const availableTasks = useMemo(() => {
    return workflowRunState?.availableTasks;
  }, [workflowRunState]);

  const selectedTask = useMemo(() => {
    return availableTasks?.find((task) => task.label === selectedTaskLabel);
  }, [availableTasks, selectedTaskLabel]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Typography.Text strong>
        What would you like to do with these documents?
      </Typography.Text>
      <div style={{ display: 'flex', gap: 8 }}>
        <Select
          options={availableTasks?.map((task) => ({
            label: task.label,
            value: task.label,
          }))}
          value={selectedTaskLabel}
          onChange={setSelectedTaskLabel}
          style={{ width: '100%' }}
        />
        <Button
          onClick={async () => {
            try {
              await createWorkflowRunEvent({
                workflowRunId,
                eventType: 'task_selected',
                eventData: { task: selectedTask },
              });
            } catch (error) {
              void message.error('Failed to save task selection');
            }
          }}
          icon={<SendOutlined />}
        />
      </div>
    </div>
  );
};

const InsightEventHistory = ({ workflowRunId }: { workflowRunId: string }) => {
  const workflowRunEventsQuery = useWorkflowRunEventsQuery({
    workflowRunIds: [workflowRunId],
    orderBy: 'createdAt',
    orderDirection: 'asc',
  });
  const workflowRunEvents = workflowRunEventsQuery.data;

  const workflowRunStateQuery = useWorkflowRunStateQuery(workflowRunId);
  const workflowRunState = workflowRunStateQuery.data;

  const documentsQuery = useDocumentsQuery(
    {
      documentIds: workflowRunState?.selectedDocumentIds,
    },
    !!workflowRunState?.selectedDocumentIds?.length,
  );
  const documents = documentsQuery.data;

  const bottomRef = useRef<HTMLDivElement>(null);

  const workflowRunQuery = useWorkflowRunQuery(workflowRunId);
  const workflowRun = workflowRunQuery.data;

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [workflowRunEvents]);

  if (!workflowRun) {
    return <CenterSpin />;
  }

  if (!workflowRunEvents?.length) {
    return <div>No events found</div>;
  }

  return (
    <EventHistoryWrapper>
      <ChatContainer>
        {workflowRunEvents
          .flatMap((item) => {
            const messages: {
              role: string;
              content: string;
              workflowRunEventId: string | null;
            }[] = [];

            switch (item.eventType) {
              case 'documents_selected': {
                messages.push({
                  role: 'assistant',
                  content:
                    'Please select the documents you would like to analyze',
                  workflowRunEventId: item.id,
                });
                messages.push({
                  role: 'user',
                  content: item.eventData.documentIds
                    .map(
                      (documentId) =>
                        `- ${documents?.find((d) => d.id === documentId)?.title}`,
                    )
                    .join('\n'),
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'task_selected': {
                messages.push({
                  role: 'assistant',
                  content: 'What would you like to do with these documents?',
                  workflowRunEventId: item.id,
                });
                messages.push({
                  role: 'user',
                  content: item.eventData.task.description,
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'question_answered': {
                messages.push({
                  role: 'assistant',
                  content: item.eventData.question,
                  workflowRunEventId: item.id,
                });
                messages.push({
                  role: 'user',
                  content: item.eventData.answer,
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'assistant_message_received': {
                messages.push({
                  role: 'assistant',
                  content: item.eventData.message,
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'guidance_message_received': {
                messages.push({
                  role: 'user',
                  content: item.eventData.message,
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'research_topic_generated': {
                messages.push({
                  role: 'assistant',
                  content: `Researching: ${item.eventData.topics.join(', ')}`,
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'research_report_generated': {
                messages.push({
                  role: 'assistant',
                  content: `Finished researching ${item.eventData.topic}`,
                  workflowRunEventId: item.id,
                });
                break;
              }
              case 'editor_task_generated':
              case 'output_revision_generated':
              case 'available_tasks_generated':
              case 'document_context_enriched':
              case 'question_asked':
                break;
            }

            return messages;
          })
          .map((message) => {
            return <ChatMessage message={message} />;
          })}
      </ChatContainer>
      <div ref={bottomRef} />
    </EventHistoryWrapper>
  );
};

const InsightQuestionAnswer = ({
  workflowRunId,
}: {
  workflowRunId: string;
}) => {
  const { message } = App.useApp();

  const workflowRunStateQuery = useWorkflowRunStateQuery(workflowRunId);
  const workflowRunState = workflowRunStateQuery.data;

  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

  const questionsWithoutAnswers = useMemo(() => {
    return workflowRunState?.questions?.filter((question) => !question.answer);
  }, [workflowRunState]);

  const nextQuestion = questionsWithoutAnswers?.[0];
  useEffect(() => {
    if (nextQuestion) {
      setSelectedAnswer(null);
    }
  }, [nextQuestion]);

  if (!nextQuestion) {
    return null;
  }

  return (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      <Typography.Text strong>{nextQuestion.question}</Typography.Text>
      <div style={{ display: 'flex', gap: 8 }}>
        <AutoComplete
          options={nextQuestion.suggestedOptions?.map((option) => ({
            label: option,
            value: option,
          }))}
          value={selectedAnswer}
          onChange={setSelectedAnswer}
          style={{ width: '100%' }}
        />
        <Button
          onClick={async () => {
            try {
              await createWorkflowRunEvent({
                workflowRunId,
                eventType: 'question_answered',
                eventData: {
                  question: nextQuestion.question,
                  answer: selectedAnswer,
                  suggestedOptions: nextQuestion.suggestedOptions,
                },
              });
            } catch (error) {
              void message.error('Failed to save question answer');
            } finally {
              setSelectedAnswer(null);
            }
          }}
          icon={<SendOutlined />}
        />
      </div>
    </div>
  );
};

const InsightGuidanceChat = ({ workflowRunId }: { workflowRunId: string }) => {
  const { message } = App.useApp();
  const [input, setInput] = useState<string>('');

  const onSubmit = async () => {
    try {
      await createWorkflowRunEvent({
        workflowRunId,
        eventType: 'guidance_message_received',
        eventData: { message: input },
      });
      setInput('');
    } catch (error) {
      void message.error('Failed to save guidance chat input');
    }
  };

  return (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 8 }}>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onPressEnter={onSubmit}
        />
        <Button onClick={onSubmit} icon={<SendOutlined />} />
      </div>
    </div>
  );
};

const InsightActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EventHistoryWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const CurrentActionWrapper = styled.div`
  flex-shrink: 0;
`;

const InsightProgressLabel = ({ progressLabel }: { progressLabel: string }) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: 8, width: '100%' }}
    >
      <SyncOutlined spin />
      <Typography.Text ellipsis>{progressLabel}</Typography.Text>
    </div>
  );
};

const InsightActions = ({
  workflowRunId,
  progressLabel,
}: {
  workflowRunId: string;
  progressLabel: string | null;
}) => {
  const workflowRunStateQuery = useWorkflowRunStateQuery(workflowRunId);
  const workflowRunState = workflowRunStateQuery.data;

  const currentAction = useMemo(() => {
    if (!workflowRunState) {
      return null;
    }

    // If no documents have been selected, prompt user to input documents
    if (!workflowRunState.selectedDocumentIds?.length) {
      return <InsightDocumentSelection workflowRunId={workflowRunId} />;
    }

    // If tasks have been generated but not selected, prompt user to select a task
    if (workflowRunState.availableTasks?.length && !workflowRunState.task) {
      return <InsightTaskSelection workflowRunId={workflowRunId} />;
    }

    // If there are questions without answers, prompt user to answer the questions
    if (workflowRunState.questions?.some((question) => !question.answer)) {
      return <InsightQuestionAnswer workflowRunId={workflowRunId} />;
    }

    // Use the progress label provided by the socket if present
    if (progressLabel && progressLabel !== 'Revised output') {
      return <InsightProgressLabel progressLabel={progressLabel} />;
    }

    // Else show generic guidance chat input
    return <InsightGuidanceChat workflowRunId={workflowRunId} />;
  }, [workflowRunId, workflowRunState, progressLabel]);

  if (!workflowRunState) {
    return <CenterSpin />;
  }

  return (
    <InsightActionsWrapper>
      <EventHistoryWrapper>
        <InsightEventHistory workflowRunId={workflowRunId} />
      </EventHistoryWrapper>
      <CurrentActionWrapper>
        <Divider />
        {currentAction}
      </CurrentActionWrapper>
    </InsightActionsWrapper>
  );
};

export default InsightActions;

import { type DarkLightModeContextValues } from '@/App';
import Button from 'antd/es/button';
import * as jose from 'jose';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CenterSpin from '@components/CenterSpin';
import ContentContainer from '@components/ContentContainer';
import Conversation from '@components/Conversation';
import {
  createConversation,
  useFetchConversations,
} from '@queries/conversations';
import { useProjectAssistantsQuery } from '@queries/project-assistants';
import { useProjectQuery } from '@queries/projects';
import { useSessionProjectId } from '@utils/auth';
import { logger } from '@utils/logger';
import { useLocalStorage } from '@utils/theme';

function useEmbedParameters() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (!token) {
    return {};
  }

  const payload = jose.decodeJwt<{
    sessionId: string;
    projectAssistantId: string;
    token: string;
  }>(token);

  return {
    sessionId: payload.sessionId,
    projectAssistantId: payload.projectAssistantId,
    token: payload.token,
  };
}

const EmbedConversationPage = () => {
  // Force theme to light
  const [themeContextValue, setThemeContextValue] =
    useLocalStorage<DarkLightModeContextValues>('theme', 'light');
  useEffect(() => {
    if (themeContextValue !== 'light') {
      setThemeContextValue('light');
    }
  }, [setThemeContextValue, themeContextValue]);

  // Random state variables
  const [isCreatingConversation, setIsCreatingConversation] = useState(false);
  const [searchParams] = useSearchParams();
  const externalId = searchParams.get('externalId');

  // Describe the embed session
  const sessionProjectId = useSessionProjectId();
  const { projectAssistantId } = useEmbedParameters();

  // Fetch the project
  const projectQuery = useProjectQuery(sessionProjectId);
  const project = projectQuery.data;

  // Fetch the project assistant
  const projectAssistantQuery = useProjectAssistantsQuery(
    {
      projectAssistantIds: projectAssistantId ? [projectAssistantId] : [],
    },
    !!projectAssistantId,
  );
  const projectAssistant = projectAssistantQuery.data?.[0];

  // Fetch the conversation projects
  const conversationsQuery = useFetchConversations(
    {
      projectIds: project ? [project.id] : [],
      projectAssistantIds: projectAssistantId ? [projectAssistantId] : [],
    },
    !!project && !!projectAssistant,
  );
  const conversations = conversationsQuery.data;
  const conversation = conversations?.[0];

  // Mask error or broken state with spinner
  if (
    projectQuery.isLoading ||
    conversationsQuery.isLoading ||
    projectAssistantQuery.isLoading
  ) {
    return <CenterSpin />;
  }

  // Mask error or broken state with spinner
  if (!project || !projectAssistant || !conversations) {
    return <ContentContainer.Error />;
  }

  // If no conversations have been created yet, we will do so!
  if (!conversation) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          loading={isCreatingConversation}
          onClick={async () => {
            setIsCreatingConversation(true);
            try {
              const newConversation = await createConversation({
                teamId: project.teamId,
                name: 'New conversation',
                projectId: project.id,
                projectAssistantId: projectAssistant.id,
                externalId: externalId ?? 'default',
              });
              logger.info({
                conversationId: newConversation.id,
              });
            } catch (error) {
              logger.error({
                error,
              });
            } finally {
              setIsCreatingConversation(false);
            }
          }}
        >
          Start New Conversation
        </Button>
      </div>
    );
  }

  return (
    <>
      {conversation && (
        <Conversation
          disableHeader
          disableReferenceLinks
          conversationId={conversation.id}
          teamId={conversation.teamId}
          projectId={project.id}
          userFullName={project.name}
        />
      )}
    </>
  );
};

export default EmbedConversationPage;

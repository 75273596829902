import { useQuery } from '@tanstack/react-query';
import Alert from 'antd/es/alert';
import mime from 'mime';

import { apiClient } from '@queries/index';

const IframeRenderer = ({ url }: { url: string }) => {
  return (
    <iframe
      src={url}
      style={{ width: '100%', height: '100%', minHeight: '800px' }}
      frameBorder="0"
    />
  );
};

const DocxRenderer = ({ documentId }: { documentId: string }) => {
  const renderedDocumentQuery = useQuery({
    queryKey: ['documents', documentId, 'render'],
    queryFn: async () => {
      const response = await apiClient.get(`/documents/${documentId}/render`);
      return response.data;
    },
  });

  const srcDoc = renderedDocumentQuery.data;

  return (
    <iframe
      srcDoc={srcDoc}
      style={{ width: '100%', height: '100%', minHeight: '800px' }}
      frameBorder="0"
    />
  );
};

const FileRenderer = ({
  documentId,
  url,
  fileType,
}: {
  documentId: string;
  url: string;
  fileType: string;
}) => {
  const fileExtension = mime.getExtension(fileType);
  if (!fileExtension) {
    return <Alert type="error" showIcon message="File type not supported" />;
  }
  if ((['pdf', 'txt'] as string[]).includes(fileExtension)) {
    return <IframeRenderer url={url} />;
  }
  if (
    fileType.includes(
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    )
  ) {
    return <DocxRenderer documentId={documentId} />;
  }
  return (
    <Alert
      type="warning"
      showIcon
      message="File type not yet supported"
      description={`We're sorry, but we don't yet support opening ${fileExtension?.toUpperCase()} files yet. Please download the file to view it.`}
    />
  );
};

export default FileRenderer;

import { useQuery } from '@tanstack/react-query';

import {
  type ListPlatformCreditsRequest,
  type ListPlatformCreditsResponse,
  ListPlatformCreditsResponseSchema,
  type TeamSubscriptionPlatformCreditsAggregate,
  TeamSubscriptionPlatformCreditsAggregateSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useFetchPlatformCredits = (
  teamId: string | undefined,
  params: ListPlatformCreditsRequest,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['platform-credits', teamId, params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListPlatformCreditsResponse>(
        `/teams/${teamId}/platform-credits`,
        {
          params,
        },
      );
      const validatedData = ListPlatformCreditsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch platform credits',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

export const useFetchTeamSubscriptionPlatformCreditAggregate = (
  teamId: string | undefined,
  params: ListPlatformCreditsRequest,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['platform-credit-aggregate', teamId, params],
    queryFn: async () => {
      const { data } =
        await apiClient.get<TeamSubscriptionPlatformCreditsAggregate>(
          `/teams/${teamId}/platform-credits/aggregate`,
          {
            params,
          },
        );
      const validatedData =
        TeamSubscriptionPlatformCreditsAggregateSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch platform credit aggregate',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

import { useQuery } from '@tanstack/react-query';

import {
  type CreateSearchRequestBody,
  type ListSearchRequestsQuery,
  type ListSearchRequestsResponse,
  ListSearchRequestsResponseSchema,
  type SearchRequest,
  SearchRequestSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useSearchRequestQuery = (searchRequestId?: string | null) => {
  return useQuery({
    queryKey: ['searchRequests', searchRequestId],
    queryFn: async () => {
      const { data } = await apiClient.get<SearchRequest>(
        `/search/${searchRequestId}`,
      );
      const validatedData = SearchRequestSchema.safeParse(data);
      if (!validatedData.success) {
        logger.warn(
          {
            errors: validatedData.error.errors,
          },
          'failed to validate search request results',
        );
        return data;
      } else {
        return validatedData.data;
      }
    },
    enabled: !!searchRequestId,
  });
};

export const useSearchRequestsQuery = (
  params: ListSearchRequestsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['searchRequests', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListSearchRequestsResponse>(
        '/search',
        {
          params,
        },
      );
      const validatedData = ListSearchRequestsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error(
          {
            errors: validatedData.error.errors,
          },
          'failed to validate search requests results',
        );
        return data;
      }
    },
    enabled,
  });
};

export const createSearchRequest = async (
  searchRequest: CreateSearchRequestBody,
) => {
  const { data } = await apiClient.post<SearchRequest>(
    '/search',
    searchRequest,
  );
  const validatedData = SearchRequestSchema.safeParse(data);
  if (validatedData.success) {
    return validatedData.data;
  } else {
    logger.error(
      {
        errors: validatedData.error.errors,
      },
      'failed to validate search request results',
    );
    return data;
  }
};

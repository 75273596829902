import Button from 'antd/es/button';
import Form from 'antd/es/form';

const FormSubmitButton = ({
  text,
  loading,
  disabled,
}: {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={disabled}
        style={{
          width: '100%',
        }}
      >
        {text ?? 'Submit'}
      </Button>
    </Form.Item>
  );
};

export default FormSubmitButton;

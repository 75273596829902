import { queryClient } from '@/globals';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';
import { useNavigate } from 'react-router-dom';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import Conversation from '@components/Conversation';
import {
  updateConversation,
  useFetchConversation,
  useFetchConversationMessages,
} from '@queries/conversations';
import { apiClient } from '@queries/index';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { track } from '@utils/mixpanel';
import { useConversationId, useTeamId } from '@utils/router';

const AssistantConversationPage = () => {
  const sessionUserId = useSessionUserId();

  const { message, modal } = App.useApp();
  const navigate = useNavigate();

  const teamIsActive = useTeamIsActive();

  const teamId = useTeamId();
  const conversationId = useConversationId();

  const conversationQuery = useFetchConversation(conversationId);
  const conversation = conversationQuery.data;

  const conversationMessagesQuery =
    useFetchConversationMessages(conversationId);

  if (conversationQuery.isLoading || conversationMessagesQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (!conversation || !teamId) {
    return <ContentContainer.NotFound />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title={
          conversation?.name ?? (
            <Skeleton
              active
              paragraph={false}
              style={{
                width: '10rem',
              }}
            />
          )
        }
        subtitle={
          <div
            style={{
              maxWidth: 'calc(100% - 2rem)',
            }}
          >
            <Typography.Paragraph
              type="secondary"
              ellipsis={{
                rows: 1,
                expandable: true,
              }}
            >
              {conversation?.description ?? 'No description'}
            </Typography.Paragraph>
            {!teamIsActive ? <ActiveTeamAlert teamId={teamId} /> : null}
          </div>
        }
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Assistant', to: `/team/${teamId}/assistant` },
          { label: conversation.name },
        ]}
        cta={
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'regenerate-conversation-title',
                  label: 'Generate Title',
                  icon: <ReloadOutlined />,
                  onClick: async () => {
                    const loadingMessage = message.loading(
                      'Regenerating conversation title...',
                    );
                    try {
                      await updateConversation(conversation.id, {
                        shouldRegenerateTitle: true,
                      });
                      await queryClient.invalidateQueries({
                        queryKey: ['conversation'],
                      });
                      void message.success('Requested title regeneration');
                      loadingMessage();
                    } catch (e) {
                      await message.error(
                        'Failed to regenerate title. Please try again later.',
                      );
                    }
                  },
                },
                {
                  key: 'delete-conversation',
                  label: 'Delete conversation',
                  icon: <DeleteOutlined />,
                  danger: true,
                  onClick: () =>
                    modal.confirm({
                      title: 'Delete conversation',
                      icon: null,
                      maskClosable: true,
                      content:
                        'Are you sure you want to delete this conversation?',
                      closable: true,
                      okButtonProps: {
                        danger: true,
                      },
                      onOk: async () => {
                        const loadingMessage = message.loading(
                          'Deleting conversation...',
                        );
                        try {
                          await apiClient.delete(
                            `/conversations/${conversationId}`,
                          );
                          void queryClient.invalidateQueries({
                            queryKey: ['conversations'],
                          });
                          void queryClient.invalidateQueries({
                            queryKey: ['conversation'],
                          });
                          void queryClient.invalidateQueries({
                            queryKey: ['conversationMessages'],
                          });
                          track('DELETED_CONVERSATION', {
                            conversationId,
                            sessionUserId,
                            teamId,
                          });
                          navigate(`/team/${teamId}/assistant`);
                          loadingMessage();
                          void message.success('Conversation deleted');
                        } catch (e) {
                          loadingMessage();
                          void message.error(
                            'Failed to delete conversation. Please try again later.',
                          );
                        }
                      },
                    }),
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />}></Button>
          </Dropdown>
        }
      />
      <Conversation conversationId={conversation.id} teamId={teamId} />
    </ContentContainer>
  );
};

export default AssistantConversationPage;

import Form from 'antd/es/form';
import Input from 'antd/es/input';

const FacetValueField = ({
  name,
  initialValue,
}: {
  name: unknown[];
  initialValue: string;
}) => {
  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: true,
          message: 'Please enter a value',
        },
      ]}
      style={{
        margin: 0,
      }}
    >
      <Input.TextArea
        size="small"
        placeholder="Please enter a description "
        rows={1}
      />
    </Form.Item>
  );
};

export default FacetValueField;

import Card from 'antd/es/card';
import Skeleton from 'antd/es/skeleton';
import Statistic from 'antd/es/statistic';
import Typography from 'antd/es/typography';
import { useMemo } from 'react';
import styled from 'styled-components';

import { useFetchTeamSubscriptionPlatformCreditAggregate } from '@queries/platform-credits';
import { useTeamSubscriptionsQuery } from '@queries/team-subscriptions';
import { formatPlatformCredits } from '@utils/money';

const ResponsiveCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 10rem;
`;

const CreditUsageCard = ({
  teamId,
  period,
}: {
  teamId: string;
  period: 'current' | 'previous' | 'all';
}) => {
  const teamSubscriptionQuery = useTeamSubscriptionsQuery({
    teamIds: [teamId],
  });
  const teamSubscription = teamSubscriptionQuery.data?.[0];

  const {
    billingPeriodStart,
    billingPeriodEnd,
    previousBillingPeriodStart,
    previousBillingPeriodEnd,
  } = useMemo(() => {
    if (!teamSubscription) {
      return {};
    }

    return {
      billingPeriodStart: teamSubscription.stripeProperties.billingPeriodStart,
      billingPeriodEnd: teamSubscription.stripeProperties.billingPeriodEnd,
      previousBillingPeriodStart:
        teamSubscription.stripeProperties.previousBillingPeriodStart,
      previousBillingPeriodEnd:
        teamSubscription.stripeProperties.previousBillingPeriodEnd,
    };
  }, [teamSubscription]);

  const filters = useMemo(() => {
    if (period === 'current') {
      return {
        createdAtGte: billingPeriodStart,
        createdAtLt: billingPeriodEnd,
      };
    } else if (period === 'previous') {
      return {
        createdAtGte: previousBillingPeriodStart,
        createdAtLt: previousBillingPeriodEnd,
      };
    }
    return {};
  }, [
    period,
    billingPeriodStart,
    billingPeriodEnd,
    previousBillingPeriodStart,
    previousBillingPeriodEnd,
  ]);

  const platformCreditAggregateQuery =
    useFetchTeamSubscriptionPlatformCreditAggregate(
      teamId,
      filters,
      !!teamId &&
        !!billingPeriodStart &&
        !!billingPeriodEnd &&
        !!previousBillingPeriodStart &&
        !!previousBillingPeriodEnd,
    );

  const statistic = useMemo(() => {
    if (!teamSubscription) {
      return <div>-</div>;
    } else if (period === 'current') {
      return (
        <Statistic
          value={formatPlatformCredits(
            platformCreditAggregateQuery.data?.creditsUsed || 0,
          )}
        />
      );
    } else {
      return (
        <Statistic
          value={formatPlatformCredits(
            platformCreditAggregateQuery.data?.creditsUsed || 0,
          )}
        />
      );
    }
  }, [
    teamSubscription,
    period,
    platformCreditAggregateQuery.data?.creditsUsed,
  ]);

  const title = useMemo(() => {
    if (period === 'current') {
      return 'Current Billing Period Usage';
    } else if (period === 'previous') {
      return 'Previous Billing Period Usage';
    }
    return 'All Time Usage';
  }, [period]);

  if (platformCreditAggregateQuery.isLoading) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }

  return (
    <ResponsiveCard>
      <Typography.Text>{title}</Typography.Text>
      {statistic}
    </ResponsiveCard>
  );
};

export default CreditUsageCard;

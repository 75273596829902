import App from 'antd/es/app';
import Select from 'antd/es/select';
import { useEffect } from 'react';

import { useWorkflowRunsQuery, useWorkflowsQuery } from '@queries/workflows';
import { renderDate } from '@utils/dates';

const WorkflowRunSelect = ({
  value,
  onChange,
  style,
}: {
  value?: string | null;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
}) => {
  const { message } = App.useApp();
  const workflowRunsQuery = useWorkflowRunsQuery({});

  const selectedWorkflowRun = workflowRunsQuery.data?.find(
    (workflowRun) => workflowRun.id === value,
  );

  const selectedWorkflowQuery = useWorkflowsQuery(
    {
      workflowIds: selectedWorkflowRun?.workflowId
        ? [selectedWorkflowRun.workflowId]
        : undefined,
    },
    !!selectedWorkflowRun?.workflowId,
  );
  const selectedWorkflow = selectedWorkflowQuery.data?.[0];

  useEffect(() => {
    if (workflowRunsQuery.isError) {
      void message.error(
        `Failed to load workflow runs: ${workflowRunsQuery.error.message}`,
      );
    }
  }, [workflowRunsQuery.isError, message, workflowRunsQuery.error?.message]);

  return (
    <Select
      options={workflowRunsQuery.data?.map((workflowRun) => ({
        label: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 8,
              alignItems: 'center',
            }}
          >
            <span>{selectedWorkflow?.name}</span>-
            <span>{renderDate(workflowRun.createdAt)}</span>
          </div>
        ),
        value: workflowRun.id,
      }))}
      value={value}
      onChange={onChange}
      loading={workflowRunsQuery.isLoading}
      placeholder="Select an existing workflow run..."
      style={style}
    />
  );
};

export default WorkflowRunSelect;

import { queryClient } from '@/globals';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import {
  type ColumnsType,
  type TableRowSelection,
} from 'antd/es/table/interface';
import Transfer from 'antd/es/transfer';
import { type TransferItem } from 'antd/es/transfer';
import uniqBy from 'lodash/uniqBy';
import { type Key, useState } from 'react';

import { type Project, type UpdateProject } from '@mai/types';

import CenterSpin from './CenterSpin';

import { useDocumentsQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { logger } from '@utils/logger';

const LinkDocumentsToProject = ({
  project,
  onSuccess,
}: {
  project: Project;
  onSuccess: () => void;
}) => {
  const { message } = App.useApp();
  const [isSaving, setIsSaving] = useState(false);

  const [targetDocumentIds, setTargetDocumentIds] = useState<string[]>([
    ...project.links.documents,
  ]);

  const documentsQuery = useDocumentsQuery(
    {
      teamIds: project?.teamId ? [project.teamId] : undefined,
    },
    !!project,
  );
  const teamDocuments = documentsQuery.data ?? [];

  const targetDocumentsQuery = useDocumentsQuery(
    {
      documentIds: targetDocumentIds,
    },
    targetDocumentIds.length > 0,
  );
  const targetDocuments = targetDocumentsQuery.data ?? [];

  const dataSource = uniqBy(
    [...teamDocuments, ...targetDocuments],
    ({ id }) => id,
  ).map(({ id, title }) => ({
    id,
    title,
  }));

  if (documentsQuery.isLoading) {
    return <CenterSpin />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <Transfer
        style={{
          width: '100%',
        }}
        disabled={isSaving}
        dataSource={dataSource}
        targetKeys={targetDocumentIds}
        rowKey={(row) => row.id}
        onChange={(targetKeys) => {
          setTargetDocumentIds(targetKeys as string[]);
        }}
      >
        {({
          filteredItems,
          onItemSelect,
          onItemSelectAll,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const columns: ColumnsType<TransferItem> = [
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
          ];
          const rowSelection: TableRowSelection<TransferItem> = {
            getCheckboxProps: () => ({ disabled: listDisabled }),
            onChange(selectedRowKeys) {
              onItemSelectAll(selectedRowKeys, 'replace');
            },
            selectedRowKeys: listSelectedKeys,
          };
          return (
            <Table<TransferItem>
              rowKey={(row) => row.id}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              pagination={false}
              size="small"
              scroll={{ y: 400 }}
              style={{ pointerEvents: listDisabled ? 'none' : undefined }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) {
                    return;
                  }
                  onItemSelect(
                    key as Key,
                    !listSelectedKeys.includes(key as Key),
                  );
                },
              })}
            />
          );
        }}
      </Transfer>
      <Button
        type="primary"
        onClick={async () => {
          setIsSaving(true);
          const loadingMessage = message.loading('Saving...');
          try {
            await apiClient.patch(`/projects/${project.id}`, {
              links: {
                documents: targetDocumentIds,
              },
            } satisfies UpdateProject);
            loadingMessage();
            void queryClient.invalidateQueries({
              queryKey: ['projects'],
            });
            void queryClient.invalidateQueries({
              queryKey: ['documents'],
            });
            void message.success('Saved');
            onSuccess();
          } catch (error) {
            loadingMessage();
            logger.warn(
              {
                error,
              },
              'failed to save project documents',
            );
            void message.error('Failed to save');
          } finally {
            setIsSaving(false);
          }
        }}
        disabled={
          isSaving ||
          (targetDocumentIds.length === project.links.documents.length &&
            targetDocumentIds.every((id) =>
              project.links.documents.includes(id),
            ))
        }
      >
        Save Changes
      </Button>
    </div>
  );
};

export default LinkDocumentsToProject;

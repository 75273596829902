import theme from 'antd/es/theme';
import { ThemeProvider } from 'styled-components';

const StyledComponentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { token } = theme.useToken();
  return <ThemeProvider theme={{ token }}>{children}</ThemeProvider>;
};

export default StyledComponentProvider;

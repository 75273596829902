import { Navigate } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import { useSessionUserId } from '@utils/auth';

/**
 * ProfilePage
 * Will redirect user to their profile page if they are logged in, otherwise shows a loading spinner until the user is logged in.
 */
const ProfilePage = () => {
  const sessionUserId = useSessionUserId();
  if (sessionUserId) {
    return <Navigate to={`/profile/${sessionUserId}`} replace />;
  }

  return <ContentContainer.Loading />;
};

export default ProfilePage;

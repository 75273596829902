import { type DocumentSummary } from '@mai/types';

import { BaseEntity, type EntityCallbacks } from './_BaseEntity';

import { apiClient } from '@queries/index';

export class Documents extends BaseEntity {
  static get queryKey(): string {
    return 'documents';
  }

  static async get(
    id: string | undefined,
    callbacks?: EntityCallbacks<DocumentSummary>,
  ): Promise<DocumentSummary | null> {
    if (!id) {
      void this.message.error('Failed to fetch document');
      callbacks?.onError?.();
      return null;
    }

    return this.handleRequest(
      this.queryClient.fetchQuery({
        queryKey: [this.queryKey, id],
        queryFn: async () => {
          const { data } = await apiClient.get<DocumentSummary>(
            `/documents/${id}`,
          );
          return data;
        },
      }),
      'Failed to fetch document',
      callbacks,
    );
  }

  static async resync(
    id: string | undefined,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    if (!id) {
      void this.message.error('Failed to resync document');
      callbacks?.onError?.();
      return;
    }

    return this.handleMutationRequest(
      apiClient.post(`/documents/${id}/enqueue`),
      'Document re-synced successfully.',
      'Failed to re-sync document',
      callbacks,
    );
  }

  static async resyncMany(
    ids: string[] | undefined,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    if (!ids) {
      void this.message.error('Failed to resync documents');
      callbacks?.onError?.();
      return;
    }

    return this.handleMutationRequest(
      apiClient.patch(`/documents`, { documentIds: ids, shouldProcess: true }),
      'Documents re-synced successfully.',
      'Failed to re-sync documents',
      callbacks,
    );
  }

  static async archive(
    id: string | undefined,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    if (!id) {
      void this.message.error('Failed to archive document');
      callbacks?.onError?.();
      return;
    }

    return this.handleMutationRequest(
      apiClient.patch(`/documents/${id}`, { archived: true }),
      'Document archived successfully.',
      'Failed to archive document',
      callbacks,
    );
  }

  static async unarchive(
    id: string | undefined,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    if (!id) {
      void this.message.error('Failed to unarchive document');
      callbacks?.onError?.();
      return;
    }

    return this.handleMutationRequest(
      apiClient.patch(`/documents/${id}`, { archived: false }),
      'Document unarchived successfully.',
      'Failed to unarchive document',
      callbacks,
    );
  }

  static async delete(
    id: string | undefined,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    if (!id) {
      void this.message.error('Failed to delete document');
      return;
    }

    return this.handleMutationRequest(
      apiClient.delete(`/documents/${id}`),
      'Document deleted successfully.',
      'Failed to delete document',
      callbacks,
    );
  }

  static async deleteMany(
    ids: string[] | undefined,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    if (!ids) {
      void this.message.error('Failed to delete documents');
      return;
    }

    return this.handleMutationRequest(
      apiClient.delete(`/documents`, { params: { documentIds: ids } }),
      'Documents deleted successfully.',
      'Failed to delete documents',
      callbacks,
    );
  }
}

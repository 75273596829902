import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import SendOutlined from '@ant-design/icons/SendOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import useMessage from 'antd/es/message/useMessage';
import Popconfirm from 'antd/es/popconfirm';
import { type ColumnsType } from 'antd/es/table';
import startCase from 'lodash.startcase';
import { useState } from 'react';

import ContentContainer from '../ContentContainer';
import Table from '../Table';

import { apiClient } from '@queries/index';
import { useTeamInvitesQuery } from '@queries/team-invites';
import { useFetchTeamUsers } from '@queries/teams';
import { useSessionUserId } from '@utils/auth';
import { useTeamId } from '@utils/router';

type TeamInviteRow = {
  id: string;
  email: string;
  role: 'MEMBER' | 'ADMIN';
};

const useHasDeleteInvitePermission = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();

  const teamUsersQuery = useFetchTeamUsers(
    {
      teamIds: teamId ? [teamId] : [],
      userIds: sessionUserId ? [sessionUserId] : [],
    },
    {
      enabled: !!teamId && !!sessionUserId,
    },
  );

  return teamUsersQuery.data?.some(({ role }) => role === 'ADMIN') ?? false;
};

const TeamInvitesTable = ({
  teamId,
  size,
}: {
  teamId: string;
  size?: 'large' | 'middle' | 'small';
}) => {
  const queryClient = useQueryClient();
  const [messageApi, messageContext] = useMessage();

  const [activeActionMenuRowId, setActiveActionMenuRowId] = useState<
    string | null
  >(null);

  const teamInvitesQuery = useTeamInvitesQuery({
    teamIds: [teamId],
  });

  const hasDeleteInvitePermission = useHasDeleteInvitePermission();

  if (teamInvitesQuery.isError) {
    return <ContentContainer.Error />;
  }

  const dataSource: TeamInviteRow[] =
    teamInvitesQuery.data?.map((teamInvite) => ({
      id: teamInvite.id,
      email: teamInvite.email,
      role: teamInvite.role,
    })) ?? [];

  const columns: ColumnsType<TeamInviteRow> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role: TeamInviteRow['role']) => startCase(role.toLowerCase()),
    },
    {
      key: 'actions',
      align: 'right',
      width: 65,
      render: (_: unknown, value: TeamInviteRow) => {
        return (
          <Dropdown
            open={activeActionMenuRowId === value.id}
            onOpenChange={(open) => {
              setActiveActionMenuRowId(open ? value.id : null);
            }}
            menu={{
              items: [
                {
                  key: 'resendInvite',
                  disabled: !hasDeleteInvitePermission,
                  label: (
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={async () => {
                        try {
                          await apiClient.patch(`/team-invites/${value.id}`, {
                            resend: true,
                          });
                          void messageApi.success('Invitation resent');
                        } catch (error) {
                          void messageApi.error('Failed to resend invitation');
                        }
                      }}
                    >
                      Resend Invitation
                    </Popconfirm>
                  ),
                  icon: <SendOutlined />,
                },
                {
                  key: 'delete',
                  disabled: !hasDeleteInvitePermission,
                  label: (
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={async () => {
                        try {
                          await apiClient.delete(`/team-invites/${value.id}`);
                          void messageApi.success('Invitation deleted');
                          void queryClient.invalidateQueries({
                            queryKey: ['team-invites'],
                          });
                        } catch (error) {
                          void messageApi.error('Failed to delete invitation');
                        }
                      }}
                    >
                      Delete
                    </Popconfirm>
                  ),
                  icon: <DeleteOutlined />,
                  danger: true,
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />}></Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {messageContext}
      <Table<TeamInviteRow>
        rowKey={(record) => record.id}
        pagination={false}
        scroll={dataSource.length ? { x: 'max-content' } : undefined}
        columns={columns}
        dataSource={dataSource}
        loading={teamInvitesQuery.isLoading}
        size={size}
      />
    </>
  );
};

export default TeamInvitesTable;

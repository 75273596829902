import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useProjectsQuery } from '@queries/projects';

const ProjectSelect = ({
  teamIds,
  projectIds,
  allowWildcard = false,
  onChange,
  value,
}: {
  teamIds?: string[];
  projectIds?: string[];
  allowWildcard?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
}) => {
  const { token } = theme.useToken();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const queryEnabled = allowWildcard
    ? true
    : !!teamIds?.length || !!projectIds?.length;
  const projectsQuery = useProjectsQuery(
    {
      teamIds,
      projectIds,
      name: debouncedSearch ? debouncedSearch : undefined,
    },
    queryEnabled,
  );
  const projects = projectsQuery.data ?? [];

  return (
    <Select
      placeholder="Select a project"
      options={projects.map((project) => ({
        value: project.id,
        label: project.name,
      }))}
      mode="multiple"
      maxTagCount={1}
      loading={projectsQuery.isLoading || debouncedSearch !== search}
      onSearch={setSearch}
      filterOption={false}
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <Tag closable={closable} onClose={onClose} color={token.colorPrimary}>
            <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
              {label}
            </Typography.Text>
          </Tag>
        );
      }}
    />
  );
};

export default ProjectSelect;

import App from 'antd/es/app';
import Divider from 'antd/es/divider';
import useMessage from 'antd/es/message/useMessage';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { apiClient } from '../../../../queries';

import AuthFooter from '@components/AuthFooter';
import UnauthCard from '@components/UnauthCard';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

let hasSentRegistrationVerification = false;

const RegisterVerification = () => {
  const [searchParams] = useSearchParams();
  const signedToken = searchParams.get('signedToken');
  const { message } = App.useApp();
  const [messageApi, messageContext] = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      if (!signedToken || hasSentRegistrationVerification) {
        return;
      }
      hasSentRegistrationVerification = true;
      setIsLoading(true);
      try {
        const { data } = await apiClient.post('auth/register/verify', {
          signedToken,
        });
        localStorage.setItem('sessionToken', data.token);
        void message.success('Account verified. You are now logged in.');
        track('VERIFIED_REGISTRATION', {
          sessionUserId: data.sessionUserId,
          email: data.email,
        });
        window.location.reload();
      } catch (e) {
        logger.error({
          message: 'Error verifying account',
          error: e,
        });
        void messageApi.error('An error occurred. Please try again.');
        setIsLoading(false);
      }
    })();
  }, [message, messageApi, navigate, signedToken]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <UnauthCard>
      {messageContext}
      <div>
        <Typography.Text>
          {
            'This registration link is either invalid, or has already been used. Please try logging in or contact support at '
          }
          <a href="mailto:support@moderately.ai">{'support@moderately.ai'}</a>
          {' if you think this is a mistake.'}
        </Typography.Text>
      </div>
      <Divider />
      <AuthFooter />
    </UnauthCard>
  );
};

export default RegisterVerification;

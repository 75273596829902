import ContentContainer from '@components/ContentContainer';
import TeamPlatformCreditsTable from '@components/tables/TeamPlatformCreditsTable';
import { useTeamId } from '@utils/router';

const BillingUsageHistoryPage = () => {
  const teamId = useTeamId();

  if (!teamId) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Usage History"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Plan & Usage',
            to: `/team/${teamId}/billing/overview`,
          },
          {
            label: 'Usage History',
            to: `/team/${teamId}/billing/usage-history`,
          },
        ]}
      />
      <TeamPlatformCreditsTable teamId={teamId} />
    </ContentContainer>
  );
};

export default BillingUsageHistoryPage;

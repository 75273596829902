import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Slider from 'antd/es/slider';
import React from 'react';
import styled from 'styled-components';

import DocumentSelect from '@components/DocumentSelect';
import ProjectSelect from '@components/ProjectSelect';
import TeamSelect from '@components/TeamSelect';

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ControlLabel = styled.div`
  font-weight: bold;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledSlider = styled(Slider)`
  flex: 1;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 65px;
`;

interface LayoutControlsProps {
  settings: {
    gravity: number;
    scalingRatio: number;
    slowDown: number;
    linLogMode: boolean;
    strongGravityMode: boolean;
  };
  onSettingChange: (key: string, value: number | boolean) => void;
  search: string;
  onSearchChange: (value: string) => void;
  documentIds: string[] | undefined;
  onDocumentIdsChange: (value: string[]) => void;
  projectIds: string[] | undefined;
  onProjectIdsChange: (value: string[]) => void;
  teamIds: string[] | undefined;
  onTeamIdsChange: (value: string[]) => void;
  depth: number;
  onDepthChange: (value: number) => void;
  relevance: number;
  onRelevanceChange: (value: number) => void;
  isLoading: boolean;
  rootLimit: number;
  onRootLimitChange: (value: number) => void;
  layoutType: 'forceAtlas2' | 'circular';
  onLayoutTypeChange: (value: 'forceAtlas2' | 'circular') => void;
}

const LayoutControls: React.FC<LayoutControlsProps> = ({
  settings,
  onSettingChange,
  search,
  onSearchChange,
  documentIds,
  onDocumentIdsChange,
  projectIds,
  onProjectIdsChange,
  teamIds,
  onTeamIdsChange,
  depth,
  onDepthChange,
  relevance,
  onRelevanceChange,
  isLoading,
  rootLimit,
  onRootLimitChange,
  layoutType,
  onLayoutTypeChange,
}) => {
  return (
    <ControlContainer>
      <ControlGroup>
        <ControlLabel>Search:</ControlLabel>
        <Input.Search
          placeholder="Search"
          value={search}
          loading={isLoading}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Layout Type:</ControlLabel>
        <Select
          value={layoutType}
          onChange={(value) =>
            onLayoutTypeChange(value as 'forceAtlas2' | 'circular')
          }
        >
          <Select.Option value="forceAtlas2">Forced Graph</Select.Option>
          <Select.Option value="circular">Circular</Select.Option>
        </Select>
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Documents:</ControlLabel>
        <DocumentSelect
          allowWildcard
          value={documentIds ?? []}
          onChange={onDocumentIdsChange}
        />
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Projects:</ControlLabel>
        <ProjectSelect
          allowWildcard
          value={projectIds ?? []}
          onChange={onProjectIdsChange}
        />
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Teams:</ControlLabel>
        <TeamSelect
          allowWildcard
          value={teamIds ?? []}
          onChange={onTeamIdsChange}
        />
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Depth:</ControlLabel>
        <SliderContainer>
          <StyledSlider
            min={0}
            max={3}
            step={1}
            value={depth}
            onChange={(value) => onDepthChange(value as number)}
          />
          <StyledInputNumber
            min={1}
            max={10}
            step={1}
            value={depth}
            onChange={(value) => onDepthChange(value as number)}
          />
        </SliderContainer>
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Relevance:</ControlLabel>
        <SliderContainer>
          <StyledSlider
            min={0}
            max={1}
            step={0.01}
            value={relevance}
            onChange={(value) => onRelevanceChange(value as number)}
          />
          <StyledInputNumber
            min={0}
            max={1}
            step={0.01}
            value={relevance}
            onChange={(value) => onRelevanceChange(value as number)}
          />
        </SliderContainer>
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Root Limit:</ControlLabel>
        <SliderContainer>
          <StyledSlider
            min={1}
            max={50}
            step={1}
            value={rootLimit}
            onChange={(value) => onRootLimitChange(value as number)}
          />
          <StyledInputNumber
            min={1}
            max={50}
            step={1}
            value={rootLimit}
            onChange={(value) => onRootLimitChange(value as number)}
          />
        </SliderContainer>
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Gravity:</ControlLabel>
        <SliderContainer>
          <StyledSlider
            min={0}
            max={10}
            step={0.1}
            value={settings.gravity}
            onChange={(value) => onSettingChange('gravity', value)}
          />
          <StyledInputNumber
            min={0}
            max={10}
            step={0.1}
            value={settings.gravity}
            onChange={(value) => onSettingChange('gravity', value as number)}
          />
        </SliderContainer>
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Scaling Ratio:</ControlLabel>
        <SliderContainer>
          <StyledSlider
            min={0.1}
            max={10}
            step={0.1}
            value={settings.scalingRatio}
            onChange={(value) => onSettingChange('scalingRatio', value)}
          />
          <StyledInputNumber
            min={0.1}
            max={10}
            step={0.1}
            value={settings.scalingRatio}
            onChange={(value) =>
              onSettingChange('scalingRatio', value as number)
            }
          />
        </SliderContainer>
      </ControlGroup>

      <ControlGroup>
        <ControlLabel>Slow Down:</ControlLabel>
        <SliderContainer>
          <StyledSlider
            min={0.1}
            max={10}
            step={0.1}
            value={settings.slowDown}
            onChange={(value) => onSettingChange('slowDown', value)}
          />
          <StyledInputNumber
            min={0.1}
            max={10}
            step={0.1}
            value={settings.slowDown}
            onChange={(value) => onSettingChange('slowDown', value as number)}
          />
        </SliderContainer>
      </ControlGroup>
    </ControlContainer>
  );
};

export default LayoutControls;

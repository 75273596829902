import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import GoogleDriveLogo from '@assets/google-drive.svg';
import OneDriveLogo from '@assets/onedrive-1.svg';
import ContentContainer from '@components/ContentContainer';

type Integration = {
  name: string;
  description: string;
  image: React.ReactNode;
};

const IntegrationTile = ({ integration }: { integration: Integration }) => {
  return (
    <Card
      style={{
        display: 'flex',
        maxWidth: '350px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '280px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '150px',
          }}
        >
          {integration.image}
        </div>
        <Typography.Title level={4}>{integration.name}</Typography.Title>
        <Typography.Paragraph>{integration.description}</Typography.Paragraph>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button disabled type="primary">
          Coming Soon
        </Button>
      </div>
    </Card>
  );
};

const Integrations = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const integrations: Integration[] = useMemo(() => {
    return [
      {
        name: 'Google Drive',
        description: 'Connect your Google Drive account to your team',
        image: (
          <img
            src={GoogleDriveLogo}
            alt="Google Drive"
            style={{
              width: '40%',
            }}
          />
        ),
      },
      {
        name: 'Microsoft OneDrive',
        description: 'Connect your Microsoft OneDrive account to your team',
        image: (
          <img
            src={OneDriveLogo}
            alt="Microsoft OneDrive"
            style={{
              width: '70%',
            }}
          />
        ),
      },
    ];
  }, []);

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Integrations"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Integrations',
          },
        ]}
      />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '1rem',
        }}
      >
        {integrations.map((integration) => (
          <IntegrationTile key={integration.name} integration={integration} />
        ))}
      </div>
    </ContentContainer>
  );
};

export default Integrations;

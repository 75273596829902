import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';
import uniqBy from 'lodash.uniqby';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useDocumentsQuery } from '@queries/documents';

const DocumentSelect = <T extends string | string[]>({
  teamIds,
  projectIds,
  documentIds,
  allowWildcard = false,
  value,
  onChange,
  size = 'middle',
  style,
}: {
  teamIds?: string[];
  projectIds?: string[];
  documentIds?: string[];
  allowWildcard?: boolean;
  size?: 'large' | 'middle' | 'small';
  style?: React.CSSProperties;
  value: T | null;
  onChange: (value: T) => void;
}) => {
  const mode = Array.isArray(value) ? 'multiple' : undefined;

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const queryEnabled = allowWildcard
    ? true
    : !!teamIds?.length || !!projectIds?.length || !!documentIds?.length;
  const documentsQuery = useDocumentsQuery(
    {
      teamIds,
      projectIds,
      documentIds,
      title: debouncedSearch ? debouncedSearch : undefined,
    },
    queryEnabled,
  );

  const selectedDocumentsQuery = useDocumentsQuery(
    {
      documentIds: value ? (Array.isArray(value) ? value : [value]) : undefined,
    },
    (value?.length ?? 0) > 0,
  );

  const documents = useMemo(() => {
    return uniqBy(
      [...(documentsQuery.data ?? []), ...(selectedDocumentsQuery.data ?? [])],
      (document) => document.id,
    );
  }, [documentsQuery.data, selectedDocumentsQuery.data]);

  return (
    <Select
      allowClear
      placeholder="Select a document"
      size={size}
      options={documents.map((document) => ({
        value: document.id,
        label: document.title,
      }))}
      mode={mode}
      maxTagCount={1}
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <Tag closable={closable} onClose={onClose} color="purple">
            <Typography.Text
              ellipsis
              style={{ maxWidth: '120px', fontSize: '0.7rem' }}
            >
              {label}
            </Typography.Text>
          </Tag>
        );
      }}
      loading={documentsQuery.isLoading || debouncedSearch !== search}
      onSearch={setSearch}
      filterOption={false}
      style={{ width: '100%', ...style }}
      value={value as string[]}
      onChange={onChange as (value: string[]) => void}
    />
  );
};

export default DocumentSelect;

import FullscreenExitOutlined from '@ant-design/icons/FullscreenExitOutlined';
import FullscreenOutlined from '@ant-design/icons/FullscreenOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import Breadcrumb from 'antd/es/breadcrumb';
import Button from 'antd/es/button';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NotFound from './NotFound';

import ErrorImage from '@assets/undraw_bug_fixing.svg';
import { usePersistentState } from '@utils/hooks';

const MAX_WIDTH = 1000;

const Outer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  .fullscreenButton {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    z-index: 1;
  }
`;

const Inner = styled.div<{
  $maxWidth: string;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding: 1rem;
  overflow: scroll;
  max-width: ${(props) => props.$maxWidth};
  margin: 0 auto;
  position: relative;
`;

const BreadcrumbSkeleton = styled(Skeleton)`
  width: 100px;
`;

const ButtonSkeleton = styled(Skeleton.Button)`
  width: 100px;
`;

const TitleSkeleton = styled(Skeleton)`
  width: 200px;
`;

const ContentContainer = ({
  children,
}: {
  children: React.ReactNode;
  showFullWidthToggle?: boolean;
}) => {
  const [showFullWidth, setShowFullWidth] = usePersistentState(
    'showFullWidth',
    false,
  );
  const [shouldShowButton, setShouldShowButton] = useState(true);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (innerRef.current) {
        const clientWidth = innerRef.current.clientWidth;
        setShouldShowButton(clientWidth >= MAX_WIDTH);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Outer>
      {shouldShowButton ? (
        <Tooltip
          title={showFullWidth ? 'Exit full width' : 'Expand to full width'}
          placement="topRight"
        >
          <Button
            className="fullscreenButton"
            type="text"
            size="small"
            icon={
              showFullWidth ? (
                <FullscreenExitOutlined />
              ) : (
                <FullscreenOutlined />
              )
            }
            onClick={() => setShowFullWidth(!showFullWidth)}
          />
        </Tooltip>
      ) : null}
      <Inner
        ref={innerRef}
        $maxWidth={
          showFullWidth ? 'min(100%, calc(100vw - 4rem))' : `${MAX_WIDTH}px`
        }
      >
        {children}
      </Inner>
    </Outer>
  );
};

ContentContainer.Loading = () => {
  return (
    <ContentContainer>
      <ContentContainer.Header
        breadcrumbs={[
          { label: <BreadcrumbSkeleton title paragraph={false} active /> },
          { label: <BreadcrumbSkeleton title paragraph={false} active /> },
        ]}
        title={<TitleSkeleton title paragraph={false} active />}
        cta={<ButtonSkeleton active />}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Spin size="large" tip={'Loading...'}>
          <div
            style={{
              padding: 50,
              borderRadius: 4,
            }}
          />
        </Spin>
      </div>
    </ContentContainer>
  );
};

ContentContainer.Error = ({
  error,
  description,
}:
  | {
      error: Error | React.ReactNode;
      description: React.ReactNode;
    }
  | {
      error?: undefined;
      description?: undefined;
    }) => {
  const title =
    (error instanceof Error ? error.message : error) ??
    "We're sorry, there was a problem while loading this page.";
  return (
    <ContentContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <img src={ErrorImage} style={{ width: '50%', maxWidth: '300px' }} />
        <Typography.Title level={3}>{title}</Typography.Title>
        <Typography.Paragraph>
          {description ?? (
            <>
              Please try refreshing the page or email our support team{' '}
              <Typography.Text type="secondary">
                (support@moderately.ai)
              </Typography.Text>{' '}
              if the problem persists.
            </>
          )}
        </Typography.Paragraph>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            icon={<SyncOutlined />}
            type="primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>

          <Button
            onClick={() =>
              (window.location.href = 'mailto:support@moderately.ai')
            }
            icon={<MailOutlined />}
          >
            Contact support
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
};

ContentContainer.NotFound = () => {
  return (
    <ContentContainer>
      <NotFound />
    </ContentContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
`;

const Subtitle = styled.div`
  margin-top: 8px;
`;

ContentContainer.Header = ({
  breadcrumbs,
  title,
  subtitle,
  cta,
}: {
  breadcrumbs?: { label: ReactNode; to?: string }[];
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  cta?: React.ReactNode;
}) => {
  return (
    <Container>
      <Breadcrumb
        items={(breadcrumbs ?? []).map(({ label, to }, index) => ({
          key: index,
          title: (
            <div
              style={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {to ? <Link to={to}>{label}</Link> : label}
            </div>
          ),
        }))}
      />
      <Header>
        {typeof title === 'string' ? (
          <Typography.Title
            level={3}
            style={{
              marginBottom: '0px',
              marginTop: '0px',
              maxWidth: '60%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography.Title>
        ) : (
          title
        )}
        {cta}
      </Header>
      <Subtitle>
        {(subtitle && typeof subtitle === 'string' && (
          <Typography.Text>{subtitle}</Typography.Text>
        )) ||
          (subtitle && subtitle)}
      </Subtitle>
    </Container>
  );
};

export default ContentContainer;

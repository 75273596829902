import SendOutlined from '@ant-design/icons/SendOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import { type AxiosError } from 'axios';
import { useState } from 'react';

import { type ConversationMessageLink } from '@mai/types';

import { useFocus } from '@utils/hooks';

const ChatInput = ({
  disabled,
  onSubmit,
  links,
}: {
  disabled: boolean;
  onSubmit: ({
    value,
  }: {
    value: string;
    links: ConversationMessageLink[];
  }) => Promise<void>;
  links: ConversationMessageLink[];
}) => {
  const [value, setValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const { message } = App.useApp();

  const onClickSend = () => {
    setIsSubmitting(true);
    onSubmit({ value, links })
      .then(() => {
        setValue('');
      })
      .catch((e: AxiosError<{ error: string }>) => {
        const error = e.response?.data?.error;
        void message.error(
          `Error: ${error ?? 'Failed to generate message. Please try again later'}`,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setInputFocus();
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        width: '100%',
        maxWidth: '600px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <Input.TextArea
          ref={inputRef}
          autoFocus
          tabIndex={0}
          autoSize={{ minRows: 1, maxRows: 3 }}
          rows={1}
          placeholder="Type your message here..."
          size="large"
          disabled={disabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={(e) => {
            if (value.length > 0 && !e.shiftKey) {
              onClickSend();
              e.preventDefault(); // Prevent default behavior to avoid any additional handling by the browser
            }
          }}
        />
        <Button
          type="primary"
          size="large"
          shape="circle"
          loading={isSubmitting}
          disabled={!value.length || disabled}
          onClick={() => {
            if (value.length > 0) {
              onClickSend();
            }
          }}
        >
          {isSubmitting ? null : <SendOutlined />}
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography.Text type="secondary" style={{ fontSize: '0.7rem' }}>
          The assistant can make mistakes. Check important info.
        </Typography.Text>
      </div>
    </div>
  );
};

export default ChatInput;

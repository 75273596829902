import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';

const AuthFooter = () => {
  return (
    <Typography.Text>
      {'Already have an account? '}
      <Link to="/auth/login">{'Login'}</Link>
    </Typography.Text>
  );
};

export default AuthFooter;

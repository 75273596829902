import { useThemeConfig } from '@/hooks/theme';
import BulbOutlined from '@ant-design/icons/BulbOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import FolderOutlined from '@ant-design/icons/FolderOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/FundProjectionScreenOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import Divider from 'antd/es/divider';
import Menu from 'antd/es/menu';
import Tag from 'antd/es/tag';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CompanySelector from '@components/CompanySelector';

const StyledMenu = styled(Menu)<{
  $itemSelectedBg: string;
  $itemSelectedColor: string;
}>`
  .ant-menu-item-selected {
    background-color: ${(props) => props.$itemSelectedBg} !important;
    color: ${(props) => props.$itemSelectedColor} !important;
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background-color: ${(props) => props.$itemSelectedBg} !important;
    color: ${(props) => props.$itemSelectedColor} !important;
  }
`;

const NavMenu = ({
  collapsed,
  setCollapsed,
  isDrawer,
  setDrawerOpen,
}: {
  collapsed: boolean;
  setCollapsed: (value: boolean | ((prev: boolean) => boolean)) => void;
  isDrawer?: boolean;
  setDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { teamId } = useParams();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const themeConfig = useThemeConfig();

  const sidebarItems = useMemo(() => {
    return [
      {
        key: `/team/${teamId}`,
        label: <Link to={`/team/${teamId}`}>Home</Link>,
        icon: <HomeOutlined />,
      },
      {
        key: `/team/${teamId}/assistant`,
        label: <Link to={`/team/${teamId}/assistant`}>Assistant</Link>,
        icon: <MessageOutlined />,
      },
      {
        key: `/team/${teamId}/insights`,
        label: <Link to={`/team/${teamId}/insights`}>Insights</Link>,
        icon: <BulbOutlined />,
        isNew: true,
      },
      {
        key: `/team/${teamId}/analyze`,
        label: <Link to={`/team/${teamId}/analyze`}>Analyze</Link>,
        icon: <FundProjectionScreenOutlined />,
      },
      {
        key: `/team/${teamId}/search`,
        label: <Link to={`/team/${teamId}/search`}>Search</Link>,
        icon: <SearchOutlined />,
      },
      {
        key: `/team/${teamId}/projects`,
        label: <Link to={`/team/${teamId}/projects`}>Projects</Link>,
        icon: <FolderOutlined />,
      },
      {
        key: `/team/${teamId}/documents`,
        label: <Link to={`/team/${teamId}/documents/overview`}>Documents</Link>,
        icon: <FileOutlined />,
      },
      {
        key: `/team/${teamId}/billing`,
        label: (
          <Link to={`/team/${teamId}/billing/overview`}>Plan & Usage</Link>
        ),
        icon: <DollarOutlined />,
      },
      {
        key: `/team/${teamId}/manage`,
        label: <Link to={`/team/${teamId}/manage`}>Settings</Link>,
        icon: <SettingOutlined />,
      },
    ];
  }, [teamId]);

  useEffect(() => {
    // Ensures that the selected sidebar item's group is automatically expanded when the page loads
    const path = location.pathname;
    let activeKey = '';
    for (const item of sidebarItems) {
      const { key } = item;
      if (path.startsWith(key) && key.length > activeKey.length) {
        activeKey = key;
      }
    }
    if (activeKey && !collapsed) {
      setOpenKeys([activeKey]);
    }
  }, [location.pathname, sidebarItems, teamId, collapsed]);

  const selectedKey = useMemo(() => {
    const path = location.pathname;
    const interpolatedKeys = sidebarItems
      .flatMap((item) => [item.key])
      .map((key) => key.replace(':teamId', teamId ?? ''))
      .sort((a, b) => b.length - a.length);
    return interpolatedKeys.find((key) => path.startsWith(key));
  }, [location.pathname, sidebarItems, teamId]);

  const isSelectedGroup = (itemKey: string) => {
    return selectedKey?.startsWith(itemKey) && selectedKey !== itemKey;
  };

  const handleCollapseClick = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '0.5rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '0.25rem',
          marginRight: '0.25rem',
        }}
      >
        <CompanySelector collapsed={collapsed} />
      </div>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          overflowY: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <StyledMenu
          mode="inline"
          selectedKeys={selectedKey ? [selectedKey] : []}
          openKeys={openKeys}
          onOpenChange={(keys: string[]) => {
            setOpenKeys(keys);
          }}
          style={{
            borderInlineEnd: 'none',
            ...(isDrawer ? { background: 'unset' } : {}),
          }}
          onSelect={() => {
            setDrawerOpen?.(false);
          }}
          items={sidebarItems.map((item) => ({
            key: item.key,
            icon: item.icon,
            label: item.isNew ? (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                {item.label}
                <Tag
                  color="purple"
                  style={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    pointerEvents: 'none',
                  }}
                >
                  New
                </Tag>
              </div>
            ) : (
              item.label
            ),
            className: isSelectedGroup(item.key)
              ? 'ant-menu-submenu-selected'
              : '',
          }))}
          $itemSelectedBg={
            themeConfig.components?.Menu?.itemSelectedBg ?? 'black'
          }
          $itemSelectedColor={
            themeConfig.components?.Menu?.itemSelectedColor ?? 'white'
          }
        />
        {!isDrawer && (
          <div>
            <Divider />
            <StyledMenu
              mode="inline"
              style={{ borderInlineEnd: 'none' }}
              items={[
                {
                  key: 'collapse',
                  icon: collapsed ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  ),
                  label: collapsed ? 'Expand' : 'Collapse',
                  onClick: handleCollapseClick,
                },
              ]}
              selectable={false}
              $itemSelectedBg={
                themeConfig.components?.Menu?.itemSelectedBg ?? 'black'
              }
              $itemSelectedColor={
                themeConfig.components?.Menu?.itemSelectedColor ?? 'white'
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavMenu;

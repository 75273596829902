import Button from 'antd/es/button';
import { Link } from 'react-router-dom';

import ChatImage from '@assets/undraw_online_chat.svg';
import ActionCard from '@components/ActionCard';
import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import { Hero } from '@components/Hero';
import ActionCardsLayout from '@components/layouts/ActionCardsLayout';
import { useFetchConversations } from '@queries/conversations';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const AssistantPage = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();
  const teamIsActive = useTeamIsActive();

  const conversationsQuery = useFetchConversations(
    {
      teamIds: teamId ? [teamId] : [],
      createdBy: sessionUserId ?? undefined,
    },
    !!teamId && !!sessionUserId,
  );

  if (!teamId) {
    return <ContentContainer.Error />;
  }

  if (conversationsQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  const latestConversation = conversationsQuery.data?.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  )[0];

  return (
    <ContentContainer showFullWidthToggle>
      <ContentContainer.Header
        title="Assistant Overview"
        subtitle={
          teamIsActive ? undefined : <ActiveTeamAlert teamId={teamId} />
        }
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Assistant' },
        ]}
      />
      <Hero
        id="assistant-overview"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={ChatImage}
        title="Interact with your data and generate insights"
        extra={
          <div
            style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}
          >
            Learn more about how to save time with the Assistant today.
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <div>
                <Link to={`/team/${teamId}/assistant/new`}>
                  <Button
                    type="primary"
                    style={{
                      boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Start Conversation
                  </Button>
                </Link>
              </div>
              <div>
                <Link
                  to="https://scribehow.com/shared/Generating_Insights_for_Contract_Addendum_Modification__3NMIqzyVRYOYph7XgA9CgQ"
                  target="_blank"
                >
                  <Button
                    style={{
                      boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Learn More
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        }
      />
      <ActionCardsLayout>
        <ActionCard
          title="Start a Conversation"
          description="Get started with a new conversation to generate insights from your data."
          actionTitle="Create New Conversation"
          actionLink={`/team/${teamId}/assistant/new`}
        />
        <ActionCard
          title="View Past Conversations"
          description="Browse and continue your previous conversations."
          actionTitle="View Past Conversations"
          actionLink={`/team/${teamId}/assistant/history`}
        />
        {latestConversation && (
          <ActionCard
            title="View Latest Conversation"
            description={latestConversation?.name ?? 'No conversations yet'}
            actionTitle="View Latest Conversation"
            actionLink={`/team/${teamId}/assistant/${latestConversation?.id ?? ''}`}
          />
        )}
      </ActionCardsLayout>
    </ContentContainer>
  );
};

export default AssistantPage;

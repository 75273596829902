import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

/**
 * User email.
 * Contains the email value along with verification status
 */
export const UserEmailSchema = z.object({
  email: z.string(),
  verifiedAt: z.coerce.date().nullable(),
});

/**
 * User summary.
 * Contains minimal information, meant for lookup queries.
 */
export const UserSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.coerce.date(),
});

export type UserSummary = z.infer<typeof UserSummarySchema>;

/**
 * User details.
 * Contains extended information about a user.
 */
export const UserDetailSchema = UserSummarySchema.extend({
  emails: z.array(UserEmailSchema),
});

export type UserDetail = z.infer<typeof UserDetailSchema>;

/**
 * List users query schema
 * Contains query parameters for listing users.
 */
export const ListUsersQuerySchema = z
  .object({
    userIds: commaDelimitedList().optional(),
    name: z.string().optional(),
    emails: commaDelimitedList().optional(),
    teamIds: commaDelimitedList().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt', 'name'])));

export type ListUsersQuery = z.infer<typeof ListUsersQuerySchema>;

/**
 * List users response schema
 * Contains response schema for listing users.
 */
export const ListUsersResponseSchema = z.array(UserSummarySchema);

export type ListUsersResponse = z.infer<typeof ListUsersResponseSchema>;

/**
 * Get user by ID path schema
 */
export const GetUserRequestParamsSchema = z.object({
  userId: z.string(),
});

export type GetUserRequestParams = z.infer<typeof GetUserRequestParamsSchema>;

/**
 * Get user by ID response schema
 */
export const GetUserResponseSchema = UserDetailSchema;

export type GetUserResponse = z.infer<typeof GetUserResponseSchema>;

/**
 * Update user request schema
 */
export const UpdateUserBodySchema = z.object({
  name: z.string().optional(),
  password: z
    .object({
      oldPassword: z.string(),
      newPassword: z.string(),
    })
    .optional(),
  emails: z.string().array().min(1).optional(),
});

export type UpdateUserBody = z.infer<typeof UpdateUserBodySchema>;

export const ResetPasswordRequestSchema = z.object({
  email: z.string(),
});

export type ResetPasswordRequest = z.infer<typeof ResetPasswordRequestSchema>;

import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const Content = styled(Layout.Content)<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  display: flex;
  border-radius: ${(props) => props.$borderRadius}px 0
    ${(props) => props.$borderRadius}px ${(props) => props.$borderRadius}px;
  margin-top: 8px;
  margin-left: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

const AdminLayout = ({ children }: { children: ReactNode }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Content
        $backgroundColor={colorBgContainer}
        $borderRadius={borderRadiusLG}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default AdminLayout;

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import AntLayout from 'antd/es/layout';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Split from 'react-split';
import styled from 'styled-components';

import InsightActions from './InsightActions';
import InsightsContent from './InsightsContent';

import WorkflowRunSelect from '@components/WorkflowRunSelect';
import CreateWorkflowRunForm from '@components/forms/CreateWorkflowRunForm';
import { subscribe } from '@queries/websockets';
import {
  invalidateAllWorkflowQueries,
  updateWorkflowRun,
  useWorkflowRunStateQuery,
} from '@queries/workflows';

const { Content } = AntLayout;

const StyledLayout = styled(AntLayout)`
  height: calc(100vh - 180px);
  border: 1px solid ${({ theme }) => theme.token.colorBorderSecondary};
  border-radius: ${({ theme }) => theme.token.borderRadiusLG}px;
  overflow: hidden;
`;

const StyledContent = styled(Content)`
  display: flex;
  height: 100%;
`;

const StyledSplit = styled(Split)`
  display: flex;
  width: 100%;

  .gutter {
    background-color: ${({ theme }) => theme.token.colorBorderSecondary};
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .gutter.gutter-horizontal {
    cursor: col-resize;
  }
`;

const ActionsPanel = styled.div`
  padding: ${({ theme }) => theme.token.padding}px;
  overflow: auto;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const InsightsPanel = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.token.padding}px;
`;

const Layout: React.FC<{
  insights: React.ReactNode;
  actions: React.ReactNode;
}> = ({ insights, actions }) => (
  <StyledLayout>
    <StyledContent>
      <StyledSplit
        sizes={[33, 67]}
        minSize={200}
        expandToMin={false}
        gutterSize={4}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
      >
        <ActionsPanel>{actions}</ActionsPanel>
        <RightPanel>
          <InsightsPanel>{insights}</InsightsPanel>
        </RightPanel>
      </StyledSplit>
    </StyledContent>
  </StyledLayout>
);

const SEARCH_PARAM_PREFIX = 'labs.insights';

const Insights = () => {
  const { modal, message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSyncingWorkflowRun, setIsSyncingWorkflowRun] = useState(false);
  const [streamedOutput, setStreamedOutput] = useState<string | null>(null);
  const [progressLabel, setProgressLabel] = useState<string | null>(null);

  const workflowRunId = searchParams.get(
    `${SEARCH_PARAM_PREFIX}.workflowRunId`,
  );
  const setWorkflowRunId = (workflowRunId: string) => {
    setSearchParams((prev) => {
      prev.set(`${SEARCH_PARAM_PREFIX}.workflowRunId`, workflowRunId);
      return prev;
    });
  };

  const workflowRunStateQuery = useWorkflowRunStateQuery(workflowRunId);
  const workflowRunState = workflowRunStateQuery.data;

  useEffect(() => {
    if (!workflowRunId) return;
    subscribe('workflow_run_event', { workflowRunId }, (event) => {
      if (event.workflowRunId !== workflowRunId) return;
      if (event.eventType === 'information') {
        setProgressLabel(event.information ?? progressLabel);
        setTimeout(() => {
          void invalidateAllWorkflowQueries();
        }, 1000);
      } else if (event.eventType === 'outputChunk') {
        const { outputChunk } = event;
        if (outputChunk) {
          setStreamedOutput(outputChunk);
        }
      } else if (event.eventType === 'breakpoint') {
        setStreamedOutput(null);
        setProgressLabel(null);
      }
    });
  }, [message, progressLabel, workflowRunId]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
        <WorkflowRunSelect
          value={workflowRunId}
          onChange={setWorkflowRunId}
          style={{
            width: '100%',
          }}
        />
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            const { destroy } = modal.info({
              title: 'New Workflow Run',
              content: (
                <CreateWorkflowRunForm
                  onSuccess={(workflowRun) => {
                    setWorkflowRunId(workflowRun.id);
                    destroy();
                    void message.success('Workflow run created');
                  }}
                />
              ),
              closable: true,
              maskClosable: true,
              footer: null,
              icon: null,
            });
          }}
        />
        <Button
          icon={<SyncOutlined />}
          disabled={!workflowRunId}
          loading={isSyncingWorkflowRun}
          onClick={async () => {
            if (!workflowRunId) {
              return;
            }

            setIsSyncingWorkflowRun(true);
            const loading = message.loading('Syncing workflow run...');
            try {
              await updateWorkflowRun(workflowRunId, {
                shouldSync: true,
              });
              loading();
              void message.success('Workflow run synced');
            } catch (error) {
              loading();
              void message.error('Error syncing workflow run');
            } finally {
              setIsSyncingWorkflowRun(false);
            }
          }}
        />
        <Button
          onClick={() => {
            void modal.info({
              closable: true,
              maskClosable: true,
              footer: null,
              icon: null,
              width: '80%',
              content: (
                <div style={{ maxHeight: '500px', overflow: 'scroll' }}>
                  <pre>{JSON.stringify(workflowRunState, null, 2)}</pre>
                </div>
              ),
            });
          }}
        >
          Show History
        </Button>
      </div>
      {workflowRunId ? (
        <Layout
          insights={
            <InsightsContent
              workflowRunId={workflowRunId}
              streamedOutput={streamedOutput}
            />
          }
          actions={
            <InsightActions
              workflowRunId={workflowRunId}
              progressLabel={progressLabel}
            />
          }
        />
      ) : (
        <div>Select or create a workflow run to get started</div>
      )}
    </div>
  );
};

export default Insights;

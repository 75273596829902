import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * Determines the device's capabilities for machine learning tasks.
 */
export function useFetchCapabilities() {
  // Check if the host computer is running an Ollama instance
  const hasOllama = async () => {
    try {
      const response = await axios.get('http://localhost:11434');
      return response.status === 200;
    } catch (error) {
      return false;
    }
  };

  // Check if the browser supports WebGPU
  const hasWebGPU = async () => {
    try {
      const gpu = navigator.gpu;
      return gpu !== undefined;
    } catch (error) {
      return false;
    }
  };

  // Check if the browser supports Wasm
  const hasWasm = async () => {
    try {
      const wasm = typeof WebAssembly === 'object';
      return wasm;
    } catch (error) {
      return false;
    }
  };

  return useQuery({
    queryKey: ['capabilities'],
    queryFn: async () => {
      const [ollama, webGPU, wasm] = await Promise.all([
        hasOllama(),
        hasWebGPU(),
        hasWasm(),
      ]);
      return { ollama, webGPU, wasm };
    },
  });
}

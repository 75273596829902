import { queryClient } from '@/globals';
import { useEffect } from 'react';

import { usePersistentState } from './hooks';
import { track } from './mixpanel';

import { useAuthWhoami } from '@queries/auth';

export type SessionUserId = string & { __userIdBrand: never };

export type SessionProjectId = string & { __projectIdBrand: never };

export const useSessionProjectId = (): SessionProjectId | null => {
  const [projectContext, setProjectContext] =
    usePersistentState<SessionProjectId | null>('projectId', null);
  const authWhoamiQuery = useAuthWhoami();
  const projectId = authWhoamiQuery.data?.projectId as SessionProjectId | null;

  useEffect(() => {
    if (projectId && projectContext !== projectId) {
      setProjectContext(projectId);
    }

    if (!projectId && projectContext && !authWhoamiQuery.isLoading) {
      setProjectContext(null);
    }
  }, [authWhoamiQuery.isLoading, setProjectContext, projectContext, projectId]);

  return projectContext;
};

export const useSessionUserId = (): SessionUserId | null => {
  const [userContext, setUserContext] =
    usePersistentState<SessionUserId | null>('sessionUserId', null);
  const authWhoamiQuery = useAuthWhoami();
  const userId = authWhoamiQuery.data?.userId as SessionUserId | null;
  useEffect(() => {
    // If the user is logged in and the user context is not set, set it
    if (userId && userContext !== userId) {
      setUserContext(userId);
    }

    // If the user is not logged but there is a user context, clear it
    if (!userId && userContext && !authWhoamiQuery.isLoading) {
      setUserContext(null);
    }
  }, [authWhoamiQuery.isLoading, setUserContext, userContext, userId]);

  return userContext;
};

export const logout = () => {
  void queryClient.clear();
  track('LOGGED_OUT');
  window.location.href = '/';
};

import EditOutlined from '@ant-design/icons/EditOutlined';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import { Link } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import SectionContainer from '@components/SectionContainer';
import { useFetchTeam } from '@queries/teams';
import { useTeamId } from '@utils/router';

const ManageTeamGeneralPage = () => {
  const teamId = useTeamId();
  const teamQuery = useFetchTeam(teamId);
  const teamName = teamQuery.data?.name;

  if (!teamQuery.isSuccess) {
    return null;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Manage Team"
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Manage' },
        ]}
      />
      <SectionContainer
        title="Details"
        cta={
          <Link to={`/team/${teamId}/manage/general/edit`}>
            <Button size="small" type="primary" icon={<EditOutlined />}>
              Edit
            </Button>
          </Link>
        }
      >
        <Descriptions>
          <Descriptions.Item label="Team Name">{teamName}</Descriptions.Item>
        </Descriptions>
      </SectionContainer>
    </ContentContainer>
  );
};

export default ManageTeamGeneralPage;

import { z } from 'zod';

import { passwordRegex } from './regex';

// Schema for the response of the whoami endpoint
export const WhoamiResponseSchema = z.object({
  userId: z.string().nullable(),
  projectId: z.string().nullable(),
});

export type WhoamiResponse = z.infer<typeof WhoamiResponseSchema>;

// Schema for the login request body
export const LoginRequestBodySchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

export type LoginRequestBody = z.infer<typeof LoginRequestBodySchema>;

// Schema for the login response
export const LoginResponseSchema = z.object({
  token: z.string(),
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;

// Schema for the register request body
export const RegisterRequestBodySchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  password: z.string().min(8).regex(passwordRegex),
  subscribeToMarketing: z.boolean().optional(),
  acceptedTermsAndConditions: z.boolean(),
});

export type RegisterRequestBody = z.infer<typeof RegisterRequestBodySchema>;

// Schema for the register response
export const RegisterResponseSchema = z.object({
  accountRegistrationId: z.string(),
});

export type RegisterResponse = z.infer<typeof RegisterResponseSchema>;

// Schema for the register verification request body
export const RegisterVerificationRequestBodySchema = z.object({
  signedToken: z.string(),
});

export type RegisterVerificationRequestBody = z.infer<
  typeof RegisterVerificationRequestBodySchema
>;

// Schema for the register verification response
export const RegisterVerificationResponseSchema = z.object({
  token: z.string(),
});

export type RegisterVerificationResponse = z.infer<
  typeof RegisterVerificationResponseSchema
>;

import { useEffect, useRef, useState } from 'react';

import { logger } from './logger';

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useFocus: () => [
  React.RefObject<HTMLElement>,
  () => void,
] = () => {
  const htmlElRef = useRef<HTMLElement>(null);
  const setFocus = () =>
    setTimeout(() => {
      if (htmlElRef.current) {
        htmlElRef.current.focus();
      }
    }, 0);

  return [htmlElRef, setFocus] as [React.RefObject<HTMLElement>, () => void];
};

export function usePersistentState<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  const scopedKey = `persistentState-${key}`;

  const getValueFromLocalStorage = () => {
    const value = localStorage.getItem(scopedKey);
    if (!value) return initialValue;
    try {
      return JSON.parse(value);
    } catch {
      logger.error(
        {
          userScopedKey: scopedKey,
          value,
        },
        'Failed to parse persisted state value from localStorage',
      );
      return initialValue;
    }
  };

  const [state, setInternalState] = useState<T>(getValueFromLocalStorage());

  useEffect(() => {
    const value = localStorage.getItem(scopedKey);

    if (!value) return;

    try {
      setInternalState(JSON.parse(value));
    } catch {
      logger.error(
        {
          userScopedKey: scopedKey,
          value,
        },
        'Failed to parse persisted state value from localStorage',
      );
      setInternalState(initialValue);
    }
  }, [initialValue, scopedKey]);

  const setState = (value: T) => {
    localStorage.setItem(scopedKey, JSON.stringify(value));
    setInternalState(value);
  };

  return [state, setState];
}

/**
 * Reset the persistent state for a given key, keys, or all keys if no key is provided.
 */
export function resetPersistentState(key?: string | string[] | undefined) {
  if (!key) {
    const localStorageKeys = Object.keys(localStorage);
    localStorageKeys.forEach((k) => {
      if (k.startsWith('persistentState-')) {
        localStorage.removeItem(k);
      }
    });
  }

  if (Array.isArray(key)) {
    key.forEach((k) => {
      localStorage.removeItem(`persistentState-${k}`);
    });
    return;
  }

  localStorage.removeItem(`persistentState-${key}`);
}

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  type GetTeamResponseBody,
  GetTeamResponseBodySchema,
  type ListTeamUsersResponse,
  ListTeamUsersResponseSchema,
  type ListTeamsRequest,
  type ListTeamsResponse,
  ListTeamsResponseSchema,
} from '@mai/types';

import { type QueryOptions, apiClient } from '.';

import { logger } from '@utils/logger';

export const useFetchTeamUsers = (
  params: {
    userIds?: string[];
    teamIds?: string[];
  },
  options: QueryOptions = {},
) => {
  return useQuery({
    queryKey: ['team-users', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListTeamUsersResponse>(
        `/team-users`,
        {
          params,
        },
      );
      const validatedData = ListTeamUsersResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch team users',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled: options.enabled,
  });
};

export const useFetchTeam = (teamId?: string) => {
  return useQuery({
    queryKey: ['team', teamId],
    queryFn: async () => {
      const { data } = await apiClient.get<GetTeamResponseBody>(
        `/teams/${teamId}`,
      );
      const validatedData = GetTeamResponseBodySchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch team',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled: !!teamId,
    placeholderData: keepPreviousData,
  });
};

export const useFetchTeams = (params: ListTeamsRequest, enabled?: boolean) => {
  return useQuery({
    queryKey: ['teams', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListTeamsResponse>('/teams', {
        params,
      });
      const validatedData = ListTeamsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch teams',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

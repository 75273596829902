import Form from 'antd/es/form';
import Input from 'antd/es/input';

const FacetNameField = ({
  name,
  initialValue,
}: {
  name: unknown[];
  initialValue: string;
}) => {
  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: true,
          message: 'Please enter a name',
        },
        {
          min: 1,
          message: 'Name must be at least 1 character',
        },
        {
          max: 50,
          message: 'Name must be at most 50 characters',
        },
      ]}
      style={{
        margin: 0,
      }}
    >
      <Input placeholder="Please enter a name" />
    </Form.Item>
  );
};

export default FacetNameField;

import ContentContainer from '@components/ContentContainer';
import InviteTeamMemberButton from '@components/InviteTeamMemberButton';
import SectionContainer from '@components/SectionContainer';
import TeamInvitesTable from '@components/tables/TeamInvitesTable';
import TeamUsersTable from '@components/tables/TeamUsersTable';
import { useTeamId } from '@utils/router';

const ManageTeamUsersPage = () => {
  const teamId = useTeamId();

  if (!teamId) {
    return <ContentContainer.Error />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Team Members"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          { label: 'Team Members' },
        ]}
      />
      <SectionContainer
        title="Members"
        cta={<InviteTeamMemberButton teamId={teamId} buttonSize="small" />}
      >
        <TeamUsersTable teamId={teamId} />
      </SectionContainer>
      <SectionContainer title="Invitations">
        <TeamInvitesTable teamId={teamId} />
      </SectionContainer>
    </ContentContainer>
  );
};

export default ManageTeamUsersPage;

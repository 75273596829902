import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const AnalysisRequestRunEventTypeSchema = z.enum([
  'started',
  'completed',
  'failed',
  'cancelled',
]);

export type AnalysisRequestRunEventType = z.infer<
  typeof AnalysisRequestRunEventTypeSchema
>;

export const AnalysisRequestRunEventSchema = z.object({
  id: z.string(),
  analysisRequestRunId: z.string(),
  eventType: AnalysisRequestRunEventTypeSchema,
  eventData: z.unknown(),
  createdAt: z.coerce.date(),
});

export type AnalysisRequestRunEvent = z.infer<
  typeof AnalysisRequestRunEventSchema
>;

export const AnalysisRequestRunSchema = z.object({
  id: z.string(),
  analysisRequestId: z.string(),
  createdAt: z.coerce.date(),
  createdBy: z.string().nullable(),
  events: z.array(AnalysisRequestRunEventSchema),
});

export type AnalysisRequestRun = z.infer<typeof AnalysisRequestRunSchema>;

export const ListAnalysisRequestRunQuerySchema = z.object({
  analysisRequestRunIds: commaDelimitedList().optional(),
  analysisRequestIds: commaDelimitedList().optional(),
});

export type ListAnalysisRequestRunQuery = z.infer<
  typeof ListAnalysisRequestRunQuerySchema
>;

export const ListAnalysisRequestRunResponseSchema = z.array(
  AnalysisRequestRunSchema,
);

export type ListAnalysisRequestRunResponse = z.infer<
  typeof ListAnalysisRequestRunResponseSchema
>;

export const CreateAnalysisRequestRunBodySchema = z.object({
  analysisRequestId: z.string(),
  shouldRun: z.boolean().optional(),
});

export type CreateAnalysisRequestRunBody = z.infer<
  typeof CreateAnalysisRequestRunBodySchema
>;

export const CreateAnalysisRequestRunResponseSchema = AnalysisRequestRunSchema;

export type CreateAnalysisRequestRunResponse = z.infer<
  typeof CreateAnalysisRequestRunResponseSchema
>;

export const GetAnalysisRequestRunResponseSchema = AnalysisRequestRunSchema;

export type GetAnalysisRequestRunResponse = z.infer<
  typeof GetAnalysisRequestRunResponseSchema
>;

export const UpdateAnalysisRequestRunBodySchema = z.object({
  shouldCancel: z.boolean().optional(),
});

export type UpdateAnalysisRequestRunBody = z.infer<
  typeof UpdateAnalysisRequestRunBodySchema
>;

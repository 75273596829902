import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { type WhoamiResponse, WhoamiResponseSchema } from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useAuthWhoami = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['auth'],
    queryFn: async () => {
      try {
        const { data } = await apiClient.get('/auth/whoami');
        const validatedData = WhoamiResponseSchema.safeParse(data);
        if (validatedData.success) {
          return validatedData.data;
        } else {
          logger.warn({
            message: 'failed to validate whoami response',
            error: validatedData.error,
          });
          return data as WhoamiResponse;
        }
      } catch (error) {
        logger.error({
          message: 'Failed to fetch whoami',
          error,
        });
        // set a timeout to invalidate the data every 5 seconds if the user is not logged in
        // this is to ensure that we reconnect on failed connections
        setTimeout(() => {
          void queryClient.invalidateQueries({
            queryKey: ['auth'],
          });
        }, 5000);
        return {
          userId: null,
        } as WhoamiResponse;
      }
    },
    // Stale time is set to 5 minutes to ensure the user is logged in continually
    staleTime: 1000 * 60 * 5,
    // Keep previous data while fetching new data
    placeholderData: keepPreviousData,
  });
};

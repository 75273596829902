import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import { type ColumnProps } from 'antd/es/table';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import ContentContainer from '../ContentContainer';
import StackedDateTime from '../StackedDateTime';
import Table from '../Table';

import TableHeader from '@components/TableHeader';
import { useFetchConversations } from '@queries/conversations';
import { apiClient } from '@queries/index';
import { renderDate } from '@utils/dates';
import { logger } from '@utils/logger';

type ConversationRow = {
  id: string;
  name: string;
  description: string | null;
  createdAt: string;
};

const ConversationsTableHeader = ({
  selectedRowKeys,
  setSelectedRowKeys,
  dataSource,
}: {
  dataSource: ConversationRow[];
  selectedRowKeys: string[];
  setSelectedRowKeys: (keys: string[]) => void;
}) => {
  const { modal } = App.useApp();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1', 10);
  return (
    <TableHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>
          {selectedRowKeys.length ? (
            <Button
              danger
              size="small"
              onClick={() => {
                return modal.confirm({
                  maskClosable: true,
                  title:
                    'Are you sure you want to delete the selected conversations?',
                  onOk: async () => {
                    try {
                      await apiClient.delete(`/conversations`, {
                        data: {
                          conversationIds: selectedRowKeys,
                        },
                      });
                      await queryClient.invalidateQueries({
                        queryKey: ['conversations'],
                      });
                      setSelectedRowKeys([]);
                    } catch (e) {
                      logger.warn(
                        {
                          error:
                            e instanceof Error ? e.message : 'Unknown error',
                        },
                        'Failed to delete conversations',
                      );
                    }
                  },
                });
              }}
            >
              Delete Selected
            </Button>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <Button
            size="small"
            icon={<ArrowLeftOutlined />}
            disabled={page === 1}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', Math.max(page - 1, 1).toString());
                return prev;
              });
            }}
          />
          <Button
            size="small"
            disabled={dataSource.length < 10}
            icon={<ArrowRightOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page + 1).toString());
                return prev;
              });
            }}
          />
          <Button
            size="small"
            icon={<ReloadOutlined />}
            onClick={() => {
              void queryClient.invalidateQueries({
                queryKey: ['conversations'],
              });
            }}
          />
        </div>
      </div>
    </TableHeader>
  );
};

const ConversationsTable = ({
  teamId,
  createdBy,
  size = 'middle',
}: {
  teamId: string;
  createdBy: string;
  size?: 'small' | 'middle' | 'large';
}) => {
  const { modal } = App.useApp();
  const [searchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') ?? '1', 10);
  const pageSize = 10;

  const fetchConversationsQuery = useFetchConversations(
    {
      teamIds: [teamId],
      createdBy,
      page,
      pageSize,
    },
    !!teamId && !!createdBy,
  );
  const conversations = fetchConversationsQuery.data ?? [];

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  if (fetchConversationsQuery.isError) {
    return <ContentContainer.Error />;
  }

  const columns: ColumnProps<ConversationRow>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (name: string, record) => (
        <Link to={`/team/${teamId}/assistant/${record.id}`}>{name}</Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (createdAt: string) => {
        if (size === 'large') {
          return <StackedDateTime dateString={createdAt} />;
        }
        return renderDate(createdAt);
      },
    },
    {
      key: 'actions',
      width: 50,
      align: 'right',
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'view',
                  label: (
                    <Link to={`/team/${teamId}/assistant/${record.id}`}>
                      View
                    </Link>
                  ),
                },
                {
                  key: 'delete',
                  label: 'Delete',
                  danger: true,
                  onClick: () => {
                    return modal.confirm({
                      maskClosable: true,
                      title:
                        'Are you sure you want to delete this conversation?',
                      onOk: async () => {
                        try {
                          await apiClient.delete(`/conversations/${record.id}`);
                          await fetchConversationsQuery.refetch();
                        } catch (e) {
                          logger.warn(
                            {
                              error:
                                e instanceof Error
                                  ? e.message
                                  : 'Unknown error',
                            },
                            'Failed to delete conversation',
                          );
                        }
                      },
                    });
                  },
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const dataSource: ConversationRow[] = conversations.map((conversation) => ({
    id: conversation.id,
    name: conversation.name,
    description: conversation.description,
    createdAt: conversation.createdAt.toISOString(),
  }));

  return (
    <>
      <ConversationsTableHeader
        dataSource={dataSource}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <Table<ConversationRow>
        columns={columns}
        dataSource={dataSource}
        loading={fetchConversationsQuery.isLoading}
        rowKey={(record) => record.id}
        pagination={false}
        expandable={
          conversations.length > 0
            ? {
                expandedRowRender: (record) =>
                  record.description ?? 'No description',
              }
            : undefined
        }
        rowSelection={
          dataSource.length
            ? {
                type: 'checkbox',
                selectedRowKeys,
                onChange: (keys) => {
                  setSelectedRowKeys(keys as string[]);
                },
              }
            : undefined
        }
        size={size}
      />
    </>
  );
};

export default ConversationsTable;

import { queryClient } from '@/globals';
import { useQuery } from '@tanstack/react-query';

import {
  type CreateTeamCreditUsageLimitRequest,
  type TeamCreditUsageLimit,
} from '@mai/types';

import { apiClient } from '.';

export const TEAM_CREDIT_USAGE_LIMIT_QUERY_KEY = 'team-credit-usage-limit';

export const useTeamCreditUsageLimitQuery = (teamId?: string) => {
  return useQuery({
    queryKey: [TEAM_CREDIT_USAGE_LIMIT_QUERY_KEY, teamId],
    queryFn: async () => {
      const { data } = await apiClient.get<TeamCreditUsageLimit>(
        `/teams/${teamId}/current-credit-usage-limit`,
      );
      return data;
    },
    enabled: !!teamId,
  });
};

export const createTeamCreditUsageLimit = async (
  params: CreateTeamCreditUsageLimitRequest,
) => {
  const { data } = await apiClient.post<TeamCreditUsageLimit>(
    `/team-credit-usage-limits`,
    params,
  );
  void queryClient.invalidateQueries({
    queryKey: [TEAM_CREDIT_USAGE_LIMIT_QUERY_KEY],
  });
  return data;
};

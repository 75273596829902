import { cloneElement } from 'react';

const DisabledWrapper = ({
  disabled,
  children,
}: {
  disabled?: boolean;
  children: JSX.Element;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const childWithProps = cloneElement(children, {
    style: {
      ...children.props.style,
      cursor: disabled ? 'not-allowed' : children.props.style?.cursor,
    },
    onClick: disabled ? handleClick : children.props.onClick,
  });

  return (
    <div style={{ cursor: disabled ? 'not-allowed' : undefined }}>
      {childWithProps}
    </div>
  );
};

export default DisabledWrapper;

import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {
  return (
    <Card>
      <Typography.Title level={1}>Privacy Policy</Typography.Title>
      <Typography.Paragraph>
        Privacy Policy for Moderately AI Inc. Effective Date: August 31st 2024
        Last Updated: August 31st 2024 Introduction In the digital age,
        safeguarding personal data is of paramount importance. At Moderately AI
        Inc., we are dedicated to upholding your privacy and protecting your
        personal information. This Privacy Policy is designed to explain how we
        gather, utilize, disclose, and safeguard your personal data when you
        access and use our website. Your use of our website implies your consent
        to the practices detailed herein. Information We Collect 2.1. Personal
        Information We may collect personal information that you willingly
        provide when utilizing our services, including but not limited to: Name:
        To personalize your experience. Email Address: To communicate with you
        and send updates. Postal Address: When necessary for specific services.
        Phone Number: To contact you, if required. Any other data: You may
        provide voluntarily, such as preferences or user-generated content. This
        information might be collected during account registration, subscription
        to newsletters, or when you contact us. 2.2. Automatically Collected
        Information When you access our website, certain information is
        automatically collected, including: IP Address: For security and
        analytics. Browser Type: To optimize your browsing experience. Operating
        System: To ensure compatibility. Referring URLs: To understand how you
        found our website. Pages Visited: For site improvement and analytics.
        This data helps us enhance our website and improve your user experience.
        How We Use Your Information We employ your data for various purposes,
        including: 3.1. Providing and Enhancing Services: We use your personal
        information to deliver the services you request and continually enhance
        your experience on our website. 3.2. Personalization: We may personalize
        your website experience based on the data we collect to make your
        interactions more relevant and enjoyable. 3.3. Communication: Your
        contact information allows us to respond to your inquiries, provide
        updates, and engage with you when necessary. 3.4. Analytics and
        Security: We monitor and analyze usage patterns to bolster our website’s
        functionality and security. Data Security Measures The security of your
        data is a top priority. We implement robust security measures to protect
        your personal information from unauthorized access, disclosure,
        alteration, or destruction. These measures include encryption, access
        controls, and regular security assessments. Sharing of Information We do
        not sell, trade, or transfer your personal information to third parties
        without your consent. However, we may share your data with trusted
        service providers who assist us in operating our website, subject to
        strict confidentiality agreements. Cookies and Tracking Technologies Our
        website may utilize cookies and similar tracking technologies to collect
        information about your browsing behavior. You can manage your cookie
        preferences through your browser settings. Please review our Cookie
        Policy <Link to="/cookie-policy">here</Link> for detailed information.
        Your Rights 7.1. Access: You have the right to access the personal
        information we hold about you. Feel free to contact us for a copy of
        your data. 7.2. Correction: If you believe the personal information we
        hold about you is inaccurate or incomplete, you can request corrections.
        7.3. Deletion: You can request the deletion of your personal
        information. 7.4. Objection: You have the right to object to the
        processing of your personal information for certain purposes, such as
        direct marketing. 7.5. Data Portability: You can request your personal
        information in a structured, commonly used, and machine-readable format.
        Changes to this Privacy Policy We may periodically update this Privacy
        Policy to reflect changes in our practices or for legal reasons.
        Significant changes will be communicated by posting the revised Privacy
        Policy on our website. Contact Us If you have questions, concerns, or
        requests regarding this Privacy Policy or our data practices, please
        contact us at{' '}
        <a href="mailto:support@moderately.ai">support@moderately.ai</a>. This
        Privacy Policy was last updated on August 31st 2024.
      </Typography.Paragraph>
    </Card>
  );
};

export default PrivacyPolicyPage;

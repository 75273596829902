import {
  type ListConversationMessagesResponse,
  ListConversationMessagesResponseSchema,
} from '@mai/types';

import { apiClient } from '@queries/index';

export const exportConversationToCSV = async (conversationId: string) => {
  const response = await apiClient.get<ListConversationMessagesResponse>(
    `/conversations/${conversationId}/messages`,
  );
  const messages = ListConversationMessagesResponseSchema.parse(response.data);

  const csvContent = [
    ['Role', 'Content', 'Created At'],
    ...messages
      .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      .map((message) => [
        message.role,
        message.content.replace(/"/g, '""'), // Escape double quotes
        message.createdAt.toISOString(),
      ]),
  ]
    .map((row) => row.map((cell) => `"${cell}"`).join(','))
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `conversation_${conversationId}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const StripeCustomerLinkSchema = z.object({
  type: z.enum(['team']),
  id: z.string(),
});

export type StripeCustomerLink = z.infer<typeof StripeCustomerLinkSchema>;

export const StripeCustomerSchema = z.object({
  id: z.string(),
  stripeId: z.string(),
  links: z.array(StripeCustomerLinkSchema),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type StripeCustomer = z.infer<typeof StripeCustomerSchema>;

export const ListStripeCustomersQuerySchema = z.object({
  teamIds: commaDelimitedList().optional(),
  stripeIds: commaDelimitedList().optional(),
  stripeCustomerIds: commaDelimitedList().optional(),
});

export type ListStripeCustomersQuery = z.infer<
  typeof ListStripeCustomersQuerySchema
>;

export const ListStripeCustomersResponseSchema = z.array(StripeCustomerSchema);

export type ListStripeCustomersResponse = z.infer<
  typeof ListStripeCustomersResponseSchema
>;

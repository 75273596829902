import WarningOutlined from '@ant-design/icons/WarningOutlined';
import Alert from 'antd/es/alert';
import { Link } from 'react-router-dom';

const ActiveTeamAlert = ({ teamId }: { teamId: string }) => {
  return (
    <Alert
      showIcon
      icon={<WarningOutlined />}
      type="warning"
      message={
        <div>
          {
            'You will not be able to fully use this feature until your team has an active subscription. '
          }
          <Link to={`/team/${teamId}/billing`}>Upgrade your team now</Link>
        </div>
      }
    />
  );
};

export default ActiveTeamAlert;

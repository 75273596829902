import { Suspense } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import Root from './pages';

import AdminLayout from '@components/AdminLayout';
import ContentContainer from '@components/ContentContainer';
import ProfileLayout from '@components/ProfileLayout';
import EmbedLayout from '@components/layouts/EmbedLayout';
import UnauthenticatedLayout from '@components/layouts/UnauthenticatedLayout';
import Home from '@pages/Home';
import RootRedirect from '@pages/RootRedirect';
import UnauthenticatedRoot from '@pages/UnauthenticatedRoot';
import AdminPage from '@pages/admin';
import LoginPage from '@pages/auth/login';
import RegisterPage from '@pages/auth/register';
import RegisterPending from '@pages/auth/register/pending';
import RegisterVerification from '@pages/auth/register/verification';
import ResetPassword from '@pages/auth/reset-password';
import ResetPasswordCallback from '@pages/auth/reset-password/callback';
import ResetPasswordSent from '@pages/auth/reset-password/sent';
import ResetPasswordSuccess from '@pages/auth/reset-password/success';
import CookiePolicyPage from '@pages/cookie-policy';
import EmbedPage from '@pages/embed';
import EmbedConversationPage from '@pages/embed/conversation';
import LabsPage from '@pages/labs';
import PrivacyPolicyPage from '@pages/privacy-policy';
import ProfilePage from '@pages/profile';
import UserProfilePage from '@pages/profile/:userId';
import TeamsRoot from '@pages/team';
import TeamRoot from '@pages/team/:teamId';
import AnalyzePage from '@pages/team/:teamId/analyze';
import AnalyzeDetailsPage from '@pages/team/:teamId/analyze/:analysisRequestId';
import AssistantPage from '@pages/team/:teamId/assistant';
import AssistantConversationPage from '@pages/team/:teamId/assistant/:conversationId';
import AssistantHistoryPage from '@pages/team/:teamId/assistant/history';
import NewAssistantPage from '@pages/team/:teamId/assistant/new';
import BillingOverviewPage from '@pages/team/:teamId/billing/overview';
import BillingUsageHistoryPage from '@pages/team/:teamId/billing/usage-history';
import TeamDocumentPage from '@pages/team/:teamId/documents/:documentId';
import EditDocumentPage from '@pages/team/:teamId/documents/:documentId/edit';
import CreateDocumentPage from '@pages/team/:teamId/documents/new';
import DocumentsOverview from '@pages/team/:teamId/documents/overview';
import InsightRequestDetailsPage from '@pages/team/:teamId/insights/:insightRequestId';
import InsightsHistoryPage from '@pages/team/:teamId/insights/history';
import InsightsNewPage from '@pages/team/:teamId/insights/new';
import InsightsOverviewPage from '@pages/team/:teamId/insights/overview';
import Integrations from '@pages/team/:teamId/integrations';
import TeamManageRoot from '@pages/team/:teamId/manage';
import ManageTeamGeneralPage from '@pages/team/:teamId/manage/general';
import EditTeamDetailsPage from '@pages/team/:teamId/manage/general/edit';
import ManageTeamUsersPage from '@pages/team/:teamId/manage/users';
import TeamUserDetailPage from '@pages/team/:teamId/manage/users/:userId';
import ProjectDetailsPage from '@pages/team/:teamId/projects/:projectId';
import ProjectsOverviewPage from '@pages/team/:teamId/projects/overview';
import SearchPage from '@pages/team/:teamId/search';
import StripeCheckoutSuccessPage from '@pages/team/:teamId/stripe-checkout/success';
import TeamRootRedirect from '@pages/team/TeamRootRedirect';
import ManageTeams from '@pages/team/manage';
import TermsAndConditionsPage from '@pages/terms-and-conditions';

export const router = createBrowserRouter([
  {
    path: '/embed',
    element: <EmbedLayout />,
    children: [
      {
        path: '',
        element: <EmbedPage />,
      },
      {
        path: 'conversation',
        element: <EmbedConversationPage />,
      },
    ],
  },
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <RootRedirect />,
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'cookie-policy',
        element: <CookiePolicyPage />,
      },
      {
        path: 'labs',
        element: (
          <Suspense fallback={<ContentContainer.Loading />}>
            <LabsPage />
          </Suspense>
        ),
      },
      {
        path: 'auth',
        element: (
          <Suspense>
            <UnauthenticatedRoot />
          </Suspense>
        ),
        children: [
          {
            path: 'login',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <LoginPage />
              </Suspense>
            ),
          },
          {
            path: 'reset-password',
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <ResetPassword />
                  </Suspense>
                ),
              },
              {
                path: 'sent',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <ResetPasswordSent />
                  </Suspense>
                ),
              },
              {
                path: 'callback',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <ResetPasswordCallback />
                  </Suspense>
                ),
              },
              {
                path: 'success',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <ResetPasswordSuccess />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'register',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <Outlet />
              </Suspense>
            ),
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <RegisterPage />
                  </Suspense>
                ),
              },
              {
                path: 'verification',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <RegisterVerification />
                  </Suspense>
                ),
              },
              {
                path: 'pending',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <RegisterPending />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: '*',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <Navigate to="/auth/login" replace />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'admin',
        element: (
          <AdminLayout>
            <Outlet />
          </AdminLayout>
        ),
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <AdminPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'profile',
        element: (
          <ProfileLayout>
            <Outlet />
          </ProfileLayout>
        ),
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <ProfilePage />
              </Suspense>
            ),
          },
          {
            path: ':userId',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <UserProfilePage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'team',
        element: <TeamsRoot />,
        children: [
          {
            path: '',
            element: <TeamRootRedirect />,
          },
          {
            path: 'manage',
            element: (
              <Suspense fallback={<ContentContainer.Loading />}>
                <ManageTeams />
              </Suspense>
            ),
          },
          {
            path: ':teamId',
            element: <TeamRoot />,
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <Home />
                  </Suspense>
                ),
              },
              {
                path: 'stripe-checkout',
                children: [
                  {
                    path: 'success',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <StripeCheckoutSuccessPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'insights',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <InsightsOverviewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'history',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <InsightsHistoryPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <InsightsNewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':insightRequestId',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <InsightRequestDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'assistant',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <AssistantPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <NewAssistantPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'history',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <AssistantHistoryPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':conversationId',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <AssistantConversationPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'search',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <SearchPage />
                  </Suspense>
                ),
              },
              {
                path: 'analyze',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <AnalyzePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':analysisRequestId',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <AnalyzeDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'billing',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <BillingOverviewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'usage-history',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <BillingUsageHistoryPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'manage',
                children: [
                  {
                    path: '',
                    element: <TeamManageRoot />,
                  },
                  {
                    path: 'general',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <ManageTeamGeneralPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'general/edit',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <EditTeamDetailsPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'users',
                    children: [
                      {
                        path: '',
                        element: (
                          <Suspense fallback={<ContentContainer.Loading />}>
                            <ManageTeamUsersPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':userId',
                        element: (
                          <Suspense fallback={<ContentContainer.Loading />}>
                            <TeamUserDetailPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'integrations',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <Integrations />
                  </Suspense>
                ),
              },
              {
                path: 'projects',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <ProjectsOverviewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':projectId',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <ProjectDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'documents',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <DocumentsOverview />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <CreateDocumentPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':documentId',
                    children: [
                      {
                        path: '',
                        element: (
                          <Suspense fallback={<ContentContainer.Loading />}>
                            <TeamDocumentPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'edit',
                        element: (
                          <Suspense fallback={<ContentContainer.Loading />}>
                            <EditDocumentPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: '*',
                    element: (
                      <Suspense fallback={<ContentContainer.Loading />}>
                        <UnauthenticatedLayout.NotFound />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: '*',
                element: (
                  <Suspense fallback={<ContentContainer.Loading />}>
                    <UnauthenticatedLayout.NotFound />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'error',
    element: (
      <UnauthenticatedLayout>
        <ContentContainer.Error />
      </UnauthenticatedLayout>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<ContentContainer.Loading />}>
        <UnauthenticatedLayout.NotFound />
      </Suspense>
    ),
  },
]);

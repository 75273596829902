import Spin from 'antd/es/spin';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthWhoami } from '@queries/auth';

const RootRedirect = () => {
  const authWhoamiQuery = useAuthWhoami();
  const authUserId = authWhoamiQuery.data?.userId;
  const location = useLocation();

  if (authWhoamiQuery.isLoading) {
    return <Spin />;
  } else if (authWhoamiQuery.isError) {
    return <Spin />;
  } else if (!authUserId) {
    return (
      <Navigate to={`/auth/login?previous=${location.pathname}`} replace />
    );
  }

  return <Navigate to="/team" replace />;
};

export default RootRedirect;

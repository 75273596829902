import Descriptions from 'antd/es/descriptions';
import { useParams } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import { useFetchUserQuery } from '@queries/users';

const TeamUserDetailPage = () => {
  const { teamId, userId } = useParams<{ teamId: string; userId: string }>();

  const fetchUserQuery = useFetchUserQuery(userId);
  const user = fetchUserQuery.data;

  if (fetchUserQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (fetchUserQuery.isError) {
    return <ContentContainer.Error />;
  }

  if (!user) {
    return <ContentContainer.NotFound />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title={user.name}
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Team Members', to: `/team/${teamId}/manage/users` },
          { label: user.name },
        ]}
      />
      <Descriptions title="General">
        <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
      </Descriptions>
      <Descriptions title="Contact">
        {user.emails.map(({ email }) => (
          <Descriptions.Item key={email}>{email}</Descriptions.Item>
        ))}
      </Descriptions>
    </ContentContainer>
  );
};

export default TeamUserDetailPage;

import { useQuery } from '@tanstack/react-query';

import {
  type ListTeamInvitesRequest,
  type ListTeamInvitesResponse,
  ListTeamInvitesResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useTeamInvitesQuery = (
  params: ListTeamInvitesRequest,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['team-invites', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListTeamInvitesResponse>(
        `/team-invites`,
        {
          params,
        },
      );
      const validatedData = ListTeamInvitesResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch team invites',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

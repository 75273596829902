import List from 'antd/es/list';
import Typography from 'antd/es/typography';

import CenterSpin from '@components/CenterSpin';
import { useEmbeddingsQuery } from '@queries/embeddings';

const CitationList = ({
  documentId,
  embeddingIds,
}: {
  documentId: string;
  embeddingIds: string[];
}) => {
  const embeddingsQuery = useEmbeddingsQuery({
    documentIds: [documentId],
    embeddingIds,
  });
  const embeddings = (embeddingsQuery.data ?? []).sort(
    (a, b) => a.links[0].startOffset - b.links[0].startOffset,
  );

  if (embeddingsQuery.isLoading) {
    return <CenterSpin />;
  }

  return (
    <List
      dataSource={embeddings}
      renderItem={(embedding) => {
        const startOffset = embedding.links[0].startOffset;
        const endOffset = embedding.links[0].endOffset;
        return (
          <List.Item>
            <div>
              <Typography.Title level={5}>
                {startOffset} - {endOffset}
              </Typography.Title>
              <Typography.Paragraph>
                ... {embedding.content} ...
              </Typography.Paragraph>
            </div>
          </List.Item>
        );
      }}
    />
  );
};

export default CitationList;

import { useState } from 'react';

export const screenSizes = {
  mobile: 480,
  tablet: 800,
};

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return defaultValue;
  });

  const setLocalStorage = (value: T) => {
    setState(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [state, setLocalStorage];
};

export const convertHexToRGB = (color: string): number[] => {
  const hexMatch = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(
    color,
  );
  if (hexMatch) {
    const r = parseInt(hexMatch[1], 16);
    const g = parseInt(hexMatch[2], 16);
    const b = parseInt(hexMatch[3], 16);
    return [r, g, b];
  }

  throw new Error('Invalid color format');
};

export const getDarkerColor = (color: number[]): number[] => {
  const [r, g, b] = color;
  const distanceFromWhite = {
    r: 255 - r,
    g: 255 - g,
    b: 255 - b,
  };

  const darkerColor = {
    r: Math.max(0, r - distanceFromWhite.r),
    g: Math.max(0, g - distanceFromWhite.g),
    b: Math.max(0, b - distanceFromWhite.b),
  };

  return [darkerColor.r, darkerColor.g, darkerColor.b];
};

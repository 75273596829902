import MinusCircleOutlined from '@ant-design/icons/MinusCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Alert from 'antd/es/alert/Alert';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import { useState } from 'react';

import { apiClient } from '@queries/index';
import { useFetchTeamUsers } from '@queries/teams';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { logger } from '@utils/logger';

type FormData = {
  emails: string[];
};

const InviteTeamMemberButton = ({
  teamId,
  buttonSize,
  buttonType = 'primary',
}: {
  teamId?: string;
  buttonSize?: 'small';
  buttonType?: 'primary' | 'default';
}) => {
  const queryClient = useQueryClient();
  const sessionUserId = useSessionUserId();
  const { message } = App.useApp();
  const [form] = Form.useForm<FormData>();
  const [isInviteModalVisible, setIsInviteModalVisible] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const emails = Form.useWatch(['emails'], form);
  const teamIsActive = useTeamIsActive();

  const sessionTeamUsersQuery = useFetchTeamUsers(
    {
      teamIds: teamId ? [teamId] : [],
      userIds: sessionUserId ? [sessionUserId] : [],
    },
    {
      enabled: !!teamId && !!sessionUserId,
    },
  );

  const hasPermission =
    sessionTeamUsersQuery.data?.some(({ role }) => role === 'ADMIN') ?? false;

  return (
    <>
      <Modal
        open={isInviteModalVisible}
        okText="Send"
        onCancel={() => {
          setIsInviteModalVisible(false);
        }}
        onClose={() => {
          setIsInviteModalVisible(false);
        }}
        onOk={async () => {
          setIsSubmitting(true);
          try {
            await form.validateFields();
            const results = await Promise.allSettled(
              emails.map((email) => {
                return apiClient.post('/team-invites', {
                  teamId,
                  email,
                  role: 'MEMBER',
                });
              }),
            );
            const failedEmails: string[] = [];
            results.forEach((result, i) => {
              if (result.status === 'rejected') {
                failedEmails.push(emails[i]);
                logger.error({
                  error: result.reason,
                  message: 'Failed to send invite',
                });
              }
            });
            if (failedEmails.length) {
              logger.warn({
                message: 'Some invites failed to send',
                failedEmails,
              });
              void message.error(
                'Some invites failed to send: ' + failedEmails.join(', '),
              );
            } else {
              setIsInviteModalVisible(false);
              void message.success('Invites sent');
              void queryClient.invalidateQueries({
                queryKey: ['team-invites'],
              });
            }
          } catch (error) {
            logger.error({ error, message: 'Failed to send invites' });
          } finally {
            setIsSubmitting(false);
          }
        }}
        title="Invite Other Team Members"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingTop: '16px',
          }}
        >
          <Alert
            description="Invite members of your organization to collaborate with you. They will receive an email with an invitation to join your team."
            type="info"
          />
          <Form form={form} layout="vertical">
            <Form.List name="emails" initialValue={['']}>
              {(fields, { add, remove }, { errors }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  {fields.map((field, index) => {
                    return (
                      <div
                        key={`${field.key}-${index}`}
                        style={{
                          display: 'flex',
                          gap: '8px',
                        }}
                      >
                        <Form.Item
                          {...field}
                          key={field.key}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                "Please input passenger's name or delete this field.",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            type="text"
                            autoComplete="off"
                            placeholder="email@domain.com"
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Another Email
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Form>
        </div>
      </Modal>
      <Button
        type={buttonType}
        size={buttonSize}
        loading={isSubmitting || sessionTeamUsersQuery.isLoading}
        disabled={
          !teamIsActive ||
          (sessionTeamUsersQuery.isLoading && (!teamId || !hasPermission))
        }
        onClick={() => {
          setIsInviteModalVisible(true);
        }}
        style={{
          boxShadow: 'none',
        }}
      >
        <PlusCircleOutlined /> Invite Member
      </Button>
    </>
  );
};

export default InviteTeamMemberButton;

import List from 'antd/es/list';
import Tag from 'antd/es/tag';
import Timeline from 'antd/es/timeline';
import Typography from 'antd/es/typography';
import startCase from 'lodash.startcase';

import { type AnalysisRequest } from '@mai/types';

import { getStatusFromAnalysisRequestRunEvents } from './hooks';

import { useAnalysisRequestRunsQuery } from '@queries/analysis-request-runs';
import { renderDate } from '@utils/dates';

const RunHistoryList = ({
  analysisRequest,
}: {
  analysisRequest: AnalysisRequest;
}) => {
  const analysisRequestRunsQuery = useAnalysisRequestRunsQuery({
    analysisRequestIds: [analysisRequest.id],
  });
  const analysisRequestRuns = analysisRequestRunsQuery.data;

  return (
    <List>
      {analysisRequestRuns?.map((analysisRequestRun) => {
        const events = analysisRequestRun.events;
        const { status } = getStatusFromAnalysisRequestRunEvents(events) ?? {
          status: 'not-started',
        };
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <div>{renderDate(analysisRequestRun.createdAt)}</div>
              <div>
                <Tag>{startCase(status)}</Tag>
              </div>
            </div>
            <Timeline
              items={events.map((event) => ({
                color:
                  (event.eventType === 'started' && 'blue') ||
                  (event.eventType === 'completed' && 'green') ||
                  (event.eventType === 'failed' && 'red') ||
                  (event.eventType === 'cancelled' && 'orange') ||
                  'gray',
                children: (
                  <div>
                    <Typography.Text>
                      {startCase(event.eventType)}
                    </Typography.Text>{' '}
                    <Typography.Text type="secondary">
                      {'('}
                      {renderDate(event.createdAt)}
                      {')'}
                    </Typography.Text>
                  </div>
                ),
              }))}
              style={{
                width: '400px',
              }}
            />
          </div>
        );
      })}
    </List>
  );
};

export default RunHistoryList;

import theme from 'antd/es/theme';
import styled from 'styled-components';

import NavMenu from './NavMenu';

const Container = styled.div<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border-radius: 0 ${(props) => props.$borderRadius}px
    ${(props) => props.$borderRadius}px ${(props) => props.$borderRadius}px;
  background-color: ${(props) => props.$backgroundColor};
`;

const SideBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: (value: boolean | ((prev: boolean) => boolean)) => void;
}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Container
      $backgroundColor={colorBgContainer}
      $borderRadius={borderRadiusLG}
    >
      <NavMenu collapsed={collapsed} setCollapsed={setCollapsed} />
    </Container>
  );
};

export default SideBar;

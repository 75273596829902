import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Badge from 'antd/es/badge';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import List from 'antd/es/list';
import Progress from 'antd/es/progress';
import Tabs from 'antd/es/tabs';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { AxiosError } from 'axios';
import startCase from 'lodash.startcase';
import { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  type CreateInsightRequestRun,
  type InsightRequestResultExtractedQuestions,
  type InsightRequestResultIssues,
  type InsightRequestResultSummary,
} from '@mai/types';

import EmptySummaryImage from '@assets/undraw_location_search.svg';
import ContentContainer from '@components/ContentContainer';
import MarkdownContent from '@components/MarkdownContent';
import { useDocumentsQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import {
  useInsightRequestQuery,
  useInsightRequestResultsQuery,
  useInsightRequestRunsQuery,
} from '@queries/insight-requests';
import { subscribe, unsubscribe } from '@queries/websockets';
import { usePrevious } from '@utils/hooks';
import { useInsightRequestId, useTeamId } from '@utils/router';

const IssuesListContainer = styled.div`
  height: calc(100vh - 300px);
  overflow: auto;
`;

const IssueListItem = ({
  item,
}: {
  item: {
    key: number;
    issue: {
      issue: string;
      description?: string;
      examples: string[];
      consequences: string;
    };
  };
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <List.Item key={item.key}>
      <List.Item.Meta
        title={item.issue.issue}
        description={
          <Descriptions column={1} layout="vertical">
            <Descriptions.Item>
              <Typography.Text>
                {item.issue.description ?? 'No description available.'}{' '}
                {!expanded && (
                  <Button
                    type="link"
                    onClick={() => setExpanded(true)}
                    style={{
                      padding: '0',
                    }}
                  >
                    Show More
                  </Button>
                )}
              </Typography.Text>
            </Descriptions.Item>
            {expanded && (
              <>
                <Descriptions.Item label="Consequences">
                  {item.issue.consequences}
                </Descriptions.Item>
                <Descriptions.Item label="Potential Problems">
                  <ul>
                    {item.issue.examples.map((example) => (
                      <li key={example}>{example}</li>
                    ))}
                  </ul>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        }
      />
    </List.Item>
  );
};

const IssuesList = ({
  issues,
}: {
  issues: {
    issue: string;
    description?: string;
    examples: string[];
    consequences: string;
  }[];
}) => {
  return issues.length > 0 ? (
    <IssuesListContainer>
      <List
        dataSource={issues.map((issue, index) => ({
          key: index,
          issue,
        }))}
        renderItem={(item) => <IssueListItem item={item} />}
      />
    </IssuesListContainer>
  ) : (
    <div style={{ textAlign: 'center' }}>No issues available</div>
  );
};

const QuestionListItem = ({
  item,
}: {
  item: {
    key: number;
    question: {
      question: string;
      answer: string;
    };
  };
}) => {
  return (
    <List.Item key={item.key}>
      <List.Item.Meta
        title={item.question.question}
        description={<MarkdownContent content={item.question.answer} />}
      />
    </List.Item>
  );
};

const InsightRequestQuestions = ({
  questions,
}: {
  questions: { question: string; answer: string }[];
}) => {
  return (
    <List
      dataSource={questions.map((question, index) => ({
        key: index,
        question,
      }))}
      renderItem={(item) => <QuestionListItem item={item} />}
    />
  );
};
const InsightRequestSummary = ({ summary }: { summary: string }) => {
  if (!summary) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={EmptySummaryImage}
          alt="No summary available"
          style={{
            width: '50%',
            height: 'auto',
            maxWidth: '400px',
            marginBottom: '0.5rem',
          }}
        />
        <Typography.Text strong>
          Start the analysis to view a summary
        </Typography.Text>
      </div>
    );
  }
  return (
    <ReactMarkdown
      components={{
        h1: ({ children }) => (
          <Typography.Title level={3}>{children}</Typography.Title>
        ),
        h2: ({ children }) => (
          <Typography.Title level={4}>{children}</Typography.Title>
        ),
        h3: ({ children }) => (
          <Typography.Title level={5}>{children}</Typography.Title>
        ),
      }}
    >
      {summary}
    </ReactMarkdown>
  );
};

const LoadingPlaceholder = ({
  progress,
  label,
}: {
  progress: number;
  label?: string;
}) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Progress
        status="active"
        percent={progress}
        style={{
          color: token.colorPrimary,
        }}
      />
      <Typography.Text type="secondary" ellipsis>
        Processing
        <SyncOutlined spin style={{ marginLeft: '0.25rem' }} />
      </Typography.Text>
      {label && (
        <Typography.Text type="secondary" style={{ maxWidth: '500px' }}>
          {label}
        </Typography.Text>
      )}
    </div>
  );
};

const InsightRequestDetailsPage = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  const [progressLabel, setProgressLabel] = useState<string | undefined>(
    undefined,
  );
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const teamId = useTeamId();
  const insightRequestId = useInsightRequestId();

  const insightRequestQuery = useInsightRequestQuery(insightRequestId);
  const insightRequest = insightRequestQuery.data;

  const insightRequestRunsQuery = useInsightRequestRunsQuery(
    {
      insightRequestIds: insightRequestId ? [insightRequestId] : undefined,
      orderBy: 'createdAt',
      orderDirection: 'desc',
    },
    !!insightRequestId,
  );
  const insightRequestRuns = insightRequestRunsQuery.data ?? [];
  const latestInsightRequestRun = insightRequestRuns.at(0);

  const insightRequestResultSummaryQuery = useInsightRequestResultsQuery(
    insightRequestId,
    {
      insightRequestRunIds: latestInsightRequestRun?.id
        ? [latestInsightRequestRun.id]
        : [],
      resultTypes: ['summary'],
      pageSize: 1,
      orderBy: 'createdAt',
      orderDirection: 'desc',
    },
  );
  const insightRequestResultSummary = insightRequestResultSummaryQuery.data;

  const insightRequestResultIssuesQuery = useInsightRequestResultsQuery(
    insightRequestId,
    {
      insightRequestRunIds: latestInsightRequestRun?.id
        ? [latestInsightRequestRun.id]
        : [],
      resultTypes: ['issues'],
      pageSize: 100,
      orderBy: 'createdAt',
      orderDirection: 'desc',
    },
  );
  const insightRequestResultIssues = insightRequestResultIssuesQuery.data;

  const insightRequestResultExtractedQuestionsQuery =
    useInsightRequestResultsQuery(insightRequestId, {
      insightRequestRunIds: latestInsightRequestRun?.id
        ? [latestInsightRequestRun.id]
        : [],
      resultTypes: ['extracted_questions'],
    });
  const insightRequestResultExtractedQuestions =
    insightRequestResultExtractedQuestionsQuery.data;

  const documentIds = insightRequest?.links
    .filter((link) => link.type === 'document')
    .map((link) => link.id);
  const documentsQuery = useDocumentsQuery(
    {
      teamIds: teamId ? [teamId] : undefined,
      documentIds,
    },
    Boolean(documentIds?.length),
  );
  const documents = documentsQuery.data;

  const { issues, summary, questions } = useMemo(() => {
    const issues =
      insightRequestResultIssues?.flatMap(
        (result) => (result.resultData as InsightRequestResultIssues).issues,
      ) ?? [];
    const summary = (
      insightRequestResultSummary?.[0]
        ?.resultData as InsightRequestResultSummary
    )?.summary;
    const questions =
      (insightRequestResultExtractedQuestions?.[0]
        ?.resultData as InsightRequestResultExtractedQuestions) ?? [];
    return {
      issues,
      summary,
      questions,
    };
  }, [
    insightRequestResultIssues,
    insightRequestResultSummary,
    insightRequestResultExtractedQuestions,
  ]);

  // Dynamically receive events from the backend as processing completes
  const previousLatestInsightRequestRun = usePrevious(latestInsightRequestRun);
  useEffect(() => {
    const previousLatestInsightRequestRunId =
      previousLatestInsightRequestRun?.id;
    const latestInsightRequestRunId = latestInsightRequestRun?.id;

    // If the run id has changed and the previous one was non null, we want to unsubscribe
    if (
      previousLatestInsightRequestRunId !== latestInsightRequestRunId &&
      previousLatestInsightRequestRunId
    ) {
      unsubscribe('insight_request_run_event', {
        insightRequestRunId: previousLatestInsightRequestRunId,
      });
    }

    // Subscribe to the new run id
    if (latestInsightRequestRunId) {
      subscribe(
        'insight_request_run_event',
        {
          insightRequestRunId: latestInsightRequestRunId,
        },
        ({ label, progress }) => {
          if (progress === 100) {
            setProgressLabel(undefined);
            setProgress(undefined);
            setTimeout(() => {
              void queryClient.invalidateQueries({
                queryKey: ['insightRequestResults'],
              });
              void queryClient.invalidateQueries({
                queryKey: ['insightRequestRuns'],
              });
            }, 1000);
          } else {
            setProgressLabel(label);
            setProgress(progress);
          }
        },
      );
    }
  }, [previousLatestInsightRequestRun, latestInsightRequestRun, queryClient]);

  const actionButton = useMemo(() => {
    if (!insightRequestId) {
      return null;
    }

    const handleStartAnalysis = async () => {
      const loadingMessage = message.loading('Starting analysis...');
      try {
        await apiClient.post<CreateInsightRequestRun>(`/insight-request-runs`, {
          insightRequestId,
        });
        loadingMessage();
        void message.success('Insight request run started');
        void queryClient.invalidateQueries({
          queryKey: ['insightRequestRuns'],
        });
      } catch (error) {
        loadingMessage();
        let errorMessage = 'Failed to start analysis';
        if (error instanceof AxiosError) {
          errorMessage = error.response?.data.error ?? errorMessage;
        }
        void message.error(errorMessage);
      }
    };

    const handleCancelAnalysis = async () => {
      const loadingMessage = message.loading('Cancelling analysis...');
      try {
        if (!latestInsightRequestRun) {
          return;
        }
        await apiClient.patch(
          `/insight-request-runs/${latestInsightRequestRun?.id}`,
          {
            shouldCancel: true,
          },
        );
        loadingMessage();
        void message.success('Analysis cancelled');
        void queryClient.invalidateQueries({
          queryKey: ['insightRequestRuns'],
        });
      } catch (error) {
        loadingMessage();
        let errorMessage = 'Failed to cancel analysis';
        if (error instanceof AxiosError) {
          errorMessage =
            error.response?.data?.errors?.[0]?.message ??
            error.response?.data?.error ??
            errorMessage;
        }
        void message.error(errorMessage);
      }
    };

    if (!latestInsightRequestRun) {
      return (
        <Button
          type="primary"
          size="small"
          icon={<PlayCircleOutlined />}
          onClick={handleStartAnalysis}
        >
          Start Analysis
        </Button>
      );
    }

    if (['pending', 'running'].includes(latestInsightRequestRun.status)) {
      return (
        <Button
          type="primary"
          size="small"
          icon={<CloseCircleOutlined />}
          onClick={handleCancelAnalysis}
        >
          Cancel Analysis
        </Button>
      );
    }

    return (
      <Button
        type="primary"
        size="small"
        icon={<PlayCircleOutlined />}
        onClick={handleStartAnalysis}
      >
        Re-run Analysis
      </Button>
    );
  }, [insightRequestId, latestInsightRequestRun, message, queryClient]);

  const tabs = useMemo(() => {
    if (progress !== undefined && progress !== 100) {
      return [
        {
          key: 'summary',
          disabled: true,
          label: 'Summary',
          children: (
            <LoadingPlaceholder progress={progress} label={progressLabel} />
          ),
        },
        {
          key: 'questions',
          disabled: true,
          label: 'Questions',
          children: (
            <LoadingPlaceholder progress={progress} label={progressLabel} />
          ),
        },
        {
          key: 'issues',
          disabled: true,
          label: 'Issues',
          children: (
            <LoadingPlaceholder progress={progress} label={progressLabel} />
          ),
        },
      ];
    }

    return [
      {
        key: 'summary',
        label: 'Summary',
        children: <InsightRequestSummary summary={summary} />,
      },
      {
        key: 'questions',
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.25rem',
            }}
          >
            {'Questions '}
            <Badge
              showZero={false}
              color="purple"
              size="small"
              count={questions.length}
            />
          </div>
        ),
        children: <InsightRequestQuestions questions={questions} />,
      },
      {
        key: 'issues',
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.25rem',
            }}
          >
            {'Issues '}
            <Badge
              showZero={false}
              color="purple"
              size="small"
              count={issues.length}
            />
          </div>
        ),
        children: <IssuesList issues={issues} />,
      },
    ];
  }, [issues, progress, progressLabel, questions, summary]);

  if (
    !teamId ||
    !insightRequestId ||
    insightRequestQuery.isLoading ||
    insightRequestResultSummaryQuery.isLoading ||
    insightRequestResultIssuesQuery.isLoading
  ) {
    return <ContentContainer.Loading />;
  }

  if (!insightRequest) {
    return <ContentContainer.NotFound />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Insight Request"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Insights',
            to: `/team/${teamId}/insights`,
          },
          {
            label: 'Insight Request',
            to: `/team/${teamId}/insights/${insightRequestId}`,
          },
        ]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {documents?.map((document) => (
            <Tag
              style={{
                maxWidth: '300px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Link
                to={`/team/${teamId}/documents/${document.id}`}
                key={document.id}
              >
                {document.title}
              </Link>
            </Tag>
          ))}
          <Tag color="purple">
            Analysis Type: {startCase(insightRequest.analysisType)}
          </Tag>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {(latestInsightRequestRun?.status === 'running' ||
            latestInsightRequestRun?.status === 'pending') && (
            <Tag
              icon={
                <Progress
                  percent={progress}
                  type="circle"
                  size={13}
                  status="active"
                  style={{
                    marginRight: '0.25rem',
                  }}
                />
              }
            >
              {progressLabel ?? 'Processing'}
            </Tag>
          )}
          {actionButton}
        </div>
      </div>
      <Tabs items={tabs} />
    </ContentContainer>
  );
};

export default InsightRequestDetailsPage;

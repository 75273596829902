import { type ThemeConfig } from 'antd/es/config-provider';
import React, { createContext } from 'react';

export const ThemeConfigContext = createContext<ThemeConfig | undefined>(
  undefined,
);

const ThemeConfigProvider = ({
  children,
  themeConfiguration,
}: {
  children: React.ReactNode;
  themeConfiguration: ThemeConfig;
}) => {
  return (
    <ThemeConfigContext.Provider value={themeConfiguration}>
      {children}
    </ThemeConfigContext.Provider>
  );
};

export default ThemeConfigProvider;

import Select from 'antd/es/select';
import { useEffect } from 'react';

import { useWorkflowsQuery } from '@queries/workflows';

const WorkflowSelect = ({
  value,
  onChange,
}: {
  value?: string | null;
  onChange?: (value: string) => void;
}) => {
  const workflowsQuery = useWorkflowsQuery({});

  useEffect(() => {
    if (workflowsQuery.isSuccess && workflowsQuery.data?.length && !value) {
      onChange?.(workflowsQuery.data[0].id);
    }
  }, [
    workflowsQuery.isSuccess,
    workflowsQuery.data?.length,
    onChange,
    workflowsQuery.data,
    value,
  ]);

  return (
    <Select
      value={value}
      onChange={onChange}
      options={workflowsQuery.data?.map((workflow) => ({
        label: workflow.name,
        value: workflow.id,
      }))}
      loading={workflowsQuery.isLoading}
      placeholder="Select a workflow"
    />
  );
};

export default WorkflowSelect;

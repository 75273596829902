import React, { type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { screenSizes } from '@utils/theme';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
  align-items: stretch;

  @media (max-width: ${screenSizes.tablet}px) {
    grid-template-columns: 1fr;
  }
`;

const ActionCardsLayout = ({ children }: PropsWithChildren) => {
  return (
    <GridContainer>
      {React.Children.map(children, (child, index) => (
        <React.Fragment key={index}>{child}</React.Fragment>
      ))}
    </GridContainer>
  );
};

export default ActionCardsLayout;

import { useThemeConfig } from '@/hooks/theme';
import { type AnyObject } from 'antd/es/_util/type';
import AntTable, { type TableProps } from 'antd/es/table';
import Typography from 'antd/es/typography';
import { styled } from 'styled-components';

const StyledTable = styled(AntTable)<
  TableProps<AnyObject> & { $borderColor?: string }
>`
  border: 1px solid ${(props) => props.$borderColor};
  border-radius: 0.5rem;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  overflow: auto;

  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .ant-table-body {
    overflow: auto !important;
  }

  .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    right: 0 !important;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 1px solid ${(props) => props.$borderColor};
  }
`;

const Table = <T extends object>({ ...props }: TableProps<T>) => {
  const themeConfig = useThemeConfig();
  const borderColor = themeConfig.components?.Menu?.itemSelectedBg ?? 'black';

  return (
    <StyledTable
      scroll={{
        y: 'max-content',
      }}
      $borderColor={borderColor}
      locale={{
        emptyText: <Typography.Text type="secondary">No data</Typography.Text>,
      }}
      size={props.size ?? 'middle'}
      {...props}
    />
  );
};

const TableError = () => {
  const themeConfig = useThemeConfig();
  const borderColor = themeConfig.components?.Menu?.itemSelectedBg ?? 'black';
  return (
    <StyledTable
      scroll={{
        x: 'max-content',
        y: 'max-content',
      }}
      $borderColor={borderColor}
      locale={{
        emptyText: (
          <Typography.Text type="secondary">
            There was an error while loading your data. Please refresh the page
            or try again later.
          </Typography.Text>
        ),
      }}
      dataSource={[]}
    />
  );
};

Table.Error = TableError;

export default Table;

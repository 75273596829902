import CopyOutlined from '@ant-design/icons/CopyOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import Alert from 'antd/es/alert';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import type { ColumnsType } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { useState } from 'react';

import { type ProjectAssistant } from '@mai/types';

import StackedDateTime from '@components/StackedDateTime';
import Table from '@components/Table';
import {
  deleteProjectAssistant,
  generateProjectAssistantUrl,
  updateProjectAssistant,
  useProjectAssistantsQuery,
} from '@queries/project-assistants';
import { renderDate } from '@utils/dates';

const ProjectAssistantTable = ({
  projectId,
  size,
}: {
  projectId: string;
  size: 'small' | 'middle' | 'large';
}) => {
  const { modal, message } = App.useApp();

  const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);

  const projectAssistantsQuery = useProjectAssistantsQuery({
    projectIds: [projectId],
  });

  const columns: ColumnsType<ProjectAssistant> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (value) => value ?? '-',
    },
    {
      title: 'Link',
      key: 'link',
      ellipsis: true,
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="link"
            icon={<LinkOutlined />}
            loading={isGeneratingUrl}
            onClick={async () => {
              setIsGeneratingUrl(true);
              try {
                const url = await generateProjectAssistantUrl(record.id);
                setIsGeneratingUrl(false);
                await modal.info({
                  title: 'Project Assistant Link',
                  closable: true,
                  maskClosable: true,
                  footer: null,
                  icon: null,
                  width: 600,
                  content: (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                      }}
                    >
                      <Alert
                        message="Treat this link as a password."
                        type="warning"
                        icon={<WarningOutlined />}
                        showIcon
                      />
                      <div style={{ display: 'flex', gap: 8 }}>
                        <Input.Password
                          readOnly
                          value={url}
                          contentEditable={false}
                        />
                        <Tooltip title="Copy to clipboard">
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                              void navigator.clipboard.writeText(url);
                              void message.info('Link copied to clipboard');
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ),
                });
              } catch (error) {
                void message.error('Failed to generate link');
              } finally {
                setIsGeneratingUrl(false);
              }
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      title: 'Token Generated At',
      dataIndex: 'sessionCreatedAt',
      key: 'sessionCreatedAt',
      ellipsis: true,
      width: 200,
      render: (_, record) => {
        if (size === 'large') {
          return <StackedDateTime dateString={record.sessionCreatedAt} />;
        }
        return renderDate(record.sessionCreatedAt);
      },
    },
    {
      key: 'actions',
      width: 50,
      align: 'center',
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'rotate-session',
                  label: 'Rotate Session',
                  icon: <ReloadOutlined />,
                  onClick: () => {
                    return modal.confirm({
                      title: 'Are you sure you want to rotate the session?',
                      closable: true,
                      maskClosable: true,
                      content: (
                        <div>
                          All existing project assistant links for this instance
                          will be invalidated and will require creation of a new
                          link.
                        </div>
                      ),
                      onOk: async () => {
                        const loading = message.loading('Rotating...');
                        try {
                          await updateProjectAssistant(record.id, {
                            shouldRegenerateSession: true,
                          });
                          loading();
                          void message.success('Session rotated');
                        } catch (error) {
                          loading();
                          void message.error('Failed to rotate session');
                        }
                      },
                    });
                  },
                },
                {
                  key: 'delete',
                  label: 'Delete',
                  icon: <DeleteOutlined />,
                  danger: true,
                  onClick: () => {
                    return modal.confirm({
                      title:
                        'Are you sure you want to delete this project assistant?',
                      closable: true,
                      maskClosable: true,
                      onOk: async () => {
                        const loading = message.loading('Deleting...');
                        try {
                          await deleteProjectAssistant(record.id);
                          loading();
                          void message.success('Project assistant deleted');
                        } catch (error) {
                          loading();
                          void message.error(
                            'Failed to delete project assistant',
                          );
                        }
                      },
                    });
                  },
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const data: ProjectAssistant[] = projectAssistantsQuery.data ?? [];

  return (
    <Table
      size={size}
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default ProjectAssistantTable;

import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import SectionContainer from '@components/SectionContainer';
import TeamBillingOverview from '@components/TeamBillingOverview';
import TeamCreditUsageLimitsOverview from '@components/TeamCreditUsageLimitsOverview';
import TeamCreditUsageOverview from '@components/TeamCreditUsageOverview';
import EditTeamCreditUsageLimitsForm from '@components/forms/EditTeamCreditUsageLimitsForm';
import { useFetchTeamUsers } from '@queries/teams';
import { useSessionUserId } from '@utils/auth';
import { useTeamId } from '@utils/router';

const BillingOverviewPage = () => {
  const [isEditLimitsModalOpen, setIsEditLimitsModalOpen] = useState(false);
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();
  const teamUsersQuery = useFetchTeamUsers(
    {
      userIds: sessionUserId ? [sessionUserId] : undefined,
      teamIds: teamId ? [teamId] : undefined,
    },
    { enabled: !!teamId && !!sessionUserId },
  );

  const canEditLimits = teamUsersQuery.data?.some(
    (user) => user.role === 'ADMIN',
  );

  if (!teamId || teamUsersQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer>
      <Modal
        open={isEditLimitsModalOpen}
        onCancel={() => setIsEditLimitsModalOpen(false)}
        footer={null}
        title="Edit credit usage limit"
        destroyOnClose
      >
        <EditTeamCreditUsageLimitsForm
          teamId={teamId}
          onFinish={() => setIsEditLimitsModalOpen(false)}
        />
      </Modal>
      <ContentContainer.Header
        title="Plan & Usage"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Plan & Usage',
            to: `/team/${teamId}/billing/overview`,
          },
        ]}
      />
      <SectionContainer title="Plan">
        <TeamBillingOverview teamId={teamId} />
      </SectionContainer>
      <SectionContainer
        title="Usage"
        cta={
          <Button size="small">
            <Link to={`/team/${teamId}/billing/usage-history`}>View Usage</Link>
          </Button>
        }
      >
        <TeamCreditUsageOverview teamId={teamId} />
      </SectionContainer>
      <SectionContainer
        title="Limits"
        cta={
          <Button
            disabled={!canEditLimits}
            size="small"
            onClick={() => setIsEditLimitsModalOpen(true)}
          >
            Edit
          </Button>
        }
      >
        <TeamCreditUsageLimitsOverview teamId={teamId} />
      </SectionContainer>
    </ContentContainer>
  );
};

export default BillingOverviewPage;

import { queryClient } from '@/globals';
import { useQuery } from '@tanstack/react-query';

import {
  type CreateWorkflowRun,
  type CreateWorkflowRunEvent,
  type ListWorkflowFilters,
  type ListWorkflowRunEventsRequest,
  type ListWorkflowRunEventsResponse,
  ListWorkflowRunEventsResponseSchema,
  type ListWorkflowRunsRequest,
  type ListWorkflowRunsResponse,
  ListWorkflowRunsResponseSchema,
  type ListWorkflowsResponse,
  type UpdateWorkflowRun,
  type WorkflowRun,
  type WorkflowRunEvent,
  WorkflowRunEventSchema,
  WorkflowRunSchema,
  type WorkflowRunState,
  WorkflowRunStateSchema,
} from '@mai/types';

import { apiClient } from '.';

export const WORKFLOWS_QUERY_KEY = 'workflows';
export const WORKFLOW_RUNS_QUERY_KEY = 'workflow-runs';
export const WORKFLOW_RUN_STATE_QUERY_KEY = 'workflow-run-state';
export const WORKFLOW_RUN_EVENTS_QUERY_KEY = 'workflow-run-events';

export const useWorkflowsQuery = (
  params: ListWorkflowFilters,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['workflows', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListWorkflowsResponse>(
        '/workflows',
        {
          params,
        },
      );
      return data;
    },
    enabled,
  });
};

export const createWorkflowRun = async (payload: CreateWorkflowRun) => {
  const { data } = await apiClient.post<WorkflowRun>('/workflow-runs', payload);
  const validatedData = WorkflowRunSchema.safeParse(data);
  await queryClient.invalidateQueries({ queryKey: ['workflow-runs'] });
  return validatedData.success ? validatedData.data : data;
};

export const updateWorkflowRun = async (
  workflowRunId: string,
  payload: UpdateWorkflowRun,
) => {
  const { data } = await apiClient.patch<WorkflowRun>(
    `/workflow-runs/${workflowRunId}`,
    payload,
  );
  const validatedData = WorkflowRunSchema.safeParse(data);
  await queryClient.invalidateQueries({ queryKey: ['workflow-runs'] });
  return validatedData.success ? validatedData.data : data;
};

export const useWorkflowRunsQuery = (
  params: ListWorkflowRunsRequest,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [WORKFLOW_RUNS_QUERY_KEY, params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListWorkflowRunsResponse>(
        '/workflow-runs',
        {
          params,
        },
      );
      const validatedData = ListWorkflowRunsResponseSchema.safeParse(data);
      return validatedData.success ? validatedData.data : data;
    },
    enabled,
  });
};

export const useWorkflowRunQuery = (workflowRunId?: string) => {
  return useQuery({
    queryKey: [WORKFLOW_RUNS_QUERY_KEY, workflowRunId],
    queryFn: async () => {
      const { data } = await apiClient.get<WorkflowRun>(
        `/workflow-runs/${workflowRunId}`,
      );
      const validatedData = WorkflowRunSchema.safeParse(data);
      return validatedData.success ? validatedData.data : data;
    },
    enabled: !!workflowRunId,
  });
};

export const useWorkflowRunStateQuery = (
  workflowRunId: string | null | undefined,
) => {
  return useQuery({
    queryKey: [WORKFLOW_RUN_STATE_QUERY_KEY, workflowRunId],
    queryFn: async () => {
      const { data } = await apiClient.get<WorkflowRunState>(
        `/workflow-runs/${workflowRunId}/state`,
      );
      const validatedData = WorkflowRunStateSchema.safeParse(data);
      return validatedData.success ? validatedData.data : data;
    },
    enabled: !!workflowRunId,
  });
};

export const useWorkflowRunEventsQuery = (
  params: ListWorkflowRunEventsRequest,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [WORKFLOW_RUN_EVENTS_QUERY_KEY, params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListWorkflowRunEventsResponse>(
        '/workflow-run-events',
        { params },
      );
      const validatedData = ListWorkflowRunEventsResponseSchema.safeParse(data);
      return validatedData.success ? validatedData.data : data;
    },
    enabled,
  });
};

export const createWorkflowRunEvent = async (
  payload: CreateWorkflowRunEvent,
) => {
  const { data } = await apiClient.post<WorkflowRunEvent>(
    '/workflow-run-events',
    payload,
  );
  const validatedData = WorkflowRunEventSchema.safeParse(data);
  await queryClient.invalidateQueries({ queryKey: ['workflow-run-events'] });
  await queryClient.invalidateQueries({ queryKey: ['workflow-run-state'] });
  return validatedData.success ? validatedData.data : data;
};

export const invalidateAllWorkflowQueries = () => {
  void queryClient.invalidateQueries({ queryKey: ['workflow-runs'] });
  void queryClient.invalidateQueries({ queryKey: ['workflow-run-state'] });
  void queryClient.invalidateQueries({ queryKey: ['workflow-run-events'] });
};

export const deleteWorkflowRunEvent = async (workflowRunEventId: string) => {
  const { data } = await apiClient.delete<WorkflowRunEvent>(
    `/workflow-run-events/${workflowRunEventId}`,
  );
  await queryClient.invalidateQueries({ queryKey: ['workflow-run-events'] });
  await queryClient.invalidateQueries({ queryKey: ['workflow-run-state'] });
  return data;
};

import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';

import { type AnalysisRequest } from '@mai/types';

const EditDetails = ({
  initialAnalysisRequest,
  onFinish,
}: {
  initialAnalysisRequest: AnalysisRequest;
  onFinish: (analysisRequest: AnalysisRequest) => Promise<void>;
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      size="middle"
      onFinish={(values) => {
        void onFinish(values);
      }}
      initialValues={initialAnalysisRequest}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter a name',
          },
          {
            min: 1,
            message: 'Name must be at least 1 character',
          },
          {
            max: 50,
            message: 'Name must be at most 50 characters',
          },
        ]}
      >
        <Input placeholder="Please enter a name" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save & Close
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditDetails;

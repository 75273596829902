import { type ColumnProps } from 'antd/es/table';
import Tag from 'antd/es/tag';
import startCase from 'lodash.startcase';
import { Link } from 'react-router-dom';

import ContentContainer from '../ContentContainer';
import Table from '../Table';

import { useFetchTeamUsers } from '@queries/teams';
import { useFetchUsers } from '@queries/users';

type TeamUserRow = {
  userId: string;
  name: string;
  roles: ('MEMBER' | 'ADMIN')[];
};

const TeamUsersTable = ({
  teamId,
  size,
}: {
  teamId: string;
  size?: 'large' | 'middle' | 'small';
}) => {
  const columns: ColumnProps<TeamUserRow>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      fixed: 'left',
      render: (name, row) => {
        return (
          <Link to={`/team/${teamId}/manage/users/${row.userId}`}>{name}</Link>
        );
      },
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      align: 'left',
      render: (roles: TeamUserRow['roles']) => {
        return (
          <>
            {roles.map((role) => {
              const color = role === 'ADMIN' ? 'purple' : 'blue';
              return (
                <Tag key={role} color={color}>
                  {startCase(role.toLowerCase())}
                </Tag>
              );
            })}
          </>
        );
      },
    },
  ];

  const fetchTeamUsersQuery = useFetchTeamUsers(
    {
      teamIds: teamId ? [teamId] : undefined,
    },
    { enabled: teamId !== undefined },
  );
  const teamUsers = fetchTeamUsersQuery.data || [];

  const fetchUsersQuery = useFetchUsers(
    {
      userIds: teamUsers.map((teamUser) => teamUser.userId),
    },
    teamUsers.length > 0,
  );
  const users = fetchUsersQuery.data || [];

  const dataSource = teamUsers.reduce<TeamUserRow[]>((acc, teamUser) => {
    const user = users.find((user) => user.id === teamUser.userId);
    const existingTeamUser = acc.find(
      (teamMember) => teamMember.userId === teamUser.userId,
    );
    return [
      ...acc.filter((teamMember) => teamMember.userId !== teamUser.userId),
      {
        userId: teamUser.userId,
        name: user?.name || '',
        roles: [
          ...(existingTeamUser ? existingTeamUser.roles : []),
          teamUser.role,
        ],
      },
    ];
  }, []);

  if (fetchTeamUsersQuery.isError || fetchUsersQuery.isError) {
    return <ContentContainer.Error />;
  }

  return (
    <Table<TeamUserRow>
      columns={columns}
      dataSource={dataSource}
      loading={fetchTeamUsersQuery.isLoading || fetchUsersQuery.isLoading}
      rowKey="userId"
      pagination={false}
      scroll={{ x: 'max-content' }}
      size={size}
    />
  );
};

export default TeamUsersTable;

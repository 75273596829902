import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import Alert from 'antd/es/alert';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import { type ColumnType } from 'antd/es/table';
import { type TableRowSelection } from 'antd/es/table/interface';
import Transfer from 'antd/es/transfer';
import type { TransferItem, TransferProps } from 'antd/es/transfer';
import { useState } from 'react';

import {
  type AnalysisRequest,
  type CreateAnalysisRequestBody,
} from '@mai/types';

import CenterSpin from '@components/CenterSpin';
import { useDocumentsQuery } from '@queries/documents';

const EditData = ({
  teamId,
  analysisRequest,
  onFinish,
}: {
  teamId: string;
  analysisRequest: AnalysisRequest;
  onFinish: (
    resources: CreateAnalysisRequestBody['resources'],
  ) => Promise<void>;
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const [targetKeys, setTargetKeys] = useState<TransferProps['targetKeys']>(
    analysisRequest.resources.map((r) => r.resourceId) ?? [],
  );

  const [selectedKeys, setSelectedKeys] = useState<TransferProps['targetKeys']>(
    [],
  );

  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(1);
  const pageSize = 100;

  const sourceDocumentsQuery = useDocumentsQuery(
    {
      excludeDocumentIds: targetKeys?.length
        ? (targetKeys as string[])
        : undefined,
      teamIds: [teamId],
      pageSize,
      page: leftPage,
      isProcessed: true,
    },
    !!teamId,
  );

  const targetDocumentsQuery = useDocumentsQuery(
    {
      documentIds: targetKeys as string[],
      page: rightPage,
    },
    !!targetKeys?.length,
  );

  const pendingProcessingDocumentsQuery = useDocumentsQuery(
    {
      teamIds: [teamId],
      isProcessed: false,
    },
    !!teamId,
  );
  const pendingProcessingCount =
    pendingProcessingDocumentsQuery.data?.length ?? 0;

  const isLoading = [
    sourceDocumentsQuery,
    pendingProcessingDocumentsQuery,
  ].some(({ isLoading }) => isLoading);
  if (isLoading) {
    return <CenterSpin />;
  }

  const dataSource = [
    ...(sourceDocumentsQuery.data ?? []),
    ...(targetDocumentsQuery.data ?? []),
  ].map(({ id, title }) => ({
    key: id,
    title,
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Alert
        type="info"
        message={`There are ${pendingProcessingCount} document${pendingProcessingCount > 1 ? 's' : ''} pending processing, they will not be available for analysis until they finish processing.`}
      />
      <Transfer
        disabled={isSaving}
        oneWay
        dataSource={dataSource}
        titles={['Available Documents', 'Selected Documents']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        pagination
        style={{
          width: '100%',
        }}
        onChange={(nextTargetKeys, _, moveKeys) => {
          setTargetKeys(nextTargetKeys);
          setSelectedKeys(
            selectedKeys?.filter((key) => !moveKeys.includes(key)),
          );
        }}
      >
        {({
          direction,
          filteredItems,
          onItemSelect,
          onItemSelectAll,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const columns: ColumnType<TransferItem>[] = [
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
              ellipsis: true,
            },
            ...(direction === 'right'
              ? [
                  {
                    key: 'delete',
                    width: 50,
                    render: (_: unknown, record: TransferItem) => (
                      <Button
                        type="link"
                        onClick={() => {
                          if (!targetKeys) return;
                          setTargetKeys(
                            targetKeys.filter((key) => key !== record.key),
                          );
                        }}
                        danger
                        size="small"
                        icon={<DeleteOutlined />}
                      />
                    ),
                  },
                ]
              : []),
          ];
          const rowSelection: TableRowSelection<TransferItem> = {
            getCheckboxProps: () => ({ disabled: listDisabled }),
            onChange(selectedRowKeys) {
              onItemSelectAll(selectedRowKeys, 'replace');
            },
            selectedRowKeys: listSelectedKeys,
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
            ],
          };

          return (
            <Table<TransferItem>
              showHeader={false}
              rowSelection={direction === 'left' ? rowSelection : undefined}
              columns={columns}
              dataSource={filteredItems}
              size="small"
              style={{ pointerEvents: listDisabled ? 'none' : undefined }}
              onRow={({ key }) => ({
                onClick: () => {
                  onItemSelect(key!, !listSelectedKeys.includes(key!));
                },
              })}
              pagination={{
                total:
                  direction === 'left'
                    ? sourceDocumentsQuery.data?.length
                    : undefined,
                pageSize,
                onChange: direction === 'left' ? setLeftPage : setRightPage,
              }}
              locale={{
                emptyText: 'No documents',
              }}
              scroll={{
                y: 500,
              }}
            />
          );
        }}
      </Transfer>
      <Button
        loading={isSaving}
        type="primary"
        onClick={async () => {
          setIsSaving(true);
          try {
            await onFinish(
              targetKeys?.map((key) => {
                return {
                  resourceId: key as string,
                  resourceType: 'document',
                };
              }) ?? [],
            );
          } finally {
            setIsSaving(false);
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default EditData;

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Typography from 'antd/es/typography';

import { type AnalysisRequest, type AnalysisRequestFacet } from '@mai/types';

import FacetNameField from './FacetNameField';
import FacetTypeField from './FacetTypeField';
import FacetValueField from './FacetValueField';

import Table from '@components/Table';

const EditDimensions = ({
  initialAnalysisRequest,
  onFinish,
  onCancel,
}: {
  initialAnalysisRequest: AnalysisRequest;
  onFinish: (facets: AnalysisRequestFacet[]) => Promise<void>;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      size="middle"
      onFinish={async (values) => {
        await onFinish(values.facets ?? []);
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          height: '100%',
        }}
      >
        <Form.List name="facets" initialValue={initialAnalysisRequest.facets}>
          {(fields, { add, remove }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                <Table
                  rowKey={(record) => record.name}
                  columns={[
                    {
                      key: 'type',
                      title: 'Type',
                      dataIndex: 'type',
                      width: 105,
                      render: (_, record) => {
                        const initialValue = initialAnalysisRequest.facets.find(
                          (facet) => facet.name === record.name,
                        )?.type;
                        return (
                          <FacetTypeField
                            name={[record.key, 'type']}
                            initialValue={initialValue ?? 'string'}
                          />
                        );
                      },
                    },
                    {
                      key: 'name',
                      title: 'Name',
                      dataIndex: 'name',
                      render: (_, record) => {
                        const initialValue = initialAnalysisRequest.facets.find(
                          (facet) => facet.name === record.name,
                        )?.name;
                        return (
                          <FacetNameField
                            name={[record.key, 'name']}
                            initialValue={initialValue ?? ''}
                          />
                        );
                      },
                    },
                    {
                      key: 'description',
                      title: 'Description',
                      dataIndex: 'description',
                      render: (_, record) => {
                        const initialValue = initialAnalysisRequest.facets.find(
                          (facet) => facet.name === record.name,
                        )?.description;

                        return (
                          <FacetValueField
                            name={[record.key, 'description']}
                            initialValue={initialValue ?? ''}
                          />
                        );
                      },
                    },
                    {
                      key: 'actions',
                      width: 50,
                      render: (_, record) => (
                        <Button
                          size="small"
                          shape="circle"
                          type="text"
                          onClick={() => {
                            remove(record.key);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      ),
                    },
                  ]}
                  size="small"
                  pagination={false}
                  dataSource={fields.map((_, index) => {
                    const facet = initialAnalysisRequest.facets[index] ?? null;
                    const placeholder = (
                      <Typography.Text type="secondary">-</Typography.Text>
                    );
                    return {
                      key: index,
                      type: facet?.type ?? placeholder,
                      name: facet?.name ?? placeholder,
                      description: facet?.description ?? placeholder,
                    };
                  })}
                />
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}
                >
                  Add Dimension
                </Button>
              </div>
            );
          }}
        </Form.List>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default EditDimensions;

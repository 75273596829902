import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import Button from 'antd/es/button';
import Card from 'antd/es/card/Card';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import DisabledWrapper from './DisabledWrapper';

type ActionCardProps = {
  title: string;
  description: string;
  actionTitle: string;
  actionLink: string;
  isComingSoon?: boolean;
  disabled?: boolean;
};

const StyledCard = styled(Card)<{ $disabled?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-card-body {
    padding-top: 0;
  }

  .ant-card-head,
  .ant-card-body,
  .ant-card-extra {
    border-bottom: none;
  }
  .ant-card-actions {
    border-top: none;
  }

  .ant-card-actions > li > span {
    ${({ $disabled }) => ($disabled ? 'cursor: not-allowed;' : '')}
  }
`;

const ActionCard = (props: ActionCardProps) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <DisabledWrapper disabled={props.disabled}>
      <Link to={props.actionLink}>
        <StyledCard
          $disabled={props.disabled}
          hoverable={!props.disabled}
          title={props.title}
          actions={[
            <div>
              {props.disabled ? (
                <div style={{ width: '100%', height: '2rem' }} />
              ) : (
                <Button type="link">
                  {props.actionTitle}
                  <ArrowRightOutlined
                    style={{ display: 'inline', marginLeft: '0.5rem' }}
                  />
                </Button>
              )}
            </div>,
          ]}
          extra={
            props.isComingSoon ? (
              <Tag style={{ margin: '0 0 0 0.5rem' }} color={colorPrimary}>
                <b>Coming Soon</b>
              </Tag>
            ) : null
          }
        >
          <Typography.Text type="secondary">
            {props.description}
          </Typography.Text>
        </StyledCard>
      </Link>
    </DisabledWrapper>
  );
};

export default ActionCard;

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import styled from 'styled-components';

const Container = styled.div<{ $maxHeightDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $maxHeightDisabled }) =>
    ($maxHeightDisabled ?? false) ? 'height: 100%;' : 'max-height: 500px;'};
  margin-bottom: 2rem;
`;

const SectionContainer = ({
  title,
  children,
  cta,
  info,
  maxHeightDisabled = false,
}: {
  title: string | React.ReactNode;
  children: React.ReactNode;
  maxHeightDisabled?: boolean;
  info?: React.ReactNode;
  cta?: React.ReactNode;
}) => {
  return (
    <Container $maxHeightDisabled={maxHeightDisabled}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {typeof title === 'string' ? (
            <Typography.Title
              level={5}
              style={{
                margin: 0,
              }}
            >
              {title}
            </Typography.Title>
          ) : (
            title
          )}
          {info ? (
            <Tooltip title={info}>
              <QuestionCircleOutlined style={{ marginLeft: '0.5rem' }} />
            </Tooltip>
          ) : null}
        </div>
        {cta}
      </div>
      {children}
    </Container>
  );
};

SectionContainer.Loading = () => {
  return (
    <Container>
      <Spin />
    </Container>
  );
};

SectionContainer.Error = () => {
  return (
    <Container>
      <Typography.Text>
        An error occurred, please try again later or reach out to support.
      </Typography.Text>
    </Container>
  );
};

SectionContainer.NotFound = () => {
  return (
    <Container>
      <Typography.Text>Not found</Typography.Text>
    </Container>
  );
};

export default SectionContainer;

import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

const SearchRequestLinkTypeSchema = z.enum([
  'team',
  'user',
  'document',
  'project',
]);

const SearchRequestLinkSchema = z.object({
  id: z.string(),
  type: SearchRequestLinkTypeSchema,
});

export const SearchRequestFilterDateOperatorSchema = z.enum([
  '>',
  '<',
  '>=',
  '<=',
  '=',
  '!=',
]);

export const SearchRequestFilterStringOperatorSchema = z.enum([
  'equals',
  'notEquals',
  'contains',
  'startsWith',
  'endsWith',
]);

export const SearchRequestFilterSchema = z.union([
  // Filter by the creation date of the entity
  z.object({
    type: z.literal('createdAt'),
    value: z.coerce.date(),
    operator: SearchRequestFilterDateOperatorSchema,
  }),
  // Filter by the last updated date of the entity
  z.object({
    type: z.literal('updatedAt'),
    value: z.coerce.date(),
    operator: SearchRequestFilterDateOperatorSchema,
  }),
  // Filter by a specific classification linked to the entity
  z.object({
    type: z.literal('classification'),
    key: z.string(),
    value: z.string(),
    operator: SearchRequestFilterStringOperatorSchema,
  }),
  // Filter by type of entity
  z.object({
    type: z.literal('type'),
    value: SearchRequestLinkTypeSchema,
  }),
]);

export type SearchRequestFilter = z.infer<typeof SearchRequestFilterSchema>;

export const CreateSearchRequestBodySchema = z.object({
  query: z.string(),
  links: z.array(SearchRequestLinkSchema).min(1),
  filters: z.array(SearchRequestFilterSchema).default([]).optional(),
});

export type CreateSearchRequestBody = z.infer<
  typeof CreateSearchRequestBodySchema
>;

const BaseSearchRequestEventSchema = {
  id: z.string(),
  searchRequestId: z.string(),
  createdAt: z.coerce.date(),
};

export const SearchRequestEventSchema = z.union([
  // Search request started
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('started'),
    payload: z.object({}),
  }),
  // Search request initial research completed
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('initialResearchCompleted'),
    payload: z.object({
      initialResearch: z.array(
        z.object({
          type: z.string(),
          id: z.string(),
          label: z.string(),
        }),
      ),
    }),
  }),
  // Search request plan completed
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('planCompleted'),
    payload: z.object({
      plan: z.string(),
      subqueries: z.array(z.string()),
    }),
  }),
  // Search request subquery completed
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('subqueryCompleted'),
    payload: z.object({
      subquery: z.string(),
      results: z.array(
        z.object({
          type: z.string(),
          id: z.string(),
          label: z.string(),
          embedding_ids: z.array(z.string()),
          score: z.number().nullable().optional(), // optional since older events don't have the score
          normalizedScore: z.number().nullable().optional(), // optional since older events don't have the normalized score
        }),
      ),
    }),
  }),
  // Search request aggregation completed
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('aggregationCompleted'),
    payload: z.object({
      subqueries: z.array(z.string()),
      results: z.array(
        z.object({
          type: z.string(),
          id: z.string(),
          label: z.string(),
          score: z.number().nullable().optional(), // optional since older events don't have the score
          normalizedScore: z.number().nullable().optional(), // optional since older events don't have the normalized score
        }),
      ),
    }),
  }),
  // Search request failed
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('failed'),
    payload: z.object({
      error: z.object({ message: z.string() }),
    }),
  }),
  // Search request succeeded
  z.object({
    ...BaseSearchRequestEventSchema,
    eventType: z.literal('succeeded'),
    payload: z.object({}),
  }),
]);

export type SearchRequestEvent = z.infer<typeof SearchRequestEventSchema>;

export const SearchRequestSchema = z.object({
  id: z.string(),
  query: z.string(),
  filters: z.array(SearchRequestFilterSchema),
  links: z.array(SearchRequestLinkSchema),
  events: z.array(SearchRequestEventSchema),
  createdAt: z.coerce.date(),
  startedAt: z.coerce.date().nullable(),
  succeededAt: z.coerce.date().nullable(),
  failedAt: z.coerce.date().nullable(),
  createdBy: z.string().nullable(),
});

export type SearchRequest = z.infer<typeof SearchRequestSchema>;

export const GetSearchRequestParamsSchema = z.object({
  searchRequestId: z.string(),
});

export type GetSearchRequestParams = z.infer<
  typeof GetSearchRequestParamsSchema
>;

export const ListSearchRequestsQuerySchema = z
  .object({
    searchRequestIds: commaDelimitedList().optional(),
    teamIds: commaDelimitedList().optional(),
    createdBy: z.string().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListSearchRequestsQuery = z.infer<
  typeof ListSearchRequestsQuerySchema
>;

export const ListSearchRequestsResponseSchema = z.array(SearchRequestSchema);

export type ListSearchRequestsResponse = z.infer<
  typeof ListSearchRequestsResponseSchema
>;

import App from 'antd/es/app';
import Form from 'antd/es/form';

import { type WorkflowRun } from '@mai/types';

import FormSubmitButton from '@components/FormSubmitButton';
import TeamSelect from '@components/TeamSelect';
import WorkflowSelect from '@components/WorkflowSelect';
import { createWorkflowRun } from '@queries/workflows';

type FormData = Partial<{
  workflowId: string;
  teamId: string;
}>;

const CreateWorkflowRunForm = ({
  onSuccess,
}: {
  onSuccess?: (workflowRun: WorkflowRun) => void;
}) => {
  const [form] = Form.useForm<FormData>();
  const { message } = App.useApp();

  const onSubmit = async (data: FormData) => {
    const workflowId = data.workflowId;
    const teamId = data.teamId;
    if (!workflowId || !teamId) {
      void message.error('Workflow and team are required');
      return;
    }

    const loading = message.loading('Creating workflow run...');
    try {
      const workflowRun = await createWorkflowRun({
        workflowId,
        teamId,
      });
      loading();
      onSuccess?.(workflowRun);
    } catch (error) {
      loading();
      void message.error('Failed to create workflow run');
    }
  };

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <Form.Item name="workflowId" label="Workflow">
        <WorkflowSelect />
      </Form.Item>
      <Form.Item name="teamId" label="Team">
        <TeamSelect allowWildcard />
      </Form.Item>
      <FormSubmitButton text="Create" />
    </Form>
  );
};

export default CreateWorkflowRunForm;

import { Navigate, Outlet } from 'react-router-dom';

import UnauthenticatedLayout from '@components/layouts/UnauthenticatedLayout';
import { useAuthWhoami } from '@queries/auth';

const UnauthenticatedRoot = () => {
  const authWhoamiQuery = useAuthWhoami();

  // If the user is authenticated, redirect them to the team root
  if (authWhoamiQuery.data?.userId) {
    return <Navigate to="/team" />;
  }

  return (
    <UnauthenticatedLayout>
      <Outlet />
    </UnauthenticatedLayout>
  );
};

export default UnauthenticatedRoot;

import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Dragger from 'antd/es/upload/Dragger';
import startCase from 'lodash.startcase';

import { useProjectsQuery } from '@queries/projects';
import { useFetchTeam } from '@queries/teams';
import { useTeamId } from '@utils/router';

export const DocumentTitleField = ({
  initialValue = '',
}: {
  initialValue?: string;
}) => (
  <Form.Item
    label="Title"
    name="title"
    initialValue={initialValue}
    rules={[
      {
        required: true,
        message: 'Title is required.',
      },
      {
        max: 255,
        message: 'Title must be less than 255 characters.',
      },
    ]}
  >
    <Input showCount maxLength={255} />
  </Form.Item>
);

export const DocumentDescriptionField = ({
  initialValue = '',
}: {
  initialValue?: string;
}) => (
  <Form.Item
    label="Description"
    name="description"
    initialValue={initialValue}
    rules={[
      {
        max: 2000,
        message: 'Description must be less than 2000 characters.',
      },
    ]}
  >
    <Input.TextArea showCount maxLength={2000} />
  </Form.Item>
);

export const DocumentUploadField = () => {
  return (
    <Form.Item
      name="files"
      label="Upload"
      getValueFromEvent={(e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      }}
      valuePropName="fileList"
      rules={[
        {
          required: true,
          message: 'You must upload a file.',
        },
      ]}
    >
      <Dragger
        accept="application/pdf"
        multiple={false}
        maxCount={1}
        beforeUpload={() => {
          return false;
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click here to select a file, or drag and drop a file here.
        </p>
      </Dragger>
    </Form.Item>
  );
};

export const DocumentLinksField = ({
  initialValue = [],
}: {
  initialValue?: string[];
}) => {
  const teamId = useTeamId();

  const teamQuery = useFetchTeam(teamId);
  const team = teamQuery.data;

  const projectsQuery = useProjectsQuery(
    {
      teamIds: teamId ? [teamId] : undefined,
    },
    !!teamId,
  );
  const projects = projectsQuery.data;

  const isLoading = teamQuery.isLoading || projectsQuery.isLoading || !teamId;
  const options = [
    {
      label: 'Teams',
      title: 'Teams',
      options: [
        {
          label: team?.name ?? '',
          value: 'team_' + team?.id,
        },
      ],
    },
    {
      label: 'Projects',
      title: 'Projects',
      options: projects?.map((project) => ({
        label: project.name,
        value: 'project_' + project.id,
      })),
    },
  ];

  return (
    <Form.Item
      label="Links"
      name="links"
      rules={[
        {
          type: 'array',
          max: 10,
          message: 'You can only link up to 10 resources.',
        },
        {
          type: 'array',
          min: 1,
          message: 'You must link at least one resource.',
        },
      ]}
      initialValue={initialValue}
    >
      <Select
        loading={isLoading}
        options={options}
        mode="multiple"
        allowClear
        placeholder="Add links"
        labelRender={({ label, value }) => {
          const [type] = (value as string).split('_');
          return `${startCase(type)}: ${label}`;
        }}
      />
    </Form.Item>
  );
};

import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import { type ReactNode } from 'react';

import TopBar from '../TopBar';

const AuthenticatedLayout = ({ children }: { children: ReactNode }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        scrollbarWidth: 'thin',
        scrollbarColor: `grey ${colorBgContainer}`,
        flexDirection: 'column',
      }}
    >
      <TopBar />
      <Layout style={{ flexDirection: 'row' }}>{children}</Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;

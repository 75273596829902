import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import styled from 'styled-components';

const Content = styled(Layout.Content)<{ $backgroundColor: string }>`
  display: flex;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

const ProfileLayout = ({ children }: { children: React.ReactNode }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Content $backgroundColor={colorBgContainer}>{children}</Content>
    </Layout>
  );
};

export default ProfileLayout;

import * as sentry from '@sentry/react';
import pino from 'pino';

// Logger instance
// In development environment logs are printed to the console
// In production environment logs are sent to sentry for warning and error levels
export const logger = pino({
  browser: {
    asObject: true,
    write:
      import.meta.env.MODE === 'production'
        ? {
            write: (o) => {
              sentry.captureEvent(o);
            },
          }
        : undefined,
  },
  level: import.meta.env.MODE === 'production' ? 'warn' : 'debug',
  base: {
    deploymentEnvironment: import.meta.env.MODE,
    version: import.meta.env.VITE_VERSION ?? 'unknown',
  },
});

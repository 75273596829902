import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import useMessage from 'antd/es/message/useMessage';
import Typography from 'antd/es/typography';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import UnauthCard from '@components/UnauthCard';
import { apiClient } from '@queries/index';
import { logger } from '@utils/logger';

type ResetPasswordCallbackFormData = Partial<{
  newPassword: string;
  confirmPassword: string;
}>;

const ResetPasswordCallback = () => {
  const [searchParams] = useSearchParams();
  const [messageApi, messageContext] = useMessage();
  const [form] = Form.useForm<ResetPasswordCallbackFormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const signedToken = searchParams.get('signedToken');

  const onFinish = async (values: ResetPasswordCallbackFormData) => {
    const payload = {
      signedToken,
      password: values.newPassword,
    };

    setIsSubmitting(true);
    try {
      await apiClient.post('/auth/password-reset', payload);
      void messageApi.success('Password reset successfully.');
      navigate('/auth/reset-password/success');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          void messageApi.warning(error.response.data.errors?.[0]?.message);
        }
      } else {
        logger.error({
          message: 'Error resetting password',
          error,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <UnauthCard>
      {messageContext}
      <Typography.Title level={3}>{'Reset your password'}</Typography.Title>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: 'Please input your new password' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button loading={isSubmitting} type="primary" htmlType="submit">
            {'Reset Password'}
          </Button>
        </Form.Item>
      </Form>
      <Typography.Paragraph>
        {'Return to '}
        <Link to="/auth/login">{'Login'}</Link>
      </Typography.Paragraph>
    </UnauthCard>
  );
};

export default ResetPasswordCallback;

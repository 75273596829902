import App from 'antd/es/app';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { apiClient } from '../../../queries';

import UnauthCard from '@components/UnauthCard';
import { useSessionUserId } from '@utils/auth';

type FormData = {
  email: string;
};

const ResetPassword = () => {
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const sessionUserId = useSessionUserId();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const onFinish = async ({ email }: FormData) => {
    setIsLoading(true);
    try {
      await apiClient.get('/auth/password-reset', {
        params: { email },
      });
      navigate('/auth/reset-password/sent');
    } catch (error) {
      void message.error('Invalid email or password');
    } finally {
      setIsLoading(false);
    }
  };

  if (sessionUserId) {
    return <Navigate to="/home" />;
  }

  return (
    <div
      style={{
        marginLeft: '16px',
      }}
    >
      <UnauthCard>
        <Typography.Title level={3}>{'Reset your password'}</Typography.Title>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="email" label="Email" required>
            <Input size="large" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              {'Send Password Reset Email'}
            </Button>
          </Form.Item>
        </Form>
        <Typography.Text>
          <Link to="/auth/login">{'Return to Login'}</Link>
        </Typography.Text>
      </UnauthCard>
    </div>
  );
};

export default ResetPassword;

import { queryClient } from '@/globals';
import App from 'antd/es/app';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  type ConversationMessageLink,
  type CreateConversationMessageBody,
  type UpdateConversationBody,
} from '@mai/types';

import ChatInput from './ChatInput';
import ConversationHeader from './Header';
import Message from './Message';

import EmptyChatImage from '@assets/undraw_messaging.svg';
import ContentContainer from '@components/ContentContainer';
import {
  useFetchConversation,
  useFetchConversationMessages,
} from '@queries/conversations';
import { apiClient } from '@queries/index';
import { subscribe, unsubscribe } from '@queries/websockets';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

const Conversation = ({
  conversationId,
  teamId,
  userFullName,
  projectId,
  disableHeader,
  disableReferenceLinks,
}: {
  conversationId: string;
  teamId: string;
  userFullName?: string;
  projectId?: string;
  disableHeader?: boolean;
  disableReferenceLinks?: boolean;
}) => {
  const sessionUserId = useSessionUserId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasSetDefaultParams, setHasSetDefaultParams] = useState(false);

  const { message } = App.useApp();

  const teamIsActive = useTeamIsActive();

  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const [currentStreamingMessageId, setCurrentStreamingMessageId] = useState<
    string | null
  >(null);
  const [currentStreamingMessageContent, setCurrentStreamingMessageContent] =
    useState<string | null>(null);
  const [
    currentStreamingMessageInformation,
    setCurrentStreamingMessageInformation,
  ] = useState<string | null>(null);

  const autoScrollRef = useRef<HTMLDivElement>(null);

  const conversationQuery = useFetchConversation(conversationId);
  const conversation = conversationQuery.data;

  const conversationMessagesQuery =
    useFetchConversationMessages(conversationId);

  const messages = useMemo(() => {
    return (
      conversationMessagesQuery.data?.sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      ) ?? []
    );
  }, [conversationMessagesQuery.data]);

  const streamingMessage = useMemo(() => {
    if (!currentStreamingMessageId || !currentStreamingMessageContent) return;
    return {
      id: currentStreamingMessageId,
      role: 'assistant' as const,
      content: currentStreamingMessageContent,
      references: [],
      plan: null,
    };
  }, [currentStreamingMessageContent, currentStreamingMessageId]);

  // Clear the current streaming message content and id if it appears in the list of messages
  useEffect(() => {
    if (!streamingMessage) return;
    if (messages.some((message) => message.id === streamingMessage.id)) {
      setIsGenerating(false);
      setCurrentStreamingMessageId(null);
      setCurrentStreamingMessageContent(null);
      setCurrentStreamingMessageInformation(null);
    }
  }, [messages, streamingMessage]);

  // Subscribe to updates on the conversation itself
  useEffect(() => {
    if (!conversationId) return;
    subscribe('conversation_updated', { conversationId }, () => {
      void queryClient.invalidateQueries({
        queryKey: ['conversation'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['conversationMessages'],
      });
    });
    subscribe('chat_completion_chunk', { conversationId }, (data) => {
      if (currentStreamingMessageId !== data.conversationMessageId)
        setCurrentStreamingMessageId(data.conversationMessageId);
      if (data.contentType === 'chunk') {
        setCurrentStreamingMessageContent(data.content);
      } else if (data.contentType === 'information') {
        setCurrentStreamingMessageInformation(data.content);
      }
    });
    return () => {
      unsubscribe('conversation_updated', { conversationId });
      unsubscribe('chat_completion_chunk', { conversationId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto scroll to the bottom of the chat when a new message is received
  // TODO: if a user has scrolled up manually, we probably shouldn't pull them to the bottom
  useEffect(() => {
    if (!autoScrollRef.current) return;
    autoScrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, streamingMessage]);

  // Source the currently selected links from the search params
  const links: ConversationMessageLink[] = useMemo(() => {
    const focusedResourceType =
      searchParams.get('focusedResourceType') ?? 'document';

    if (projectId) {
      return [{ type: 'project', id: projectId }];
    }

    if (focusedResourceType === 'document') {
      const focusedDocumentIds =
        searchParams.get('focusedDocumentIds')?.split(',') ?? [];
      return focusedDocumentIds.map((id) => ({
        type: 'document',
        id,
      }));
    } else if (focusedResourceType === 'project') {
      const focusedProjectId = searchParams.get('focusedProjectId');
      return focusedProjectId
        ? [{ type: 'project', id: focusedProjectId }]
        : [];
    }
    return [];
  }, [projectId, searchParams]);

  // On first load, set the filters to the latest message's links
  useEffect(() => {
    if (conversationMessagesQuery.isLoading || hasSetDefaultParams) {
      return;
    }
    const latestUserMessage = conversationMessagesQuery.data
      ?.filter((message) => message.role === 'user')
      .at(-1);
    if (!latestUserMessage) {
      return;
    }
    const projectId = latestUserMessage.links.find(
      (link) => link.type === 'project',
    )?.id;
    const documentIds = latestUserMessage.links
      .filter((link) => link.type === 'document')
      .map(({ id }) => id);
    if (projectId) {
      setSearchParams((prev) => {
        prev.set('focusedResourceType', 'project');
        prev.set('focusedProjectId', projectId);
        return prev;
      });
    } else if (documentIds.length > 0) {
      setSearchParams((prev) => {
        prev.set('focusedResourceType', 'document');
        prev.set('focusedDocumentIds', documentIds.join(','));
        return prev;
      });
    }
    setHasSetDefaultParams(true);
  }, [
    conversationMessagesQuery.data,
    conversationMessagesQuery.isLoading,
    hasSetDefaultParams,
    setSearchParams,
  ]);

  // Broken state, shouldn't happen
  if (!teamId || !conversationId) {
    logger.error(
      {
        teamId,
        conversationId,
      },
      'Missing teamId or conversationId in the path',
    );
    return <ContentContainer.Error />;
  }

  const onMessageSubmit = async ({
    value,
    links,
  }: {
    value: string;
    links: ConversationMessageLink[];
  }) => {
    setIsSending(true);
    setIsGenerating(true);
    try {
      await apiClient.post(`/conversations/${conversationId}/messages`, {
        content: value,
        role: 'user',
        links,
      } satisfies CreateConversationMessageBody);
      track('CREATED_CONVERSATION_MESSAGE', {
        conversationId,
        sessionUserId,
        teamId,
      });
      await conversationMessagesQuery.refetch();
    } finally {
      setIsSending(false);
    }
  };

  const renderedMessages = (
    streamingMessage ? [...messages, streamingMessage] : messages
  ).map((conversationMessage, i) => (
    <Message
      id={conversationMessage.id}
      teamId={teamId}
      userFullName={userFullName}
      key={conversationMessage.id}
      role={conversationMessage.role}
      content={conversationMessage.content}
      references={conversationMessage?.references ?? []}
      disableReferenceLinks={disableReferenceLinks}
      plan={conversationMessage.plan}
      onDeleteMessage={async () => {
        await apiClient.delete(
          `/conversations/${conversationId}/messages/${conversationMessage.id}`,
        );
        track('DELETED_CONVERSATION_MESSAGE', {
          conversationId,
          conversationMessageId: conversationMessage.id,
          sessionUserId,
          teamId,
        });
        await conversationMessagesQuery.refetch();
      }}
      isRegenerating={isGenerating}
      onRegenerate={
        i === messages.length - 1 && conversationMessage.role === 'user'
          ? async () => {
              try {
                await apiClient.patch(`/conversations/${conversationId}`, {
                  shouldRegenerateMessages: true,
                } satisfies UpdateConversationBody);
                track('REGENERATED_CONVERSATION_MESSAGE', {
                  conversationId,
                  conversationMessageId: conversationMessage.id,
                  sessionUserId,
                  teamId,
                });
                setIsGenerating(true);
                setTimeout(() => {
                  autoScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 1000);
              } catch (e) {
                await message.error(
                  'Failed to regenerate message. Please try again.',
                );
              }
            }
          : undefined
      }
    />
  ));

  if (conversationQuery.isLoading || conversationMessagesQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (!conversation) {
    return <ContentContainer.NotFound />;
  }

  return (
    <>
      {disableHeader ? (
        <div style={{ height: '0.5rem' }} />
      ) : (
        <ConversationHeader
          conversationId={conversationId}
          teamId={teamId}
          projectId={projectId}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            flexShrink: 1,
            overflowY: 'auto',
            height: '100%',
          }}
        >
          {renderedMessages}
          {messages.length === 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <img
                src={EmptyChatImage}
                alt="Empty chat"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '300px',
                  marginBottom: '1rem',
                }}
              />
              <Typography.Text type="secondary">
                No messages in this chat yet
              </Typography.Text>
            </div>
          )}
          {isGenerating && !currentStreamingMessageContent && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '0.25rem',
              }}
            >
              <Typography.Text type="secondary">
                {currentStreamingMessageInformation ??
                  'Generating response ...'}
              </Typography.Text>
              <Spin style={{ marginRight: '0.25rem' }} />
            </div>
          )}

          <div ref={autoScrollRef} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '1rem',
            flexShrink: 0,
            marginBottom: '1rem',
          }}
        >
          <ChatInput
            disabled={isSending || !teamIsActive}
            onSubmit={onMessageSubmit}
            links={links}
          />
        </div>
      </div>
    </>
  );
};

export default Conversation;

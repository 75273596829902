import { useQuery } from '@tanstack/react-query';

import {
  type InsightRequest,
  type InsightRequestRun,
  InsightRequestRunSchema,
  InsightRequestSchema,
  type ListInsightRequestFilters,
  type ListInsightRequestResponse,
  ListInsightRequestResponseSchema,
  type ListInsightRequestResultFilters,
  type ListInsightRequestResultResponse,
  ListInsightRequestResultResponseSchema,
  type ListInsightRequestRunFilters,
  type ListInsightRequestRunResponse,
  ListInsightRequestRunResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

export const useInsightRequestQuery = (
  insightRequestId: string | undefined,
) => {
  return useQuery({
    queryKey: ['insightRequests', insightRequestId],
    queryFn: async () => {
      const { data } = await apiClient.get<InsightRequest>(
        `/insight-requests/${insightRequestId}`,
      );
      const validatedData = InsightRequestSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      return data;
    },
    enabled: !!insightRequestId,
  });
};

export const useInsightRequestsQuery = (params: ListInsightRequestFilters) => {
  return useQuery({
    queryKey: ['insightRequests', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListInsightRequestResponse>(
        `/insight-requests`,
        { params },
      );
      const validatedData = ListInsightRequestResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      return data;
    },
  });
};

export const useInsightRequestResultsQuery = (
  insightRequestId: string | undefined,
  params: ListInsightRequestResultFilters,
) => {
  return useQuery({
    queryKey: ['insightRequestResults', insightRequestId, params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListInsightRequestResultResponse>(
        `/insight-requests/${insightRequestId}/results`,
        { params },
      );
      const validatedData =
        ListInsightRequestResultResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      return data;
    },
    enabled: !!insightRequestId,
  });
};

export const useInsightRequestRunQuery = (
  insightRequestRunId: string | undefined,
) => {
  return useQuery({
    queryKey: ['insightRequestRuns', insightRequestRunId],
    queryFn: async () => {
      const { data } = await apiClient.get<InsightRequestRun>(
        `/insight-request-runs/${insightRequestRunId}`,
      );
      const validatedData = InsightRequestRunSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      return data;
    },
    enabled: !!insightRequestRunId,
  });
};

export const useInsightRequestRunsQuery = (
  params: ListInsightRequestRunFilters,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['insightRequestRuns', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListInsightRequestRunResponse>(
        `/insight-request-runs`,
        { params },
      );
      const validatedData = ListInsightRequestRunResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      return data;
    },
    enabled,
  });
};

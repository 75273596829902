import Button from 'antd/es/button';
import Typography from 'antd/es/typography';

import NotFoundImage from '@assets/undraw_page_not_found.svg';

const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 24,
        paddingBottom: 96,
      }}
    >
      <img
        style={{
          width: '50%',
          maxWidth: 300,
          height: 'auto',
        }}
        src={NotFoundImage}
        alt="Page not found"
      />
      <Typography.Title level={3}>
        The page you are looking for does not exist.
      </Typography.Title>
      <Typography.Text>
        Please check the URL in the address bar and try again, or go back to the
        previous page to continue.
      </Typography.Text>
      <div
        style={{
          marginTop: 24,
        }}
      >
        <Button type="primary" onClick={() => window.location.reload()}>
          Try Again
        </Button>
        <Button style={{ marginLeft: 8 }} onClick={() => window.history.back()}>
          Go back
        </Button>
      </div>
    </div>
  );
};

export default NotFound;

import { z } from 'zod';

export const commaDelimitedList = <T = string>(
  outputType?: z.ZodArray<z.ZodType<T>>,
) => {
  const output = z
    .string()
    .nullable()
    .transform((v) => v?.split(',') ?? [])
    .pipe(outputType ?? z.string().array());

  return output as z.ZodPipeline<
    z.ZodEffects<z.ZodNullable<z.ZodString>, string[], string | null>,
    z.ZodArray<z.ZodType<T>, 'many'>
  >;
};

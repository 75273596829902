import { z } from 'zod';

import { commaDelimitedList } from './utils';

const BaseTeamConfigurationSchema = z.object({
  teamId: z.string().nanoid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const TeamConfigurationSchema = z.union([
  BaseTeamConfigurationSchema.extend({
    key: z.literal('deployedProjectAssistantLimit'),
    value: z.number().int().positive(),
  }),
  BaseTeamConfigurationSchema.extend({
    key: z.literal('betaFeatures'),
    value: z.array(z.enum(['deployedProjectAssistants'])),
  }),
]);

export const TeamConfigurationKeySchema = z.enum([
  'deployedProjectAssistantLimit',
  'betaFeatures',
]);

export type TeamConfigurationKey = z.infer<typeof TeamConfigurationKeySchema>;

export type TeamConfiguration = z.infer<typeof TeamConfigurationSchema>;

export const CreateTeamConfigurationRequestSchema = z.union([
  BaseTeamConfigurationSchema.omit({
    createdAt: true,
    updatedAt: true,
  }).extend({
    key: z.literal('deployedProjectAssistantLimit'),
    value: z.number().int().positive(),
  }),
  BaseTeamConfigurationSchema.omit({
    createdAt: true,
    updatedAt: true,
  }).extend({
    key: z.literal('betaFeatures'),
    value: z.array(z.enum(['deployedProjectAssistants'])),
  }),
]);

export type CreateTeamConfigurationRequest = z.infer<
  typeof CreateTeamConfigurationRequestSchema
>;

export const ListTeamConfigurationsRequestSchema = z.object({
  teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
  keys: commaDelimitedList(TeamConfigurationKeySchema.array()).optional(),
});

export type ListTeamConfigurationsRequest = z.infer<
  typeof ListTeamConfigurationsRequestSchema
>;

export const ListTeamConfigurationsResponseSchema = z.array(
  TeamConfigurationSchema,
);

export type ListTeamConfigurationsResponse = z.infer<
  typeof ListTeamConfigurationsResponseSchema
>;

export const DeleteTeamConfigurationsRequestSchema = z.object({
  teamIds: z.string().nanoid().array(),
  keys: TeamConfigurationKeySchema.array(),
});

export type DeleteTeamConfigurationsRequest = z.infer<
  typeof DeleteTeamConfigurationsRequestSchema
>;

import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';

import UnauthCard from '@components/UnauthCard';

const ResetPasswordSuccess = () => {
  return (
    <UnauthCard>
      <Typography.Title level={3}>{'Reset your password'}</Typography.Title>
      <div>
        <Typography.Text>
          {
            'Your password has been successfully reset. Please return to the login page to sign in using your new password.'
          }
        </Typography.Text>
      </div>
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <Typography.Text>
          {'Back to '}
          <Link to="/auth/login">{'Login'}</Link>
        </Typography.Text>
      </div>
    </UnauthCard>
  );
};

export default ResetPasswordSuccess;

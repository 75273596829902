import theme from 'antd/es/theme';
import styled from 'styled-components';

const Container = styled.div<{ $backgroundColor: string }>`
  display: flex;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding-bottom: 1rem;
  width: 100%;
`;

const TableHeader = ({ children }: { children: React.ReactNode }) => {
  const { token } = theme.useToken();
  return (
    <Container
      $backgroundColor={token.Table?.headerBg ?? token.colorBgContainer}
    >
      {children}
    </Container>
  );
};

export default TableHeader;

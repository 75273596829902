import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const ClassificationLinkSchema = z.object({
  type: z.enum(['embedding']),
  id: z.string(),
});

export type ClassificationLink = z.infer<typeof ClassificationLinkSchema>;

export const ClassificationSchema = z.object({
  id: z.string(),
  classifier: z.string(),
  key: z.string(),
  value: z.string(),
  confidence: z.number(),
  createdAt: z.coerce.date(),
  links: z.array(ClassificationLinkSchema),
});

export type Classification = z.infer<typeof ClassificationSchema>;

export const ListClassificationsQuerySchema = z.object({
  classifiers: commaDelimitedList().optional(),
  keys: commaDelimitedList().optional(),
  teamIds: commaDelimitedList().optional(),
  documentIds: commaDelimitedList().optional(),
  documentExtractionIds: commaDelimitedList().optional(),
  classificationIds: commaDelimitedList().optional(),
});

export type ListClassificationsQuery = z.infer<
  typeof ListClassificationsQuerySchema
>;

export const ListClassificationsResponseSchema = z.array(ClassificationSchema);

export type ListClassificationsResponse = z.infer<
  typeof ListClassificationsResponseSchema
>;

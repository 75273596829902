import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const AnalysisRequestResourceSchema = z.object({
  resourceType: z.enum(['document']),
  resourceId: z.string(),
  id: z.string(),
});

export const AnalysisRequestFacetTypeSchema = z.enum([
  'date',
  'string',
  'boolean',
  'number',
]);

export type AnalysisRequestFacetType = z.infer<
  typeof AnalysisRequestFacetTypeSchema
>;

export const AnalysisRequestFacetSchema = z.object({
  id: z.string(),
  type: AnalysisRequestFacetTypeSchema,
  name: z.string(),
  description: z.string(),
});

export type AnalysisRequestFacet = z.infer<typeof AnalysisRequestFacetSchema>;

export const AnalysisRequestSchema = z.object({
  id: z.string(),
  teamId: z.string(),
  name: z.string(),
  resources: z.array(AnalysisRequestResourceSchema),
  facets: z.array(AnalysisRequestFacetSchema),
  createdAt: z.coerce.date(),
  createdBy: z.string(),
});

export type AnalysisRequest = z.infer<typeof AnalysisRequestSchema>;

export const CreateAnalysisRequestBodySchema = z.object({
  name: z.string(),
  teamId: z.string(),
  resources: z
    .array(
      AnalysisRequestResourceSchema.omit({
        id: true,
      }),
    )
    .max(100),
  facets: z
    .array(
      AnalysisRequestFacetSchema.omit({
        id: true,
      }),
    )
    .max(10),
});

export type CreateAnalysisRequestBody = z.infer<
  typeof CreateAnalysisRequestBodySchema
>;

export const GetAnalysisRequestPathSchema = z.object({
  analysisRequestId: z.string(),
});

export type GetAnalysisRequestPath = z.infer<
  typeof GetAnalysisRequestPathSchema
>;

export const GetAnalysisRequestResponseSchema = AnalysisRequestSchema;

export type GetAnalysisRequestResponse = z.infer<
  typeof GetAnalysisRequestResponseSchema
>;

export const ListAnalysisRequestsQuerySchema = z.object({
  teamIds: commaDelimitedList().optional(),
  analysisRequestIds: commaDelimitedList().optional(),
  createdBy: z.string().optional(),
});

export type ListAnalysisRequestsQuery = z.infer<
  typeof ListAnalysisRequestsQuerySchema
>;

export const ListAnalysisRequestsResponseSchema = z.array(
  AnalysisRequestSchema,
);

export type ListAnalysisRequestsResponse = z.infer<
  typeof ListAnalysisRequestsResponseSchema
>;

export const UpdateAnalysisRequestRequestBodySchema = z.object({
  name: z.string().optional(),
  resources: z
    .array(
      AnalysisRequestResourceSchema.omit({
        id: true,
      }),
    )
    .max(100)
    .optional(),
  facets: z
    .array(
      AnalysisRequestFacetSchema.omit({
        id: true,
      }),
    )
    .max(10)
    .optional(),
  shouldDeleteResults: z.boolean().optional(),
});

export type UpdateAnalysisRequestRequestBody = z.infer<
  typeof UpdateAnalysisRequestRequestBodySchema
>;

export const DeleteAnalysisRequestsQuerySchema = z.object({
  analysisRequestIds: commaDelimitedList(),
});

export type DeleteAnalysisRequestsQuery = z.infer<
  typeof DeleteAnalysisRequestsQuerySchema
>;

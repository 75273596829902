import { useParams } from 'react-router-dom';

// Returns the current team id based on the URL or Local Context
export const useTeamId = () => {
  const { teamId } = useParams();
  return teamId;
};

// Returns the current client id based on the URL or Local Context
export const useClientId = () => {
  const { clientId } = useParams();
  return clientId;
};

// Returns the current matter id based on the URL or Local Context
export const useMatterId = () => {
  const { matterId } = useParams();
  return matterId;
};

// Returns the current workflow id based on the URL or Local Context
export const useWorkflowId = () => {
  const { workflowId } = useParams();
  return workflowId;
};

// Returns the current task id based on the URL or Local Context
export const useTaskId = () => {
  const { taskId } = useParams();
  return taskId;
};

// Returns the current note id based on the URL or Local Context
export const useNoteId = () => {
  const { noteId } = useParams();
  return noteId;
};

// Returns the current document id based on the URL or Local Context
export const useDocumentId = () => {
  const { documentId } = useParams();
  return documentId;
};

// Returns the current conversation id based on the URL or Local Context
export const useConversationId = () => {
  const { conversationId } = useParams();
  return conversationId;
};

// Returns the current contract id based on the URL or Local Context
export const useContractId = () => {
  const { contractId } = useParams();
  return contractId;
};

// Returns the current contact id based on the URL or Local Context
export const useContactId = () => {
  const { contactId } = useParams();
  return contactId;
};

// Returns the current analysis request id based on the URL or Local Context
export const useAnalysisRequestId = () => {
  const { analysisRequestId } = useParams();
  return analysisRequestId;
};

// Returns the current insight request id based on the URL or Local Context
export const useInsightRequestId = () => {
  const { insightRequestId } = useParams();
  return insightRequestId;
};

// Returns the current project id based on the URL or Local Context
export const useProjectId = () => {
  const { projectId } = useParams();
  return projectId;
};

import { formatInTimeZone } from 'date-fns-tz';

const StackedDateTime = ({ dateString }: { dateString: string | Date }) => {
  const dateTime = new Date(dateString);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = formatInTimeZone(dateTime, timeZone, 'MMM d, yyyy');
  const time = formatInTimeZone(dateTime, timeZone, 'h:mm a z');

  return (
    <div>
      <div>{date}</div>
      <div style={{ opacity: 0.5, fontSize: '0.8rem' }}>{time}</div>
    </div>
  );
};

export default StackedDateTime;

import { queryClient } from '@/globals';
import { useQuery } from '@tanstack/react-query';

import {
  type DeleteProjectsFilter,
  type ListProjectsFilter,
  type ListProjectsResponse,
  ListProjectsResponseSchema,
  type Project,
  ProjectSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useProjectsQuery = (
  params: ListProjectsFilter,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['projects', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListProjectsResponse>('/projects', {
        params,
      });
      const validatedData = ListProjectsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch projects',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

export const useProjectQuery = (projectId?: string | null) => {
  return useQuery({
    queryKey: ['projects', projectId],
    queryFn: async () => {
      const { data } = await apiClient.get<Project>(`/projects/${projectId}`);
      const validatedData = ProjectSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch project',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled: !!projectId,
  });
};

export const deleteProjects = async (projectIds: string[]) => {
  try {
    if (projectIds.length === 0) {
      logger.warn({ projectIds }, 'No project ids provided');
      return;
    }

    if (projectIds.length > 1) {
      logger.info({ projectIds }, 'Deleting multiple projects');
      await apiClient.delete('/projects', {
        params: {
          projectIds,
        } satisfies DeleteProjectsFilter,
      });
    } else {
      logger.info({ projectIds }, 'Deleting single project');
      await apiClient.delete(`/projects/${projectIds[0]}`);
    }
  } finally {
    await queryClient.invalidateQueries({ queryKey: ['projects'] });
  }
};

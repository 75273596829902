import Graph from 'graphology';
import ForceSupervisor from 'graphology-layout-forceatlas2/worker';
import { useEffect, useRef } from 'react';
import { Sigma } from 'sigma';
import styled from 'styled-components';

import { type ConversationMessage } from '@mai/types';

const MessagePlan = ({ plan }: { plan: ConversationMessage['plan'] }) => {
  const sigmaGraphRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sigmaGraphRef.current || !plan) return;

    const graph = new Graph();

    // Calculate node sizes based on edge count
    const nodeSizes = new Map<string, number>();
    plan.steps.forEach((item) => {
      const edgeCount =
        item.dependencies.length +
        plan.steps.filter((step) => step.dependencies.includes(item.id)).length;
      const size = Math.max(5, edgeCount * 2 + 5);
      nodeSizes.set(item.id.toString(), size);
    });

    const writerNodeId = plan.steps.find((item) => item.agent === 'writer')?.id;

    // Add nodes and edges
    plan.steps.forEach((item) => {
      const isWriter = item.id === writerNodeId;
      graph.addNode(item.id.toString(), {
        label: item.summary ?? item.instruction,
        size: (nodeSizes.get(item.id.toString()) || 10) + 10,
        color: isWriter ? '#FF6347' : '#6495ED', // Different color for writer node
        x: isWriter ? 0 : Math.random() * 100,
        y: isWriter ? 0 : Math.random() * 100,
        fixed: isWriter, // Fix position for writer node
      });
      item.subtopics.forEach((subtopic) => {
        graph.addNode(subtopic, {
          label: subtopic,
          size: 8,
          color: '#A9A9A9',
          x: Math.random() * 100,
          y: Math.random() * 100,
        });
      });
    });

    plan.steps.forEach((item) => {
      item.subtopics.forEach((subtopic) => {
        graph.addEdge(subtopic, item.id.toString(), {
          size: 6,
          color: '#A9A9A9',
          type: 'arrow',
        });
      });
      item.dependencies.forEach((dependency) => {
        graph.addEdge(dependency.toString(), item.id.toString(), {
          size: 6,
          color: '#A9A9A9',
          type: 'arrow',
        });
      });
    });

    // Apply force-directed layout
    const layout = new ForceSupervisor(graph, {
      settings: {
        linLogMode: false,
        outboundAttractionDistribution: true,
        adjustSizes: false,
        strongGravityMode: false,
        gravity: 0.5,
        scalingRatio: 100,
        barnesHutOptimize: true,
        barnesHutTheta: 0.5,
        slowDown: 10,
        edgeWeightInfluence: 0.5,
      },
    });

    const sigma = new Sigma(graph, sigmaGraphRef.current, {
      renderEdgeLabels: false,
      labelSize: 12,
      labelColor: {
        color: '#000000',
      },
      nodeReducer: (_node, data) => ({
        ...data,
        label: data.label,
        color: data.color,
        highlighted: data.fixed ? true : data.highlighted, // Highlight the writer node
      }),
      edgeReducer: (_edge, data) => ({
        ...data,
        type: 'arrow',
      }),
      labelRenderedSizeThreshold: 1,
      labelDensity: 0.07,
      labelGridCellSize: 60,
    });

    // Adjust the camera to fit the graph
    sigma.getCamera().animate({ ratio: 1.5 }, { duration: 500 });

    layout.start();
    setTimeout(() => {
      layout.stop();
    }, 1000);

    return () => {
      sigma.kill();
    };
  }, [plan]);

  return <GraphContainer ref={sigmaGraphRef} />;
};

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
`;

export default MessagePlan;

import { z } from 'zod';

export const KnowledgeEdgeSchema = z.object({
  id: z.string().nanoid(),
  sourceKnowledgeNodeId: z.string().nanoid(),
  targetKnowledgeNodeId: z.string().nanoid(),
  label: z.string(),
  embeddingId: z.string().nanoid(),
  createdAt: z.coerce.date(),
  links: z.object({
    embeddings: z.array(z.string().nanoid()),
  }),
});

export type KnowledgeEdge = z.infer<typeof KnowledgeEdgeSchema>;

export const ListKnowledgeEdgeFiltersSchema = z.object({
  knowledgeEdgeIds: z.array(z.string().nanoid()).optional(),
  embeddingIds: z.array(z.string().nanoid()).optional(),
  nodeIds: z.array(z.string().nanoid()).optional(),
  teamIds: z.array(z.string().nanoid()).optional(),
  projectIds: z.array(z.string().nanoid()).optional(),
  documentIds: z.array(z.string().nanoid()).optional(),
});

export type ListKnowledgeEdgeFilters = z.infer<
  typeof ListKnowledgeEdgeFiltersSchema
>;

export const KnowledgeNodeSchema = z.object({
  id: z.string(),
  label: z.string(),
  scope: z.string(),
  createdAt: z.coerce.date(),
});

export type KnowledgeNode = z.infer<typeof KnowledgeNodeSchema>;

export const ListKnowledgeNodesFiltersSchema = z.object({
  knowledgeNodeIds: z.array(z.string().nanoid()).optional(),
});

export type ListKnowledgeNodesFilters = z.infer<
  typeof ListKnowledgeNodesFiltersSchema
>;

export const SearchKnowledgeGraphResponseSchema = z.object({
  nodes: z.array(KnowledgeNodeSchema),
  edges: z.array(KnowledgeEdgeSchema.extend({ relevance: z.number() })),
});

export type SearchKnowledgeGraphResponse = z.infer<
  typeof SearchKnowledgeGraphResponseSchema
>;

export const SearchKnowledgeGraphRequestSchema = z.object({
  query: z.string(),
  rootLimit: z.number().min(1).max(100).optional(),
  depth: z.number().min(0).max(3).optional(),
  documentIds: z.array(z.string().nanoid()).optional(),
  projectIds: z.array(z.string().nanoid()).optional(),
  teamIds: z.array(z.string().nanoid()).optional(),
  relevance: z.number().min(0).max(1).optional(),
});

export type SearchKnowledgeGraphRequest = z.infer<
  typeof SearchKnowledgeGraphRequestSchema
>;

import { z } from 'zod';

export const StripeWebhookRecordSchema = z.object({
  id: z.string(),
  stripeEventId: z.string(),
  stripeEventType: z.string(),
  stripeEventData: z.unknown(),
  createdAt: z.coerce.date(),
});

export type StripeWebhookRecord = z.infer<typeof StripeWebhookRecordSchema>;

export const ListStripeWebhookRecordFiltersSchema = z.object({
  stripeEventIds: z.array(z.string()).optional(),
  stripeEventTypes: z.array(z.string()).optional(),
});

export type ListStripeWebhookRecordFilters = z.infer<
  typeof ListStripeWebhookRecordFiltersSchema
>;

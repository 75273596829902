import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';

import SearchImage from '@assets/undraw_location_search.svg';
import ActiveTeamAlert from '@components/ActiveTeamAlert';
import AdvancedSearch from '@components/AdvancedSearch';
import ContentContainer from '@components/ContentContainer';
import { Hero } from '@components/Hero';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const SearchPage = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();
  const teamIsActive = useTeamIsActive();

  if (!teamId || !sessionUserId) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Search Overview"
        breadcrumbs={[
          {
            label: 'Team',
            to: `/team/${teamId}`,
          },
          {
            label: 'Search',
          },
        ]}
        subtitle={!teamIsActive && <ActiveTeamAlert teamId={teamId} />}
      />
      <Hero
        id="search-overview"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={SearchImage}
        title="Search Across Everything"
        extra={
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
          >
            <div>
              <Typography.Text>
                Find what you need, when you need it. With our semantic search
                you can search across entire documents and find items that would
                normally be lost.
              </Typography.Text>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              <Link
                to="https://scribehow.com/shared/How_to_Search_for_Employment_Agreements_Online__TKqOlGuqRSmELFhRKskmkQ"
                target="_blank"
              >
                <Button
                  disabled={!teamIsActive}
                  style={{
                    boxShadow: '0px 0px 0px rgba(98, 92, 221, 0.2)',
                  }}
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        }
      />
      <AdvancedSearch teamId={teamId} sessionUserId={sessionUserId} />
    </ContentContainer>
  );
};

export default SearchPage;

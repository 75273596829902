import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const TeamCreditUsageLimitSchema = z.object({
  id: z.string(),
  teamId: z.string(),
  value: z.number().min(0).nullable(),
  createdAt: z.coerce.date(),
});

export type TeamCreditUsageLimit = z.infer<typeof TeamCreditUsageLimitSchema>;

export const ListTeamCreditUsageLimitsQuerySchema = z
  .object({
    teamCreditUsageLimitIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    createdAtGt: z.coerce.date().optional(),
    createdAtLt: z.coerce.date().optional(),
    createdAtGte: z.coerce.date().optional(),
    createdAtLte: z.coerce.date().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListTeamCreditUsageLimitsQuery = z.infer<
  typeof ListTeamCreditUsageLimitsQuerySchema
>;

export const ListTeamCreditUsageLimitsResponseSchema = z.array(
  TeamCreditUsageLimitSchema,
);

export type ListTeamCreditUsageLimitsResponse = z.infer<
  typeof ListTeamCreditUsageLimitsResponseSchema
>;

export const CreateTeamCreditUsageLimitRequestSchema = z.object({
  teamId: z.string(),
  value: z.number().min(0),
});

export type CreateTeamCreditUsageLimitRequest = z.infer<
  typeof CreateTeamCreditUsageLimitRequestSchema
>;

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import CreateProjectForm from '@components/forms/CreateProjectForm';
import ProjectsTable from '@components/tables/ProjectsTable';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const ProjectsOverviewPage = () => {
  const navigate = useNavigate();
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState(false);

  if (!teamId) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer>
      <Modal
        title="Create Project"
        open={isCreateProjectModalOpen}
        onCancel={() => {
          setIsCreateProjectModalOpen(false);
        }}
        footer={null}
        closable
        onClose={() => {
          setIsCreateProjectModalOpen(false);
        }}
        destroyOnClose
      >
        <div style={{ padding: '1rem', height: '100%' }}>
          <CreateProjectForm
            teamId={teamId}
            onSuccess={(data) => {
              setIsCreateProjectModalOpen(false);
              navigate(`/team/${teamId}/projects/${data.id}`);
            }}
          />
        </div>
      </Modal>
      <ContentContainer.Header
        title="Projects Overview"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/teams/${teamId}`,
          },
          {
            label: 'Projects',
          },
        ]}
        subtitle={
          !teamIsActive ? <ActiveTeamAlert teamId={teamId} /> : undefined
        }
        cta={
          <Button
            type="primary"
            onClick={() => {
              setIsCreateProjectModalOpen(true);
            }}
            icon={<PlusCircleOutlined />}
            disabled={!teamIsActive}
          >
            New Project
          </Button>
        }
      />
      <ProjectsTable teamId={teamId} />
    </ContentContainer>
  );
};

export default ProjectsOverviewPage;

import Card from 'antd/es/card';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { Logo } from './Logo';
import ThemeSwitch from './ThemeSwitch';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: 70vw;
  min-width: 400px;
  max-width: 500px;
  border-radius: 8px;
`;

const UnauthCard = ({ children }: { children: ReactNode }) => {
  return (
    <StyledCard>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Logo width="50px" />
        <ThemeSwitch />
      </div>
      {children}
    </StyledCard>
  );
};

export default UnauthCard;

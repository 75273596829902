import { useTeamId } from './router';

import { useFetchTeam } from '@queries/teams';

/**
 * Returns whether or not the team is active and has a subscription.
 */
export function useTeamIsActive(): boolean {
  const teamId = useTeamId();
  const team = useFetchTeam(teamId);
  return team.data?.isActive ?? true;
}

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AnalyzeImage from '@assets/undraw_goals.svg';
import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import { Hero } from '@components/Hero';
import AnalysisRequestsTable from '@components/tables/AnalysisRequestsTable';
import { createAnalysisRequest } from '@queries/analysis-requests';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { track } from '@utils/mixpanel';
import { useTeamId } from '@utils/router';

const AnalyzePage = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [isCreatingNewAnalysis, setIsCreatingNewAnalysis] = useState(false);
  const teamIsActive = useTeamIsActive();

  if (!teamId) {
    return <ContentContainer.Error />;
  }

  if (!sessionUserId) {
    return <ContentContainer.Loading />;
  }

  const newAnalysisButton = (
    <Button
      loading={isCreatingNewAnalysis}
      type="primary"
      icon={<PlusCircleOutlined />}
      disabled={!teamIsActive}
      onClick={async () => {
        setIsCreatingNewAnalysis(true);
        try {
          const data = await createAnalysisRequest({
            teamId,
            name: `New Analysis (${new Date().toLocaleString()})`,
            resources: [],
            facets: [],
          });
          void message.success('Analysis request created successfully');
          navigate(`/team/${teamId}/analyze/${data.id}`);
          track('CREATED_ANALYSIS_REQUEST', {
            teamId,
            sessionUserId,
            analysisRequestId: data.id,
          });
        } catch (error) {
          void message.error('Failed to create analysis request');
        } finally {
          setIsCreatingNewAnalysis(false);
        }
      }}
      style={{
        boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1)',
      }}
    >
      New Analysis
    </Button>
  );

  return (
    <ContentContainer showFullWidthToggle>
      <ContentContainer.Header
        title="Analyze Overview"
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Analyze' },
        ]}
        subtitle={
          teamIsActive ? undefined : <ActiveTeamAlert teamId={teamId} />
        }
        cta={newAnalysisButton}
      />
      <Hero
        id="analyze-overview"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={AnalyzeImage}
        title="Extract knowledge from your data"
        extra={
          <div
            style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}
          >
            <div>
              <Typography.Text>
                Learn more about how to run analysis requests and automate
                information extraction.
              </Typography.Text>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              {newAnalysisButton}
              <Link
                to="https://scribehow.com/shared/How_To_Create_An_Analysis_For_Contract_Dates__9VajJq8ISp6Jj9hm3bEllQ"
                target="_blank"
              >
                <Button
                  style={{
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1)',
                  }}
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        }
      />
      <AnalysisRequestsTable teamId={teamId} />
    </ContentContainer>
  );
};

export default AnalyzePage;

import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const DocumentExtractionStatementSchema = z.object({
  id: z.string(),
  documentExtractionId: z.string(),
  statement: z.string(),
  startOffset: z.number(),
  endOffset: z.number(),
  createdAt: z.coerce.date(),
});

export type DocumentExtractionStatement = z.infer<
  typeof DocumentExtractionStatementSchema
>;

export const ListDocumentExtractionStatementsQuerySchema = z
  .object({
    documentExtractionIds: commaDelimitedList().optional(),
    documentExtractionStatementIds: commaDelimitedList().optional(),
    startOffset: z.number().optional(),
    endOffset: z.number().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListDocumentExtractionStatementsQuery = z.infer<
  typeof ListDocumentExtractionStatementsQuerySchema
>;

export const ListDocumentExtractionStatementsResponseSchema = z.array(
  DocumentExtractionStatementSchema,
);

export type ListDocumentExtractionStatementsResponse = z.infer<
  typeof ListDocumentExtractionStatementsResponseSchema
>;

import { useQuery } from '@tanstack/react-query';

import {
  type ListEmbeddingsQuery,
  type ListEmbeddingsResponse,
  ListEmbeddingsResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

export const useEmbeddingsQuery = (params: ListEmbeddingsQuery) => {
  return useQuery({
    queryKey: ['embeddings', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListEmbeddingsResponse>(
        '/embeddings',
        {
          params,
        },
      );

      const validatedData = ListEmbeddingsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      return data;
    },
  });
};

import EdgeCurveProgram from '@sigma/edge-curve';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Graph from 'graphology';
import FA2Layout from 'graphology-layout-forceatlas2/worker';
import circularLayout from 'graphology-layout/circular';
import uniqBy from 'lodash.uniqby';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Sigma from 'sigma';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import LayoutControls from './LayoutControls';

import { useSearchKnowledgeGraph } from '@queries/knowledge';

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 150px);
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  min-height: 0; // This is important for the flex child to shrink properly
`;

const Sidebar = styled.div<{ $colorBgElevated: string; $colorBorder: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 300px;
  padding: 1rem;
  background-color: ${({ $colorBgElevated }) => $colorBgElevated};
  overflow-y: auto;
`;

const GraphWrapper = styled.div`
  flex: 1;
`;

const SigmaContainer = styled.div<{
  $colorText: string;
  $colorBgElevated: string;
}>`
  width: 100%;
  height: 100%;

  .sigma-label {
    color: ${({ $colorText }) => $colorText};
  }

  .sigma-hover {
    .sigma-label {
      color: ${({ $colorText }) => $colorText};
      background-color: ${({ $colorBgElevated }) => $colorBgElevated};
    }
  }
`;

const SEARCH_QUERY_KEY = 'labs.knowledge-graph.search';
const DOCUMENT_IDS_QUERY_KEY = 'labs.knowledge-graph.document-ids';
const PROJECT_IDS_QUERY_KEY = 'labs.knowledge-graph.project-ids';
const TEAM_IDS_QUERY_KEY = 'labs.knowledge-graph.team-ids';
const DEPTH_QUERY_KEY = 'labs.knowledge-graph.depth';
const RELEVANCE_QUERY_KEY = 'labs.knowledge-graph.relevance';
const ROOT_LIMIT_QUERY_KEY = 'labs.knowledge-graph.root-limit';
const LAYOUT_TYPE_QUERY_KEY = 'labs.knowledge-graph.layout-type';

const KnowledgeGraph = () => {
  const { token } = theme.useToken();

  const sigmaCanvasRef = useRef<HTMLDivElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get(SEARCH_QUERY_KEY) ?? '';
  const [debouncedSearch] = useDebounce(search, 500);

  const documentIds =
    searchParams.get(DOCUMENT_IDS_QUERY_KEY)?.split(',') ?? undefined;
  const [debouncedDocumentIds] = useDebounce(documentIds, 500);

  const projectIds =
    searchParams.get(PROJECT_IDS_QUERY_KEY)?.split(',') ?? undefined;
  const [debouncedProjectIds] = useDebounce(projectIds, 500);

  const teamIds = searchParams.get(TEAM_IDS_QUERY_KEY)?.split(',') ?? undefined;
  const [debouncedTeamIds] = useDebounce(teamIds, 500);

  const depth = parseInt(searchParams.get(DEPTH_QUERY_KEY) ?? '1', 10);
  const [debouncedDepth] = useDebounce(depth, 500);

  const relevance = parseFloat(searchParams.get(RELEVANCE_QUERY_KEY) ?? '0.5');
  const [debouncedRelevance] = useDebounce(relevance, 500);

  const rootLimit = parseInt(
    searchParams.get(ROOT_LIMIT_QUERY_KEY) ?? '10',
    10,
  );
  const [debouncedRootLimit] = useDebounce(rootLimit, 500);

  const layoutType = (searchParams.get(LAYOUT_TYPE_QUERY_KEY) ??
    'forceAtlas2') as 'forceAtlas2' | 'circular';

  const searchKnowledgeGraphQuery = useSearchKnowledgeGraph(
    {
      query: debouncedSearch,
      documentIds: debouncedDocumentIds,
      projectIds: debouncedProjectIds,
      teamIds: debouncedTeamIds,
      depth: debouncedDepth,
      relevance: debouncedRelevance,
      rootLimit: debouncedRootLimit,
    },
    !!debouncedSearch,
  );

  const [graph] = useState(() => new Graph({ multi: true }));
  const [sigma, setSigma] = useState<Sigma | null>(null);
  const [layoutSettings, setLayoutSettings] = useState({
    gravity: 2,
    linLogMode: true,
    strongGravityMode: false,
    scalingRatio: 2,
    slowDown: 2,
  });

  useEffect(() => {
    if (sigmaCanvasRef.current && !sigma) {
      const newSigma = new Sigma(graph, sigmaCanvasRef.current, {
        renderEdgeLabels: true,
        edgeProgramClasses: {
          curved: EdgeCurveProgram,
        },
      });
      setSigma(newSigma);
    }
  }, [graph, sigma]);

  const runLayout = useCallback(() => {
    if (layoutType === 'forceAtlas2') {
      const layout = new FA2Layout(graph, {
        settings: {
          ...layoutSettings,
          barnesHutOptimize: true,
          barnesHutTheta: 0.5,
        },
      });
      layout.start();

      setTimeout(() => {
        layout.stop();
        if (sigma) {
          sigma.refresh();
        }
      }, 2000);
    } else if (layoutType === 'circular') {
      circularLayout.assign(graph);
      graph.forEachEdge((edge) => {
        graph.setEdgeAttribute(edge, 'type', 'curved');
      });
      if (sigma) {
        sigma.refresh();
      }
    }
  }, [graph, sigma, layoutSettings, layoutType]);

  useEffect(() => {
    const { nodes, edges } = searchKnowledgeGraphQuery.data ?? {};
    if (!nodes || !edges) {
      return;
    }

    // normalize the relevance value on each edge with minmax
    const minRelevance = Math.min(...edges.map((edge) => edge.relevance));
    const maxRelevance = Math.max(...edges.map((edge) => edge.relevance));
    const normalizedEdges = edges.map((edge) => ({
      ...edge,
      relevance:
        (edge.relevance - minRelevance) / (maxRelevance - minRelevance),
    }));

    graph.clear();

    const edgeCountByNode = normalizedEdges.reduce(
      (acc, edge) => {
        acc[edge.sourceKnowledgeNodeId] =
          (acc[edge.sourceKnowledgeNodeId] ?? 0) + 1;
        acc[edge.targetKnowledgeNodeId] =
          (acc[edge.targetKnowledgeNodeId] ?? 0) + 1;
        return acc;
      },
      {} as Record<string, number>,
    );

    // Find the maximum edge count
    const maxEdgeCount = Math.max(...Object.values(edgeCountByNode));

    // Logarithmic scaling function
    const scaleNodeSize = (count: number) => {
      const baseSize = 1;
      const maxSize = 20;
      const scaleFactor = (maxSize - baseSize) / Math.log(maxEdgeCount + 1);
      return baseSize + scaleFactor * Math.log(count + 1);
    };

    uniqBy(nodes, 'id').forEach((node) => {
      const edgeCount = edgeCountByNode[node.id] ?? 1;
      graph.addNode(node.id, {
        label: node.label,
        x: Math.random(),
        y: Math.random(),
        size: scaleNodeSize(edgeCount),
        color: token.colorPrimary,
      });
    });

    normalizedEdges.forEach((edge) => {
      graph.addEdge(edge.sourceKnowledgeNodeId, edge.targetKnowledgeNodeId, {
        size: edge.relevance * 5, // Adjust the multiplier as needed
        label: edge.label,
        type: layoutType === 'circular' ? 'curved' : 'arrow', // Set edge type based on layout
      });
    });

    runLayout();
  }, [
    searchKnowledgeGraphQuery.data,
    graph,
    sigma,
    token.colorPrimary,
    runLayout,
    layoutType,
  ]);

  const handleSettingChange = (key: string, value: number | boolean) => {
    setLayoutSettings((prev) => ({ ...prev, [key]: value }));
  };

  const handleLayoutTypeChange = (value: 'forceAtlas2' | 'circular') => {
    setSearchParams((prev) => {
      prev.set(LAYOUT_TYPE_QUERY_KEY, value);
      return prev;
    });
  };

  return (
    <GraphContainer>
      <MainContent>
        <Sidebar
          $colorBgElevated={token.colorBgElevated}
          $colorBorder={token.colorBorder}
        >
          <Tag>{`${searchKnowledgeGraphQuery.data?.edges.length} edges, ${searchKnowledgeGraphQuery.data?.nodes.length} nodes`}</Tag>
          <LayoutControls
            settings={layoutSettings}
            onSettingChange={handleSettingChange}
            search={search}
            onSearchChange={(value) =>
              setSearchParams(
                (prev) => {
                  if (value) {
                    prev.set(SEARCH_QUERY_KEY, value);
                  } else {
                    prev.delete(SEARCH_QUERY_KEY);
                  }
                  return prev;
                },
                { replace: true },
              )
            }
            documentIds={documentIds}
            onDocumentIdsChange={(value) => {
              setSearchParams((prev) => {
                if (value.length > 0) {
                  prev.set(DOCUMENT_IDS_QUERY_KEY, value.join(','));
                } else {
                  prev.delete(DOCUMENT_IDS_QUERY_KEY);
                }
                return prev;
              });
            }}
            projectIds={projectIds}
            onProjectIdsChange={(value) => {
              setSearchParams((prev) => {
                if (value.length > 0) {
                  prev.set(PROJECT_IDS_QUERY_KEY, value.join(','));
                } else {
                  prev.delete(PROJECT_IDS_QUERY_KEY);
                }
                return prev;
              });
            }}
            teamIds={teamIds}
            onTeamIdsChange={(value) => {
              setSearchParams((prev) => {
                if (value.length > 0) {
                  prev.set(TEAM_IDS_QUERY_KEY, value.join(','));
                } else {
                  prev.delete(TEAM_IDS_QUERY_KEY);
                }
                return prev;
              });
            }}
            depth={depth}
            onDepthChange={(value) => {
              setSearchParams((prev) => {
                prev.set(DEPTH_QUERY_KEY, value.toString());
                return prev;
              });
            }}
            relevance={relevance}
            onRelevanceChange={(value) => {
              setSearchParams((prev) => {
                prev.set(RELEVANCE_QUERY_KEY, value.toString());
                return prev;
              });
            }}
            rootLimit={rootLimit}
            onRootLimitChange={(value) => {
              setSearchParams((prev) => {
                prev.set(ROOT_LIMIT_QUERY_KEY, value.toString());
                return prev;
              });
            }}
            isLoading={
              searchKnowledgeGraphQuery.isLoading || search !== debouncedSearch
            }
            layoutType={layoutType}
            onLayoutTypeChange={handleLayoutTypeChange}
          />
        </Sidebar>
        <GraphWrapper>
          <SigmaContainer
            ref={sigmaCanvasRef}
            $colorText={token.colorText}
            $colorBgElevated={token.colorBgElevated}
          />
        </GraphWrapper>
      </MainContent>
    </GraphContainer>
  );
};

export default KnowledgeGraph;

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import Button from 'antd/es/button';
import { Link } from 'react-router-dom';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import InsightRequestsTable from '@components/tables/InsightRequestsTable';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const InsightsHistoryPage = () => {
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();

  if (!teamId) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Insights History"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Insights',
            to: `/team/${teamId}/insights`,
          },
          {
            label: 'History',
            to: `/team/${teamId}/insights/history`,
          },
        ]}
        subtitle={
          !teamIsActive ? <ActiveTeamAlert teamId={teamId} /> : undefined
        }
        cta={
          <Link to={`/team/${teamId}/insights/new`}>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              disabled={!teamIsActive}
            >
              New Request
            </Button>
          </Link>
        }
      />
      <InsightRequestsTable teamId={teamId} />
    </ContentContainer>
  );
};

export default InsightsHistoryPage;

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  type ListTeamSubscriptionsQuery,
  type ListTeamSubscriptionsResponse,
  ListTeamSubscriptionsResponseSchema,
  type TeamSubscription,
  TeamSubscriptionSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useTeamSubscriptionsQuery = (
  params: ListTeamSubscriptionsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['team-subscriptions', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListTeamSubscriptionsResponse>(
        '/team-subscriptions',
        {
          params,
        },
      );
      const validatedData = ListTeamSubscriptionsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch team subscriptions',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

export const useCurrentTeamSubscriptionQuery = (teamId?: string) => {
  return useQuery({
    queryKey: ['current-team-subscription', teamId],
    queryFn: async () => {
      try {
        const { data } = await apiClient.get<TeamSubscription>(
          `/teams/${teamId}/subscription`,
        );

        const validatedData = TeamSubscriptionSchema.safeParse(data);
        if (validatedData.success) {
          return validatedData.data;
        } else {
          logger.error({
            message: 'Failed to fetch current team subscription',
            error: validatedData.error,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.status === 404) {
            return null;
          }
        }
        throw error;
      }
    },
  });
};

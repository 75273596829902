import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DislikeFilled from '@ant-design/icons/DislikeFilled';
import DislikeOutlined from '@ant-design/icons/DislikeOutlined';
import LikeFilled from '@ant-design/icons/LikeFilled';
import LikeOutlined from '@ant-design/icons/LikeOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Popconfirm from 'antd/es/popconfirm';
import Skeleton from 'antd/es/skeleton';
import theme from 'antd/es/theme';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { type ConversationMessage } from '@mai/types';

import MessagePlan from './MessagePlan';

import MarkdownContent from '@components/MarkdownContent';
import { useFetchUserQuery } from '@queries/users';
import { useSessionUserId } from '@utils/auth';
import { track } from '@utils/mixpanel';

type MessageRowType = {
  id: string;
  role: 'user' | 'assistant' | 'system';
  content: string;
  plan: ConversationMessage['plan'];
};

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0.5rem;
  }
`;

const Message = ({
  id,
  role,
  content,
  onDeleteMessage,
  isRegenerating,
  onRegenerate,
  userFullName,
  plan,
}: MessageRowType & {
  references: ConversationMessage['references'];
  onDeleteMessage: () => Promise<void>;
  isRegenerating?: boolean;
  onRegenerate?: () => Promise<void>;
  teamId: string;
  userFullName?: string;
  disableReferenceLinks?: boolean;
}) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const { message, modal } = App.useApp();
  const sessionUserId = useSessionUserId();
  const fetchUser = useFetchUserQuery(sessionUserId);
  const [isDeleting, setIsDeleting] = useState(false);
  const [feedback, setFeedback] = useState<'like' | 'dislike' | null>(null);

  const name = useMemo(() => {
    return (
      userFullName ??
      fetchUser.data?.name ?? <Skeleton active paragraph={false} />
    );
  }, [userFullName, fetchUser.data?.name]);

  return (
    <div>
      <StyledCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Typography.Text type="secondary" strong>
              {role === 'assistant' ? 'Assistant' : name}
            </Typography.Text>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {plan && (
                <Tooltip title="Explain Answer">
                  <Button
                    size="small"
                    shape="circle"
                    type="text"
                    icon={<QuestionCircleOutlined />}
                    onClick={() => {
                      track('EXPLAINED_ASSISTANT_MESSAGE', {
                        conversationMessageId: id,
                      });
                      return modal.info({
                        title: 'Explain Answer',
                        content: <MessagePlan plan={plan} />,
                        maskClosable: true,
                        closable: true,
                        footer: null,
                        icon: null,
                        width: 'min(90vw, 800px)',
                      });
                    }}
                  />
                </Tooltip>
              )}
              {role === 'assistant' && (
                <>
                  <Button
                    size="small"
                    shape="circle"
                    type="text"
                    icon={
                      feedback === 'like' ? <LikeFilled /> : <LikeOutlined />
                    }
                    style={{
                      color: feedback === 'like' ? colorPrimary : undefined,
                    }}
                    onClick={() => {
                      setFeedback('like');
                      track('LIKED_ASSISTANT_MESSAGE', {
                        conversationMessageId: id,
                      });
                    }}
                  />
                  <Button
                    size="small"
                    shape="circle"
                    type="text"
                    icon={
                      feedback === 'dislike' ? (
                        <DislikeFilled />
                      ) : (
                        <DislikeOutlined />
                      )
                    }
                    style={{
                      color: feedback === 'dislike' ? colorPrimary : undefined,
                    }}
                    onClick={() => {
                      setFeedback('dislike');
                      track('DISLIKED_ASSISTANT_MESSAGE', {
                        conversationMessageId: id,
                      });
                    }}
                  />
                </>
              )}
            </div>
            {onRegenerate && (
              <Button
                size="small"
                shape="circle"
                type="text"
                loading={isRegenerating}
                icon={<ReloadOutlined />}
                onClick={onRegenerate}
              />
            )}
            <Popconfirm
              title="Delete this message?"
              onConfirm={async () => {
                setIsDeleting(true);
                try {
                  await onDeleteMessage();
                } catch (e) {
                  await message.error(
                    'Failed to delete message. Please try again.',
                  );
                } finally {
                  setIsDeleting(false);
                }
              }}
              icon={<DeleteOutlined />}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                shape="circle"
                type="text"
                loading={isDeleting}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        </div>
        <MarkdownContent content={content} />
      </StyledCard>
    </div>
  );
};

export default Message;

import App from 'antd/es/app';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { AxiosError } from 'axios';
import { useState } from 'react';

import {
  type CreateProject,
  CreateProjectSchema,
  type Project,
} from '@mai/types';

import FormSubmitButton from '@components/FormSubmitButton';
import { apiClient } from '@queries/index';
import { logger } from '@utils/logger';

type ProjectFormData =
  | Partial<{
      name: string;
      description: string;
    }>
  | undefined;

const CreateProjectForm = ({
  teamId,
  onSuccess,
}: {
  teamId: string;
  onSuccess: (data: Project) => void;
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm<ProjectFormData>();
  const [isCreating, setIsCreating] = useState(false);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values) => {
        const name = values?.name;
        const description = values?.description ?? null;
        if (!name) {
          return;
        }

        const loadingMessage = message.loading('Creating project...');
        setIsCreating(true);
        try {
          const response = await apiClient.post<Project>(
            '/projects',
            CreateProjectSchema.parse({
              name,
              description,
              teamId,
              links: {
                documents: [],
              },
            } satisfies CreateProject),
          );
          loadingMessage();
          void message.success('Project created successfully');
          onSuccess(response.data);
        } catch (error) {
          loadingMessage();
          let errorMessage = 'Failed to create project';
          if (error instanceof AxiosError) {
            errorMessage = error.response?.data?.message ?? errorMessage;
          }
          void message.error(errorMessage);
          logger.warn({
            message: 'Failed to create project',
            error,
          });
        } finally {
          setIsCreating(false);
        }
      }}
    >
      <Form.Item name="name" label="Project Name" rules={[{ required: true }]}>
        <Input placeholder="Project Name" />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input placeholder="Project Description" />
      </Form.Item>
      <Form.Item>
        <FormSubmitButton text="Create Project" loading={isCreating} />
      </Form.Item>
    </Form>
  );
};

export default CreateProjectForm;

import EditOutlined from '@ant-design/icons/EditOutlined';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import { Link } from 'react-router-dom';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import InviteTeamMemberButton from '@components/InviteTeamMemberButton';
import SectionContainer from '@components/SectionContainer';
import TeamInvitesTable from '@components/tables/TeamInvitesTable';
import TeamUsersTable from '@components/tables/TeamUsersTable';
import { useFetchTeam } from '@queries/teams';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const TeamManageRoot = () => {
  const teamId = useTeamId();
  const teamQuery = useFetchTeam(teamId);
  const teamName = teamQuery.data?.name;
  const teamIsActive = useTeamIsActive();

  if (teamQuery.isError || !teamId) {
    return <ContentContainer.Error />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Team Settings"
        subtitle={
          teamIsActive ? undefined : <ActiveTeamAlert teamId={teamId} />
        }
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Team Settings' },
        ]}
      />
      <SectionContainer
        title="Details"
        cta={
          <Link to={`/team/${teamId}/manage/general/edit`}>
            <Button size="small" icon={<EditOutlined />}>
              Edit
            </Button>
          </Link>
        }
      >
        <Descriptions>
          <Descriptions.Item label="Team Name">{teamName}</Descriptions.Item>
        </Descriptions>
      </SectionContainer>
      <SectionContainer title="Members">
        <TeamUsersTable teamId={teamId} size="small" />
      </SectionContainer>
      <SectionContainer
        title="Invitations"
        cta={
          <InviteTeamMemberButton
            teamId={teamId}
            buttonSize="small"
            buttonType="default"
          />
        }
      >
        <TeamInvitesTable teamId={teamId} size="small" />
      </SectionContainer>
    </ContentContainer>
  );
};

export default TeamManageRoot;

import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import { type ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import SideBar from './SideBar';

import { usePersistentState } from '@utils/hooks';
import { screenSizes } from '@utils/theme';

const Content = styled(Layout.Content)<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  display: flex;
  border-radius: ${(props) => props.$borderRadius}px 0
    ${(props) => props.$borderRadius}px ${(props) => props.$borderRadius}px;
  margin-top: 8px;
  margin-left: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

const Sider = styled(Layout.Sider)`
  padding: 8px 0px 0px 0px;
  @media (max-width: ${screenSizes.mobile}px) {
    display: none;
  }
`;

const TeamLayout = ({ children }: { children: ReactNode }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [collapsed, setCollapsed] = usePersistentState(
    'teamSidebarCollapsed',
    window.innerWidth < screenSizes.tablet,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < screenSizes.tablet) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Sider width={250} collapsed={collapsed}>
        <SideBar
          setCollapsed={(input) => {
            if (typeof input === 'boolean') {
              setCollapsed(input);
            } else {
              setCollapsed(input(collapsed));
            }
          }}
          collapsed={collapsed}
        />
      </Sider>
      <Layout>
        <Content
          $backgroundColor={colorBgContainer}
          $borderRadius={borderRadiusLG}
        >
          {children}
        </Content>
      </Layout>
    </>
  );
};

export default TeamLayout;

import { QueryClientProvider } from '@tanstack/react-query';
import App from 'antd/es/app';
import ConfigProvider, { type ThemeConfig } from 'antd/es/config-provider';
import theme from 'antd/es/theme';
import { createContext, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import tinyColor from 'tinycolor2';

import ThemeConfigProvider from './context/ThemeContext';
import { BaseEntityInit } from './entities/_BaseEntity';
import { queryClient } from './globals';
import { router } from './routes';

import StyledComponentProvider from '@components/StyledComponentProvider';
import { useLocalStorage } from '@utils/theme';

export type DarkLightModeContextValues = 'dark' | 'light';

export const DarkLightModeContext = createContext<{
  value: DarkLightModeContextValues;
  setValue: (value: DarkLightModeContextValues) => void;
}>({
  value: 'light',
  setValue: () => {},
});

const MyApp = () => {
  const [themeContextValue, setThemeContextValue] =
    useLocalStorage<DarkLightModeContextValues>('theme', 'light');

  const themeConfiguration: ThemeConfig = useMemo(() => {
    // Brand Color, use this for primary components and accents
    const primaryColor = '#5954cc';

    // We tone the error color down, it's too bright
    const colorError = tinyColor('#ff4d4f').darken(10).toString();

    // Secondary Color, we use this for non-primary buttons, selected items etc...
    const secondaryColor =
      themeContextValue === 'dark'
        ? '#3d3d3d'
        : tinyColor(primaryColor).lighten(30).toString();
    const backgroundColor =
      themeContextValue === 'dark' ? '#121212' : '#f0f2f5';
    const textColor = themeContextValue === 'dark' ? '#f0f2f5' : '#121212';
    const colorFillSecondary =
      themeContextValue === 'dark' ? '#817de4' : '#c0bef1';

    // Derived Colours
    const surfaceColor =
      themeContextValue === 'dark'
        ? tinyColor(backgroundColor).lighten(5).toString()
        : tinyColor(backgroundColor).darken(2).toString();
    const borderColor = tinyColor(primaryColor).darken(5).toString();

    return {
      algorithm:
        themeContextValue === 'dark'
          ? theme.darkAlgorithm
          : theme.defaultAlgorithm,
      components: {
        Button: {
          dangerShadow: colorError,
        },
        Layout: {
          headerBg: backgroundColor,
          siderBg: backgroundColor,
          margin: 0,
          padding: 0,
          headerHeight: 55,
        },
        Menu: {
          itemSelectedBg: secondaryColor,
          itemSelectedColor: textColor,
          itemHeight: 35,
        },
        Tag: {
          defaultBg: surfaceColor,
        },
        Card: {
          colorBgContainer:
            themeContextValue === 'dark'
              ? tinyColor(surfaceColor).darken(2).toString()
              : tinyColor(surfaceColor).lighten(2).toString(),
        },
      },
      token: {
        colorPrimary: primaryColor,
        colorBorderBg: backgroundColor,
        colorBgBase: backgroundColor,
        colorBgContainer: surfaceColor,
        colorBgLayout: backgroundColor,
        colorBorder: borderColor,
        colorTextBase: textColor,
        colorFillSecondary,
        colorError,
      },
    } as ThemeConfig;
  }, [themeContextValue]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeConfigProvider themeConfiguration={themeConfiguration}>
        <DarkLightModeContext.Provider
          value={{
            value: themeContextValue,
            setValue: setThemeContextValue,
          }}
        >
          <ConfigProvider theme={themeConfiguration}>
            <StyledComponentProvider>
              <App>
                <BaseEntityInit />
                <RouterProvider router={router} />
              </App>
            </StyledComponentProvider>
          </ConfigProvider>
        </DarkLightModeContext.Provider>
      </ThemeConfigProvider>
    </QueryClientProvider>
  );
};

export { MyApp as App };

import Spin from 'antd/es/spin';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CenterSpin = () => {
  return (
    <Container>
      <Spin />
    </Container>
  );
};

export default CenterSpin;

import { useQuery } from '@tanstack/react-query';

import {
  type AnalysisRequest,
  AnalysisRequestSchema,
  type CreateAnalysisRequestBody,
  type ListAnalysisRequestsQuery,
  type ListAnalysisRequestsResponse,
  ListAnalysisRequestsResponseSchema,
  type UpdateAnalysisRequestRequestBody,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useAnalysisRequestQuery = (analysisRequestId?: string) => {
  return useQuery({
    queryKey: ['analysisRequest', analysisRequestId],
    queryFn: async () => {
      const { data } = await apiClient.get<AnalysisRequest>(
        `/analysis-requests/${analysisRequestId}`,
      );

      const validatedData = AnalysisRequestSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.warn({
          message: 'Failed to fetch analysis request',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled: !!analysisRequestId,
  });
};

export const useAnalysisRequestsQuery = (
  params: ListAnalysisRequestsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['analysisRequests', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListAnalysisRequestsResponse>(
        '/analysis-requests',
        {
          params,
        },
      );
      const validatedData = ListAnalysisRequestsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.warn({
          message: 'Failed to fetch analysis requests',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

export const createAnalysisRequest = async (
  analysisRequest: CreateAnalysisRequestBody,
) => {
  const { data } = await apiClient.post<AnalysisRequest>(
    '/analysis-requests',
    analysisRequest,
  );
  const validatedData = AnalysisRequestSchema.safeParse(data);
  if (validatedData.success) {
    return validatedData.data;
  } else {
    logger.warn({
      message: 'Failed to create analysis request',
      error: validatedData.error,
    });
    return data;
  }
};

export const updateAnalysisRequest = async (
  analysisRequestId: string,
  payload: UpdateAnalysisRequestRequestBody,
) => {
  const { data } = await apiClient.patch<AnalysisRequest>(
    `/analysis-requests/${analysisRequestId}`,
    payload,
  );
  const validatedData = AnalysisRequestSchema.safeParse(data);
  if (validatedData.success) {
    return validatedData.data;
  } else {
    logger.warn({
      message: 'Failed to update analysis request',
      error: validatedData.error,
    });
    return data;
  }
};

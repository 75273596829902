import { useQuery } from '@tanstack/react-query';

import {
  type SearchKnowledgeGraphRequest,
  type SearchKnowledgeGraphResponse,
} from '@mai/types';

import { apiClient } from '.';

export const useSearchKnowledgeGraph = (
  params: SearchKnowledgeGraphRequest,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['knowledge-graph', params],
    queryFn: async () => {
      const { data } = await apiClient.post<SearchKnowledgeGraphResponse>(
        '/knowledge/search',
        params,
      );
      return data;
    },
    enabled,
  });
};

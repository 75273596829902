import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const ConversationMessageLinkSchema = z.union([
  z.object({
    type: z.literal('project'),
    id: z.string(),
  }),
  z.object({
    type: z.literal('document'),
    id: z.string(),
  }),
]);

export type ConversationMessageLink = z.infer<
  typeof ConversationMessageLinkSchema
>;

export const ConversationMessageSchema = z.object({
  id: z.string(),
  conversationId: z.string(),
  content: z.string(),
  references: z.array(
    z.object({
      documentId: z.string(),
      content: z.string(),
    }),
  ),
  role: z.enum(['user', 'assistant', 'system']),
  createdAt: z.coerce.date(),
  plan: z
    .object({
      steps: z.array(
        z.object({
          id: z.number(),
          agent: z.enum(['researcher', 'writer']),
          instruction: z.string(),
          summary: z.string().nullable().optional().default(null),
          dependencies: z.array(z.number()).optional().default([]),
          subtopics: z.array(z.string()).optional().default([]),
        }),
      ),
    })
    .nullable(),
  links: z.array(ConversationMessageLinkSchema),
});

export type ConversationMessage = z.infer<typeof ConversationMessageSchema>;

export const CreateConversationMessageBodySchema = z.object({
  content: z.string(),
  role: z.enum(['user']),
  links: z.array(ConversationMessageLinkSchema),
});

export type CreateConversationMessageBody = z.infer<
  typeof CreateConversationMessageBodySchema
>;

export const ConversationSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  teamId: z.string(),
  projectId: z.string().nullable(),
  createdAt: z.coerce.date(),
  createdBy: z.string().nullable(),
});

export type Conversation = z.infer<typeof ConversationSchema>;

const BaseCreateConversationBodySchema = z.object({
  name: z.string(),
  teamId: z.string(),
});

export const CreateConversationBodySchema = z.union([
  BaseCreateConversationBodySchema.extend({
    projectId: z.null().optional(),
    projectAssistantId: z.null().optional(),
    externalId: z.null().optional(),
  }),
  BaseCreateConversationBodySchema.extend({
    projectId: z.string().nanoid(),
    projectAssistantId: z.string().nanoid(),
    externalId: z.string(),
  }),
]);

export type CreateConversationBody = z.infer<
  typeof CreateConversationBodySchema
>;

export const ListConversationMessagesQuerySchema = z
  .object({
    conversationIds: commaDelimitedList().optional(),
    teamIds: commaDelimitedList().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListConversationMessagesQuery = z.infer<
  typeof ListConversationMessagesQuerySchema
>;

export const ListConversationMessagesResponseSchema = z.array(
  ConversationMessageSchema,
);

export type ListConversationMessagesResponse = z.infer<
  typeof ListConversationMessagesResponseSchema
>;

export const UpdateConversationBodySchema = z.object({
  shouldRegenerateMessages: z.boolean().optional(),
  shouldRegenerateTitle: z.boolean().optional(),
});

export type UpdateConversationBody = z.infer<
  typeof UpdateConversationBodySchema
>;

export const ListConversationsQuerySchema = z
  .object({
    conversationIds: commaDelimitedList().optional(),
    teamIds: commaDelimitedList().optional(),
    createdBy: z.string().optional(),
    projectIds: commaDelimitedList().optional(),
    projectAssistantIds: commaDelimitedList().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListConversationsQuery = z.infer<
  typeof ListConversationsQuerySchema
>;

export const ListConversationsResponseSchema = z.array(ConversationSchema);

export type ListConversationsResponse = z.infer<
  typeof ListConversationsResponseSchema
>;

import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const ProjectAssistantConversationSchema = z.object({
  projectAssistantId: z.string().nanoid(),
  conversationId: z.string().nanoid(),
  externalId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type ProjectAssistantConversation = z.infer<
  typeof ProjectAssistantConversationSchema
>;

export const CreateProjectAssistantConversationRequestSchema = z.object({
  projectAssistantId: z.string().nanoid(),
  externalId: z.string(),
});

export type CreateProjectAssistantConversationRequest = z.infer<
  typeof CreateProjectAssistantConversationRequestSchema
>;

export const ListProjectAssistantConversationsRequestSchema = z.object({
  projectAssistantIds: commaDelimitedList(
    z.string().nanoid().array(),
  ).optional(),
  conversationIds: commaDelimitedList(z.string().nanoid().array()).optional(),
  externalIds: commaDelimitedList(z.string().array()).optional(),
});

export type ListProjectAssistantConversationsRequest = z.infer<
  typeof ListProjectAssistantConversationsRequestSchema
>;

import { type ColumnProps } from 'antd/es/table';

import { type DocumentLink } from '@mai/types';

import Table from '../Table';

import { useFetchDocumentQuery } from '@queries/documents';
import { useProjectsQuery } from '@queries/projects';
import { useFetchTeams } from '@queries/teams';

type DocumentLinkRow = {
  id: string;
  linkType: DocumentLink['type'];
  name: string | null;
  href: string | null;
};

const DocumentLinksTable = ({
  documentId,
  size,
}: {
  documentId: string;
  size?: 'small' | 'middle' | 'large';
}) => {
  const documentQuery = useFetchDocumentQuery(documentId);
  const document = documentQuery.data;

  const teamIds = document?.links
    .filter((link) => link.type === 'team')
    .map((link) => link.id);
  const linkedTeamsQuery = useFetchTeams(
    {
      teamIds,
    },
    !!teamIds?.length,
  );
  const linkedTeams = linkedTeamsQuery.data || [];

  const projectIds = document?.links
    .filter((link) => link.type === 'project')
    .map((link) => link.id);
  const linkedProjectsQuery = useProjectsQuery(
    {
      projectIds,
    },
    !!projectIds?.length,
  );
  const linkedProjects = linkedProjectsQuery.data || [];

  const columns: ColumnProps<DocumentLinkRow>[] = [
    {
      title: 'Type',
      dataIndex: 'linkType',
      key: 'linkType',
      width: 300,
      render: (linkType) => {
        if (linkType === 'team') {
          return 'Team';
        } else if (linkType === 'project') {
          return 'Project';
        }
        return null;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const dataSource: DocumentLinkRow[] =
    document?.links.map((link) => {
      if (link.type === 'team') {
        const team = linkedTeams.find((team) => team.id === link.id);
        return {
          id: link.id,
          linkType: link.type,
          name: team?.name || null,
          href: `/team/${link.id}`,
        };
      } else if (link.type === 'project') {
        const project = linkedProjects.find(
          (project) => project.id === link.id,
        );
        return {
          id: link.id,
          linkType: link.type,
          name: project?.name || null,
          href: `/project/${link.id}`,
        };
      }
      throw new Error(`Unknown link type: ${link.type}`);
    }) ?? [];

  return (
    <>
      <Table<DocumentLinkRow>
        columns={columns}
        dataSource={dataSource}
        loading={documentQuery.isLoading}
        rowKey={(record) => record.id}
        pagination={false}
        size={size}
      />
    </>
  );
};

export default DocumentLinksTable;

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import AnalysisRequestEditor from '@components/AnalysisRequestEditor';
import ContentContainer from '@components/ContentContainer';
import { useAnalysisRequestQuery } from '@queries/analysis-requests';
import { useTeamIsActive } from '@utils/billing';
import { useAnalysisRequestId, useTeamId } from '@utils/router';

const AnalyzeDetailsPage = () => {
  const analysisRequestId = useAnalysisRequestId();
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();

  const analysisRequestQuery = useAnalysisRequestQuery(analysisRequestId);
  const analysisRequest = analysisRequestQuery.data;

  if (!teamId || !analysisRequestId) {
    return <ContentContainer.Error />;
  }

  if (analysisRequestQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (!analysisRequest) {
    return <ContentContainer.NotFound />;
  }

  return (
    <ContentContainer showFullWidthToggle>
      <ContentContainer.Header
        title={analysisRequest.name}
        subtitle={!teamIsActive ? <ActiveTeamAlert teamId={teamId} /> : null}
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Analyze', to: `/team/${teamId}/analyze` },
          { label: analysisRequest.name },
        ]}
      />
      <AnalysisRequestEditor
        analysisRequestId={analysisRequestId}
        teamId={teamId}
      />
    </ContentContainer>
  );
};

export default AnalyzeDetailsPage;

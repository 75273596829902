import { useQuery } from '@tanstack/react-query';

import {
  type ListUsersQuery,
  type ListUsersResponse,
  ListUsersResponseSchema,
  type UserDetail,
  UserDetailSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useFetchUserQuery = (userId: string | undefined | null) => {
  return useQuery({
    queryKey: ['user', { userId }],
    queryFn: async () => {
      const { data } = await apiClient.get<UserDetail>(`/users/${userId}`);
      const validatedData = UserDetailSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch user',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled: !!userId,
  });
};

export const useFetchUsers = (args: ListUsersQuery, enabled: boolean) => {
  return useQuery({
    queryKey: ['users', args],
    queryFn: async () => {
      const { data } = await apiClient.get<ListUsersResponse>('/users', {
        params: args,
      });
      const validatedData = ListUsersResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch users',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

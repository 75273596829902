import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const PlatformCreditTypeSchema = z.enum([
  'search-request-v1',
  'conversation-message-v1',
  'document-upload-v1',
  'analysis-request-run-v1',
  'insight-request-run-v1',
]);

export type PlatformCreditType = z.infer<typeof PlatformCreditTypeSchema>;

export const PlatformCreditMetadataSchema = z
  .object({
    searchRequestId: z.string().optional(),
    conversationMessageId: z.string().optional(),
    documentId: z.string().optional(),
    analysisRequestRunId: z.string().optional(),
    insightRequestRunId: z.string().optional(),
  })
  .partial();

export type PlatformCreditMetadata = z.infer<
  typeof PlatformCreditMetadataSchema
>;

export const PlatformCreditSchema = z.object({
  id: z.string(),
  teamId: z.string(),
  amount: z.number(),
  type: PlatformCreditTypeSchema,
  note: z.string().nullable(),
  metadata: PlatformCreditMetadataSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  idempotencyKey: z.string(),
});

export type PlatformCredit = z.infer<typeof PlatformCreditSchema>;

export const UpdatePlatformCreditRequestSchema = z.object({
  note: z.string().nullable().optional(),
});

export type UpdatePlatformCreditRequest = z.infer<
  typeof UpdatePlatformCreditRequestSchema
>;

export const ListPlatformCreditsRequestSchema = z
  .object({
    teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    types: commaDelimitedList(PlatformCreditTypeSchema.array()).optional(),
    createdAtGte: z.coerce.date().optional(),
    createdAtLte: z.coerce.date().optional(),
    createdAtGt: z.coerce.date().optional(),
    createdAtLt: z.coerce.date().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListPlatformCreditsRequest = z.infer<
  typeof ListPlatformCreditsRequestSchema
>;

export const ListPlatformCreditsResponseSchema = z.array(PlatformCreditSchema);

export type ListPlatformCreditsResponse = z.infer<
  typeof ListPlatformCreditsResponseSchema
>;

export const PlatformCreditsAggregateSchema = z.object({
  creditsUsed: z.number(),
});

export type PlatformCreditsAggregate = z.infer<
  typeof PlatformCreditsAggregateSchema
>;

export const TeamSubscriptionPlatformCreditsAggregateSchema =
  PlatformCreditsAggregateSchema.extend({
    totalCredits: z.number().nullable(),
  });

export type TeamSubscriptionPlatformCreditsAggregate = z.infer<
  typeof TeamSubscriptionPlatformCreditsAggregateSchema
>;

import styled from 'styled-components';

import CenterSpin from './CenterSpin';
import CreditUsageCard from './CreditUsageCard';
import SectionContainer from './SectionContainer';

import { useTeamSubscriptionsQuery } from '@queries/team-subscriptions';

const ResponsiveCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1rem;
  flex-wrap: wrap;
`;

const TeamCreditUsageOverview = ({ teamId }: { teamId: string }) => {
  const teamSubscriptionQuery = useTeamSubscriptionsQuery({
    teamIds: [teamId],
  });
  const teamSubscription = teamSubscriptionQuery.data?.[0];

  if (teamSubscriptionQuery.isLoading) {
    return <CenterSpin />;
  }

  if (teamSubscriptionQuery.isError) {
    return <SectionContainer.Error />;
  }

  return (
    <>
      {teamSubscription && (
        <ResponsiveCardContainer>
          <CreditUsageCard teamId={teamId} period="current" />
          <CreditUsageCard teamId={teamId} period="previous" />
          <CreditUsageCard teamId={teamId} period="all" />
        </ResponsiveCardContainer>
      )}
    </>
  );
};

export default TeamCreditUsageOverview;

import { z } from 'zod';

export function createPaginationRequestQuerySchema<
  OrderBySchema extends [string, ...string[]],
>(orderBy: z.ZodEnum<OrderBySchema>) {
  return z.object({
    pageSize: z.number().int().positive().max(1000).default(10).optional(),
    page: z.number().int().positive().default(1).optional(),
    orderBy: orderBy.optional(),
    orderDirection: z.enum(['asc', 'desc']).default('asc').optional(),
  });
}

export type PaginationRequestQuery<OrderByKeys extends string> = Partial<{
  pageSize: number;
  page: number;
  orderBy: OrderByKeys;
  orderDirection: 'asc' | 'desc';
}>;

import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const DocumentExtractionTypeSchema = z.enum(['ocr', 'post_processing']);

export type DocumentExtractionType = z.infer<
  typeof DocumentExtractionTypeSchema
>;

export const DocumentExtractionSchema = z.object({
  id: z.string(),
  documentId: z.string(),
  type: DocumentExtractionTypeSchema,
  createdAt: z.coerce.date(),
});

export type DocumentExtraction = z.infer<typeof DocumentExtractionSchema>;

export const ListDocumentExtractionsQuerySchema = z
  .object({
    documentExtractionIds: commaDelimitedList().optional(),
    documentIds: commaDelimitedList().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListDocumentExtractionsQuery = z.infer<
  typeof ListDocumentExtractionsQuerySchema
>;

export const ListDocumentExtractionsResponseSchema = z.array(
  DocumentExtractionSchema.extend({
    viewUrl: z.string(),
  }),
);

export type ListDocumentExtractionsResponse = z.infer<
  typeof ListDocumentExtractionsResponseSchema
>;

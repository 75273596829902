import { Outlet } from 'react-router-dom';

import TeamLayout from '@components/TeamLayout';

const TeamRoot = () => {
  return (
    <TeamLayout>
      <Outlet />
    </TeamLayout>
  );
};

export default TeamRoot;

import { Documents } from '@/entities/Documents';
import BulbOutlined from '@ant-design/icons/BulbOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import FullscreenOutlined from '@ant-design/icons/FullscreenOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import Descriptions from 'antd/es/descriptions';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import mime from 'mime';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { type CreateInsightRequest, type InsightRequest } from '@mai/types';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import FileRenderer from '@components/FileRenderer';
import SectionContainer from '@components/SectionContainer';
import DocumentLinksTable from '@components/tables/DocumentLinksTable';
import DocumentProcessingEventTable from '@components/tables/DocumentProcessingEventsTable';
import { useFetchDocumentQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { subscribe, unsubscribe } from '@queries/websockets';
import { useTeamIsActive } from '@utils/billing';
import { logger } from '@utils/logger';
import { useDocumentId, useTeamId } from '@utils/router';

const FullScreenModal = styled(Modal)`
  height: 100vh;
  max-width: 100vw !important;
  top: 0;
  padding: 0;
  margin: 0 !important;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
  .ant-modal-body {
    height: calc(100vh - 108px);
    padding: 0;
  }
`;

type ModalType = 'preview' | 'delete' | 'archive' | 'unarchive';

const TeamDocumentPage = () => {
  const navigate = useNavigate();
  const teamId = useTeamId();
  const documentId = useDocumentId();
  const teamIsActive = useTeamIsActive();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageApi, messageContext] = message.useMessage();

  const isPreviewModalVisible = searchParams.get('modal') === 'preview';
  const isDeleteModalVisible = searchParams.get('modal') === 'delete';
  const isArchiveModalVisible = searchParams.get('modal') === 'archive';
  const isUnarchiveModalVisible = searchParams.get('modal') === 'unarchive';

  useEffect(() => {
    if (!documentId) return;
    subscribe('document_processing_event', { documentId }, () => {
      void Documents.invalidate();
    });

    return () => {
      unsubscribe('document_processing_event', { documentId });
    };
  }, [documentId]);

  const openModal = useMemo(() => {
    return (modalType: ModalType) => {
      setSearchParams((prev) => {
        if (!modalType) prev.delete('modal');
        else prev.set('modal', modalType);
        return prev;
      });
    };
  }, [setSearchParams]);

  const closeModal = () => {
    setSearchParams((prev) => {
      prev.delete('modal');
      return prev;
    });
  };

  const fetchDocumentQuery = useFetchDocumentQuery(documentId);
  const document = fetchDocumentQuery.data;

  const cta = useMemo(() => {
    if (!document || !teamId) {
      return null;
    }

    return (
      <div>
        <Dropdown.Button
          type="primary"
          onClick={async () => {
            const loadingMessage = messageApi.loading('Loading insights...');
            try {
              const { data } = await apiClient.post<InsightRequest>(
                `/insight-requests`,
                {
                  links: [{ id: document.id, type: 'document' }],
                  teamId,
                  analysisType: 'coherence',
                } satisfies CreateInsightRequest,
              );
              loadingMessage();
              void messageApi.success('Insight request created successfully');
              navigate(`/team/${teamId}/insights/${data.id}`);
            } catch (e) {
              logger.error({
                message: 'Failed to create insight request',
                error: e,
              });
              loadingMessage();
              void messageApi.error('Failed to create insight request');
            }
          }}
          menu={{
            items: [
              {
                key: 'download',
                label: 'Download',
                icon: <DownloadOutlined />,
                onClick: () => {
                  window.open(document.downloadUrl);
                },
              },
              {
                key: 'preview',
                label: 'Preview',
                icon: <FullscreenOutlined />,
                onClick: () => {
                  openModal('preview');
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
                onClick: () => {
                  navigate('edit');
                },
              },
              {
                key: 're-sync',
                icon: <SyncOutlined />,
                disabled: !teamIsActive,
                label: 'Re-sync',
                onClick: async () => {
                  await Documents.resync(document.id);
                },
              },
              {
                key: 'delete',
                label: 'Delete',
                danger: true,
                icon: <DeleteOutlined />,
                onClick: () => {
                  openModal('delete');
                },
              },
            ],
          }}
        >
          <BulbOutlined />
          Insights
        </Dropdown.Button>
      </div>
    );
  }, [document, teamId, teamIsActive, messageApi, navigate, openModal]);

  if (fetchDocumentQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (fetchDocumentQuery.isError || !document || !teamId) {
    return <ContentContainer.Error />;
  }

  const onConfirmDelete = async () => {
    setIsSubmitting(true);
    await Documents.delete(documentId, {
      onSuccess: () => {
        closeModal();
        navigate('..');
      },
      onFinish: () => setIsSubmitting(false),
    });
  };

  const onClickArchive = async () => {
    setIsSubmitting(true);
    await Documents.archive(documentId, {
      onSuccess: () => {
        closeModal();
        navigate('..');
      },
      onFinish: () => setIsSubmitting(false),
    });
  };

  const onClickUnarchive = async () => {
    setIsSubmitting(true);
    await Documents.unarchive(documentId, {
      onSuccess: () => {
        closeModal();
        navigate('..');
      },
      onFinish: () => setIsSubmitting(false),
    });
  };

  return (
    <ContentContainer>
      {messageContext}
      <FullScreenModal
        title={document.title}
        open={isPreviewModalVisible}
        onCancel={() => closeModal()}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <FileRenderer
          documentId={document.id}
          url={document.viewUrl}
          fileType={document.fileType}
        />
      </FullScreenModal>
      <Modal
        title="Delete Document"
        open={isDeleteModalVisible}
        onOk={onConfirmDelete}
        okText="Delete"
        okType="danger"
        onCancel={closeModal}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to delete this document?
      </Modal>
      <Modal
        title="Archive Document"
        open={isArchiveModalVisible}
        onOk={onClickArchive}
        okText="Archive"
        onCancel={closeModal}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to archive this document?
      </Modal>
      <Modal
        title="Unarchive Document"
        open={isUnarchiveModalVisible}
        onOk={onClickUnarchive}
        okText="Unarchive"
        onCancel={closeModal}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to unarchive this document?
      </Modal>
      <ContentContainer.Header
        title={document.title}
        subtitle={
          teamIsActive ? undefined : <ActiveTeamAlert teamId={teamId} />
        }
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Documents', to: `/team/${teamId}/documents` },
          { label: document.title },
        ]}
        cta={cta}
      />
      <SectionContainer title="Details">
        <Descriptions column={1}>
          <Descriptions.Item label="Title">{document.title}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {document.createdAt.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="File Name">
            {document.fileName}
          </Descriptions.Item>
          <Descriptions.Item label="File Type">
            {mime.getExtension(document.fileType)?.toUpperCase() || 'Unknown'}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={2}>
            {document.description || 'No description'}
          </Descriptions.Item>
        </Descriptions>
      </SectionContainer>
      <SectionContainer title="Links">
        <DocumentLinksTable documentId={document.id} size="small" />
      </SectionContainer>
      <SectionContainer title="Processing History">
        <DocumentProcessingEventTable
          teamId={teamId}
          documentIds={[document.id]}
          size="small"
        />
      </SectionContainer>
    </ContentContainer>
  );
};

export default TeamDocumentPage;

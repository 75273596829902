import Card from 'antd/es/card';
import Progress from 'antd/es/progress';
import Skeleton from 'antd/es/skeleton';
import Statistic from 'antd/es/statistic';
import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useFetchTeamSubscriptionPlatformCreditAggregate } from '@queries/platform-credits';
import { useTeamCreditUsageLimitQuery } from '@queries/team-credit-usage-limits';
import { useCurrentTeamSubscriptionQuery } from '@queries/team-subscriptions';

const ResponsiveCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1rem;
  flex-wrap: wrap;
`;

const ResponsiveCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 14rem;
`;

const useTeamCreditProperties = (teamId: string) => {
  const teamCreditUsageLimitQuery = useTeamCreditUsageLimitQuery(teamId);
  const teamCreditUsageLimit = teamCreditUsageLimitQuery.data;

  const currentTeamSubscriptionQuery = useCurrentTeamSubscriptionQuery(teamId);
  const currentTeamSubscription = currentTeamSubscriptionQuery.data;

  if (
    teamCreditUsageLimitQuery.isLoading ||
    currentTeamSubscriptionQuery.isLoading
  ) {
    return null;
  }

  if (!currentTeamSubscription) {
    return {
      costPerCredit: null,
      currency: null,
    };
  }

  if (currentTeamSubscription.stripeProperties.type !== 'usage-based') {
    return {
      costPerCredit: null,
      currency: null,
    };
  }

  const tiers = currentTeamSubscription.stripeProperties.tiers.sort(
    (a, b) =>
      (a.upTo ?? Number.MAX_SAFE_INTEGER) - (b.upTo ?? Number.MAX_SAFE_INTEGER),
  );

  return {
    creditUsageLimit: teamCreditUsageLimit?.value ?? null,
    costPerCredit: tiers[1]?.unitAmount ?? null,
    currency: currentTeamSubscription?.stripeProperties.currency ?? null,
    freeCredits: tiers[0]?.upTo ?? null,
  };
};

const PerCreditCostCard = ({ teamId }: { teamId: string }) => {
  const properties = useTeamCreditProperties(teamId);

  if (!properties) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }
  const { costPerCredit, currency } = properties;

  return (
    <ResponsiveCard>
      <Typography.Text>Cost Per Credit</Typography.Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Statistic value={costPerCredit ? costPerCredit / 100 : '-'} />
        <Typography.Text type="secondary">
          {currency?.toUpperCase()}
        </Typography.Text>
      </div>
    </ResponsiveCard>
  );
};

const CurrentMonthlyLimitCard = ({ teamId }: { teamId: string }) => {
  const properties = useTeamCreditProperties(teamId);

  if (!properties) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }
  const { creditUsageLimit } = properties;

  return (
    <ResponsiveCard>
      <Typography.Text>Credit Usage Limit</Typography.Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Statistic value={creditUsageLimit ?? '-'} />
      </div>
    </ResponsiveCard>
  );
};

const CurrentMonthlyCostCard = ({ teamId }: { teamId: string }) => {
  const properties = useTeamCreditProperties(teamId);

  if (!properties) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }
  const { creditUsageLimit, costPerCredit, currency, freeCredits } = properties;

  const cost = (() => {
    if (
      creditUsageLimit === null ||
      freeCredits === null ||
      costPerCredit === null
    ) {
      return null;
    }
    return (
      Math.ceil(Math.max((creditUsageLimit - freeCredits) * costPerCredit, 0)) /
      100
    );
  })();

  return (
    <ResponsiveCard>
      <Typography.Text>On-Demand Spend Limit</Typography.Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Statistic
          value={cost !== null ? cost : '-'}
          prefix={cost !== null ? '$' : ''}
          precision={2}
        />
        <Typography.Text type="secondary">
          {currency?.toUpperCase()}
        </Typography.Text>
      </div>
    </ResponsiveCard>
  );
};

const LimitProgressBar = ({ teamId }: { teamId: string }) => {
  const properties = useTeamCreditProperties(teamId);
  const currentTeamSubscriptionQuery = useCurrentTeamSubscriptionQuery(teamId);
  const currentTeamSubscription = currentTeamSubscriptionQuery.data;
  const platformCreditAggregateQuery =
    useFetchTeamSubscriptionPlatformCreditAggregate(
      teamId,
      {
        createdAtGte:
          currentTeamSubscription?.stripeProperties.billingPeriodStart,
        createdAtLt: currentTeamSubscription?.stripeProperties.billingPeriodEnd,
      },
      !!teamId && !!currentTeamSubscription,
    );

  if (!properties || !currentTeamSubscription) {
    return (
      <Progress
        status="active"
        showInfo={false}
        style={{ marginBottom: '1rem' }}
      />
    );
  }

  const { creditUsageLimit } = properties;
  const creditsUsed = platformCreditAggregateQuery.data?.creditsUsed;

  return (
    <Progress
      percent={
        Math.round(((creditsUsed ?? 0) / (creditUsageLimit ?? 1)) * 10000) / 100
      }
      style={{ marginBottom: '1rem' }}
    />
  );
};

const TeamCreditUsageLimitsOverview = ({ teamId }: { teamId: string }) => {
  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <Typography.Text>
          {
            'Manage your team-wide monthly spending by setting usage limit for all users. Notification emails will be sent to Admins when monthly spending limits are about to be reached. '
          }
          <Link
            to="https://ossified-chameleon-fe8.notion.site/Limits-8d3776d248de44ce9f26f91746d74ba3"
            target="_blank"
          >
            Learn more.
          </Link>
        </Typography.Text>
      </div>
      <LimitProgressBar teamId={teamId} />
      <ResponsiveCardContainer>
        <CurrentMonthlyLimitCard teamId={teamId} />
        <PerCreditCostCard teamId={teamId} />
        <CurrentMonthlyCostCard teamId={teamId} />
      </ResponsiveCardContainer>
    </div>
  );
};

export default TeamCreditUsageLimitsOverview;

import Typography from 'antd/es/typography';

export const renderDate = (
  date: string | undefined | null | Date,
  size?: 'default' | 'medium',
  showTime = true,
) => {
  if (date === undefined || date === null) {
    return 'N/A';
  }
  const parsedDate = new Date(date);
  const dateString = parsedDate.toLocaleDateString();
  const timesString = parsedDate.toLocaleTimeString();
  const fontSize = size === 'medium' ? 20 : undefined;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography.Text
        style={{
          fontSize: `${fontSize}px`,
        }}
      >
        {dateString}
      </Typography.Text>
      <div
        style={{
          marginLeft: '0.25rem',
        }}
      />
      {showTime && (
        <Typography.Text
          type="secondary"
          style={{
            fontSize: `${fontSize}px`,
          }}
        >
          {timesString}
        </Typography.Text>
      )}
    </div>
  );
};

import QuestionOutlined from '@ant-design/icons/QuestionOutlined';
import Button from 'antd/es/button';
import Popover from 'antd/es/popover';

const HelpButton = () => {
  return (
    <Popover
      trigger="click"
      content={
        <div
          style={{
            width: '300px',
          }}
        >
          Please email any questions or feedback to{' '}
          <a href="mailto:support@moderately.ai">support@moderately.ai</a>
        </div>
      }
    >
      <Button shape="circle">
        <QuestionOutlined />
      </Button>
    </Popover>
  );
};

export default HelpButton;

import { queryClient } from '@/globals';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import DislikeFilled from '@ant-design/icons/DislikeFilled';
import DislikeOutlined from '@ant-design/icons/DislikeOutlined';
import LikeFilled from '@ant-design/icons/LikeFilled';
import LikeOutlined from '@ant-design/icons/LikeOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import List from 'antd/es/list';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import startCase from 'lodash.startcase';
import hash from 'object-hash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  type CreateSearchRequestBody,
  type SearchRequestFilter,
  SearchRequestFilterDateOperatorSchema,
  SearchRequestFilterSchema,
} from '@mai/types';

import CenterSpin from '@components/CenterSpin';
import FormSubmitButton from '@components/FormSubmitButton';
import SectionContainer from '@components/SectionContainer';
import {
  createSearchRequest,
  useSearchRequestQuery,
} from '@queries/search-requests';
import { subscribe, unsubscribe } from '@queries/websockets';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { usePrevious } from '@utils/hooks';
import { track } from '@utils/mixpanel';

const EmptyResults = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography.Text type="secondary">
        No results found, try searching for something else.
      </Typography.Text>
    </div>
  );
};

const PreSearchResults = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography.Text type="secondary">
        Use the search options above to get started!
      </Typography.Text>
    </div>
  );
};

function softmax(arr: number[], temperature: number = 1.0) {
  const scaledArr = arr.map((value) => value / temperature);
  const expArr = scaledArr.map(Math.exp);
  const sumExp = expArr.reduce((a, b) => a + b, 0);
  return expArr.map((exp) => exp / sumExp);
}

const SearchResults = ({
  teamId,
  searchRequestId,
}: {
  teamId: string;
  searchRequestId: string;
}) => {
  const searchRequestQuery = useSearchRequestQuery(searchRequestId);
  const searchRequest = searchRequestQuery.data;

  // Websocket events are sent whenever a search request is updated
  const previousSearchRequestId = usePrevious(searchRequestId);
  useEffect(() => {
    if (previousSearchRequestId === searchRequestId) {
      return;
    } else if (previousSearchRequestId) {
      unsubscribe('search_request_updated', {
        searchRequestId: previousSearchRequestId,
      });
    }
    subscribe('search_request_updated', { searchRequestId }, () => {
      void queryClient.invalidateQueries({
        queryKey: ['searchRequests', searchRequestId],
      });
    });
  }, [previousSearchRequestId, searchRequestId]);

  const results: {
    id: string;
    type: string;
    label: string;
    isKeyWordResult: boolean;
    isSemanticResult: boolean;
    score: number | null;
    normalizedScore: number | null;
  }[] = useMemo(() => {
    let initialResearchCompletedEvent;
    for (const event of searchRequest?.events ?? []) {
      if (event.eventType === 'initialResearchCompleted') {
        initialResearchCompletedEvent = event;
        break;
      }
    }

    let aggregationCompletedEvent;
    for (const event of searchRequest?.events ?? []) {
      if (event.eventType === 'aggregationCompleted') {
        aggregationCompletedEvent = event;
        break;
      }
    }

    const results: {
      id: string;
      type: string;
      label: string;
    }[] = [];
    if (initialResearchCompletedEvent) {
      for (const result of initialResearchCompletedEvent.payload
        .initialResearch) {
        results.push(result);
      }
    }
    if (aggregationCompletedEvent) {
      for (const result of aggregationCompletedEvent.payload.results) {
        if (results.some((r) => r.id === result.id)) continue;
        results.push(result);
      }
    }

    const resultsWithFlags = [];
    for (const result of results) {
      const isKeyWordResult =
        initialResearchCompletedEvent?.payload.initialResearch.some(
          (initialResearchResult) => initialResearchResult.id === result.id,
        ) ?? false;
      const isSemanticResult =
        aggregationCompletedEvent?.payload.results.some(
          (aggregatedResult) => aggregatedResult.id === result.id,
        ) ?? false;
      const score =
        aggregationCompletedEvent?.payload.results.find(
          (aggregatedResult) => aggregatedResult.id === result.id,
        )?.score ?? null;

      let subqueryScore: number | null = null;
      let normalizedSubqueryScore: number | null = null;
      for (const event of searchRequest?.events ?? []) {
        if (event.eventType === 'subqueryCompleted') {
          const subqueryResult = event.payload.results.find(
            (subqueryResult) => subqueryResult.id === result.id,
          );
          if (subqueryResult) {
            if (
              subqueryScore === null ||
              (subqueryResult.score ?? 0) > (subqueryScore ?? 0)
            ) {
              subqueryScore = subqueryResult.score ?? null;
            }
            if (
              normalizedSubqueryScore === null ||
              (subqueryResult.normalizedScore ?? 0) >
                (normalizedSubqueryScore ?? 0)
            ) {
              normalizedSubqueryScore = subqueryResult.normalizedScore ?? null;
            }
          }
        }
      }

      const weightedScore = [
        (score ?? 0) * 0.4,
        (subqueryScore ?? 1) * 0.6,
      ].reduce((a, b) => a + b, 0);

      resultsWithFlags.push({
        ...result,
        isKeyWordResult,
        isSemanticResult,
        score: weightedScore,
      });
    }

    const sortedResults = resultsWithFlags.sort((a, b) => b.score - a.score);
    const softmaxedScores = softmax(
      sortedResults.map((r) => r.score),
      0.5,
    );
    return sortedResults.map((r, index) => ({
      ...r,
      normalizedScore: softmaxedScores[index],
    }));
  }, [searchRequest?.events]);

  const searchResult = useMemo(() => {
    const initialResearchCompletedEvent = searchRequest?.events.find(
      (event) => event.eventType === 'initialResearchCompleted',
    );

    const aggregationCompletedEvent = searchRequest?.events.find(
      (event) => event.eventType === 'aggregationCompleted',
    );

    // if the initial results have not finished and we are loading, show generic spinner
    if (!initialResearchCompletedEvent) {
      return <CenterSpin />;
    }

    // if the initial results has finished, the result is length zero, show the generic spinner
    if (initialResearchCompletedEvent && results.length === 0) {
      return <CenterSpin />;
    }

    // If the aggregation has finished and results is empty show empty message
    if (aggregationCompletedEvent && results.length === 0) {
      return <EmptyResults />;
    }

    const maxScore = results.reduce(
      (max, result) =>
        result.normalizedScore && result.normalizedScore > max
          ? result.normalizedScore
          : max,
      0,
    );
    const standardDeviation = Math.sqrt(
      results.reduce(
        (sum, result) =>
          sum +
          Math.pow((result.normalizedScore ?? 0) - maxScore, 2) /
            results.length,
        0,
      ),
    );
    return (
      <List
        loading={searchRequestQuery.isLoading}
        dataSource={results}
        renderItem={(result) => {
          let to;
          let tagColor;
          if (result.type === 'team') {
            to = `/team/${result.id}`;
            tagColor = 'green';
          }
          if (result.type === 'document') {
            to = `/team/${teamId}/documents/${result.id}`;
            tagColor = 'blue';
          }
          if (result.type === 'user') {
            to = `/team/${teamId}/manage/users/${result.id}`;
            tagColor = 'orange';
          }
          if (result.type === 'project') {
            to = `/team/${teamId}/projects/${result.id}`;
            tagColor = 'pink';
          }

          const deviationsFromMax = Math.abs(
            ((result.normalizedScore ?? 0) - maxScore) / standardDeviation,
          );
          let deviationsFromMaxIcon = null;
          if (deviationsFromMax > 1) {
            deviationsFromMaxIcon = <WarningOutlined />;
          } else if (deviationsFromMax > 2) {
            deviationsFromMaxIcon = <CloseCircleOutlined />;
          }

          return (
            <List.Item>
              <List.Item.Meta
                title={result.label}
                description={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Tag color={tagColor}>{startCase(result.type)}</Tag>
                      {result.score !== null ? (
                        <Tag>
                          {`Semantic Score: ${Math.round(result.score * 100)}`}
                        </Tag>
                      ) : null}
                      {result.normalizedScore !== null ? (
                        <Tag icon={deviationsFromMaxIcon}>
                          {`Relative Score: ${Math.round(
                            result.normalizedScore * 100,
                          )}`}
                        </Tag>
                      ) : null}
                    </div>
                    {to ? <Link to={to}>View</Link> : null}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    );
  }, [results, searchRequest?.events, searchRequestQuery.isLoading, teamId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        overflow: 'auto',
      }}
    >
      {searchResult}
    </div>
  );
};

const AddFilterForm = ({
  onFinish,
}: {
  onFinish: (values: SearchRequestFilter & { id: string }) => Promise<void>;
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm<Partial<SearchRequestFilter>>();

  const typeOptions = Array.from(
    SearchRequestFilterSchema.options.values(),
  ).map((filter) => ({
    label: startCase(filter.shape.type.value),
    value: filter.shape.type.value,
  }));

  const type = Form.useWatch<SearchRequestFilter['type']>('type', form);

  const typeFields = useMemo(() => {
    if (type === 'createdAt' || type === 'updatedAt') {
      return (
        <>
          <Form.Item
            name="operator"
            label="Operator"
            rules={[{ required: true }]}
          >
            <Select
              options={SearchRequestFilterDateOperatorSchema.options.map(
                (operator) => ({
                  label: operator,
                  value: operator,
                }),
              )}
              placeholder="Select operator"
            />
          </Form.Item>
          <Form.Item name="value" label="Value" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </>
      );
    }
    return null;
  }, [type]);

  return (
    <Form
      form={form}
      onFinish={async (formValues) => {
        const filter = SearchRequestFilterSchema.safeParse(formValues);
        if (filter.success) {
          await onFinish({ ...filter.data, id: `filter-${hash(filter.data)}` });
        } else {
          await message.error('Invalid filter');
        }
      }}
      layout="vertical"
    >
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select options={typeOptions} />
      </Form.Item>
      {typeFields}
      <FormSubmitButton text="Save" />
    </Form>
  );
};

const AdvancedSearch = ({
  teamId,
}: {
  teamId: string;
  sessionUserId: string;
}) => {
  const { token } = theme.useToken();
  const sessionUserId = useSessionUserId();

  const teamIsActive = useTeamIsActive();
  const [searchInput, setSearchInput] = useState('');
  const [filters, setFilters] = useState<
    (SearchRequestFilter & { id: string })[]
  >([]);
  const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { modal, message } = App.useApp();

  const currentSearchRequestId = searchParams.get('advancedSearchRequestId');
  const currentSearchRequestQuery = useSearchRequestQuery(
    currentSearchRequestId,
  );
  const currentSearchRequest = currentSearchRequestQuery.data;
  const [likedResults, setLikedResults] = useState<string[]>([]);
  const [dislikedResults, setDislikedResults] = useState<string[]>([]);

  const isLoading =
    currentSearchRequestQuery.isLoading ||
    !currentSearchRequest?.events.some(
      (event) => event.eventType === 'aggregationCompleted',
    );

  useEffect(() => {
    if (currentSearchRequest) {
      setSearchInput(currentSearchRequest.query);
      setFilters(
        currentSearchRequest.filters.map((filter) => ({
          ...filter,
          id: `filter-${hash(filter)}`,
        })),
      );
    }
  }, [currentSearchRequest]);

  // const onAddFilter = async () => {
  //   setIsAddFilterModalOpen(true);
  // };

  const onSearch = async () => {
    const loadingMessage = message.loading('Starting search...');
    try {
      const searchRequest = await createSearchRequest({
        filters,
        query: searchInput,
        links: [
          {
            type: 'team',
            id: teamId,
          },
        ],
      } satisfies CreateSearchRequestBody);
      loadingMessage();
      setSearchParams(
        (prev) => {
          prev.set('advancedSearchRequestId', searchRequest.id);
          return prev;
        },
        {
          replace: true,
        },
      );
      void message.success('Search started');
    } catch (error) {
      await loadingMessage();
      await message.error('Failed to start search');
    }
  };

  const loadingMessage = useMemo(() => {
    const hasSucceededEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'succeeded',
    );
    const hasFailedEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'failed',
    );
    const hasAggregationCompletedEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'aggregationCompleted',
    );
    const hasSubqueryCompletedEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'subqueryCompleted',
    );
    const hasPlanCompletedEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'planCompleted',
    );
    const hasInitialResearchCompletedEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'initialResearchCompleted',
    );
    const hasStartedEvent = currentSearchRequest?.events.find(
      (event) => event.eventType === 'started',
    );

    let message = 'Loading...';
    let color = 'processing';
    let icon = <ReloadOutlined spin />;
    if (hasSucceededEvent) {
      const renderLikeDislike = () => {
        if (!currentSearchRequestId) {
          return null;
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '0.25rem',
            }}
          >
            <Typography.Text type="secondary">
              How are the results?
            </Typography.Text>
            <Button
              type="text"
              size="small"
              icon={
                likedResults.includes(currentSearchRequestId) ? (
                  <LikeFilled style={{ color: token.colorPrimary }} />
                ) : (
                  <LikeOutlined />
                )
              }
              onClick={() => {
                setLikedResults([currentSearchRequestId]);
                setDislikedResults([]);
                track('LIKED_SEARCH_RESULT', {
                  sessionUserId,
                  searchRequestId: currentSearchRequestId,
                  teamId,
                });
              }}
            />
            <Button
              size="small"
              type="text"
              icon={
                dislikedResults.includes(currentSearchRequestId) ? (
                  <DislikeFilled style={{ color: token.colorPrimary }} />
                ) : (
                  <DislikeOutlined />
                )
              }
              onClick={() => {
                setLikedResults([]);
                setDislikedResults([currentSearchRequestId]);
                track('DISLIKED_SEARCH_RESULT', {
                  sessionUserId,
                  searchRequestId: currentSearchRequestId,
                  teamId,
                });
              }}
            />
          </div>
        );
      };
      return renderLikeDislike();
    } else if (hasFailedEvent) {
      message = 'Search request failed';
      color = 'error';
      icon = <CloseCircleOutlined />;
    } else if (hasAggregationCompletedEvent) {
      message = 'Aggregating results';
    } else if (hasSubqueryCompletedEvent) {
      message = 'Running subqueries';
    } else if (hasPlanCompletedEvent) {
      message = 'Planning search';
    } else if (hasInitialResearchCompletedEvent) {
      message = 'Initial research';
    } else if (hasStartedEvent) {
      message = 'Starting search';
    } else {
      return null;
    }

    return (
      <div>
        <Tag color={color} icon={icon}>
          {message}
        </Tag>
      </div>
    );
  }, [
    currentSearchRequest?.events,
    currentSearchRequestId,
    dislikedResults,
    likedResults,
    sessionUserId,
    teamId,
    token.colorPrimary,
  ]);

  return (
    <>
      <SectionContainer
        title="Options"
        cta={
          <div style={{ display: 'flex', gap: '1rem' }}>
            {loadingMessage}
            <Button
              size="small"
              icon={<CloseCircleOutlined />}
              onClick={() => {
                return modal.confirm({
                  title: 'Reset search',
                  content: 'Are you sure you want to reset the search?',
                  onOk: () => {
                    setSearchParams(
                      (prev) => {
                        prev.delete('advancedSearchRequestId');
                        setSearchInput('');
                        setFilters([]);
                        void queryClient.invalidateQueries({
                          queryKey: ['searchRequests', currentSearchRequestId],
                        });
                        return prev;
                      },
                      {
                        replace: true,
                      },
                    );
                  },
                });
              }}
            >
              Reset
            </Button>
          </div>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '1rem',
          }}
        >
          <Modal
            title="Add Filter"
            open={isAddFilterModalOpen}
            onCancel={() => setIsAddFilterModalOpen(false)}
            footer={null}
          >
            <AddFilterForm
              onFinish={async (filter) => {
                setFilters([...filters, filter]);
                setIsAddFilterModalOpen(false);
              }}
            />
          </Modal>
          <Input
            disabled={currentSearchRequestQuery.isLoading || !teamIsActive}
            placeholder="Search"
            style={{ width: '100%' }}
            value={searchInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                void onSearch();
              }
            }}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={onSearch}
            disabled={!searchInput || !teamIsActive}
            loading={isLoading}
          >
            Search
          </Button>
        </div>
      </SectionContainer>
      {currentSearchRequestId ? (
        <SearchResults
          teamId={teamId}
          searchRequestId={currentSearchRequestId}
        />
      ) : (
        <PreSearchResults />
      )}
    </>
  );
};

export default AdvancedSearch;

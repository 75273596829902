import App from 'antd/es/app';
import { useNavigate } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import FormContainer from '@components/FormContainer';
import EditTeamDetails from '@components/forms/EditTeamDetailsForm';
import { useTeamId } from '@utils/router';

const EditTeamDetailsPage = () => {
  const teamId = useTeamId();
  const navigate = useNavigate();
  const { message } = App.useApp();

  if (!teamId) {
    return <ContentContainer.Error />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Edit Team Details"
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Manage', to: `/team/${teamId}/manage` },
          { label: 'Edit' },
        ]}
      />
      <FormContainer>
        <EditTeamDetails
          onFinish={() => {
            void message.success('Team details updated successfully.');
            navigate(`/team/${teamId}/manage`);
          }}
        />
      </FormContainer>
    </ContentContainer>
  );
};

export default EditTeamDetailsPage;

import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const ProjectLinkSchema = z.object({
  documents: z.array(z.string().nanoid()),
});

export type ProjectLink = z.infer<typeof ProjectLinkSchema>;

export const ProjectSchema = z.object({
  id: z.string().nanoid(),
  teamId: z.string().nanoid(),
  name: z.string(),
  description: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  links: ProjectLinkSchema,
});

export type Project = z.infer<typeof ProjectSchema>;

export const CreateProjectSchema = ProjectSchema.pick({
  teamId: true,
  name: true,
  description: true,
  links: true,
});

export type CreateProject = z.infer<typeof CreateProjectSchema>;

export const UpdateProjectSchema = ProjectSchema.pick({
  name: true,
  description: true,
  links: true,
}).partial();

export type UpdateProject = z.infer<typeof UpdateProjectSchema>;

export const ListProjectsFilterSchema = z
  .object({
    projectIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    documentIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    name: z.string().optional(),
  })
  .merge(
    createPaginationRequestQuerySchema(z.enum(['createdAt', 'updatedAt'])),
  );

export type ListProjectsFilter = z.infer<typeof ListProjectsFilterSchema>;

export const ListProjectsResponseSchema = z.array(ProjectSchema);

export type ListProjectsResponse = z.infer<typeof ListProjectsResponseSchema>;

export const DeleteProjectsFilterSchema = z.object({
  projectIds: commaDelimitedList(z.string().nanoid().array().min(1)),
});

export type DeleteProjectsFilter = z.infer<typeof DeleteProjectsFilterSchema>;

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import Button from 'antd/es/button';
import { Link } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import ConversationsTable from '@components/tables/ConversationsTable';
import { useSessionUserId } from '@utils/auth';
import { useTeamId } from '@utils/router';

const AssistantHistoryPage = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();

  if (!teamId || !sessionUserId) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer showFullWidthToggle>
      <ContentContainer.Header
        title="Conversation History"
        breadcrumbs={[
          { to: `/teams/${teamId}`, label: 'Home' },
          { to: `/team/${teamId}/assistant`, label: 'Assistant' },
          { label: 'History' },
        ]}
        cta={
          <Link to={`/team/${teamId}/assistant/new`}>
            <Button type="primary" icon={<PlusCircleOutlined />}>
              New Conversation
            </Button>
          </Link>
        }
      />
      <ConversationsTable teamId={teamId} createdBy={sessionUserId} />
    </ContentContainer>
  );
};

export default AssistantHistoryPage;

import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import Input from 'antd/es/input';
import List from 'antd/es/list';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import Dragger from 'antd/es/upload/Dragger';
import type { RcFile } from 'antd/es/upload/interface';
import uniqBy from 'lodash.uniqby';
import { type ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type CreateDocumentRequest } from '@mai/types';

import { createDocument } from '@queries/documents';
import { useProjectsQuery } from '@queries/projects';
import { useSessionUserId } from '@utils/auth';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const ControlLabel = styled.div`
  font-weight: 550;
  font-size: 0.83rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const CreateTextDocumentForm = ({
  teamId,
  onFinish,
  links,
  projectId,
}: {
  teamId: string;
  projectId?: string | null;
  onFinish?: () => void;
  links: CreateDocumentRequest['links'];
}) => {
  const { message } = App.useApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDone] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: '1rem',
      }}
    >
      <ControlGroup>
        <ControlLabel>
          Title
          <Tooltip title="Leave this blank to have a title automatically generated from the content">
            <QuestionCircleOutlined />
          </Tooltip>
        </ControlLabel>
        <Input
          placeholder="A short title for your document (e.g. 'My Meeting Notes')"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </ControlGroup>
      <ControlGroup>
        <ControlLabel>Content</ControlLabel>
        <Input.TextArea
          placeholder="Write or paste your text here..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows={10}
        />
      </ControlGroup>
      <Button
        type="primary"
        loading={isSubmitting}
        disabled={isDone || !title || !content}
        onClick={async () => {
          setIsSubmitting(true);
          // convert the content to a txt file
          const file = new File([content], `${title}.txt`, {
            type: 'text/plain',
          }) as RcFile;
          const loading = message.loading('Creating document...');
          try {
            const documentId = await createDocument({
              title,
              description: null,
              file,
              links,
            });
            logger.info(
              {
                documentId,
                teamId,
              },
              'Document created',
            );
            loading();
            void message.success('Document created');
            onFinish?.();
          } catch (e) {
            loading();
            void message.error(
              e instanceof Error ? e.message : 'Failed to create document',
            );
            logger.error(
              {
                error: e,
              },
              'Failed to create document',
            );
          } finally {
            setIsSubmitting(false);
          }
        }}
        style={{
          width: '100%',
        }}
      >
        {projectId ? 'Create & Add to Project' : 'Create'}
      </Button>
    </div>
  );
};

const CreateUploadDocumentForm = ({
  teamId,
  onFinish,
  projectId,
  links,
}: {
  teamId: string;
  projectId?: string | null;
  onFinish?: () => void;
  links: CreateDocumentRequest['links'];
}) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [files, setFiles] = useState<RcFile[]>([]);
  const [currentFile, setCurrentFile] = useState<string | null>(null);
  const sessionUserId = useSessionUserId();
  const failedFiles: string[] = [];
  const finishedFiles: string[] = [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: '1rem',
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <Dragger
          accept={'.pdf, .docx'}
          multiple={true}
          beforeUpload={(_, fileList) => {
            // set files and reset state
            setFiles(fileList);
            setIsDone(false);
            setIsSubmitting(false);
            return false;
          }}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click here to select files, or drag and drop your files here.
          </p>
        </Dragger>
      </div>
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          maxHeight: '300px',
        }}
      >
        {files.length ? (
          <List
            dataSource={files.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })}
            renderItem={(file) => {
              let decoration: ReactNode = (
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setFiles((prev) => prev.filter((f) => f.uid !== file.uid));
                  }}
                />
              );
              if (finishedFiles.includes(file.uid)) {
                decoration = <CheckCircleOutlined />;
              } else if (currentFile === file.uid) {
                decoration = (
                  <Spin
                    size="small"
                    style={{
                      marginRight: '0.25rem',
                    }}
                  />
                );
              } else if (failedFiles.includes(file.uid)) {
                decoration = <CloseCircleOutlined />;
              }
              return (
                <List.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      height: '20px',
                    }}
                  >
                    <Typography.Text
                      ellipsis
                      style={{
                        maxWidth: '90%',
                      }}
                    >
                      {file.name}
                    </Typography.Text>
                    {decoration}
                  </div>
                </List.Item>
              );
            }}
          />
        ) : null}
      </div>
      <Descriptions column={1}>
        <Descriptions.Item label="Files">
          {files.length} files selected
        </Descriptions.Item>
        <Descriptions.Item label="Total Size">
          {files.length ? (
            <>
              {'~'}
              {Math.ceil(
                files.reduce((acc, file) => acc + (file.size ?? 0), 0) /
                  1_000_000,
              )}
              {' MB'}
            </>
          ) : (
            '0 MB'
          )}
        </Descriptions.Item>
      </Descriptions>
      <Button
        type="primary"
        loading={isSubmitting}
        disabled={isDone || !files.length}
        onClick={async () => {
          setIsSubmitting(true);
          try {
            for (const file of files) {
              setCurrentFile(file.uid);
              try {
                const title = file.name;
                const description = null;
                const documentId = await createDocument({
                  title,
                  description,
                  file,
                  links,
                });
                logger.info(
                  {
                    documentId,
                    teamId,
                  },
                  'Document created',
                );
                finishedFiles.push(file.uid);
                void queryClient.invalidateQueries({
                  queryKey: ['documents'],
                });
              } catch (e) {
                logger.warn(
                  {
                    error: e,
                    file,
                  },
                  'Failed to upload document',
                );
                failedFiles.push(file.uid);
              }
            }
          } finally {
            setIsSubmitting(false);
            setIsDone(true);
            const errorCount = failedFiles.length;
            track('CREATED_DOCUMENT', {
              teamId,
              sessionUserId,
              metadata: {
                fileCount: files.length,
                errorCount,
              },
            });
            if (errorCount === files.length) {
              void message.error(
                `Failed to upload ${errorCount} file${errorCount > 1 ? 's' : ''}`,
              );
            } else if (errorCount) {
              void message.warning(
                `Finished uploading ${errorCount} file${errorCount > 1 ? 's' : ''}`,
              );
            } else {
              void message.success(
                `Finished uploading ${files.length} file${files.length > 1 ? 's' : ''}`,
              );
              onFinish?.();
            }
          }
        }}
        style={{
          width: '100%',
        }}
      >
        {projectId ? 'Upload & Add to Project' : 'Upload'}
      </Button>
    </div>
  );
};

const CreateDocumentForm = ({
  teamId,
  projectId: initialProjectId,
  onFinish,
  initialLinks,
}: {
  teamId: string;
  projectId?: string | null;
  onFinish?: () => void;
  initialLinks: CreateDocumentRequest['links'];
}) => {
  const [mode, setMode] = useState<'upload' | 'text'>('upload');
  const [projectId, setProjectId] = useState<string | null>(
    initialProjectId ?? null,
  );

  const projectsQuery = useProjectsQuery({
    teamIds: [teamId],
  });
  const projects = projectsQuery.data ?? [];

  const links: CreateDocumentRequest['links'] = useMemo(() => {
    return projectId
      ? uniqBy(
          [...initialLinks, { id: projectId, type: 'project' }],
          ({ type, id }) => `${type}-${id}`,
        )
      : initialLinks;
  }, [projectId, initialLinks]);

  const form = useMemo(() => {
    if (mode === 'upload') {
      return (
        <CreateUploadDocumentForm
          teamId={teamId}
          projectId={initialProjectId}
          onFinish={onFinish}
          links={links}
        />
      );
    } else {
      return (
        <CreateTextDocumentForm
          teamId={teamId}
          projectId={initialProjectId}
          onFinish={onFinish}
          links={links}
        />
      );
    }
  }, [mode, teamId, initialProjectId, onFinish, links]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <ControlGroup>
        <Radio.Group
          block
          options={[
            {
              label: (
                <>
                  <Tooltip title="Upload one or more files directly to your team">
                    Upload <QuestionCircleOutlined />
                  </Tooltip>
                </>
              ),
              value: 'upload',
            },
            {
              label: (
                <>
                  <Tooltip title="Write or paste the contents ">
                    Text <QuestionCircleOutlined />
                  </Tooltip>
                </>
              ),
              value: 'text',
            },
          ]}
          defaultValue="upload"
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => {
            setMode(e.target.value);
          }}
        />
      </ControlGroup>
      {projects.length ? (
        <ControlGroup>
          <ControlLabel>
            Project
            <Tooltip title="Optional: Add this document to a project">
              <QuestionCircleOutlined />
            </Tooltip>
          </ControlLabel>
          <Select
            allowClear
            options={projects.map((project) => ({
              label: project.name,
              value: project.id,
            }))}
            onChange={(value) => {
              setProjectId(value);
            }}
            style={{
              width: '100%',
            }}
            placeholder="Optional: Add to Project"
            value={projectId}
          />
        </ControlGroup>
      ) : null}
      {form}
    </div>
  );
};

export default CreateDocumentForm;

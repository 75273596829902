import ThunderboltFilled from '@ant-design/icons/ThunderboltFilled';
import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { Outlet } from 'react-router-dom';

const EmbedLayout = () => {
  const { token } = theme.useToken();

  return (
    <Layout
      style={{
        height: '100vh',
        width: '100vw',
        scrollbarWidth: 'thin',
        scrollbarColor: `grey ${token.colorBgContainer}`,
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          padding: 16,
          width: '100%',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: 16,
          paddingTop: 32,
        }}
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'flex-end',
            top: 6,
            right: 16,
          }}
        >
          <ThunderboltFilled style={{ color: token.colorPrimary }} />
          <Typography.Text
            type="secondary"
            style={{
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Powered by{' '}
            <a
              style={{ color: token.colorPrimary }}
              href="https://moderately.ai"
              target="_blank"
            >
              Moderately AI
            </a>
          </Typography.Text>
        </div>
        <Outlet />
      </div>
    </Layout>
  );
};

export default EmbedLayout;

import Button from 'antd/es/button';
import { Link } from 'react-router-dom';

import InsightsImage from '@assets/undraw_detailed_analysis.svg';
import ActionCard from '@components/ActionCard';
import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import { Hero } from '@components/Hero';
import ActionCardsLayout from '@components/layouts/ActionCardsLayout';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const InsightsOverviewPage = () => {
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();

  if (!teamId) {
    return <ContentContainer.Loading />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Insights"
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Insights',
            to: `/team/${teamId}/insights/overview`,
          },
        ]}
        subtitle={
          !teamIsActive ? <ActiveTeamAlert teamId={teamId} /> : undefined
        }
      />
      <Hero
        id="insights-overview"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={InsightsImage}
        title="Interact with your data and generate insights"
        extra={
          <div
            style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}
          >
            Learn more about how to best use Insights to power decision making
            in your organization.
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Link to={`/team/${teamId}/insights/new`}>
                <Button
                  type="primary"
                  disabled={!teamIsActive}
                  style={{
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1)',
                  }}
                >
                  Start Now
                </Button>
              </Link>
              <Link
                to="https://scribehow.com/shared/Generating_Insights_for_Contract_Addendum_Modification__3NMIqzyVRYOYph7XgA9CgQ"
                target="_blank"
              >
                <Button
                  style={{
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1)',
                  }}
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        }
      />
      <ActionCardsLayout>
        <ActionCard
          disabled={!teamIsActive}
          title="Generate Insights"
          description="Pick a data source and let the Assistant generate insights tailored to your needs."
          actionTitle="Generate Insights"
          actionLink={`/team/${teamId}/insights/new`}
        />
        <ActionCard
          title="Insights History"
          description="View the history of insights generated for your team."
          actionTitle="View History"
          actionLink={`/team/${teamId}/insights/history`}
        />
      </ActionCardsLayout>
    </ContentContainer>
  );
};

export default InsightsOverviewPage;

import ExperimentOutlined from '@ant-design/icons/ExperimentOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import ThemeSwitch from './ThemeSwitch';

import { useFetchUserQuery } from '@queries/users';
import { useSessionUserId } from '@utils/auth';
import { track } from '@utils/mixpanel';

const Settings = () => {
  const queryClient = useQueryClient();
  const sessionUserId = useSessionUserId();

  const fetchUserQuery = useFetchUserQuery(sessionUserId);
  const user = fetchUserQuery.data;

  const isLocalEnvironment = process.env.NODE_ENV === 'development';

  const settings = useMemo(() => {
    return [
      {
        id: 'profile',
        icon: <SettingOutlined />,
        title: <Link to={`/profile/${sessionUserId}`}>Profile</Link>,
      },
      ...(isLocalEnvironment
        ? [
            {
              id: 'labs',
              icon: <ExperimentOutlined />,
              title: <Link to="/labs">Labs</Link>,
            },
          ]
        : []),
      {
        id: 'logout',
        title: 'Logout',
        icon: <LogoutOutlined />,
        onClick: async () => {
          // TODO: extract this to a utility function
          localStorage.removeItem('sessionToken');
          await queryClient.clear();
          track('LOGGED_OUT', {
            sessionUserId,
          });
          window.location.href = '/';
        },
      },
    ];
  }, [isLocalEnvironment, queryClient, sessionUserId]);

  // Get the first and last initials of the user's name if available
  const initials = useMemo(() => {
    const initials = user?.name?.split(' ').map((n) => n[0]) ?? [];
    return [initials[0], initials[initials.length - 1]].join('');
  }, [user]);

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          ...settings.map((n) => {
            return {
              key: n.id,
              label: n.title,
              onClick: n.onClick,
              icon: n.icon,
            };
          }),
          {
            type: 'divider',
          },
          {
            key: 'theme-switch',
            type: 'group',
            label: (
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                Theme Switch
                <ThemeSwitch />
              </div>
            ),
          },
        ],
      }}
    >
      <Button shape="circle" type="primary">
        {initials || <SettingOutlined />}
      </Button>
    </Dropdown>
  );
};

export default Settings;

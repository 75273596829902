import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import { apiClient } from '@queries/index';
import { logger } from '@utils/logger';
import { useTeamId } from '@utils/router';

let hasSentPostCheckoutRequest = false;

const StripeCheckoutSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const teamId = useTeamId();
  const sessionId = searchParams.get('session_id');
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionId || hasSentPostCheckoutRequest) {
      return;
    }
    hasSentPostCheckoutRequest = true;
    void (async () => {
      const {
        data: { teamSubscriptionId },
      } = await apiClient.post<{ teamSubscriptionId: string }>(
        '/stripe/checkout-session/success',
        {
          stripeCheckoutSessionId: sessionId,
        },
      );
      logger.info(
        {
          teamId,
          teamSubscriptionId,
          sessionId,
        },
        'Stripe checkout success',
      );
      navigate(`/team/${teamId}/billing`);
    })();
  }, [sessionId, navigate, teamId]);

  if (!teamId) {
    logger.error(
      {
        teamId,
        sessionId,
      },
      "Missing 'team_id' in query params",
    );
    return <ContentContainer.Error />;
  }

  if (!sessionId) {
    logger.error(
      {
        teamId,
        sessionId,
      },
      "Missing 'session_id' in query params",
    );
    return <ContentContainer.Error />;
  }

  return <></>;
};

export default StripeCheckoutSuccessPage;

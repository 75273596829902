import { useQueryClient } from '@tanstack/react-query';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input';
import useMessage from 'antd/es/message/useMessage';
import { AxiosError } from 'axios';
import { useState } from 'react';

import FormSubmitButton from '../FormSubmitButton';

import CenterSpin from '@components/CenterSpin';
import { apiClient } from '@queries/index';
import { useProjectAssistantsQuery } from '@queries/project-assistants';
import { useFetchTeam } from '@queries/teams';
import { logger } from '@utils/logger';
import { useTeamId } from '@utils/router';

type ManageTeamFormData = Partial<{
  name: string;
  defaultProjectAssistantPrompt: string;
}>;

const EditTeamDetails = ({ onFinish }: { onFinish?: () => void }) => {
  const teamId = useTeamId();
  const [messageApi, messageContext] = useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const teamQuery = useFetchTeam(teamId);
  const teamName = teamQuery.data?.name;
  const [form] = useForm<ManageTeamFormData>();
  const queryClient = useQueryClient();
  const projectAssistantsQuery = useProjectAssistantsQuery(
    {
      teamIds: teamId ? [teamId] : undefined,
    },
    !!teamId,
  );

  const onFormSubmit = async (formData: ManageTeamFormData) => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    setIsSubmitting(true);
    try {
      await apiClient.patch(`/teams/${teamId}`, {
        name: formData.name,
        defaultProjectAssistantPrompt: formData.defaultProjectAssistantPrompt,
      });
      void messageApi.success('Successfully updated team name');
      void queryClient.invalidateQueries({ queryKey: ['team'] });
      void queryClient.invalidateQueries({ queryKey: ['teams'] });
      if (onFinish) onFinish();
    } catch (error) {
      if (error instanceof AxiosError) {
        if ((error.response?.status ?? 0) >= 500) {
          logger.error({
            error,
            message: 'Failed to update team name',
          });
          void messageApi.error(
            'An error occurred; please refresh the page and try again',
          );
        } else if (error.response?.status === 400) {
          logger.warn({
            error,
            message: 'Failed to update team name',
          });
          void messageApi.warning(error.response?.data.errors[0].message);
        } else {
          void messageApi.error(error.response?.data.message);
        }
      } else {
        logger.error({
          error,
          message: 'Failed to update team name',
        });
        void messageApi.error(
          'An error occurred; please refresh the page and try again',
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!teamQuery.data) {
    return <CenterSpin />;
  }

  return (
    <>
      {messageContext}
      <Form
        form={form}
        initialValues={{
          name: teamName,
        }}
        layout="vertical"
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="name"
          label="Team Name"
          rules={[
            {
              required: true,
              message: 'Team name must be at least 1 character long',
            },
            {
              max: 255,
              message: 'Team name must be less than 255 characters',
            },
          ]}
        >
          <Input showCount max={255} min={1} />
        </Form.Item>
        {projectAssistantsQuery.data?.length ? (
          <Form.Item
            name="defaultProjectAssistantPrompt"
            label="Default Assistant Prompt"
            initialValue={teamQuery.data?.defaultProjectAssistantPrompt}
          >
            <Input.TextArea maxLength={1000} showCount />
          </Form.Item>
        ) : null}
        <FormSubmitButton loading={isSubmitting} text="Save" />
      </Form>
    </>
  );
};

export default EditTeamDetails;

import { useContext } from 'react';

import { ThemeConfigContext } from '../context/ThemeContext';

export const useThemeConfig = () => {
  const context = useContext(ThemeConfigContext);
  if (!context) {
    throw new Error('useThemeConfig must be used within a ThemeConfigProvider');
  }
  return context;
};

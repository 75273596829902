import Typography from 'antd/es/typography';

import NoOutput from '@assets/undraw_location_search.svg';
import MarkdownContent from '@components/MarkdownContent';
import { useWorkflowRunStateQuery } from '@queries/workflows';

const InsightsContent = ({
  workflowRunId,
  streamedOutput,
}: {
  workflowRunId: string;
  streamedOutput: string | null;
}) => {
  const workflowRunStateQuery = useWorkflowRunStateQuery(workflowRunId);
  const workflowRunState = workflowRunStateQuery.data;

  if (!workflowRunState) {
    return <div>No Workflow History Yet</div>;
  }

  const output = workflowRunState.outputRevision?.includes(streamedOutput ?? '')
    ? workflowRunState.outputRevision
    : streamedOutput;

  if (!output) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <img
          src={NoOutput}
          alt="No Output"
          style={{ width: '50%', maxWidth: 500 }}
        />
        <Typography.Text>No output has been generated yet</Typography.Text>
      </div>
    );
  }

  return <MarkdownContent content={output} />;
};

export default InsightsContent;

import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const DocumentExtractionStatementIssueLinkSchema = z.object({
  documentExtractionStatementId: z.string(),
  documentExtractionStatementIssueId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type DocumentExtractionStatementIssueLink = z.infer<
  typeof DocumentExtractionStatementIssueLinkSchema
>;

export const DocumentExtractionStatementIssueSchema = z.object({
  id: z.string(),
  issue: z.string(),
  links: DocumentExtractionStatementIssueLinkSchema.array(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type DocumentExtractionStatementIssue = z.infer<
  typeof DocumentExtractionStatementIssueSchema
>;

export const ListDocumentExtractionStatementIssuesQuerySchema = z.object({
  documentIds: commaDelimitedList(z.string().nanoid().array()).optional(),
  documentExtractionIds: commaDelimitedList(
    z.string().nanoid().array(),
  ).optional(),
  documentExtractionStatementIds: commaDelimitedList(
    z.string().nanoid().array(),
  ).optional(),
  documentExtractionStatementIssueIds: commaDelimitedList(
    z.string().nanoid().array(),
  ).optional(),
});

export type ListDocumentExtractionStatementIssuesQuery = z.infer<
  typeof ListDocumentExtractionStatementIssuesQuerySchema
>;

export const ListDocumentExtractionStatementIssuesResponseSchema = z.array(
  DocumentExtractionStatementIssueSchema,
);

export type ListDocumentExtractionStatementIssuesResponse = z.infer<
  typeof ListDocumentExtractionStatementIssuesResponseSchema
>;

import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const TeamSubscriptionStripePropertiesSchema = z.union([
  z.object({
    type: z.literal('seat-based'),
    costPerSeat: z.number().min(0),
    currency: z.string(),
    billingPeriodStart: z.coerce.date(),
    billingPeriodEnd: z.coerce.date(),
    previousBillingPeriodStart: z.coerce.date(),
    previousBillingPeriodEnd: z.coerce.date(),
    isCanceled: z.boolean(),
    seats: z.number().min(0),
  }),
  z.object({
    type: z.literal('usage-based'),
    tiers: z.array(
      z.object({
        upTo: z.number().nullable(),
        unitAmount: z.number().nullable(),
      }),
    ),
    currency: z.string(),
    billingPeriodStart: z.coerce.date(),
    billingPeriodEnd: z.coerce.date(),
    previousBillingPeriodStart: z.coerce.date(),
    previousBillingPeriodEnd: z.coerce.date(),
    isCanceled: z.boolean(),
    meteredUsage: z.number().min(0),
  }),
]);

export type TeamSubscriptionStripeProperties = z.infer<
  typeof TeamSubscriptionStripePropertiesSchema
>;

export const TeamSubscriptionSchema = z.object({
  id: z.string(),
  teamId: z.string(),
  stripeSubscriptionId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  createdBy: z.string(),
  updatedBy: z.string(),
  stripeProperties: TeamSubscriptionStripePropertiesSchema,
});

export type TeamSubscription = z.infer<typeof TeamSubscriptionSchema>;

export const CreateTeamSubscriptionBodySchema = z.object({
  teamId: z.string(),
});

export type CreateTeamSubscriptionBody = z.infer<
  typeof CreateTeamSubscriptionBodySchema
>;

export const ListTeamSubscriptionsQuerySchema = z.object({
  teamSubscriptionIds: commaDelimitedList().optional(),
  teamIds: commaDelimitedList().optional(),
  stripeSubscriptionIds: commaDelimitedList().optional(),
});

export type ListTeamSubscriptionsQuery = z.infer<
  typeof ListTeamSubscriptionsQuerySchema
>;

export const ListTeamSubscriptionsResponseSchema = z.array(
  TeamSubscriptionSchema,
);

export type ListTeamSubscriptionsResponse = z.infer<
  typeof ListTeamSubscriptionsResponseSchema
>;

export const GetTeamSubscriptionResponseSchema = TeamSubscriptionSchema.pick({
  id: true,
  teamId: true,
  createdAt: true,
  updatedAt: true,
  createdBy: true,
  updatedBy: true,
  stripeProperties: true,
});

export type GetTeamSubscriptionResponse = z.infer<
  typeof GetTeamSubscriptionResponseSchema
>;

export const UpdateTeamSubscriptionBodySchema = z.object({
  shouldCancel: z.boolean(),
});

export type UpdateTeamSubscriptionBody = z.infer<
  typeof UpdateTeamSubscriptionBodySchema
>;

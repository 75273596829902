import Tabs from 'antd/es/tabs';
import { useSearchParams } from 'react-router-dom';

import Capabilities from '@components/Capabilities';
import Insights from '@components/Insights';
import KnowledgeGraph from '@components/KnowledgeGraph';

const LabsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') ?? 'capabilities';

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      }}
    >
      <Tabs
        items={[
          {
            key: 'capabilities',
            label: 'Capabilities',
            children: <Capabilities />,
          },
          {
            key: 'knowledge-graph',
            label: 'Knowledge Graph',
            children: <KnowledgeGraph />,
          },
          {
            key: 'insights',
            label: 'Insights',
            children: <Insights />,
          },
        ]}
        activeKey={activeTab}
        onChange={(key) =>
          setSearchParams(
            (prev) => {
              prev.set('tab', key);
              return prev;
            },
            { replace: true },
          )
        }
      />
    </div>
  );
};

export default LabsPage;

import MinusCircleOutlined from '@ant-design/icons/MinusCircleOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import useMessage from 'antd/es/message/useMessage';
import Typography from 'antd/es/typography';
import { AxiosError } from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';

import { type UpdateUserBody, type UserDetail } from '@mai/types';

import ContentContainer from '@components/ContentContainer';
import { apiClient } from '@queries/index';
import { useFetchUserQuery } from '@queries/users';
import { resetPersistentState } from '@utils/hooks';
import { logger } from '@utils/logger';

type PersonalInformationFormData = Partial<{
  name: string;
}>;

const PersonalInformation = ({ user }: { user: UserDetail }) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [form] = Form.useForm<PersonalInformationFormData>();
  const [messageApi, messageContext] = useMessage();

  const formData = Form.useWatch([], form) ?? {};

  const isFormTouched = formData.name !== user.name;

  const onFormSubmit = async (values: PersonalInformationFormData) => {
    const name = values.name ?? user.name;

    setIsSubmitting(true);
    try {
      await form.validateFields();
      await apiClient.patch(`/users/${user.id}`, {
        name,
      } satisfies UpdateUserBody);
      void messageApi.success('Personal Information updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['user', { userId: user.id }],
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {messageContext}
        <Typography.Title level={3}>Personal Information</Typography.Title>
        <Button
          disabled={!isFormTouched}
          type="primary"
          loading={isSubmitting}
          onClick={() => onFormSubmit(formData)}
        >
          Save
        </Button>
      </div>
      <Form form={form} layout="vertical">
        <Form.Item label="Name" name="name" initialValue={user.name}>
          <Input />
        </Form.Item>
      </Form>
    </Card>
  );
};

type EmailsFormData = Partial<{
  emails: string[];
}>;

const Emails = ({ user }: { user: UserDetail }) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [form] = Form.useForm<EmailsFormData>();
  const [messageApi, messageContext] = useMessage();

  const formData = Form.useWatch([], form) ?? {};

  const isFormTouched =
    formData.emails?.length !== user.emails.length ||
    formData.emails?.some((n) => !user.emails.map((e) => e.email).includes(n));

  const onFormSubmit = async (values: EmailsFormData) => {
    const emails = values.emails ?? [];

    setIsSubmitting(true);
    try {
      await form.validateFields();
      await apiClient.patch(`/users/${user.id}`, {
        emails,
      } satisfies UpdateUserBody);
      void messageApi.success('Emails updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['user', { userId: user.id }],
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {messageContext}
        <Typography.Title level={3}>Emails</Typography.Title>
        <Button
          disabled={!isFormTouched}
          type="primary"
          onClick={() => onFormSubmit(formData)}
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
      <Form form={form} layout="vertical">
        <Form.List
          name="emails"
          initialValue={user.emails.map(({ email }) => email)}
        >
          {(fields, { add, remove }, { errors }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              {fields.map((field, index) => {
                const { key, ...restField } = field;
                return (
                  <div
                    key={`${field.key}-${index}`}
                    style={{
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    <Form.Item
                      key={key}
                      {...restField}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please input passenger's name or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        type="text"
                        autoComplete="off"
                        placeholder="email@domain.com"
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </div>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Another Email
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </div>
          )}
        </Form.List>
      </Form>
    </Card>
  );
};

type PasswordFormData = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const Password = ({ user }: { user: UserDetail }) => {
  const [messageApi, messageContext] = useMessage();
  const [form] = Form.useForm<PasswordFormData>();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const formData = Form.useWatch([], form) ?? {};

  const isFormTouched =
    formData.oldPassword || formData.newPassword || formData.confirmPassword;

  const onFormSubmit = async (values: PasswordFormData) => {
    setIsSubmitting(true);
    try {
      await form.validateFields();
      await apiClient.patch(`/users/${user.id}`, {
        password: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      } satisfies UpdateUserBody);
      await queryClient.invalidateQueries({
        queryKey: ['user', { userId: user.id }],
      });
      void messageApi.success('Password updated successfully');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          void messageApi.warning(
            error.response?.data.errors?.[0]?.message ??
              error.response.data.message,
          );
        } else {
          logger.error(error);
          void messageApi.error('Failed to update password');
        }
      } else {
        logger.error(error);
        void messageApi.error('Failed to update password');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card>
      {messageContext}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title level={3}>Password</Typography.Title>
        <Button
          disabled={!isFormTouched}
          type="primary"
          onClick={() => onFormSubmit(formData)}
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[
            { required: true, message: 'Please input your old password' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: 'Please input your new password' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Card>
  );
};

const UserProfilePage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const fetchUserQuery = useFetchUserQuery(userId);
  const user = fetchUserQuery.data;

  const [isResetting, setIsResetting] = React.useState(false);

  if (fetchUserQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (fetchUserQuery.isError) {
    return <ContentContainer.Error />;
  }

  if (!user) {
    return <ContentContainer.NotFound />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title={fetchUserQuery.data?.name}
        breadcrumbs={[
          { label: 'Home', to: '/team' },
          { label: fetchUserQuery.data?.name },
        ]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <PersonalInformation user={user} />
        <Emails user={user} />
        <Password user={user} />
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '1rem',
          }}
        >
          <Typography.Title level={3}>Reset Local Settings</Typography.Title>
          <div>
            <Typography.Text>
              This will reset all your locally saved settings. These include
              hidden banners, default team, and other settings.
            </Typography.Text>
          </div>
          <Button
            type="primary"
            loading={isResetting}
            style={{
              marginTop: '1rem',
            }}
            onClick={() => {
              setIsResetting(true);
              try {
                resetPersistentState();
                window.location.reload();
              } finally {
                setIsResetting(false);
              }
            }}
          >
            Reset Locally Saved Settings
          </Button>
        </Card>
      </div>
    </ContentContainer>
  );
};

export default UserProfilePage;

import App from 'antd/es/app';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useState } from 'react';

import { type ProjectAssistant } from '@mai/types';

import FormSubmitButton from '@components/FormSubmitButton';
import { createProjectAssistant } from '@queries/project-assistants';

type ProjectAssistantFormData = {
  name: string;
  description: string;
};

const CreateProjectAssistantForm = ({
  projectId,
  onSuccess,
}: {
  projectId: string;
  onSuccess: (projectAssistant: ProjectAssistant) => void;
}) => {
  const { message } = App.useApp();

  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm<ProjectAssistantFormData>();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (data) => {
        const name = data?.name;
        const description = data?.description ?? null;

        if (!name) {
          void message.warning('Please enter a name');
          return;
        }

        setIsSaving(true);
        try {
          const projectAssistant = await createProjectAssistant({
            name,
            description,
            projectId,
          });
          onSuccess(projectAssistant);
          void message.success('Project assistant created successfully');
          form.resetFields();
        } catch (error) {
          void message.error('Failed to create project assistant');
        } finally {
          setIsSaving(false);
        }
      }}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input
          placeholder="Project Assistant Name"
          max={255}
          min={1}
          showCount
        />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea
          placeholder="Project Assistant Description"
          showCount
          maxLength={1000}
        />
      </Form.Item>
      <FormSubmitButton text="Create Project Assistant" loading={isSaving} />
    </Form>
  );
};

export default CreateProjectAssistantForm;

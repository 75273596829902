import { type QueryClient, useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import { type MessageInstance } from 'antd/es/message/interface';

import { logger } from '@utils/logger';

export type EntityCallbacks<T> = {
  onSuccess?: T extends void ? () => void : (data: T) => void;
  onError?: () => void;
  onFinish?: () => void;
};

export abstract class BaseEntity {
  static queryClient: QueryClient;
  static message: MessageInstance;

  static get queryKey(): string {
    throw new Error('queryKey must be implemented by the subclass.');
  }

  static init(queryClient: QueryClient, message: MessageInstance) {
    this.queryClient = queryClient;
    this.message = message;
  }

  static async invalidate(params: string[] = []): Promise<void> {
    if (!this.queryClient) {
      throw new Error('QueryClient not initialized. Call Entity.init() first.');
    }

    await this.queryClient.invalidateQueries({
      queryKey: [this.queryKey, ...params],
    });
  }

  static async handleRequest<T>(
    promise: Promise<T>,
    errorMessage: string,
    callbacks?: EntityCallbacks<T>,
  ): Promise<T | null> {
    try {
      const data = await promise;
      callbacks?.onSuccess?.(data);
      return data;
    } catch (error) {
      logger.error({ message: errorMessage, error });
      void this.message.error(errorMessage);
      callbacks?.onError?.();
      return null;
    } finally {
      callbacks?.onFinish?.();
    }
  }

  static async handleMutationRequest(
    promise: Promise<void>,
    successMessage: string,
    errorMessage: string,
    callbacks?: EntityCallbacks<void>,
  ): Promise<void> {
    try {
      await promise;
      await this.invalidate();
      void this.message.success(successMessage);
      callbacks?.onSuccess?.();
    } catch (error) {
      logger.error({ message: errorMessage, error });
      void this.message.error(errorMessage);
      callbacks?.onError?.();
    } finally {
      callbacks?.onFinish?.();
    }
  }
}

export const BaseEntityInit = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  BaseEntity.init(queryClient, message);

  return null;
};

import { useQueryClient } from '@tanstack/react-query';
import AutoComplete from 'antd/es/auto-complete';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';
import startCase from 'lodash.startcase';
import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { type CreateSearchRequestBody, type SearchRequest } from '@mai/types';

import InitialImage from '@assets/undraw_location_search.svg';
import { apiClient } from '@queries/index';
import { useSearchRequestQuery } from '@queries/search-requests';
import { subscribe, unsubscribe } from '@queries/websockets';
import { useSessionUserId } from '@utils/auth';
import { usePrevious } from '@utils/hooks';
import { track } from '@utils/mixpanel';
import { screenSizes } from '@utils/theme';

const StyledAutoComplete = styled(AutoComplete)`
  width: 400px;
  @media (max-width: ${screenSizes.tablet}px) {
    width: 100%;
  }
  @media (max-width: ${screenSizes.mobile}px) {
    display: none;
  }
`;

const SimpleSearch = ({ teamId }: { teamId: string }) => {
  const [inputValue, setInputValue] = useState('');
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef(null);
  const sessionUserId = useSessionUserId();

  const searchValue = searchParams.get('search') || '';
  const [searchRequestId, setSearchRequestId] = useState<string | null>(null);
  const searchRequestQuery = useSearchRequestQuery(searchRequestId);

  const [open, setOpen] = useState(false);

  const onSearch = (value: string) => {
    setSearchParams(
      (prev) => {
        if (!value) {
          prev.delete('search');
        } else {
          prev.set('search', value);
        }
        return prev;
      },
      {
        replace: true,
      },
    );
  };

  const [debouncedSearchValue] = useDebounce(inputValue, 500);
  const previousDebouncedSearchValue = usePrevious(debouncedSearchValue);
  useEffect(() => {
    if (debouncedSearchValue === '' && previousDebouncedSearchValue !== '') {
      setSearchRequestId(null);
    }
    if (
      !debouncedSearchValue ||
      debouncedSearchValue === previousDebouncedSearchValue
    ) {
      return;
    }
    void (async () => {
      const { data: searchRequest } = await apiClient.post<SearchRequest>(
        'search',
        {
          query: debouncedSearchValue,
          links: [
            {
              type: 'team',
              id: teamId,
            },
          ],
        } satisfies CreateSearchRequestBody,
      );
      setSearchRequestId(searchRequest.id);
      track('CREATED_SEARCH_REQUEST', {
        sessionUserId,
        searchRequestId: searchRequest.id,
        teamId,
      });
    })();
  }, [
    debouncedSearchValue,
    previousDebouncedSearchValue,
    sessionUserId,
    setSearchParams,
    teamId,
  ]);

  // Listen to websocket events and update the search results
  const previousSearchRequestId = usePrevious(searchRequestId);
  useEffect(() => {
    if (
      previousSearchRequestId !== searchRequestId &&
      previousSearchRequestId
    ) {
      unsubscribe('search_request_updated', {
        searchRequestId: previousSearchRequestId,
      });
    }
    if (!searchRequestId) {
      return;
    }
    subscribe(
      'search_request_updated',
      {
        searchRequestId,
      },
      () => {
        if (!searchRequestQuery.isFetching) {
          void queryClient.invalidateQueries({
            queryKey: ['searchRequests', searchRequestId],
          });
        }
      },
    );
  });

  const keywordSearchResults = searchRequestQuery.data?.events.find(
    (event) => event.eventType === 'initialResearchCompleted',
  )?.payload.initialResearch;

  const semanticSearchResults = searchRequestQuery.data?.events
    .find((event) => event.eventType === 'aggregationCompleted')
    ?.payload.results.filter((result) => {
      return !keywordSearchResults?.find(
        (keywordResult) => keywordResult.id === result.id,
      );
    });

  const loadingPlaceholderOption = {
    label: 'Loading...',
    value: 'loading',
  };

  return (
    <StyledAutoComplete
      placeholder="Search..."
      onBlur={() => {
        setTimeout(() => {
          setOpen(false);
        }, 100);
      }}
      open={open}
      options={[
        {
          label: 'Keyword Search',
          options: keywordSearchResults?.map((result) => ({
            label: result.label,
            value: result.id,
          })) ?? [loadingPlaceholderOption],
        },
        {
          label: 'Semantic Search',
          options: semanticSearchResults?.map((result) => ({
            label: result.label,
            value: result.id,
          })) ?? [loadingPlaceholderOption],
        },
      ]}
      dropdownRender={() => {
        if (!searchRequestId) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <img
                src={InitialImage}
                alt="Search"
                style={{
                  width: '100%',
                  maxWidth: '150px',
                  marginBottom: '1rem',
                }}
              />
              <Typography.Text type="secondary">
                Start searching across all data sources in your team.
              </Typography.Text>
            </div>
          );
        }

        const renderResult = (
          result: NonNullable<typeof keywordSearchResults>[0],
        ) => {
          let to;
          let tagColor;
          if (result.type === 'team') {
            to = `/team/${result.id}`;
            tagColor = 'green';
          }
          if (result.type === 'document') {
            to = `/team/${teamId}/documents/${result.id}`;
            tagColor = 'blue';
          }
          if (result.type === 'task') {
            to = `/team/${teamId}/tasks/${result.id}`;
            tagColor = 'purple';
          }
          if (result.type === 'user') {
            to = `/team/${teamId}/manage/users/${result.id}`;
            tagColor = 'orange';
          }
          if (result.type === 'note') {
            to = `/team/${teamId}/notes/${result.id}`;
            tagColor = 'magenta';
          }

          const content = (
            <Button
              key={result.id}
              type="text"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography.Text ellipsis>{result.label}</Typography.Text>
              <Tag color={tagColor}>{startCase(result.type)}</Tag>
            </Button>
          );

          if (!to) {
            return content;
          }

          return (
            <Link
              key={result.id}
              to={to}
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              {content}
            </Link>
          );
        };

        const renderNoResults = () => (
          <Typography.Text
            type="secondary"
            style={{
              textAlign: 'left',
              width: '100%',
              marginLeft: '1rem',
            }}
          >
            No results found
          </Typography.Text>
        );

        const renderLoading = () => (
          <Typography.Text
            type="secondary"
            style={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            Loading <Spin size="small" style={{ marginLeft: '0.5rem' }} />
          </Typography.Text>
        );

        const keywordResults = (() => {
          if (!keywordSearchResults) {
            return renderLoading();
          }
          if (keywordSearchResults.length === 0) {
            return renderNoResults();
          }
          if (keywordSearchResults.length > 5) {
            return (
              <>
                {keywordSearchResults.slice(0, 5).map(renderResult)}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Typography.Text type="secondary">
                    + {keywordSearchResults.length - 5} more
                  </Typography.Text>
                </div>
              </>
            );
          }

          return keywordSearchResults.map(renderResult);
        })();

        const semanticResults = (() => {
          if (!semanticSearchResults) {
            return renderLoading();
          }
          if (semanticSearchResults.length === 0) {
            return renderNoResults();
          }
          if (semanticSearchResults.length > 5) {
            return (
              <>
                {semanticSearchResults.slice(0, 5).map(renderResult)}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Typography.Text type="secondary">
                    + {semanticSearchResults.length - 5} more
                  </Typography.Text>
                </div>
              </>
            );
          }

          return semanticSearchResults.map(renderResult);
        })();

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <Typography.Text
                strong
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                Keyword Search
              </Typography.Text>
              {keywordResults}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <Typography.Text
                strong
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                Semantic Search
              </Typography.Text>
              {semanticResults}
            </div>
            <Link
              to={`/team/${teamId}/search?search=${searchValue}&advancedSearchRequestId=${searchRequestId}`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              View all results
            </Link>
          </div>
        );
      }}
    >
      <Input.Search
        ref={inputRef}
        value={searchValue}
        onFocus={() => {
          setOpen(true);
        }}
        onChange={(e) => {
          setInputValue(e.target.value);
          setOpen(true);
        }}
        onSearch={onSearch}
        enterButton
      />
    </StyledAutoComplete>
  );
};

export default SimpleSearch;

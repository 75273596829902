import { queryClient } from '@/globals';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import { type ColumnType } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { useSearchParams } from 'react-router-dom';

import { type PlatformCreditType } from '@mai/types';

import Table from '@components/Table';
import TableHeader from '@components/TableHeader';
import { useFetchPlatformCredits } from '@queries/platform-credits';
import { renderDate } from '@utils/dates';

type PlatformCreditRow = {
  id: string;
  createdAt: Date;
  amount: number;
  type: PlatformCreditType;
};

const TeamPlatformCreditsTableHeader = ({
  dataSource,
  isLoading,
}: {
  dataSource: PlatformCreditRow[];
  isLoading: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize') ?? '10';
  const page = parseInt(searchParams.get('page') ?? '1', 10);
  return (
    <TableHeader>
      <div
        style={{
          width: '100%',
        }}
      />
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <Select
          options={[
            { label: '10', value: '10' },
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
          size="small"
          value={pageSize}
          suffixIcon={<>/ Page</>}
          onChange={(value) => {
            setSearchParams((prev) => {
              prev.set('pageSize', value);
              return prev;
            });
          }}
          style={{
            width: '5rem',
          }}
        />
        <Tooltip title="Previous Page">
          <Button
            size="small"
            disabled={page === 1}
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page - 1).toString());
                return prev;
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Next Page">
          <Button
            size="small"
            disabled={dataSource.length < parseInt(pageSize ?? '10', 10)}
            icon={<ArrowRightOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page + 1).toString());
                return prev;
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Refresh">
          <Button
            size="small"
            icon={<SyncOutlined spin={isLoading} />}
            onClick={() => {
              void queryClient.invalidateQueries({
                queryKey: ['documents'],
              });
            }}
          />
        </Tooltip>
      </div>
    </TableHeader>
  );
};

const TeamPlatformCreditsTable = ({
  teamId,
  size = 'middle',
}: {
  teamId: string;
  size?: 'small' | 'middle' | 'large';
}) => {
  const [searchParams] = useSearchParams();
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10', 10);
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const teamPlatformCreditsQuery = useFetchPlatformCredits(
    teamId,
    {
      page,
      pageSize,
      orderBy: 'createdAt',
      orderDirection: 'desc',
    },
    !!teamId,
  );

  const columns: ColumnType<PlatformCreditRow>[] = [
    {
      title: 'Usage Type',
      dataIndex: 'type',
      key: 'type',
      width: '12rem',
      render: (value: PlatformCreditType) => {
        if (value === 'analysis-request-run-v1') {
          return 'Analysis';
        } else if (value === 'document-upload-v1') {
          return 'Document';
        } else if (value === 'conversation-message-v1') {
          return 'Conversation';
        } else if (value === 'search-request-v1') {
          return 'Search';
        } else if (value === 'insight-request-run-v1') {
          return 'Insight';
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Credits Used',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Used At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '12rem',
      render: (value: Date) => {
        return renderDate(value);
      },
    },
  ];

  const dataSource: PlatformCreditRow[] =
    teamPlatformCreditsQuery.data?.map((credit) => ({
      id: credit.id,
      type: credit.type,
      amount: credit.amount,
      createdAt: credit.createdAt,
    })) || [];

  return (
    <>
      <TeamPlatformCreditsTableHeader
        dataSource={dataSource}
        isLoading={teamPlatformCreditsQuery.isLoading}
      />
      <Table<PlatformCreditRow>
        loading={teamPlatformCreditsQuery.isLoading}
        columns={columns}
        dataSource={dataSource}
        size={size}
        pagination={false}
        rowKey={(row) => row.id}
      />
    </>
  );
};

export default TeamPlatformCreditsTable;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Skeleton from 'antd/es/skeleton';
import Statistic from 'antd/es/statistic';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { type ListProductsResponse, type TeamSubscription } from '@mai/types';

import CenterSpin from './CenterSpin';
import SectionContainer from './SectionContainer';

import ProductCard from '@components/ProductCard';
import { apiClient } from '@queries/index';
import { useTeamSubscriptionsQuery } from '@queries/team-subscriptions';

const ResponsiveCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1rem;
  flex-wrap: wrap;
`;

const ResponsiveCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 12rem;
`;

const PaymentInformationCard = ({ teamId }: { teamId: string }) => {
  const { token } = theme.useToken();
  const teamSubscriptionQuery = useTeamSubscriptionsQuery(
    { teamIds: [teamId] },
    !!teamId,
  );
  const teamSubscription = teamSubscriptionQuery.data?.[0];
  const { message, modal } = App.useApp();
  const [isLoadingPortal, setIsLoadingPortal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  if (teamSubscriptionQuery.isLoading) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }

  if (!teamSubscription) {
    return null;
  }

  return (
    <ResponsiveCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Typography.Text>Payment information</Typography.Text>
        </div>
        <Button
          type="link"
          size="small"
          disabled={isLoadingPortal}
          onClick={async () => {
            setIsLoadingPortal(true);
            const loadingMessage = message.loading('Loading portal...');
            try {
              const { data } = await apiClient.get(
                `/teams/${teamId}/stripe-customer-portal-link`,
              );
              window.open(data.portalLink, '_blank');
              void loadingMessage();
            } catch {
              void loadingMessage();
              void message.error('Failed to open the portal');
            } finally {
              setIsLoadingPortal(false);
            }
          }}
          style={{
            width: 'fit-content',
          }}
        >
          <Typography.Link>Update Payment Method</Typography.Link>
        </Button>
        <Button
          type="link"
          size="small"
          disabled={isLoadingPortal}
          onClick={async () => {
            if (teamSubscription.stripeProperties.isCanceled) {
              return modal.confirm({
                title: 'Confirm Reactivation',
                icon: null,
                content:
                  'Are you sure you want to reactivate your subscription? You will not be charged until the next billing period.',
                onOk: async () => {
                  const loadingMessage = message.loading(
                    'Reactivating subscription...',
                  );
                  try {
                    await apiClient.patch<TeamSubscription>(
                      `/team-subscriptions/${teamSubscription.id}`,
                      {
                        shouldCancel: false,
                      },
                    );
                    void loadingMessage();
                    void message.success('Subscription Reactivated');
                    void queryClient.invalidateQueries({
                      queryKey: ['team-subscriptions', { teamIds: [teamId] }],
                    });
                  } catch {
                    void loadingMessage();
                    void message.error('Failed to reactivate subscription');
                  }
                },
                closable: true,
                maskClosable: true,
              });
            }
            return modal.confirm({
              title: 'Confirm Cancelation',
              content:
                'Are you sure you want to cancel your subscription? This cancellation will only take effect at the end of the current billing period.',
              icon: null,
              onOk: async () => {
                const loadingMessage = message.loading(
                  'Cancelling subscription...',
                );
                try {
                  await apiClient.patch<TeamSubscription>(
                    `/team-subscriptions/${teamSubscription.id}`,
                    {
                      shouldCancel: true,
                    },
                  );
                  void loadingMessage();
                  void message.success('Subscription Cancelled');
                  void queryClient.invalidateQueries({
                    queryKey: ['team-subscriptions', { teamIds: [teamId] }],
                  });
                } catch {
                  void loadingMessage();
                  void message.error('Failed to cancel subscription');
                }
              },
              closable: true,
              maskClosable: true,
            });
          }}
          style={{
            width: 'fit-content',
          }}
        >
          <Typography.Text
            type={
              teamSubscription.stripeProperties.isCanceled
                ? undefined
                : 'danger'
            }
            style={{
              color: teamSubscription.stripeProperties.isCanceled
                ? token.colorPrimary
                : undefined,
            }}
          >
            {teamSubscription.stripeProperties.isCanceled
              ? 'Reactivate Subscription'
              : 'Cancel Subscription'}
          </Typography.Text>
        </Button>
      </div>
    </ResponsiveCard>
  );
};

const NextBillingDateCard = ({ teamId }: { teamId: string }) => {
  const teamSubscriptionQuery = useTeamSubscriptionsQuery(
    { teamIds: [teamId] },
    !!teamId,
  );
  const teamSubscription = teamSubscriptionQuery.data?.[0];

  if (teamSubscriptionQuery.isLoading) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }

  if (!teamSubscription) {
    return null;
  }

  const billingPeriodEnd = teamSubscription.stripeProperties.billingPeriodEnd;

  return (
    <ResponsiveCard>
      <Typography.Text>Next Billing Date</Typography.Text>
      <div
        style={{
          fontSize: '1.25rem',
        }}
      >
        {teamSubscription.stripeProperties.isCanceled
          ? '-'
          : billingPeriodEnd.toDateString()}
      </div>
      {teamSubscription.stripeProperties.isCanceled && (
        <Typography.Text type="secondary">
          Subscription will end on {billingPeriodEnd.toDateString()}
        </Typography.Text>
      )}
    </ResponsiveCard>
  );
};

const CurrentMonthlyCostCard = ({ teamId }: { teamId: string }) => {
  const teamSubscriptionQuery = useTeamSubscriptionsQuery(
    { teamIds: [teamId] },
    !!teamId,
  );
  const teamSubscription = teamSubscriptionQuery.data?.[0];

  const content = useMemo(() => {
    if (!teamSubscription) {
      return null;
    }

    if (teamSubscription.stripeProperties.type === 'seat-based') {
      return (
        <>
          <Typography.Text>Current Monthly Cost</Typography.Text>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Statistic
              value={
                (teamSubscription.stripeProperties.costPerSeat *
                  teamSubscription.stripeProperties.seats) /
                100
              }
              prefix="$"
              precision={2}
            />
            <Typography.Text type="secondary">
              {teamSubscription.stripeProperties.currency.toUpperCase()}
            </Typography.Text>
          </div>
        </>
      );
    } else if (teamSubscription.stripeProperties.type === 'usage-based') {
      return (
        <>
          <Typography.Text>Current Monthly Cost</Typography.Text>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Statistic
              value={teamSubscription.stripeProperties.meteredUsage / 100}
              prefix="$"
              precision={2}
            />
            <Typography.Text type="secondary">
              {teamSubscription.stripeProperties.currency.toUpperCase()}
            </Typography.Text>
          </div>
        </>
      );
    }

    return null;
  }, [teamSubscription]);

  if (teamSubscriptionQuery.isLoading) {
    return (
      <ResponsiveCard>
        <Skeleton active paragraph={{ rows: 1 }} />
      </ResponsiveCard>
    );
  }

  if (!teamSubscription) {
    return null;
  }

  return <ResponsiveCard>{content}</ResponsiveCard>;
};

const AvailableProducts = ({ teamId }: { teamId: string }) => {
  const stripeProducts = useQuery({
    queryKey: ['stripe-products'],
    queryFn: async () => {
      const { data } =
        await apiClient.get<ListProductsResponse>('/stripe/products');
      return data;
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {stripeProducts.data?.map((product) => {
        return (
          <ProductCard
            key={product.stripeProductId}
            product={product}
            teamId={teamId}
          />
        );
      })}
    </div>
  );
};

const TeamBillingOverview = ({ teamId }: { teamId: string }) => {
  const teamSubscriptionsQuery = useTeamSubscriptionsQuery(
    {
      teamIds: [teamId],
    },
    !!teamId,
  );

  if (teamSubscriptionsQuery.isLoading) {
    return <CenterSpin />;
  }

  if (teamSubscriptionsQuery.isError) {
    return <SectionContainer.Error />;
  }

  const teamSubscription = teamSubscriptionsQuery.data?.[0];
  if (!teamSubscription) {
    return <AvailableProducts teamId={teamId} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
      }}
    >
      <ResponsiveCardContainer>
        <CurrentMonthlyCostCard teamId={teamId} />
        <NextBillingDateCard teamId={teamId} />
        <PaymentInformationCard teamId={teamId} />
      </ResponsiveCardContainer>
    </div>
  );
};

export default TeamBillingOverview;

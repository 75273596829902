export const formatPlatformCredits = (num: number): string => {
  if (num === 0) {
    return '0';
  }
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const magnitude = Math.floor(Math.log10(num));
  const suffixIndex = Math.floor(magnitude / 3);
  const suffix = suffixes[suffixIndex];
  const value = num / Math.pow(10, suffixIndex * 3);
  return `${value.toFixed(magnitude > 3 ? 1 : 0)}${suffix}`;
};

export const formatMoney = (amount: number, currency: string) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount);
};

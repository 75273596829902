import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import { createConversation } from '@queries/conversations';
import { useSessionUserId } from '@utils/auth';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';
import { useTeamId } from '@utils/router';

const NewAssistantPage = () => {
  const teamId = useTeamId();
  const navigate = useNavigate();
  const sessionUserId = useSessionUserId();

  const [isCreating, setIsCreating] = useState(false);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!teamId || isCreating) return;
    setIsCreating(true);
    void (async () => {
      try {
        const data = await createConversation({
          teamId,
          name: `New Conversation ${new Date().toLocaleString()}`,
          projectAssistantId: null,
          projectId: null,
        });
        navigate(`/team/${teamId}/assistant/${data.id}`, {
          replace: true,
        });
        track('CREATED_CONVERSATION', {
          conversationId: data.id,
          teamId,
          sessionUserId,
        });
      } catch (e) {
        logger.warn(
          {
            error: e instanceof Error ? e.message : 'Unknown error',
          },
          'Failed to create conversation',
        );
        setError('Failed to create conversation');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <ContentContainer.Error />;
  }

  return <ContentContainer.Loading />;
};

export default NewAssistantPage;

import Alert from 'antd/es/alert';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Progress from 'antd/es/progress';
import Typography from 'antd/es/typography';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { type z } from 'zod';

import { type RegisterResponseSchema, passwordRegex } from '@mai/types';

import { apiClient } from '../../../queries';

import AuthFooter from '@components/AuthFooter';
import UnauthCard from '@components/UnauthCard';
import { useSessionUserId } from '@utils/auth';
import { track } from '@utils/mixpanel';

type FormData = Partial<{
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptedTermsAndConditions: boolean;
  subscribeToMarketing: boolean;
}>;

const RegisterPage = () => {
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const sessionUserId = useSessionUserId();
  const password = Form.useWatch<FormData['password']>(['password'], form);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const inviteEmail = searchParams.get('inviteEmail');

  const onFinish = async ({
    name,
    email,
    password,
    subscribeToMarketing,
    acceptedTermsAndConditions,
  }: FormData) => {
    // Form validation before submitting, return early if form is invalid
    try {
      await form.validateFields();
    } catch (error) {
      void message.warning('Please review the form for errors');
      return;
    }

    // Submit the registration request
    setIsLoading(true);
    try {
      const { data } = await apiClient.post<
        z.infer<typeof RegisterResponseSchema>
      >('auth/register', {
        name,
        email,
        password,
        subscribeToMarketing,
        acceptedTermsAndConditions,
      });
      void message.success('Registration successful');
      track('SUBMITTED_REGISTRATION', {
        name,
        email,
      });
      navigate(
        `/auth/register/pending?accountRegistrationId=${data.accountRegistrationId}`,
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          void message.warning('An account with that email already exists.');
          const errorMessage =
            error.response?.data?.message ??
            'An account with that email has already been registered.';
          setWarningMessage(errorMessage);
        } else {
          void message.error('An error occurred. Please try again.');
        }
      } else {
        void message.error('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (sessionUserId) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        marginLeft: '16px',
      }}
    >
      <UnauthCard>
        <Typography.Title level={3}>{'Register your account'}</Typography.Title>
        {warningMessage && (
          <Alert
            type="warning"
            message={warningMessage}
            showIcon
            style={{
              marginBottom: '16px',
            }}
          />
        )}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: 'Please enter your full name',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}
            initialValue={inviteEmail ?? ''}
          >
            <Input size="large" disabled={!!inviteEmail} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            required
            style={{
              marginBottom: '8px',
            }}
            rules={[
              {
                required: true,
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters',
              },
              {
                pattern: passwordRegex,
                message:
                  'Password must only contain letters, numbers, and special characters (!@#$%^&*()_+-=[]{};\':"\\|,.<>/?)',
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Progress
            percent={Math.min(((password?.length ?? 0) / 16) * 100, 100)}
            status={
              ((password?.length ?? 0) >= 12 && 'success') ||
              ((password?.length ?? 0) >= 8 && 'active') ||
              'exception'
            }
            showInfo={false}
          />
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            required
            rules={[
              {
                validator: async (_, value) => {
                  if (value !== password) {
                    return Promise.reject('Passwords do not match');
                  }
                  return Promise.resolve();
                },
                message: 'Passwords do not match',
              },
            ]}
            style={{
              marginBottom: '24px',
            }}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            name="acceptedTermsAndConditions"
            label="Terms and Conditions"
            valuePropName="checked"
            rules={[
              {
                required: true,
                validator: async (_, value) => {
                  if (value !== true) {
                    return Promise.reject(
                      'Please agree to the terms and conditions',
                    );
                  }
                  return Promise.resolve;
                },
                message: 'Please agree to the terms and conditions',
              },
            ]}
          >
            <Checkbox>
              {'I have read and accept the '}
              <Link to="/terms-and-conditions">{'Terms and Conditions'}</Link>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="subscribeToMarketing"
            label="Subscribe to Marketing"
            valuePropName="checked"
            style={{
              marginBottom: '24px',
            }}
          >
            <Checkbox>
              {'I want to receive marketing promotions and updates via email.'}
            </Checkbox>
          </Form.Item>
          <div style={{ height: '16px' }} />
          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              size="large"
            >
              {'Register'}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <AuthFooter />
      </UnauthCard>
    </div>
  );
};

export default RegisterPage;

import { queryClient } from '@/globals';
import { useQuery } from '@tanstack/react-query';

import {
  type CreateProjectAssistantRequest,
  type GetProjectAssistantUrlResponse,
  type ListProjectAssistantsRequest,
  type ListProjectAssistantsResponse,
  ListProjectAssistantsResponseSchema,
  type ProjectAssistant,
  ProjectAssistantSchema,
  type UpdateProjectAssistantRequest,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useProjectAssistantsQuery = (
  params: ListProjectAssistantsRequest,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['project-assistants', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListProjectAssistantsResponse>(
        '/project-assistants',
        { params },
      );
      const validatedData = ListProjectAssistantsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch project assistants',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

export const createProjectAssistant = async (
  payload: CreateProjectAssistantRequest,
) => {
  const { data } = await apiClient.post<ProjectAssistant>(
    '/project-assistants',
    payload satisfies CreateProjectAssistantRequest,
  );
  await queryClient.invalidateQueries({ queryKey: ['project-assistants'] });
  const validatedData = ProjectAssistantSchema.safeParse(data);
  if (validatedData.success) {
    return validatedData.data;
  } else {
    logger.error({
      message: 'Failed to create project assistant',
      error: validatedData.error,
    });
    return data;
  }
};

export const deleteProjectAssistant = async (projectAssistantId: string) => {
  await apiClient.delete(`/project-assistants/${projectAssistantId}`);
  await queryClient.invalidateQueries({ queryKey: ['project-assistants'] });
};

export const generateProjectAssistantUrl = async (
  projectAssistantId: string,
): Promise<string> => {
  const { data } = await apiClient.get<GetProjectAssistantUrlResponse>(
    `/project-assistants/${projectAssistantId}/url`,
  );
  return data.url;
};

export const updateProjectAssistant = async (
  projectAssistantId: string,
  payload: UpdateProjectAssistantRequest,
) => {
  const { data } = await apiClient.patch<ProjectAssistant>(
    `/project-assistants/${projectAssistantId}`,
    payload,
  );
  const validatedData = ProjectAssistantSchema.safeParse(data);
  await queryClient.invalidateQueries({ queryKey: ['project-assistants'] });
  if (validatedData.success) {
    return validatedData.data;
  } else {
    logger.error({
      message: 'Failed to update project assistant',
      error: validatedData.error,
    });
    return data;
  }
};

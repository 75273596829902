import axios from 'axios';
import * as jose from 'jose';

export const API_BASE_URL =
  import.meta.env.MODE === 'development'
    ? 'http://localhost:3001'
    : 'https://api.moderately.ai';

export const apiClient = axios.create({
  ...axios.defaults,
  baseURL: API_BASE_URL,
  timeout: 10_000,
  withCredentials: true,
  paramsSerializer: (params) => {
    // Convert arrays to comma-separated strings
    return Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const filteredValues = value
            .filter((v) => v !== undefined)
            .map(encodeURIComponent);
          return `${key}=${filteredValues.join(',')}`;
        } else if (value instanceof Date) {
          return `${key}=${value.toISOString()}`;
        } else if (value === undefined) {
          return '';
        }
        return `${key}=${value}`;
      })
      .join('&');
  },
});

apiClient.interceptors.request.use(
  function (config) {
    // If the url matches /embed/* we use the embedded authentication which leverages query parameters
    const currentPath = window.location.pathname;
    const isEmbedPath = currentPath.startsWith('/embed/');
    if (isEmbedPath) {
      const searchParams = new URLSearchParams(window.location.search);
      const encodedToken = searchParams.get('token');
      if (!encodedToken) {
        return config;
      }

      const payload = jose.decodeJwt<{ sessionId: string; token: string }>(
        encodedToken,
      );
      config.headers.Authorization = `Bearer ${payload.token}`;

      return config;
    } else {
      const embedSessionToken = localStorage.getItem('embedSessionToken');
      if (embedSessionToken) {
        config.headers.Authorization = `Bearer ${embedSessionToken}`;
      } else {
        const sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken) {
          config.headers.Authorization = `Bearer ${sessionToken}`;
        }
      }
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export type QueryOptions = {
  enabled?: boolean;
  retry?: boolean;
  keepPreviousData?: boolean;
};

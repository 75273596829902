import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { type ListProductsResponse } from '@mai/types';

import ContentContainer from '@components/ContentContainer';
import { apiClient } from '@queries/index';

const ProductCardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 230px;
  height: 180px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  justify-content: flex-end;
`;

const ProductCard = ({
  product,
  teamId,
}: {
  product: ListProductsResponse[number];
  teamId: string;
}) => {
  const [selectedSeats, setSelectedSeats] = useState<number>(1);

  const hasTieredPrices = useMemo(() => {
    return product.prices.some((price) => price.type === 'tiered');
  }, [product.prices]);

  const action = useMemo(() => {
    const payload = hasTieredPrices
      ? {
          stripeProductId: product.stripeProductId,
          seats: 1,
          teamId,
        }
      : {
          stripeProductId: product.stripeProductId,
          teamId,
          seats: selectedSeats,
        };

    return (
      <ActionContainer>
        <InputNumber
          disabled={hasTieredPrices}
          value={
            product.prices.some((price) => price.type === 'tiered')
              ? 1
              : selectedSeats
          }
          suffix={
            product.prices.some((price) => price.type === 'tiered')
              ? 'Team'
              : 'User'
          }
          style={{
            minWidth: '6rem',
          }}
          onChange={(value) => {
            if (value) {
              setSelectedSeats(value);
            }
          }}
          min={1}
        />
        <Button
          type="primary"
          style={{
            width: '100%',
          }}
          onClick={async () => {
            const { data } = await apiClient.post(
              `/stripe/checkout-session`,
              payload,
            );
            window.open(data.checkoutSessionUrl, '_blank');
          }}
        >
          Subscribe
        </Button>
      </ActionContainer>
    );
  }, [
    hasTieredPrices,
    product.prices,
    product.stripeProductId,
    selectedSeats,
    teamId,
  ]);

  const content = useMemo(() => {
    if (hasTieredPrices) {
      const fixedPrice = product.prices.find((price) => price.type === 'unit');
      const tieredPrice = product.prices.find(
        (price) => price.type === 'tiered',
      );
      if (!fixedPrice || !tieredPrice) {
        return <ContentContainer.Error />;
      }

      const tiers = tieredPrice.tiers.sort(
        (a, b) =>
          (a.upTo ?? Number.MAX_SAFE_INTEGER) -
          (b.upTo ?? Number.MAX_SAFE_INTEGER),
      );
      const tierBreakpoint = tiers[0]?.upTo ?? 0;
      const pricePerUnit = tiers[1]?.unitAmount ?? 0;

      return (
        <>
          <div>
            <Typography.Text>
              {`$${(fixedPrice.pricePerUnit / 100).toFixed(2)}`}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              style={{
                fontSize: '0.8rem',
              }}
            >
              {' / Month'}
            </Typography.Text>
            <Typography.Text>{` + $${pricePerUnit / 100}`}</Typography.Text>
            <Typography.Text type="secondary">{` / Credit `}</Typography.Text>
          </div>
          <Divider style={{ margin: '0.5rem 0' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <div>
              <CheckCircleOutlined />
              <Typography.Text>
                {` ${tierBreakpoint} Credits included`}
              </Typography.Text>
            </div>
            <div>
              <CheckCircleOutlined />
              <Typography.Text>
                {` Full access to all features`}
              </Typography.Text>
            </div>
            <div>
              <CheckCircleOutlined />
              <Typography.Text>{` Set monthly credit usage limits`}</Typography.Text>
            </div>
          </div>
        </>
      );
    } else {
      const [price] = product.prices;
      if (!price || price.type !== 'unit') {
        return <ContentContainer.Error />;
      }

      return (
        <>
          <div>
            <Typography.Text>
              {`${price.currency.toUpperCase()} ${price.pricePerUnit / 100}`}
            </Typography.Text>
            <Typography.Text type="secondary">
              {' / User / Month'}
            </Typography.Text>
          </div>
          <Divider style={{ margin: '0.5rem 0' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <div>
              <CheckCircleOutlined />
              <Typography.Text>{` Unlimited Platform Use`}</Typography.Text>
            </div>
            <div>
              <CheckCircleOutlined />
              <Typography.Text>{` Full access to all features`}</Typography.Text>
            </div>
            <div>
              <CheckCircleOutlined />
              <Typography.Text>{` Increased rate limits`}</Typography.Text>
            </div>
          </div>
        </>
      );
    }
  }, [hasTieredPrices, product.prices]);

  return (
    <Card actions={[action]}>
      <ProductCardBodyContainer>
        <Typography.Title
          style={{
            display: 'flex',
            marginBottom: '0.5rem',
            marginTop: '0',
          }}
          level={5}
        >
          {product.name}
        </Typography.Title>
        {content}
      </ProductCardBodyContainer>
    </Card>
  );
};

export default ProductCard;

import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Form from 'antd/es/form';
import useMessage from 'antd/es/message/useMessage';
import { type UploadFile } from 'antd/es/upload';
import { useState } from 'react';

import ContentContainer from '../ContentContainer';
import FormSubmitButton from '../FormSubmitButton';
import {
  DocumentDescriptionField,
  DocumentLinksField,
  DocumentTitleField,
} from '../form-fields/DocumentFormFields';

import { useFetchDocumentQuery } from '@queries/documents';
import { apiClient } from '@queries/index';

type EditDocumentFormData = Partial<{
  title: string;
  description: string;
  files: UploadFile[];
  links: string[];
}>;

const EditDocument = ({
  documentId,
  onFinish,
}: {
  documentId: string;
  onFinish?: (documentId: string) => void;
}) => {
  const [form] = Form.useForm<EditDocumentFormData>();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [messageApi, messageContext] = useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const documentQuery = useFetchDocumentQuery(documentId);
  const document = documentQuery.data;
  if (documentQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (documentQuery.isError || !document) {
    return <ContentContainer.Error />;
  }

  return (
    <Form<EditDocumentFormData>
      form={form}
      layout="vertical"
      onFinish={async (formData) => {
        if (isSubmitting) {
          return;
        }

        // Get values
        const { title, description, links } = formData;

        // Create the document
        setIsSubmitting(true);
        try {
          await apiClient.patch(`/documents/${documentId}`, {
            title,
            description,
            links: links?.map((link) => {
              const [type, ...rest] = link.split('_');
              const id = rest.join('_');
              return { type, id };
            }),
          });
          await queryClient.invalidateQueries({
            queryKey: ['documents'],
          });
          void message.success('Document uploaded successfully.');
          if (onFinish) onFinish(documentId);
        } catch (e) {
          void messageApi.error('Failed to update document, please try again.');
          setIsSubmitting(false);
          return;
        }

        // Close the modal
        setIsSubmitting(false);
        void queryClient.invalidateQueries({ queryKey: ['documents'] });
      }}
    >
      {messageContext}
      <DocumentTitleField initialValue={document.title} />
      <DocumentDescriptionField initialValue={document.description ?? ''} />
      <DocumentLinksField
        initialValue={document.links.map((i) => {
          if (i.type === 'team') {
            return 'team_' + i.id;
          } else if (i.type === 'project') {
            return 'project_' + i.id;
          } else {
            throw new Error('Invalid link type');
          }
        })}
      />
      <FormSubmitButton text="Save" loading={isSubmitting} />
    </Form>
  );
};

export default EditDocument;

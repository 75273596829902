import { type AnalysisRequest, type AnalysisRequestRunEvent } from '@mai/types';

import { useAnalysisRequestRunsQuery } from '@queries/analysis-request-runs';

export const getStatusFromAnalysisRequestRunEvents = (
  analysisRequestRunEvents: AnalysisRequestRunEvent[],
): {
  status: 'not-started' | 'running' | 'completed' | 'failed' | 'cancelled';
  occurredAt?: Date;
} | null => {
  if (analysisRequestRunEvents?.length === 0) {
    return {
      status: 'not-started',
    };
  } else if (
    analysisRequestRunEvents.some((event) => event.eventType === 'cancelled')
  ) {
    return {
      status: 'cancelled',
      occurredAt: analysisRequestRunEvents.find(
        (event) => event.eventType === 'cancelled',
      )?.createdAt,
    };
  } else if (
    analysisRequestRunEvents.some((event) => event.eventType === 'failed')
  ) {
    return {
      status: 'failed',
      occurredAt: analysisRequestRunEvents.find(
        (event) => event.eventType === 'failed',
      )?.createdAt,
    };
  } else if (
    analysisRequestRunEvents.some((event) => event.eventType === 'completed')
  ) {
    return {
      status: 'completed',
      occurredAt: analysisRequestRunEvents.find(
        (event) => event.eventType === 'completed',
      )?.createdAt,
    };
  }
  return {
    status: 'running',
    occurredAt: analysisRequestRunEvents.find(
      (event) => event.eventType === 'started',
    )?.createdAt,
  };
};

export const useAnalysisRequestStatus = ({
  analysisRequest,
}: {
  analysisRequest?: AnalysisRequest;
}): {
  status: 'not-started' | 'running' | 'completed' | 'failed' | 'cancelled';
  occurredAt?: Date;
} | null => {
  const analysisRequestRunsQuery = useAnalysisRequestRunsQuery(
    {
      analysisRequestIds: analysisRequest ? [analysisRequest.id] : [],
    },
    !!analysisRequest,
  );
  const analysisRequestRuns = analysisRequestRunsQuery.data;

  if (analysisRequestRunsQuery.isLoading) return null;

  const latestAnalysisRequestRun = analysisRequestRuns?.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  )[0];

  const analysisRequestRunEvents = (
    latestAnalysisRequestRun?.events ?? []
  ).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

  return getStatusFromAnalysisRequestRunEvents(analysisRequestRunEvents);
};

import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Popconfirm from 'antd/es/popconfirm';
import Select from 'antd/es/select';
import { type ColumnType } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Table from '../Table';

import TableHeader from '@components/TableHeader';
import { useAnalysisRequestsQuery } from '@queries/analysis-requests';
import { apiClient } from '@queries/index';
import { renderDate } from '@utils/dates';
import { logger } from '@utils/logger';

type AnalysisRequestRow = {
  id: string;
  name: string;
  createdAt: Date | string;
};

const AnalysisRequestsTableHeader = ({
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  setIsSubmitting,
  isLoading,
}: {
  dataSource: AnalysisRequestRow[];
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (selectedRowKeys: React.Key[]) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isLoading: boolean;
}) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10', 10);
  const page = parseInt(searchParams.get('page') ?? '1', 10);
  const { message, modal } = App.useApp();

  return (
    <TableHeader>
      <div
        style={{
          width: '100%',
        }}
      >
        {selectedRowKeys.length > 0 && (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <Button
              disabled={selectedRowKeys.length === 0}
              size="small"
              danger
              onClick={() => {
                return modal.confirm({
                  maskClosable: true,
                  title: 'Delete Analysis Requests',
                  content: `Are you sure you want to delete ${selectedRowKeys.length} analysis requests? This action is not reversible.`,
                  closable: true,
                  icon: null,
                  okButtonProps: {
                    size: 'small',
                    danger: true,
                  },
                  cancelButtonProps: {
                    size: 'small',
                  },
                  onOk: async () => {
                    setIsSubmitting(true);
                    try {
                      await apiClient.delete('/analysis-requests', {
                        params: {
                          analysisRequestIds: selectedRowKeys,
                        },
                      });
                      void message.success(
                        'Analysis requests deleted successfully.',
                      );
                      await queryClient.invalidateQueries({
                        queryKey: ['analysisRequests'],
                      });
                      setSelectedRowKeys([]);
                    } catch (error) {
                      logger.warn({
                        message: 'Failed to delete analysis requests',
                        error,
                      });
                      void message.error(
                        'Failed to delete analysis requests, please try again.',
                      );
                    } finally {
                      setIsSubmitting(false);
                    }
                  },
                });
              }}
            >
              Delete Selected
            </Button>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', gap: '0.25rem' }}>
        <Select
          options={[
            { label: '10', value: '10' },
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
          size="small"
          value={pageSize}
          suffixIcon={<>/ Page</>}
          onChange={(value) => {
            setSearchParams((prev) => {
              prev.set('pageSize', String(value));
              return prev;
            });
          }}
          style={{
            width: '5rem',
          }}
        />
        <Tooltip title="Previous Page">
          <Button
            size="small"
            disabled={page === 1}
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page - 1).toString());
                return prev;
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Next Page">
          <Button
            size="small"
            disabled={dataSource.length < pageSize}
            icon={<ArrowRightOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page + 1).toString());
                return prev;
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Refresh">
          <Button
            size="small"
            icon={<SyncOutlined spin={isLoading} />}
            onClick={() => {
              void queryClient.invalidateQueries({
                queryKey: ['analysisRequests'],
              });
            }}
          />
        </Tooltip>
      </div>
    </TableHeader>
  );
};

const AnalysisRequestsTable = ({
  teamId,
  analysisRequestIds,
  size = 'middle',
}: {
  teamId: string;
  analysisRequestIds?: string[];
  size?: 'small' | 'middle' | 'large';
}) => {
  const { message } = App.useApp();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const analysisRequestsQuery = useAnalysisRequestsQuery(
    {
      teamIds: [teamId],
      analysisRequestIds,
    },
    !!teamId,
  );
  const queryClient = useQueryClient();

  if (analysisRequestsQuery.isError) {
    return <Table.Error />;
  }

  const columns: ColumnType<AnalysisRequestRow>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <Link to={`/team/${teamId}/analyze/${record.id}`}>{value}</Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => renderDate(value),
    },
    {
      key: 'actions',
      width: 50,
      align: 'right',
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'view',
                  label: (
                    <Link to={`/team/${teamId}/analyze/${record.id}`}>
                      View
                    </Link>
                  ),
                },
                {
                  key: 'delete',
                  label: (
                    <Popconfirm
                      title="Confirm Delete"
                      okText="Delete"
                      cancelText="Cancel"
                      onConfirm={async () => {
                        const loadingMessage = message.loading(
                          'Deleting analysis request',
                        );
                        try {
                          await apiClient.delete(
                            `/analysis-requests/${record.id}`,
                          );
                          void message.success('Analysis request deleted');
                          void queryClient.invalidateQueries({
                            queryKey: ['analysisRequests'],
                          });
                        } catch (error) {
                          void message.error(
                            'Failed to delete analysis request',
                          );
                        } finally {
                          loadingMessage();
                        }
                      }}
                    >
                      Delete
                    </Popconfirm>
                  ),
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const dataSource: AnalysisRequestRow[] =
    analysisRequestsQuery.data?.map((analysisRequest) => ({
      id: analysisRequest.id,
      name: analysisRequest.name,
      createdAt: analysisRequest.createdAt,
    })) ?? [];

  return (
    <>
      <AnalysisRequestsTableHeader
        dataSource={dataSource}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        setIsSubmitting={() => {}}
        isLoading={analysisRequestsQuery.isLoading}
      />
      <Table<AnalysisRequestRow>
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.id}
        size={size ?? 'middle'}
        loading={analysisRequestsQuery.isLoading}
        rowSelection={{
          columnWidth: 40,
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
      />
    </>
  );
};

export default AnalysisRequestsTable;

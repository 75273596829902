import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import DocumentSelect from '@components/DocumentSelect';
import { useProjectsQuery } from '@queries/projects';
import { exportConversationToCSV } from '@utils/csvExport';

const ConversationHeader = ({
  conversationId,
  teamId,
  projectId,
}: {
  conversationId: string;
  teamId: string;
  projectId?: string;
}) => {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectsQuery = useProjectsQuery(
    {
      teamIds: [teamId],
      projectIds: projectId ? [projectId] : undefined,
    },
    !!teamId,
  );

  const projects = useMemo(() => {
    return projectsQuery.data ?? [];
  }, [projectsQuery.data]);

  const focusedResourceType =
    searchParams.get('focusedResourceType') ?? 'document';

  const focusedProjectId = searchParams.get('focusedProjectId');

  const resourceSelect = useMemo(() => {
    if (focusedResourceType === 'document') {
      const focusedDocumentIds =
        searchParams.get('focusedDocumentIds')?.split(',') ?? [];
      return (
        <DocumentSelect
          teamIds={[teamId]}
          value={focusedDocumentIds}
          onChange={(value) => {
            setSearchParams((prev) => {
              if (value.length === 0) {
                prev.delete('focusedDocumentIds');
              } else {
                prev.set('focusedDocumentIds', value.join(','));
              }
              return prev;
            });
          }}
          size="small"
          style={{ width: '260px' }}
        />
      );
    }
    return (
      <Select
        size="small"
        value={focusedProjectId}
        allowClear
        onChange={(value) => {
          setSearchParams((prev) => {
            if (value) {
              prev.set('focusedProjectId', value);
            } else {
              prev.delete('focusedProjectId');
            }
            return prev;
          });
        }}
        placeholder="Select a project"
        style={{ width: '200px' }}
        options={projects.map((project) => ({
          value: project.id,
          label: project.name,
        }))}
      />
    );
  }, [
    focusedResourceType,
    focusedProjectId,
    projects,
    searchParams,
    teamId,
    setSearchParams,
  ]);

  const handleExportToCSV = async () => {
    await exportConversationToCSV(conversationId);
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        marginBottom: '1rem',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Select
          size="small"
          value={focusedResourceType}
          onChange={(value) => {
            setSearchParams({ focusedResourceType: value });
          }}
          placeholder="Select a resource type"
          style={{ width: '110px' }}
        >
          <Select.Option value="document">Document</Select.Option>
          <Select.Option value="project">Project</Select.Option>
        </Select>
        {resourceSelect}
      </div>
      <Button
        size="small"
        icon={<DownloadOutlined />}
        onClick={async () => {
          try {
            await handleExportToCSV();
            void message.success('Exported to CSV');
          } catch (e) {
            void message.error('Failed to export to CSV');
          }
        }}
      >
        Export to CSV
      </Button>
    </div>
  );
};

export default ConversationHeader;

import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const TeamInviteSchema = z.object({
  id: z.string(),
  teamId: z.string(),
  teamName: z.string(),
  email: z.string().email(),
  role: z.enum(['ADMIN', 'MEMBER']),
  createdAt: z.coerce.date(),
  createdBy: z.string(),
});

export type TeamInvite = z.infer<typeof TeamInviteSchema>;

export const ListTeamInvitesRequestSchema = z.object({
  teamInviteIds: commaDelimitedList().optional(),
  teamIds: commaDelimitedList().optional(),
  emails: commaDelimitedList().optional(),
});

export type ListTeamInvitesRequest = z.infer<
  typeof ListTeamInvitesRequestSchema
>;

export const ListTeamInvitesResponseSchema = z.array(TeamInviteSchema);

export type ListTeamInvitesResponse = z.infer<
  typeof ListTeamInvitesResponseSchema
>;

export const CreateTeamInviteRequestSchema = z.object({
  teamId: z.string(),
  email: z.string().email(),
  role: z.enum(['ADMIN', 'MEMBER']),
});

export type CreateTeamInviteRequest = z.infer<
  typeof CreateTeamInviteRequestSchema
>;

export const CreateTeamInviteResponseSchema = TeamInviteSchema;

export type CreateTeamInviteResponse = z.infer<
  typeof CreateTeamInviteResponseSchema
>;

export const UpdateTeamInviteRequestSchema = z.object({
  resend: z.boolean().optional(),
  accept: z.boolean().optional(),
  decline: z.boolean().optional(),
});

export type UpdateTeamInviteRequest = z.infer<
  typeof UpdateTeamInviteRequestSchema
>;

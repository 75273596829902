import mixpanel from 'mixpanel-browser';

import { logger } from './logger';

export const MIXPANEL_EVENTS = {
  CREATED_ANALYSIS_REQUEST: 'Created Analysis Request',
  CREATED_CLIENT: 'Created Client',
  CREATED_DOCUMENT: 'Created Document',
  CREATED_TEAM: 'Created Team',
  DISLIKED_ASSISTANT_MESSAGE: 'Disliked Assistant Message',
  DISLIKED_SEARCH_RESULT: 'Disliked Search Result',
  LIKED_ASSISTANT_MESSAGE: 'Liked Assistant Message',
  LIKED_SEARCH_RESULT: 'Liked Search Result',
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',
  SUBMITTED_REGISTRATION: 'Submitted Registration',
  VERIFIED_REGISTRATION: 'Verified Registration',
  CREATED_SEARCH_REQUEST: 'Created Search Request',
  CREATED_CONVERSATION: 'Created Conversation',
  CREATED_CONVERSATION_MESSAGE: 'Created Conversation Message',
  DELETED_CONVERSATION: 'Deleted Conversation',
  DELETED_CONVERSATION_MESSAGE: 'Deleted Conversation Message',
  HERO_MINIMIZED: 'Hero Minimized',
  REGENERATED_CONVERSATION_MESSAGE: 'Regenerated Conversation Message',
  FOCUSED_CONVERSATION_RESOURCE: 'Focused Conversation Resource',
  STARTED_ANALYSIS_REQUEST_RUN: 'Started Analysis Request Run',
  STOPPED_ANALYSIS_REQUEST_RUN: 'Stopped Analysis Request Run',
  OPENED_ANALYSIS_REQUEST_EDIT_DATA: 'Opened Analysis Request Edit Data',
  OPENED_ANALYSIS_REQUEST_EDIT_METADATA:
    'Opened Analysis Request Edit Metadata',
  OPENED_ANALYSIS_REQUEST_EDIT_SOURCES: 'Opened Analysis Request Edit Sources',
  OPENED_ANALYSIS_REQUEST_RUN_HISTORY: 'Opened Analysis Request Run History',
  CLEARED_ANALYSIS_REQUEST_RESULTS: 'Cleared Analysis Request Results',
  DELETED_ANALYSIS_REQUEST: 'Deleted Analysis Request',
  PAGE_VIEWED: 'Page Viewed',
  CLICKED_SUBSCRIBE_TO_PLAN: 'Clicked Subscribe To Plan',
  CLICKED_ONBOARDING_CHECKLIST_ITEM: 'Clicked Onboarding Checklist Item',
  EXPLAINED_ASSISTANT_MESSAGE: 'Explained Assistant Message',
};

export function track(
  event: keyof typeof MIXPANEL_EVENTS,
  properties: {
    // Specific properties for each event that we want to track
    urlPath?: string | null;
    analysisRequestId?: string | null;
    clientId?: string | null;
    conversationId?: string | null;
    conversationMessageId?: string | null;
    email?: string | null;
    name?: string | null;
    searchRequestId?: string | null;
    sessionUserId?: string | null;
    teamId?: string | null;
    heroId?: string | null;
    analysisRequestRunId?: string | null;
    // Generic Metadata, kept separate from specific properties
    metadata?: Record<string, unknown> | null;
  } = {},
) {
  try {
    mixpanel.track(MIXPANEL_EVENTS[event], properties);
  } catch (error) {
    logger.warn({
      error: error instanceof Error ? error.message : 'unknown error',
    });
  }
}

export function identify(sessionUserId?: string | null | undefined) {
  if (!sessionUserId) return;
  try {
    mixpanel.identify(sessionUserId);
  } catch (error) {
    logger.warn(
      {
        error: error instanceof Error ? error.message : 'unknown error',
      },
      'Failed to identify user',
    );
  }
}

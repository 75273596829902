import { useNavigate } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import FormContainer from '@components/FormContainer';
import EditDocument from '@components/forms/EditDocumentForm';
import { useFetchDocumentQuery } from '@queries/documents';
import { useDocumentId, useTeamId } from '@utils/router';

const EditDocumentPage = () => {
  const teamId = useTeamId();
  const documentId = useDocumentId();
  const navigate = useNavigate();
  const documentQuery = useFetchDocumentQuery(documentId);
  const document = documentQuery.data;

  if (!teamId || !documentId) {
    return <ContentContainer.Error />;
  }

  if (documentQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (documentQuery.isError || !document) {
    return <ContentContainer.Error />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Edit Document"
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Documents', to: `/team/${teamId}/documents` },
          {
            label: document.title,
            to: `/team/${teamId}/documents/${documentId}`,
          },
          { label: 'Edit' },
        ]}
      />
      <FormContainer>
        <EditDocument
          documentId={documentId}
          onFinish={() => {
            navigate(`/team/${teamId}/documents/${documentId}`);
          }}
        />
      </FormContainer>
    </ContentContainer>
  );
};

export default EditDocumentPage;

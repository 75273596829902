import MoreOutlined from '@ant-design/icons/MoreOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Layout from 'antd/es/layout';
import List from 'antd/es/list';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import { type ColumnsType } from 'antd/es/table';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import startCase from 'lodash.startcase';
import { useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { type TeamSummary } from '@mai/types';

import TeamImage from '@assets/undraw_engineering_team.svg';
import ContentContainer from '@components/ContentContainer';
import FormContainer from '@components/FormContainer';
import { Hero } from '@components/Hero';
import SectionContainer from '@components/SectionContainer';
import Table from '@components/Table';
import CreateTeamForm from '@components/forms/CreateTeamForm';
import { apiClient } from '@queries/index';
import { useTeamInvitesQuery } from '@queries/team-invites';
import { useFetchTeams } from '@queries/teams';
import { useFetchUserQuery } from '@queries/users';
import { useSessionUserId } from '@utils/auth';
import { logger } from '@utils/logger';

const Content = styled(Layout.Content)<{ $backgroundColor: string }>`
  display: flex;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

const ManageTeams = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const sessionUserId = useSessionUserId();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const modalVisible = searchParams.get('modal');

  const sessionUserQuery = useFetchUserQuery(sessionUserId);
  const sessionUser = sessionUserQuery.data;

  const navigate = useNavigate();

  const teamsQuery = useFetchTeams(
    {
      teamUserIds: sessionUserId ? [sessionUserId] : undefined,
    },
    Boolean(sessionUserId),
  );

  const teamInvitesQuery = useTeamInvitesQuery(
    {
      emails: sessionUser?.emails.map((email) => email.email) ?? [],
    },
    Boolean(sessionUser?.emails.length),
  );
  const teamInvites = teamInvitesQuery.data ?? [];

  const columns: ColumnsType<TeamSummary> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (text, record) => <Link to={`/team/${record.id}`}>{text}</Link>,
    },
    {
      key: 'actions',
      align: 'right',
      fixed: 'right',
      width: 65,
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'view',
                  label: <Link to={`/team/${record.id}`}>View</Link>,
                },
                {
                  key: 'manage',
                  label: <Link to={`/team/${record.id}/manage`}>Manage</Link>,
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />}></Button>
          </Dropdown>
        );
      },
    },
  ];

  const dataSource = teamsQuery.data || [];

  const hero = useMemo(() => {
    if (teamsQuery.data?.length) {
      return (
        <Hero
          id="team-hero-existing"
          color={{
            light:
              'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
            dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
          }}
          imgSrc={TeamImage}
          title={'Get started with your existing teams'}
          extra={
            <Typography.Text>
              Select your team below and start automating your workflows.
            </Typography.Text>
          }
        />
      );
    }
    return (
      <Hero
        id="team-hero-create"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={TeamImage}
        title={'Get started by creating a new team'}
        extra={
          <>
            <div>
              <Typography.Text>
                Create a new team to start automating your workflows, it only
                takes a few seconds!
              </Typography.Text>
            </div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() =>
                setSearchParams((prev) => {
                  prev.set('modal', 'create-team');
                  return prev;
                })
              }
              style={{
                marginTop: '1rem',
                boxShadow: '0px 0px 0px rgba(98, 92, 221, 0.2)',
              }}
            >
              New Team
            </Button>
          </>
        }
      />
    );
  }, [setSearchParams, teamsQuery.data?.length]);

  if (teamsQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  return (
    <>
      <Modal
        title="Create a Team"
        open={modalVisible === 'create-team'}
        onCancel={() =>
          setSearchParams((prev) => {
            prev.delete('modal');
            return prev;
          })
        }
        onClose={() =>
          setSearchParams((prev) => {
            prev.delete('modal');
            return prev;
          })
        }
        footer={null}
      >
        <FormContainer>
          <CreateTeamForm
            afterFinish={(teamId) => {
              setSearchParams((prev) => {
                prev.delete('modal');
                return prev;
              });
              void queryClient.invalidateQueries({
                queryKey: ['teams'],
              });
              navigate(`/team/${teamId}`);
            }}
          />
        </FormContainer>
      </Modal>
      <Layout>
        <Content $backgroundColor={colorBgContainer}>
          <ContentContainer>
            {hero}
            <SectionContainer
              title="Your Teams"
              cta={
                <Button
                  type="primary"
                  size="small"
                  icon={<PlusCircleOutlined />}
                  onClick={() =>
                    setSearchParams((prev) => {
                      prev.set('modal', 'create-team');
                      return prev;
                    })
                  }
                >
                  New Team
                </Button>
              }
            >
              <Table<TeamSummary>
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={(record) => record.id}
              />
            </SectionContainer>
            <SectionContainer title="Pending Invitations">
              <List
                dataSource={teamInvites}
                locale={{
                  emptyText: 'No pending invitations',
                }}
                renderItem={(item) => (
                  <List.Item>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '0.5rem',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.25rem',
                        }}
                      >
                        <b>
                          {item.teamName} ({startCase(item.role)})
                        </b>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          size="small"
                          type="primary"
                          onClick={async () => {
                            try {
                              await apiClient.patch(`team-invites/${item.id}`, {
                                accept: true,
                              });
                              void message.success('Invitation accepted');
                              void queryClient.invalidateQueries({
                                queryKey: ['teams'],
                              });
                              void queryClient.invalidateQueries({
                                queryKey: ['team-invites'],
                              });
                            } catch (e) {
                              void message.error(
                                'An error occurred. Please try again.',
                              );
                              logger.warn({
                                message: 'Failed to accept team invite',
                                error: e,
                              });
                            }
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          size="small"
                          danger
                          onClick={async () => {
                            try {
                              await apiClient.patch(`team-invites/${item.id}`, {
                                decline: true,
                              });
                              void message.success('Invitation declined');
                              void queryClient.invalidateQueries({
                                queryKey: ['team-invites'],
                              });
                            } catch (e) {
                              void message.error(
                                'An error occurred. Please try again.',
                              );
                              logger.warn({
                                message: 'Failed to decline team invite',
                                error: e,
                              });
                            }
                          }}
                        >
                          Decline
                        </Button>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </SectionContainer>
          </ContentContainer>
        </Content>
      </Layout>
    </>
  );
};

export default ManageTeams;

import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import { type ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { type Project } from '@mai/types';

import Table from '@components/Table';
import TableHeader from '@components/TableHeader';
import { deleteProjects, useProjectsQuery } from '@queries/projects';
import { renderDate } from '@utils/dates';

const ProjectsTableHeader = ({
  selectedRowKeys,
  setSelectedRowKeys,
  dataSource,
}: {
  dataSource: Project[];
  selectedRowKeys: string[];
  setSelectedRowKeys: (keys: string[]) => void;
}) => {
  const { modal, message } = App.useApp();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  return (
    <TableHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>
          {selectedRowKeys.length ? (
            <Button
              danger
              size="small"
              onClick={() => {
                return modal.confirm({
                  maskClosable: true,
                  title:
                    'Are you sure you want to delete the selected projects?',
                  onOk: async () => {
                    const loading = message.loading('Deleting projects...');
                    try {
                      await deleteProjects(selectedRowKeys);
                      await queryClient.invalidateQueries({
                        queryKey: ['projects'],
                      });
                      loading();
                      setSelectedRowKeys([]);
                      void message.success('Projects deleted successfully');
                    } catch (e) {
                      loading();
                      void message.error('Failed to delete projects');
                    }
                  },
                });
              }}
            >
              Delete Selected
            </Button>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <Button
            size="small"
            icon={<ArrowLeftOutlined />}
            disabled={page === 1}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', Math.max(page - 1, 1).toString());
                return prev;
              });
            }}
          />
          <Button
            size="small"
            disabled={dataSource.length < 10}
            icon={<ArrowRightOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page + 1).toString());
                return prev;
              });
            }}
          />
          <Button
            size="small"
            icon={<ReloadOutlined />}
            onClick={() => {
              void queryClient.invalidateQueries({
                queryKey: ['projects'],
              });
            }}
          />
        </div>
      </div>
    </TableHeader>
  );
};

const ProjectsTable = ({
  teamId,
  size = 'middle',
}: {
  teamId: string;
  size?: 'small' | 'middle' | 'large';
}) => {
  const { modal } = App.useApp();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const projectsQuery = useProjectsQuery({ teamIds: [teamId] }, !!teamId);
  const projects = projectsQuery.data ?? [];

  const columns: ColumnsType<Project> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 230,
      ellipsis: true,
      render: (name, record) => (
        <Link to={`/team/${teamId}/projects/${record.id}`}>{name}</Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (description) => {
        return description ? description : '-';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (createdAt) => renderDate(createdAt),
    },
    {
      key: 'actions',
      align: 'right',
      width: 50,
      render: (_, record) => (
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'delete',
                label: 'Delete',
                onClick: () => {
                  return modal.confirm({
                    title: 'Delete project',
                    content: 'Are you sure you want to delete this project?',
                    maskClosable: true,
                    closable: true,
                    onOk: () => {
                      void deleteProjects([record.id]);
                    },
                  });
                },
              },
            ],
          }}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <ProjectsTableHeader
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        dataSource={projects}
      />
      <Table
        loading={projectsQuery.isLoading}
        pagination={false}
        dataSource={projects}
        columns={columns}
        rowKey={(row) => row.id}
        size={size ?? 'middle'}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedKeys) =>
            setSelectedRowKeys(selectedKeys as string[]),
        }}
      />
    </>
  );
};

export default ProjectsTable;

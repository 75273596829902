/* eslint-disable no-irregular-whitespace */
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import ReactMarkdown from 'react-markdown';

import DownloadLink from '@assets/terms_and_conditions_sept_23_2024.pdf';

const TERMS_AND_CONDITIONS = `

**MODERATELY AI INC.**

Terms & Conditions and Privacy Policy

These terms govern your use of the Moderately AI Inc. (“Moderately AI”) products, services, software and data that reference these terms. “We”, “our” and “Moderately AI” means Moderately AI providing the products, services and software, where applicable, its affiliates; “you” and “your” means the client, customer or subscriber.

Your subscription, these terms and conditions, and any additional applicable terms constitute the complete agreement (the “Agreement”) and supersede any prior and contemporaneous discussions, agreements or representations and warranties regarding your Agreement. 

**1. PRODUCTS, SERVICES, SOFTWARE AND DATA**

(a) **Limited License.** Moderately AI owns and retains ownership of all rights of whatever nature in and to our products, services, software and date.  You may access, view, install, use, copy, modify and distribute our property only as expressly specified in herein. 

(b) **Updates to Product, Services and Software.** Our products, services and software change from time to time but we will not change the fundamental nature of our products, services, software and data unless the change is the result of the modification or termination of an agreement with a third party provider or as required to comply with any law.

(c) **Passwords.** Your access to certain products, services, software and data is password protected. You are responsible for assigning the passwords and for ensuring that passwords are kept confidential. Sharing account password is strictly prohibited. You must advise us immediately if you are aware of any unauthorized use or access by third parties to your passwords.

(d) **Unauthorized Technology.** Unless previously authorized by Moderately AI, you must not (i) run or install any computer software or hardware on our products, services or network; (ii) use any technology to automatically download, mine, scrape or index our data; or (iii) automatically connect our data to other data, software, services or networks. 

(e) **Related Information.** We may access, collect and use information related to you and your use of our products and services (“Related Information”) for customer and technical support, for product review and improvement, for regulatory and third party compliance purposes, to protect and enforce our rights and monitor compliance with the terms of this Agreement, and to recommend additional products or services. You agree that we may pass this information to our third party providers for the same purposes. You grant Moderately AI and our affiliates the perpetual right to use Related Information and any feedback provided by you for purposes such as to test, develop, improve and enhance our products and services, and to create e and own derivative works based on Related Information and feedback, so long as neither you nor any other individual is identifiable.

(f) **Third Party Providers.** Our products and services may include data and software from third parties. Some third party providers require Moderately AI to pass additional terms through to you. The third party providers change their terms occasionally and new third party providers are added from time to time. You agree to comply with all applicable third party terms. 

(g) **Limitations.** Unless otherwise expressly permitted in the Agreement, you may not: (i) sell, sublicense, distribute, display, store, copy, modify, decompile or disassemble, reverse engineer, translate or transfer our property in whole or in part, or as a component of any other product, service or material; (ii) use our property or our third party providers’ property to create any derivative works or competitive products; or (iii) allow any third parties to access, use or benefit from our property in any way. Exercising legal rights that cannot be limited by agreement is not precluded. Except as expressly set forth in this Agreement we retain all rights, and you are granted no rights in or to our products, services, software and data.

(h) **Monthly Storage Limitations.** Moderately AI has the following monthly storage limitations: (i) one (1) Terabyte of Data Egress; and (ii) 1 Terabyte of Uploaded Files. You agree you will not exceed the monthly storage limitations set out by Moderately AI.  If you do exceed the monthly storage limitations outlined herein, Moderately AI may in its sole discretion, assess additional charges, suspend the performance of your monthly subscription, or terminate your subscription. In the event Moderately AI takes corrective action, you will not be entitled to a refund of any pre-paid fees. 

(i) **Services.** We will provide the services using reasonable skill and care. The professional services applicable to your Agreement, if any, are described in the ordering document or a statement of work. 

(j) **Security.** You will use and will require any third party data processors to use industry standard organizational, administrative, physical and technical safeguards to protect the other’s information. 

**2. INFORMATION SERVICES**

(a) **License.** In the ordinary course of your business and for your internal business purposes only, you may view, use, download and print data from our information services for individual use.

(b) **Distribution.** You may distribute the data to: (i) authorized users; (ii) government and regulatory authorities, if specifically requested; and (iii) third party advisors, limited to the extent required to advise you and provided they are not competitors of Moderately AI. 

**3. SOFTWARE**

(a) **License.** You may install and use our software and data only for your own internal business purposes. Software licenses include updates and at the sole discretion of Moderately AI may include new releases with updated features or additional functionality. We may provide additional terms to detail your permitted installations, users, locations, the specified operating environment and other permissions. You may make necessary copies of our software only for backup and archive purposes

(b) **Delivery.** We deliver our software by providing you with online access to it. When you access our hosted software, you are accepting it for use as specified herein.

**4. CHARGES** 

(a) **Monthly** **Fees & Subscription.**  Monthly Fees and subscription packages are selected and purchased on [www.moderately.ai](http://www.moderately.ai)

(b) **Automatic Charging.** You agree to automatic charging of all fees due to Moderately AI under your billing account. You authorize Moderately AI to automatically charge fees under your order to the credit or debit card account provided.  Such charges may include one-time and other periodic charges under your Agreement(s) with Moderately AI.  This authorization will remain in effect until you cease using Moderately AI’s programs, services, software and data.

(c) **Changes.** Except as otherwise specifically stated, Moderately AI may change the charges for our products, services, software and data in our sole discretion by providing at least 30 days’ notice. If we increase the fees by more than the greater of fifteen (15%) you may terminate the Agreement by notifying Moderately AI within thirty (30) days of the notice of increase.

**5. PRIVACY**

(a) **Access to Personal Information.**  Each of us will at all times process and disclose personal information received as a result of this Agreement in accordance with applicable law.  Each of us will use reasonable efforts to assist one another in relation to the investigation and remedy of any claim, allegation, action, suit, proceeding or litigation with respect to the unauthorized or unlawful destruction, loss, alteration, disclosure or access to personal information. You acknowledge and agree to the transfer and processing of personal information in the geographical regions necessary for Moderately AI to fulfill our obligations. 

(b) **Protection and Retention of Personal Information.** We protect your information by using technical and administrative security measures (such as firewalls, data encryption, and physical and administrative access controls to the data and servers) that limit the risk of loss, abuse, and unauthorised access, disclosure, and alteration.  We retain personal information no longer than is legally permissible and delete personal information when it is no longer necessary for the purposes as described above.

**6. INTELLECTUAL PROPERTY**

Moderately AI is and shall be the sole and exclusive owner of all right, title and interest throughout the world for their programs, services, software and data (collectively, the "Deliverables") including all patents, copyrights, trademarks, trade secrets and other intellectual property rights (collectively "Intellectual Property Rights") therein.  Moderately AI irrevocably assigns to you all rights, title and interest throughout the world in and to the Deliverables, including all Intellectual Property Rights therein. 

**7. CONFIDENTIALITY**

Confidential information received from each other will not be disclosed to anyone else except to the extent required by law or as permitted as stated herein. If a court or government agency orders either of us to disclose the confidential information of the other, the other will be promptly notified so that an appropriate protective order or other remedy can be obtained unless the court or government agency prohibits prior notification. This paragraph shall survive three (3) years after the termination of your subscription or until the information is no longer deemed confidential under applicable law, whichever occurs first.

**8. WARRANTIES AND DISCLAIMERS**

The warranties in this section are the exclusive warranties from Moderately AI and exclude all other warranties, conditions or other terms (express or implied), including warranties of performance, merchantability, non-infringement, suitability, fitness for a particular purpose, accuracy, completeness and currentness. In entering into this Agreement, neither party has relied upon any statement, representation, warranty or agreement of the other party except for those expressly contained in this agreement.

(a) **Exclusion of warranties.** To the fullest extent permissible under applicable laws, we do not warrant or represent or include any other term that the products, services, software and data will be delivered free of any inaccuracies, interruptions, delays, omissions or errors, or that any of these will be corrected.

(b) **Information.** Our information products are provided “as is” without any warranty, condition or any other term of any kind.

(c) **Disclaimer.** You are solely responsible for the preparation, content, accuracy and review of any documents, data, or output prepared or resulting from the use of any products or services and for any decisions made or actions taken based on the data contained in or generated by the products or services. In no event shall we or our third party providers be liable for any amounts imposed by any governmental or regulatory authority.

(d) **No advice.** We are not providing financial, tax and accounting, legal nor any other professional advice by allowing you to access and use our products, services or data. Your decisions made in reliance on the products or services or your interpretations of our data are your own for which you have full responsibility.

**9. LIMITATION OF LIABILITY**

(a) **Limitation.** Each party’s liability in any calendar year for damages arising out of or in connection with the agreement, including for negligence, will not exceed the amount you paid in the prior 12 months that is the subject of the claim for damages. Neither party is liable to the other for indirect, incidental, punitive, special or consequential damages, or for loss of data, or loss of profits (in either case, whether direct or indirect) even if such damages or losses could have been foreseen or prevented.

(b) **Unlimited Liability.** Section 9(a) does not limit either party’s’ liability for (i) fraud, fraudulent misrepresentation, willful misconduct, or conduct that demonstrates reckless disregard for the rights of others; (ii) negligence causing death or personal injury; or (iii) infringement of intellectual property rights. Section 9(a) does not limit your liability in relation to Section 9(d) or for claims for reimbursement arising thereunder; or to pay our charges and all amounts for use of the products, services, software and data that exceed the usage permissions and restrictions granted to you.

(c) **Claims Period.** Claims must be brought within 12 months after the basis for the claim becomes known to the person asserting the claim. 

(d) **Your Responsibilities.** You are responsible for (i) complying with this Agreement; (ii) proper use of our products, services, software and data in accordance with all usage instructions; (iii) uploading only legal or lawful content; (iv) adhering to the minimum recommended technical and security requirements including but not limited to implementing and maintaining proper and adequate virus or malware protection, proper and adequate backup and recovery systems and installing software updates; (v) changes you make to our product, services, software and data; (vi) your combination of our products, services, software and data or other property with any other materials; (vii) claims brought by third parties using or receiving the benefit of our products, services, software or data through you, except claims covered by Section 8(d); and (viii) claims resulting from your violation of law, or violation of our or any third party rights. 

**9.  INDEMNIFICATION.**

You will indemnify and hold harmless Moderately AI and its affiliates, subsidiaries and our and their respective officers, directors, agents and employees (each an “Indemnified Party”), from any claim made by any third party, together with any amounts payable to the third party whether in settlement or as may otherwise be awarded, and reasonable legal costs incurred by any of the Indemnified Parties, arising from or relating to your use of the products, services, software and data, any alleged violation by you of the applicable terms, and any alleged violation by you of any applicable law or regulation. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter subject to indemnification by you but doing so will not excuse your indemnity obligations.

**10. TERM AND TERMINATION.**

(a) **Term.** This Agreement will become effective when you license or use any of our products, services, software and data and will remain in effect during the monthly term or any monthly renewal term of our products or services. The monthly term and any monthly renewal terms for the products, services, software and data are described at time of your purchase. If not otherwise stated, the initial term of each product or service is one (1) month and will automatically renew for additional one-month periods, unless the monthly subscription is cancelled or terminated by either party.  You can terminate or cancel your subscription at any time during the term and will on be charged until the end of the current term.  No additional termination or cancellation fees apply.

(b) **Suspension and Deactivation.** Without prejudice to any other rights, we may at any time suspend or deactivate your right to use or access the products, services, software and data or terminate your Agreement. In such cases, you must stop using and accessing the products, services and data, and destroy any copies you may have. Charges remain payable in full during periods of suspension or limitation arising from your action or inaction.

(c) **Termination.** We may terminate all or part of the Agreement in relation to a product, service, software or data which is being discontinued. Either of us may terminate the Agreement immediately upon written notice if the other commits a material breach and fails to cure the material breach within fifteen (15) days of being notified to do so. Any failure to fully pay any amount when due under this Agreement is a material breach for this purpose. Unless we terminate for breach or insolvency, pre-paid charges will be refunded on a pro-rated basis. 

(d) **Effect of Termination.** Except to the extent we have agreed otherwise, upon termination, all your usage rights end immediately and each of us must uninstall or destroy all software of the other and, if requested, confirm this in writing. Termination of the Agreement will not (i) relieve you of your obligation to pay Moderately AI any amounts you owe up to and including the date of termination; (ii) affect other accrued rights and obligations; or (iii) terminate those parts of the Agreement that by their nature should continue.

**11. FORCE MAJEURE**

We are not liable for any damages or failure to perform our obligations under the Agreement because of circumstances beyond our reasonable control. If those circumstances cause material deficiencies in the products or services and continue for more than 30 days, either of us may terminate any affected product or service on written notice to the other.

**12. THIRD PARTY RIGHTS**

Our affiliates and third party providers benefit from our rights and remedies under the Agreement. No other third parties have any rights or remedies under the Agreement.

**13. GENERAL**

(a) **Assignment.** You may not assign, delegate or otherwise transfer the Agreement (including any of your rights or remedies) to anyone else without our prior written consent which may be withheld in our sole discretion. We may assign or otherwise transfer the Agreement (including any of our rights or remedies) in whole or in part, and will notify you of any such assignment or transfer. We may subcontract any of the services at our sole discretion. Any assignment, delegation or other transfer in contravention of this Section 13(a) is void.

(b) **Feedback.** You grant Moderately AI a perpetual, irrevocable, transferable, non-exclusive right to use any comments, suggestions, ideas or recommendations you provide related to any of our products or services in any manner and for any purpose.

(c) **Severability.** If any part of the Agreement that is not fundamental is illegal or unenforceable, it will be deemed modified to the minimum extent necessary to make it legal and enforceable. If such modification is not possible, the part will be deemed deleted. Any such modification or deletion will not affect the validity and enforceability of the remainder of the Agreement.

(d) **Agreement Compliance.** We or our professional representatives may review your compliance with the Agreement throughout the term of the Agreement. If the review reveals that you have exceeded the authorized use permitted by the Agreement, you will pay all unpaid or underpaid charges.

(e) **Governing Law.** The Agreement will be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein and each of us hereby irrevocably submits to the exclusive jurisdiction of the courts of the Province of Ontario to settle all disputes or claims arising out of or in connection with the Agreement.

(f) **Amendment.** We may amend this Agreement from time to time by giving you at least thirty (30) days’ written notice. If we make material changes that adversely affect you, you may request good faith negotiations regarding the amendments. If the amended terms are not agreed before their effective date, you may terminate the Agreement by providing written notice within five (5) business days.

(g) **Waiver.** Neither party waives their rights or remedies by delay or inaction.

`;

const TermsAndConditionsPage = () => {
  return (
    <Card style={{ overflow: 'auto', height: 'calc(100vh - 100px)' }}>
      <Typography.Title level={1}>Terms and Conditions</Typography.Title>
      <Button
        size="small"
        type="primary"
        icon={<FilePdfOutlined />}
        onClick={() => window.open(DownloadLink, '_blank')}
      >
        Download PDF
      </Button>
      <ReactMarkdown>{TERMS_AND_CONDITIONS}</ReactMarkdown>
    </Card>
  );
};

export default TermsAndConditionsPage;

// Workflow Entity
import { z } from 'zod';

import {
  commaDelimitedList,
  createPaginationRequestQuerySchema,
} from '@mai/types';

export const WorkflowSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.enum(['insights']),
  description: z.string(),
  createdAt: z.coerce.date(),
});

export type Workflow = z.infer<typeof WorkflowSchema>;

export const ListWorkflowFiltersSchema = z.object({
  workflowIds: commaDelimitedList(z.string().nanoid().array()).optional(),
});

export type ListWorkflowFilters = z.infer<typeof ListWorkflowFiltersSchema>;

export const WorkflowRunSchema = z.object({
  id: z.string(),
  workflowId: z.string(),
  teamId: z.string(),
  createdAt: z.coerce.date(),
});

export type WorkflowRun = z.infer<typeof WorkflowRunSchema>;

export const ListWorkflowsResponseSchema = z.array(WorkflowSchema);

export type ListWorkflowsResponse = z.infer<typeof ListWorkflowsResponseSchema>;

export const ListWorkflowRunsRequestSchema = z.object({
  workflowRunIds: commaDelimitedList(z.string().nanoid().array()).optional(),
  workflowIds: commaDelimitedList(z.string().nanoid().array()).optional(),
  teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
});

export type ListWorkflowRunsRequest = z.infer<
  typeof ListWorkflowRunsRequestSchema
>;

export const ListWorkflowRunsResponseSchema = z.array(WorkflowRunSchema);

export type ListWorkflowRunsResponse = z.infer<
  typeof ListWorkflowRunsResponseSchema
>;

export const CreateWorkflowRunSchema = z.object({
  workflowId: z.string().nanoid(),
  teamId: z.string().nanoid(),
});

export type CreateWorkflowRun = z.infer<typeof CreateWorkflowRunSchema>;

export const UpdateWorkflowRunSchema = z.object({
  shouldSync: z.boolean().optional(),
});

export type UpdateWorkflowRun = z.infer<typeof UpdateWorkflowRunSchema>;

export const InsightsWorkflowContextSchema = z
  .object({
    // Fetch document title and description
    documentTitle: z.string(),
    documentDescription: z.string().nullable(),

    // We need to classify the content of the document
    classifications: z.array(
      z.object({
        category: z.string(),
        reasoning: z.string(),
      }),
    ),

    // We need to have the user select from a list of available tasks
    availableTasks: z.array(
      z.object({
        label: z.string(),
        description: z.string(),
        reasoning: z.string(),
        expectedOutput: z.string(),
      }),
    ),

    // The system will generate a list of questions, any without answers need to be asked to the user
    questionsWithAnswers: z.array(
      z.object({
        question: z.string(),
        reasoning: z.string(),
        suggestedOptions: z.array(z.string()),
        answer: z.string().optional(),
      }),
    ),

    // Research reports, mapping of a topic to a report
    researchReports: z.record(z.string().nullable()),

    // The system will generate output to be shown to the user, store these for each iteration
    output: z.string(),

    // Force a regeneration of the research topics
    shouldRegenerateResearchTopics: z.boolean(),

    // Force a regeneration of the output
    shouldRegenerateOutput: z.boolean(),

    // Force a regeneration of the questions
    shouldRegenerateQuestions: z.boolean(),
  })
  .partial();

export type InsightsWorkflowContext = z.infer<
  typeof InsightsWorkflowContextSchema
>;

/**
 * Workflow Run Event
 */

const BaseWorkflowRunEventSchema = z.object({
  id: z.string(),
  workflowRunId: z.string(),
  createdAt: z.coerce.date(),
});

export const WorkflowRunEventSchema = z.union([
  // An event where a set of documents were selected
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('documents_selected'),
    eventData: z.object({
      documentIds: z.string().nanoid().array(),
    }),
  }),
  // An event where we have enriched the context of a particular document
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('document_context_enriched'),
    eventData: z.array(
      z.object({
        documentId: z.string().nanoid(),
        title: z.string(),
        description: z.string().nullable(),
        classifications: z.array(
          z.object({
            category: z.string(),
            reasoning: z.string(),
          }),
        ),
      }),
    ),
  }),
  // An event where the set of available tasks were generated
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('available_tasks_generated'),
    eventData: z.object({
      tasks: z.array(
        z.object({
          label: z.string(),
          description: z.string(),
          reasoning: z.string(),
          expectedOutput: z.string(),
        }),
      ),
    }),
  }),
  // An event where a task was selected
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('task_selected'),
    eventData: z.object({
      task: z.object({
        label: z.string(),
        description: z.string(),
        reasoning: z.string(),
        expectedOutput: z.string(),
      }),
    }),
  }),
  // An event where a question was asked
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('question_asked'),
    eventData: z.object({
      question: z.string(),
      suggestedOptions: z.array(z.string()).optional(),
    }),
  }),
  // An event where a question was answered, id attached is that of the question event
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('question_answered'),
    eventData: z.object({
      question: z.string(),
      answer: z.string(),
    }),
  }),
  // An event where a generic message/guidance message was received from the user
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('guidance_message_received'),
    eventData: z.object({
      message: z.string(),
    }),
  }),
  // An event where a research report topic was generated
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('research_topic_generated'),
    eventData: z.object({
      topics: z.string().array(),
    }),
  }),
  // An event where a research report was generated
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('research_report_generated'),
    eventData: z.object({
      topic: z.string(),
      report: z.string(),
    }),
  }),
  // An event where an output revision was generated
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('output_revision_generated'),
    eventData: z.object({
      guidance: z.string().optional(),
      output: z.string(),
    }),
  }),
  // An event where an assistant message was received
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('assistant_message_received'),
    eventData: z.object({
      message: z.string(),
    }),
  }),
  // An event where an editor task was generated
  BaseWorkflowRunEventSchema.extend({
    eventType: z.literal('editor_task_generated'),
    eventData: z.object({
      tasks: z.array(z.string()),
    }),
  }),
]);

export type WorkflowRunEvent = z.infer<typeof WorkflowRunEventSchema>;

export const ListWorkflowRunEventsRequestSchema = z
  .object({
    workflowRunEventIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    workflowRunIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    eventTypes: commaDelimitedList(z.string().array()).optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListWorkflowRunEventsRequest = z.infer<
  typeof ListWorkflowRunEventsRequestSchema
>;

/**
 * Workflow Run State
 * Computed from workflow run events
 */

export const WorkflowRunStateSchema = z.object({
  workflowRunId: z.string(),

  selectedDocumentIds: z.string().nanoid().array().optional(),

  documents: z
    .array(
      z.object({
        documentId: z.string().nanoid(),
        title: z.string(),
        description: z.string().nullable(),
        classifications: z.array(
          z.object({
            category: z.string(),
            reasoning: z.string(),
          }),
        ),
      }),
    )
    .optional(),

  availableTasks: z
    .array(
      z.object({
        label: z.string(),
        description: z.string(),
        reasoning: z.string(),
        expectedOutput: z.string(),
      }),
    )
    .optional(),

  task: z
    .object({
      label: z.string(),
      description: z.string(),
      reasoning: z.string(),
      expectedOutput: z.string(),
    })
    .optional(),

  messages: z
    .array(
      z.object({
        role: z.enum(['user', 'assistant']),
        message: z.string(),
        createdAt: z.coerce.date(),
      }),
    )
    .optional(),

  questions: z
    .array(
      z.object({
        question: z.string(),
        suggestedOptions: z.array(z.string()).optional(),
        answer: z.string().optional(),
      }),
    )
    .optional(),

  researchReports: z
    .array(
      z.object({
        topic: z.string(),
        report: z.string().optional(),
      }),
    )
    .optional(),

  outputRevision: z.string().optional(),

  editorTasks: z.array(z.string()).optional(),
});

export type WorkflowRunState = z.infer<typeof WorkflowRunStateSchema>;

/**
 * Create Workflow Run Event
 */

export const CreateWorkflowRunEventSchema = z.object({
  workflowRunId: z.string().nanoid(),
  eventType: z.enum([
    'documents_selected',
    'task_selected',
    'question_answered',
    'guidance_message_received',
  ]),
  eventData: z.unknown(),
});

export type CreateWorkflowRunEvent = z.infer<
  typeof CreateWorkflowRunEventSchema
>;

export const ListWorkflowRunEventsResponseSchema = z.array(
  WorkflowRunEventSchema,
);

export type ListWorkflowRunEventsResponse = z.infer<
  typeof ListWorkflowRunEventsResponseSchema
>;

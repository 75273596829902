import { useQuery } from '@tanstack/react-query';

import {
  type ListAnalysisRequestResultQuery,
  type ListAnalysisRequestResultResponse,
  ListAnalysisRequestResultResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useAnalysisRequestResultsQuery = (
  params: ListAnalysisRequestResultQuery,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['analysisRequestResults', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListAnalysisRequestResultResponse>(
        '/analysis-request-results',
        {
          params,
        },
      );
      const validatedData =
        ListAnalysisRequestResultResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.warn({
          message: 'Failed to fetch analysis request results',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

import { queryClient } from '@/globals';
import { useQuery } from '@tanstack/react-query';

import {
  type Conversation,
  ConversationSchema,
  type CreateConversationBody,
  type ListConversationMessagesResponse,
  ListConversationMessagesResponseSchema,
  type ListConversationsQuery,
  type ListConversationsResponse,
  ListConversationsResponseSchema,
  type UpdateConversationBody,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useFetchConversation = (conversationId?: string) => {
  return useQuery({
    queryKey: ['conversation', { conversationId }],
    queryFn: async () => {
      const { data } = await apiClient.get<Conversation>(
        `/conversations/${conversationId}`,
      );
      const validatedData = ConversationSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      logger.warn({
        message: 'Failed to validate conversation schema',
        error: validatedData.error,
      });
      return data;
    },
    enabled: !!conversationId,
  });
};

export const useFetchConversationMessages = (conversationId?: string) => {
  return useQuery({
    queryKey: ['conversationMessages', { conversationId }],
    queryFn: async () => {
      const { data } = await apiClient.get<ListConversationMessagesResponse>(
        `/conversations/${conversationId}/messages`,
      );
      const validatedData =
        ListConversationMessagesResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      logger.warn({
        message: 'Failed to validate conversation messages schema',
        error: validatedData.error,
      });
      return data;
    },
    enabled: !!conversationId,
  });
};

export const useFetchConversations = (
  params: ListConversationsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['conversations', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListConversationsResponse>(
        '/conversations',
        {
          params,
        },
      );
      const validatedData = ListConversationsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      logger.warn({
        message: 'Failed to validate conversations schema',
        error: validatedData.error,
      });
      return data;
    },
    enabled,
  });
};

export const createConversation = async (body: CreateConversationBody) => {
  const { data } = await apiClient.post<Conversation>('/conversations', body);
  await queryClient.invalidateQueries({ queryKey: ['conversations'] });
  const validatedData = ConversationSchema.safeParse(data);
  if (validatedData.success) {
    return validatedData.data;
  }
  logger.warn({
    message: 'Failed to validate conversation schema',
    error: validatedData.error,
  });
  return data;
};

export const updateConversation = async (
  conversationId: string,
  body: UpdateConversationBody,
) => {
  const { data } = await apiClient.patch<Conversation>(
    `/conversations/${conversationId}`,
    body,
  );
  const validatedData = ConversationSchema.safeParse(data);
  if (validatedData.success) {
    return validatedData.data;
  }
  logger.warn({
    message: 'Failed to validate conversation schema',
    error: validatedData.error,
  });
  return data;
};

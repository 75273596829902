import App from 'antd/es/app';
import Divider from 'antd/es/divider';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';
import { useState } from 'react';

import { CreateTeamCreditUsageLimitRequestSchema } from '@mai/types';

import CenterSpin from '@components/CenterSpin';
import FormSubmitButton from '@components/FormSubmitButton';
import {
  createTeamCreditUsageLimit,
  useTeamCreditUsageLimitQuery,
} from '@queries/team-credit-usage-limits';
import { useCurrentTeamSubscriptionQuery } from '@queries/team-subscriptions';
import { formatMoney } from '@utils/money';

const useTeamCreditProperties = (teamId: string) => {
  const teamCreditUsageLimitQuery = useTeamCreditUsageLimitQuery(teamId);
  const teamCreditUsageLimit = teamCreditUsageLimitQuery.data;

  const currentTeamSubscriptionQuery = useCurrentTeamSubscriptionQuery(teamId);
  const currentTeamSubscription = currentTeamSubscriptionQuery.data;

  if (
    teamCreditUsageLimitQuery.isLoading ||
    currentTeamSubscriptionQuery.isLoading
  ) {
    return null;
  }

  if (!currentTeamSubscription) {
    return {
      costPerCredit: null,
      currency: null,
    };
  }

  if (currentTeamSubscription.stripeProperties.type !== 'usage-based') {
    return {
      costPerCredit: null,
      currency: null,
    };
  }

  const tiers = currentTeamSubscription.stripeProperties.tiers.sort(
    (a, b) =>
      (a.upTo ?? Number.MAX_SAFE_INTEGER) - (b.upTo ?? Number.MAX_SAFE_INTEGER),
  );

  return {
    creditUsageLimit: teamCreditUsageLimit?.value ?? null,
    costPerCredit: tiers[1]?.unitAmount ?? null,
    currency: currentTeamSubscription?.stripeProperties.currency ?? null,
    freeCredits: tiers[0]?.upTo ?? null,
  };
};

type EditTeamCreditUsageLimitsFormData = {
  value: number;
};

const EditTeamCreditUsageLimitsForm = ({
  teamId,
  onFinish,
}: {
  teamId: string;
  onFinish: () => void;
}) => {
  const { message } = App.useApp();
  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm<EditTeamCreditUsageLimitsFormData>();
  const formData = Form.useWatch([], form);

  const currentCreditUsageLimit = useTeamCreditUsageLimitQuery(teamId);
  const currentTeamSubscription = useCurrentTeamSubscriptionQuery(teamId);
  const properties = useTeamCreditProperties(teamId);

  if (
    currentCreditUsageLimit.isLoading ||
    !currentCreditUsageLimit.data ||
    currentTeamSubscription.isLoading ||
    !currentTeamSubscription.data ||
    !properties ||
    !properties.currency
  ) {
    return <CenterSpin />;
  }

  const { costPerCredit, currency, freeCredits } = properties;

  const cost =
    Math.ceil(
      Math.max(
        ((formData?.value ?? 0) - (freeCredits ?? 0)) * (costPerCredit ?? 0),
        0,
      ),
    ) / 100;

  return (
    <Form
      form={form}
      onFinish={async (values) => {
        const validatedData = CreateTeamCreditUsageLimitRequestSchema.parse({
          ...values,
          teamId,
        });
        const loadingMessage = message.loading(
          'Updating credit usage limit...',
        );
        try {
          setIsSaving(true);
          await createTeamCreditUsageLimit(validatedData);
          loadingMessage();
          void message.success('Credit usage limit updated successfully');
          onFinish();
        } catch (error) {
          loadingMessage();
          void message.error('Failed to update credit usage limit');
        } finally {
          setIsSaving(false);
        }
      }}
      layout="horizontal"
    >
      <Form.Item
        name="value"
        label="On-Demand Credit Limit"
        initialValue={currentCreditUsageLimit.data.value ?? 0}
      >
        <InputNumber min={0} max={1000000} />
      </Form.Item>
      <Divider />
      <Form.Item>
        <div>
          <Typography.Text type="secondary">
            Included in your plan: {freeCredits} credits
          </Typography.Text>
        </div>
        <div>
          <Typography.Text type="secondary">
            On-Demand Spend Limit: {formatMoney(cost, currency)}
          </Typography.Text>
        </div>
      </Form.Item>
      <FormSubmitButton text="Save" loading={isSaving} />
    </Form>
  );
};

export default EditTeamCreditUsageLimitsForm;

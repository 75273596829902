import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import Skeleton from 'antd/es/skeleton';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import startCase from 'lodash.startcase';

import { type AnalysisRequest } from '@mai/types';

import { useAnalysisRequestStatus } from './hooks';

const AnalysisRequestStatus = ({
  analysisRequest,
}: {
  analysisRequest: AnalysisRequest;
}) => {
  const analysisRequestStatus = useAnalysisRequestStatus({ analysisRequest });

  if (analysisRequestStatus === null) {
    return (
      <Skeleton.Button
        active
        style={{
          height: '24px',
          width: '100px',
        }}
      />
    );
  }

  const { status, occurredAt } = analysisRequestStatus;

  const icon =
    status === 'running' ? (
      <SyncOutlined spin />
    ) : status === 'completed' ? (
      <CheckCircleOutlined />
    ) : status === 'failed' ? (
      <DeleteOutlined />
    ) : (
      <QuestionCircleOutlined />
    );

  const color =
    status === 'running'
      ? 'blue'
      : status === 'completed'
        ? 'green'
        : status === 'failed'
          ? 'red'
          : 'purple';

  const tooltipTitle = (() => {
    if (status === 'running') {
      return 'Analysis is running';
    }
    if (status === 'completed') {
      if (occurredAt) {
        return `Analysis completed at ${occurredAt.toLocaleString()}`;
      }
      return 'Analysis completed';
    }
    if (status === 'failed') {
      if (occurredAt) {
        return `Analysis failed at ${occurredAt.toLocaleString()}`;
      }
      return 'Analysis failed';
    }
    if (status === 'cancelled') {
      if (occurredAt) {
        return `Analysis cancelled at ${occurredAt.toLocaleString()}`;
      }
      return 'Analysis cancelled';
    }
    return 'Analysis not started';
  })();

  return (
    <Tooltip title={tooltipTitle}>
      <Tag
        color={color}
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {icon}
          {startCase(status)}
        </div>
      </Tag>
    </Tooltip>
  );
};

export default AnalysisRequestStatus;

import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import { type ColumnType } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { Link, useSearchParams } from 'react-router-dom';

import Table from '@components/Table';
import TableHeader from '@components/TableHeader';
import { useFetchUsers } from '@queries/users';
import { renderDate } from '@utils/dates';

type UserRow = {
  userId: string;
  name: string;
  createdAt: Date;
};

const UsersTableHeader = ({
  dataSource,
  pageSize,
  page,
  isLoading,
}: {
  dataSource: UserRow[];
  pageSize: number;
  page: number;
  isLoading: boolean;
}) => {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  return (
    <TableHeader>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
          }}
        >
          <Select
            options={[
              { label: '10', value: '10' },
              { label: '25', value: '25' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
            size="small"
            value={pageSize}
            suffixIcon={<>/ Page</>}
            onChange={(value) => {
              setSearchParams((prev) => {
                prev.set('pageSize', value.toString());
                return prev;
              });
            }}
            style={{
              width: '5rem',
            }}
          />
          <Tooltip title="Previous Page">
            <Button
              size="small"
              disabled={page === 1}
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setSearchParams((prev) => {
                  prev.set('page', (page - 1).toString());
                  return prev;
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Next Page">
            <Button
              size="small"
              disabled={dataSource.length < pageSize}
              icon={<ArrowRightOutlined />}
              onClick={() => {
                setSearchParams((prev) => {
                  prev.set('page', (page + 1).toString());
                  return prev;
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Refresh">
            <Button
              size="small"
              icon={<SyncOutlined spin={isLoading} />}
              onClick={() => {
                void queryClient.invalidateQueries({
                  queryKey: ['users'],
                });
              }}
            />
          </Tooltip>
        </div>
      </div>
    </TableHeader>
  );
};

const UsersTable = ({
  size = 'middle',
}: {
  size?: 'large' | 'middle' | 'small';
}) => {
  const [searchParams] = useSearchParams();
  const pageSize = Number.parseInt(searchParams.get('pageSize') ?? '50', 10);
  const page = Number.parseInt(searchParams.get('page') ?? '1', 10);

  const usersQuery = useFetchUsers(
    {
      pageSize,
      page,
    },
    true,
  );

  const columns: ColumnType<UserRow>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => {
        return <Link to={`/profile/${row.userId}`}>{name}</Link>;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => renderDate(createdAt),
    },
  ];

  const dataSource = (usersQuery.data ?? []).map((user) => ({
    userId: user.id,
    name: user.name,
    createdAt: user.createdAt,
  }));

  return (
    <>
      <UsersTableHeader
        dataSource={dataSource}
        pageSize={pageSize}
        page={page}
        isLoading={usersQuery.isLoading}
      />
      <Table
        loading={usersQuery.isLoading}
        columns={columns}
        size={size}
        dataSource={dataSource}
        rowKey={(row) => row.userId}
        pagination={false}
      />
    </>
  );
};

export default UsersTable;

import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import Descriptions from 'antd/es/descriptions';

import { useFetchCapabilities } from '@utils/ml';

const Capabilities = () => {
  const capabilitiesQuery = useFetchCapabilities();

  return (
    <Descriptions column={2} bordered>
      {Object.entries(capabilitiesQuery.data ?? {}).map(([key, value]) => (
        <Descriptions.Item key={key} label={key}>
          {value ? (
            <CheckCircleOutlined
              style={{
                color: 'green',
              }}
            />
          ) : (
            <CloseCircleOutlined color="red" />
          )}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default Capabilities;

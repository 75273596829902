import { queryClient } from '@/globals';
import App from 'antd/es/app';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { AxiosError } from 'axios';
import { useState } from 'react';

import {
  type Project,
  type UpdateProject,
  UpdateProjectSchema,
} from '@mai/types';

import FormSubmitButton from '@components/FormSubmitButton';
import { apiClient } from '@queries/index';
import { logger } from '@utils/logger';

type ProjectFormData =
  | Partial<{
      name: string;
      description: string;
    }>
  | undefined;

const EditProjectForm = ({
  project,
  onSuccess,
}: {
  project: Project;
  onSuccess: (data: Project) => void;
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm<ProjectFormData>();
  const [isCreating, setIsCreating] = useState(false);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values) => {
        const name = values?.name;
        const description = values?.description ?? null;
        if (!name) {
          return;
        }

        const loadingMessage = message.loading('Updating project...');
        setIsCreating(true);
        try {
          const response = await apiClient.patch<Project>(
            `/projects/${project.id}`,
            UpdateProjectSchema.parse({
              name,
              description,
            } satisfies UpdateProject),
          );
          await queryClient.invalidateQueries({
            queryKey: ['projects'],
          });
          loadingMessage();
          void message.success('Project updated successfully');
          onSuccess(response.data);
        } catch (error) {
          loadingMessage();
          let errorMessage = 'Failed to update project';
          if (error instanceof AxiosError) {
            errorMessage = error.response?.data?.message ?? errorMessage;
          }
          void message.error(errorMessage);
          logger.warn({
            message: 'Failed to update project',
            error,
            projectId: project.id,
          });
        } finally {
          setIsCreating(false);
        }
      }}
    >
      <Form.Item
        name="name"
        label="Project Name"
        rules={[{ required: true }]}
        initialValue={project.name}
      >
        <Input placeholder="Project Name" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        initialValue={project.description}
      >
        <Input placeholder="Project Description" />
      </Form.Item>
      <Form.Item>
        <FormSubmitButton text="Save" loading={isCreating} />
      </Form.Item>
    </Form>
  );
};

export default EditProjectForm;

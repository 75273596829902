import Button from 'antd/es/button';
import Table, { type ColumnType } from 'antd/es/table';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  createAnalysisRequest,
  useAnalysisRequestsQuery,
} from '@queries/analysis-requests';
import {
  createConversation,
  useFetchConversations,
} from '@queries/conversations';
import { useDocumentsQuery } from '@queries/documents';
import { useSearchRequestsQuery } from '@queries/search-requests';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { track } from '@utils/mixpanel';
import { useTeamId } from '@utils/router';

const Container = styled.div<{ $background: string }>`
  display: flex;
  flex-direction: column;
  background: ${({ $background }) => $background};
  border-radius: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
`;

const TableContainer = styled.div<{ $background: string }>`
  border-radius: 0.5rem;
  background: ${({ $background }) => $background};
  padding: 1rem;
`;

const useHasUploadedDocument = () => {
  const teamId = useTeamId();
  const documentsQuery = useDocumentsQuery(
    { teamIds: teamId ? [teamId] : [] },
    !!teamId,
  );

  if (!teamId) {
    return false;
  }

  if (documentsQuery.isLoading) {
    return null;
  }

  return (documentsQuery.data?.length ?? 0) > 0;
};

const useHasTalkedWithAssistant = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();

  const conversationsQuery = useFetchConversations({
    teamIds: teamId ? [teamId] : [],
    createdBy: sessionUserId ?? undefined,
  });
  const conversations = conversationsQuery.data ?? [];

  if (!teamId || !sessionUserId) {
    return false;
  }

  if (conversationsQuery.isLoading) {
    return null;
  }

  return conversations.length > 0;
};

const useHasRunSearch = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();

  const searchRequestsQuery = useSearchRequestsQuery(
    {
      teamIds: teamId ? [teamId] : undefined,
      createdBy: sessionUserId ?? undefined,
      pageSize: 1,
    },
    !!teamId && !!sessionUserId,
  );

  if (!teamId) {
    return false;
  }

  if (!sessionUserId || searchRequestsQuery.isLoading) {
    return null;
  }

  return (searchRequestsQuery.data?.length ?? 0) > 0;
};

const useHasCreatedAnalysis = () => {
  const teamId = useTeamId();
  const sessionUserId = useSessionUserId();
  const analysisRequestsQuery = useAnalysisRequestsQuery(
    {
      teamIds: teamId ? [teamId] : undefined,
      createdBy: sessionUserId ?? undefined,
    },
    !!teamId && !!sessionUserId,
  );

  if (!teamId) {
    return false;
  }

  if (!sessionUserId || analysisRequestsQuery.isLoading) {
    return null;
  }

  return (analysisRequestsQuery.data?.length ?? 0) > 0;
};

const OnboardingChecklist = () => {
  const { token } = theme.useToken();
  const sessionUserId = useSessionUserId();
  const navigate = useNavigate();
  const teamId = useTeamId();
  const [isSubmitting, setIsSubmitting] = useState<string | null>(null);
  const teamIsActive = useTeamIsActive();

  const dataSource: {
    key: string;
    label: string;
    isComplete: boolean | null;
    onClick: () => void;
  }[] = [
    {
      key: '1',
      label: 'Upload a document',
      isComplete: useHasUploadedDocument(),
      onClick: () => {
        if (!teamId) return;
        track('CLICKED_ONBOARDING_CHECKLIST_ITEM', {
          sessionUserId,
          teamId,
          metadata: {
            item: 'document',
          },
        });
        navigate(`/team/${teamId}/documents/new`);
      },
    },
    {
      key: '2',
      label: 'Talk with the assistant',
      isComplete: useHasTalkedWithAssistant(),
      onClick: async () => {
        if (!teamId) return;
        const conversation = await createConversation({
          name: 'Your First Conversation',
          teamId,
          projectId: null,
          projectAssistantId: null,
        });
        track('CLICKED_ONBOARDING_CHECKLIST_ITEM', {
          sessionUserId,
          teamId,
          metadata: {
            item: 'assistant',
          },
        });
        navigate(`/team/${teamId}/assistant/${conversation.id}`);
      },
    },
    {
      key: '3',
      label: 'Run your first search',
      isComplete: useHasRunSearch(),
      onClick: () => {
        if (!teamId) return;
        track('CLICKED_ONBOARDING_CHECKLIST_ITEM', {
          sessionUserId,
          teamId,
          metadata: {
            item: 'search',
          },
        });
        navigate(`/team/${teamId}/search`);
      },
    },
    {
      key: '4',
      label: 'Create your first analysis',
      isComplete: useHasCreatedAnalysis(),
      onClick: async () => {
        if (!teamId) return;
        const data = await createAnalysisRequest({
          teamId,
          name: 'Your First Analysis',
          resources: [],
          facets: [],
        });
        navigate(`/team/${teamId}/analyze/${data.id}`);
      },
    },
  ];

  const columns: ColumnType<(typeof dataSource)[0]>[] = [
    {
      title: 'Task',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <Typography.Text
          style={{
            textDecoration: record.isComplete ? 'line-through' : undefined,
          }}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      key: 'action',
      align: 'right',
      render: (record: (typeof dataSource)[0]) => {
        return (
          <Button
            size="small"
            disabled={!!record.isComplete}
            loading={isSubmitting === record.key}
            onClick={async () => {
              setIsSubmitting(record.key);
              try {
                await record.onClick();
              } finally {
                setIsSubmitting(null);
              }
            }}
          >
            {record.isComplete ? 'Completed' : 'Start'}
          </Button>
        );
      },
    },
  ];

  const content: React.ReactNode = (
    <Table
      loading={dataSource.some((record) => record.isComplete === null)}
      size="middle"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      showHeader={false}
      rowKey={(record) => record.key}
    />
  );

  if (dataSource.some((record) => record.isComplete === null)) {
    return null;
  }

  if (dataSource.every((record) => record.isComplete)) {
    return null;
  }

  if (!teamIsActive) {
    return null;
  }

  return (
    <Container $background={token.colorBgContainer}>
      <Typography.Title level={4}>Onboarding Checklist</Typography.Title>
      <TableContainer $background={token.colorBgContainer}>
        {content}
      </TableContainer>
    </Container>
  );
};

export default OnboardingChecklist;

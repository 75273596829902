import Form from 'antd/es/form';
import Select from 'antd/es/select';

import { type AnalysisRequestFacet } from '@mai/types';

const FacetTypeField = ({
  name,
  initialValue,
}: {
  name: unknown[];
  initialValue: 'date' | 'string' | 'number' | 'boolean';
}) => {
  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: true,
          message: 'Please select a type',
        },
      ]}
      style={{
        margin: 0,
      }}
    >
      <Select
        placeholder="Please select the data type"
        options={
          [
            { label: 'Date', value: 'date' },
            { label: 'Text', value: 'string' },
            { label: 'Number', value: 'number' },
            { label: 'Yes/No', value: 'boolean' },
          ] satisfies { label: string; value: AnalysisRequestFacet['type'] }[]
        }
      />
    </Form.Item>
  );
};

export default FacetTypeField;

import MenuOutlined from '@ant-design/icons/MenuOutlined';
import Button from 'antd/es/button';
import Drawer from 'antd/es/drawer';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import NavMenu from './NavMenu';

import { screenSizes } from '@utils/theme';

const StyledButton = styled(Button)`
  display: none;

  @media (max-width: ${screenSizes.mobile}px) {
    display: block !important;
  }
`;

const MenuButton = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= screenSizes.mobile) {
        setOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledButton shape="circle" onClick={() => setOpen(true)}>
        <MenuOutlined />
      </StyledButton>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <NavMenu
          isDrawer
          setDrawerOpen={setOpen}
          collapsed={false}
          setCollapsed={() => null}
        />
      </Drawer>
    </>
  );
};

export default MenuButton;

import Divider from 'antd/es/divider';
import Typography from 'antd/es/typography';

import AuthFooter from '@components/AuthFooter';
import UnauthCard from '@components/UnauthCard';

const RegisterPending = () => {
  return (
    <UnauthCard>
      <div>
        <Typography.Text>
          An email has been sent to you with a link to verify your account.
          Please check your email in order to complete the registration process.
        </Typography.Text>
      </div>
      <div
        style={{
          marginTop: '16px',
        }}
      >
        <Typography.Text type="secondary">
          {
            "If you don't receive an email within a few minutes, please check your spam folder, or contact support at "
          }
          <a href="mailto:support@moderately.ai">support@moderately.ai</a>.
        </Typography.Text>
      </div>
      <Divider />
      <AuthFooter />
    </UnauthCard>
  );
};

export default RegisterPending;

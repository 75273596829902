import Card from 'antd/es/card';
import Typography from 'antd/es/typography';

const CookiePolicyPage = () => {
  return (
    <Card>
      <Typography.Title level={1}>Cookie Policy</Typography.Title>
      <Typography.Paragraph>
        Moderately AI Inc. Cookie Policy Introduction Welcome to Moderately AI
        Inc. This Cookie Policy is designed to provide clarity on how we employ
        cookies on our website, fostering a user-friendly and transparent online
        experience. Types of Cookies Essential Cookies: Critical for basic
        website functionality. Functional Cookies: Enhance the user experience
        by remembering preferences. Analytical Cookies: Facilitate performance
        analysis for continuous improvement. Marketing Cookies: Enable targeted
        advertising and promotions. How Cookies Are Used Essential Cookies:
        Authentication and data security. Functional Cookies: Personalized user
        experience based on preferences. Analytical Cookies: Data collection for
        performance enhancement. Marketing Cookies: Delivery of personalized
        advertisements. User Consent User consent is obtained through
        interactive pop-ups, ensuring a participatory role for users in the
        decision-making process. Opt-Out Options Users have the flexibility to
        adjust cookie settings or opt-out through both browser settings and our
        website preferences. Third-Party Cookies We explicitly disclose the
        utilization of third-party cookies, providing comprehensive information
        on user control over data shared with external entities. Policy Updates
        This policy undergoes regular reviews yearly, demonstrating our
        commitment to staying abreast of emerging risks and technological
        advancements. By utilizing our website, you express consent to the
        outlined use of cookies in this policy. August 31st 2024
      </Typography.Paragraph>
    </Card>
  );
};

export default CookiePolicyPage;

import { useQuery } from '@tanstack/react-query';

import {
  type ListAnalysisRequestRunQuery,
  type ListAnalysisRequestRunResponse,
  ListAnalysisRequestRunResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useAnalysisRequestRunsQuery = (
  params: ListAnalysisRequestRunQuery,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['analysisRequestRuns', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListAnalysisRequestRunResponse>(
        '/analysis-request-runs',
        {
          params,
        },
      );
      const validatedData =
        ListAnalysisRequestRunResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.warn({
          message: 'Failed to fetch analysis request runs',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import { type ReactNode } from 'react';

import ContentContainer from '@components/ContentContainer';

const UnauthenticatedLayout = ({ children }: { children: ReactNode }) => {
  const {
    token: { colorBgContainer, colorFillSecondary },
  } = theme.useToken();

  return (
    <Layout
      style={{
        position: 'relative',
        height: '100vh',
        width: '100vw',
      }}
    >
      <div className="custom-shape-divider-bottom-1719411069">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            style={{
              fill: colorFillSecondary,
            }}
          ></path>
        </svg>
      </div>
      <div
        style={{
          position: 'static',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          scrollbarWidth: 'thin',
          scrollbarColor: `grey ${colorBgContainer}`,
          overflowY: 'auto',
          zIndex: 1,
        }}
      >
        {children}
      </div>
    </Layout>
  );
};

UnauthenticatedLayout.NotFound = () => {
  return (
    <UnauthenticatedLayout>
      <ContentContainer.NotFound />
    </UnauthenticatedLayout>
  );
};

export default UnauthenticatedLayout;

import { z } from 'zod';

export const CreateStripeCheckoutSessionBodySchema = z.object({
  teamId: z.string(),
  stripeProductId: z.string(),
  seats: z.number(),
});

export type CreateStripeCheckoutSessionBody = z.infer<
  typeof CreateStripeCheckoutSessionBodySchema
>;

export const CreateStripeCheckoutSessionResponseSchema = z.object({
  checkoutSessionUrl: z.string(),
});

export type CreateStripeCheckoutSessionResponse = z.infer<
  typeof CreateStripeCheckoutSessionResponseSchema
>;

export const ListProductsResponseSchema = z.array(
  z.object({
    stripeProductId: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    prices: z.array(
      z.union([
        z.object({
          type: z.literal('unit'),
          pricePerUnit: z.number(),
          name: z.string(),
          currency: z.string(),
        }),
        z.object({
          type: z.literal('tiered'),
          tiers: z.array(
            z.object({
              unitAmount: z.number().nullable(),
              upTo: z.number().nullable(),
            }),
          ),
          currency: z.string(),
        }),
      ]),
    ),
  }),
);

export type ListProductsResponse = z.infer<typeof ListProductsResponseSchema>;

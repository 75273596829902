import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const TeamUserSchema = z.object({
  teamId: z.string(),
  userId: z.string(),
  role: z.enum(['ADMIN', 'MEMBER']),
  createdAt: z.coerce.date(),
});

export const ListTeamUsersRequestSchema = z.object({
  teamIds: commaDelimitedList().optional(),
  userIds: commaDelimitedList().optional(),
  roles: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.enum(['ADMIN', 'MEMBER'])))
    .optional(),
});

export type ListTeamUsersRequest = z.infer<typeof ListTeamUsersRequestSchema>;

export const ListTeamUsersResponseSchema = z.array(TeamUserSchema);

export type ListTeamUsersResponse = z.infer<typeof ListTeamUsersResponseSchema>;

export const CreateTeamUserRequestSchema = z.object({
  teamId: z.string(),
  userId: z.string(),
  role: z.enum(['ADMIN', 'MEMBER']),
});

export const CreateTeamUserResponseSchema = TeamUserSchema;

import App from 'antd/es/app';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { type CreateTeamRequest, TeamDetailsSchema } from '@mai/types';

import FormSubmitButton from '@components/FormSubmitButton';
import { apiClient } from '@queries/index';
import { useSessionUserId } from '@utils/auth';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

type FormData = Partial<{
  companyName: string;
}> | null;

const CreateTeamForm = ({
  afterFinish,
}: {
  afterFinish: (teamId: string) => void;
}) => {
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const sessionUserId = useSessionUserId();
  const { message } = App.useApp();

  const onFinish = async (values: FormData) => {
    setIsLoading(true);
    try {
      const companyName = values?.companyName;
      if (!companyName) {
        throw new Error('Please enter your team name');
      }
      const { data } = await apiClient.post('teams', {
        name: companyName,
      } satisfies CreateTeamRequest);
      const validatedData = TeamDetailsSchema.parse(data);
      void message.success('Team created successfully');
      track('CREATED_TEAM', {
        teamId: validatedData.id,
        sessionUserId,
      });
      afterFinish(validatedData.id);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 400) {
          const errorMessage = e.response?.data?.errors?.[0].message;
          void message.error(errorMessage);
        } else {
          void message.error('An error occurred. Please try again.');
          logger.error({
            message: 'Failed to create team',
            error: e,
          });
        }
      } else {
        void message.error('An error occurred. Please try again.');
        logger.error({
          message: 'Failed to create team',
          error: e,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Team Name"
        name="companyName"
        rules={[
          {
            required: true,
            message: 'Please enter your team name',
          },
        ]}
      >
        <Input placeholder="Please enter the name of your team" />
      </Form.Item>
      <FormSubmitButton loading={isLoading} text="Create Team" />
    </Form>
  );
};

export default CreateTeamForm;
